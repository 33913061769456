import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../Library/JSFUNC.js";

import AdminMobx from "../CaptureExecReactMobxPairs/Admin/AdminMobx.js";
import AdminImportMobx from "../CaptureExecReactMobxPairs/AdminImport/AdminImportMobx.js";
import AdminIntegrationsMobx from "../CaptureExecReactMobxPairs/AdminIntegrations/AdminIntegrationsMobx.js";
import CaptureExecMobx from "../CaptureExecReactMobxPairs/CaptureExec/CaptureExecMobx.js";
import CapturesMobx from "../CaptureExecReactMobxPairs/Captures/CapturesMobx.js";
import ContactsMobx from "../CaptureExecReactMobxPairs/Contacts/ContactsMobx.js";
import DivexecMobx from "../CaptureExecReactMobxPairs/Divexec/DivexecMobx.js";
import GCSSMobx from "../CaptureExecReactMobxPairs/GCSS/GCSSMobx.js";
import OpenCaptureMobx from "../CaptureExecReactMobxPairs/OpenCapture/OpenCaptureMobx.js";
import RightPanelMobx from "../CaptureExecReactMobxPairs/RightPanel/RightPanelMobx.js";
import SamGovTrackerMobx from "../CaptureExecReactMobxPairs/SamGovTracker/SamGovTrackerMobx.js";
import TeammateContractsMobx from "../CaptureExecReactMobxPairs/TeammateContracts/TeammateContractsMobx.js";

import DatabaseMobx from "./DatabaseMobx";
import UserMobx from "./UserMobx";
/* $$$$$ WHEN CODING IN OpenCaptureReact, ALWAYS REMEMBER UserMobx.c_userCanEditCaptureCardContentTF FOR NEW NON-ADMIN EDITABLE ITEMS $$$$$
=== metrics | propmanagement | AI | USAspending | GovTribe | AWS govcloud ===
*********done*********
<bug> CE email new button at bottom is fuzzy picture, change to html button
<Skip bug> banners - if style is a preset, banner does not use it
<bug CHENEGA_SSBU Bryan 4/3/25> make mass update VP (like GCSS merge) work all at once in php loop to avoid deadlocks
<capture changelog card now has loading animation when loading>
{I3 Chris 5/23/23, APERIO 10/30/23} add filter search bar above GCSS results
============= micro release 4/7/25 =============
**********************


{CHENEGA_SSBU Bryan 1/9/25,4/3/25} GCSS GovWin for Procurement Comment field ('Latest Analyst Update') - every time it changes, stack it with the old value to create a history in the textarea (GovWin Link OPP207735)
- show capture changelog with 'procurement' as filter field
{AGEISS Brian 10/23/24 video call} and {CHENEGA_SSBU Bryan 4/3/25} GCSS overwrite - control for every GCSS field overwrite or not, plus a 2nd switch to send email/notification per field change (prevent tons of notifications about every useless change)
- ask dad full setting for admin, or per division/capture type?



{CULMEN Sydney 3/27/25 Natalie email} new divexec tab in Stage Flow to load full changelog of stage changes
{DYNAMO Tomas 1/13/25} Contracts Exec - new tab Metrics
- can pick custom filter or division&contractmanager (both multiselects) to filter captures
- table that has rows for every type of contract, calc days between each status per capture/contract, also 'total time to completion' (can expand each row to show all capture/contracts that make up that row with their # days)
- monthly # contracts processed (by contract type, and separate table by who)
- also export all tbls to csv


{QED Angie 2/21/24 [skip email]} Admin 7.DDF - create new field (multiselect) - pick Contact Company Capabilities - add field to Details card - select during edit says '--No tbl_a_capabilities Selected--'
- tbl_a_business_types, tbl_a_capabilities





{Skip 10/7/24 call} GovWin GCSS search, run another pass with bad 12h token cleared if a search fails for the first time
+++++++++++++++ php/general_functions - ce_record_error_in_db() 2024-10-07 18:12:15 UTC [BITDEMO: Skip Blackburn (ID 2)]+++++++++++++
xhr database_api.php: (i_api: gcssSearchOppsFromDataSource, i_cc:BITDEMO): 
 - general_functions.php gcss_govwin_api_fetch_opps_arrayOfObjs_or_false_from_oauth_access_and_search_params_obj_or_govwin_ids_array(): 
 - general_functions.php gcss_govwin_api_fetch_json_string_from_oauth_access_json_string_and_web_address(): [errorMessage: gcssApiFailed2] failed to access GovWin API web address to download data (i_govWinOauthAccessJsonString: {"access_token":"154f454c-31a4-447e-a4f5-d58bfb7f1b5e","token_type":"bearer","refresh_token":"23611ef9-ee02-48f4-a6f4-e2397cd40c1a","expires_in":1199,"scope":"read"}, i_govWinApiWebAddress: https://services.govwin.com/neo-ws/opportunities?max=50&offset=0&sort=relevancy&order=desc&q=usaf&responseDateFrom=2026-10-07&responseDateTo=3000-01-01&oppSelectionDateFrom=1900-01-01)
 - general_functions.php ce_record_error_in_db(): 
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


new button Admin Divisions "I can't find my UEI", form to send an email to support@bit

{AGEISS Brian 10/23/24 video call}
- contacts - link to company FPDS record, also 'import from SAM entities' (or 'SBA profile') feature linked based on UEI number
- GCSS - FPDS (and identical USASpending) has wins from other companies that are soon coming up for recompete (within next 18 to 36 months), want to see those


AI - new model with no step 2 to test on new EC2 and /dev/, use new similar deal feature with 7 inputs
- need to map agency to agencyCodes, figure out if popState is 2 letters/capitalletters/etc, officeCodes (all these examples are in opportunities.pkl, can read file as an guide for each field formatting)


AI: (1. find similar, 2. ChatGPT integration)
GCSS AI - find similar - 'similar' link under posneg, also in Mode B, select for AI captures, or select for all captures in system (need pauls new 26 input code for this to work to put in custom capture data to find similar)
new tab A/B (above Run AI) for find similar to my company and find similar to 1 deal (put controls on left panel, remove pos/neg and replace company with deal Sol# when in tabB) (put A/B tabs and company picker above green Run AI button)
new link under score to 'find deals similar to this deal' on tab B (change AI score bubble color gradient for similar deal scores)
Quick Filter - AI add 2 boxes for "Pos" "Neg" (2 boxes are both encased in same border bgColor as AI controls in search result)
- AI chat: 1) describe my company, 2) summarize this currently open deal, 3) summarize how this deal is good for me
    * "summarize this deal and explain why it's relevant to my company"
    * "list out the pros and cons why this contract is a fit for my company"
[Note: CExAI - AGEISS has 9000 'old' not interested markings. These are vastly not within the 25k the AI model loads from the query to consider, thus aren't taken as actual dislikes by the model - could load the future deals, plus all not interested marked deals across all customers, then when any customer runs AI, don't show any results that have old proposal due dates or award notices]






{VAE Cathy 11/7/23 Skip meet and 1/17/24 email} PowerBI - allow admin to create a scheduled export from CE (store on our server public in 64 char scramble subfolder displayed for admin, pick a master button to do for export)
- new Admin subtab to set up batch export jobs (22. AutoReminders, 23. "Automated Exports")
- Table to create new 'jobs' columns: on/off switch, copy icon for latest file URL, download button for latest .csv [gray if never run], Job Name, Job Frequency ("Recurrence" (daily [with # days], weekly [day of week Su-Sa], monthly [1-31]), "Run Time" [0-23 int]), CE View Button, date/time last run (used for daily # days trigger), who created, date created, right down arrow shows 30 prev .csv inline rows (date run, download button), delete dots (2nd option 'run now')
- need to create new server CRON job to run every hour, check every customer on every hour to see if a job needs to be run
- save 2 of same file, one with generic name for this job that powerBI fetches every day (same name everyday), and another with a date unique name that hangs around in the CE history of files until 30 files have been created when they will be auto purged for each new one created
- each run  saved on server at /var/www/html/autoexports/JUMBLE/jobid2/export202312120900.csv   https://www.captureexec.com/autoexports/JUMBLE/jobid2/export202312120900.csv  (copy /graphics .htaccess file into /exports folder) ["Options -Indexes"]
- {{{delete needs to delete all files on server too}}}
- !!SHOWSTOPPER!!: must be triggered from JS-CE side (like from a permanently logged in user with 5sec refreshes), otherwise if called from the server in a cron, that would have to have 100,000 lines of new code in php to translate raw capture values into 'masked' display values
- one icky possibility was to just write in php like 1000 lines that do masking very rudimentary

{VAE 11/20/23 Skip a.c.b email}
1. View data could compact rows. 
2. The user capability size.
3. Add and remove criteria.
4. Filter criteria.
5. Power BI
6. Examples (Deltek & Excel)
Proposal Due Date shift w/o Time
Details fields styling and symbols (Natalie email)
Master Preset Buttons
- Pages of buttons (vertical roll of "pages" of buttons)
     o like PPT "Design" allows easy to see/find ALL preset buttons
- Button custom colors
- "System" buttons - available to add to page(s)
- In-line FILTERing edits of any cols to select "values", then save as new button or not
      Switch would add above COLs all values to pick from (Like Excel filters with lights)
- Redesigning the Preset edit system




DYNAMO special divexec projections/goals graph/tables
  ?) can this tool be released publicly or keep as a private option to DYNAMO
- new stage type to split '2-Active' into 'Active Not Included in Projections' and 'Active Included In Projections' (ask them if all or nothing is ok that all 'Capture' stage captures would be considered in projections, regardless of % progress or pwin)
- build visual graphs of quarterly goals
  1) goals (quarterly, maybe, they have them constant per year, try to use CE yearly goals for DYNAMO graph goals)
  2) backlog (wins)
  3) pipeline (new projections category of activeinclinproj/activesubmitted)
  ?) show both graph and table view? (or switch to show only graph, then show table view separate when switched?)
  ?) need to see full rollup to top level, and each individual division, do we also need capability to show combining 2 subdivisions together?
  ?) use Contract Start Date for date placement of each capture for 'projection' captures and 'win' captures (if CSD not filled out, use Added Date, or just not include?)
  ?) spread $ over PoP evenly? (costpoint data direct used makes this not needed)
  ?) Use stage colors for area graph (would have 3 colors for 3 stages in 'pipeline' area, rather than just gray, maybe an option to combine the 3 stage colors into single gray area)
- costpoint direct integration (phase II) to get 'Active Contracts' data per quarter
  ?) click button to load latest costpoint, or auto go out nightly or auto load when entering this tab?
  ?) switch to show costpoint values vs original phase I 'active contracts' of won captures in CE

1] DYNAMO creates CE 'revenue target' goals for each division 5 years into the future, split into equal 4 quarters for graph (blue graph line)
2] (Phase I) collect CE wins (like FinProj math) for 'backlog'/'active contracts' projection (red graph area)
3] get all 'Active Included In Projections' and 'Active Submitted' stage captures for 'pipeline' projection (gray graph area)



{ODY 3/26/25 Skip email} Their TA process requires CEO approval & he travels all the time.  He would.like to get an email from CE where he can see the details, then click Approve or Not approve w/ comments that he can easily do from cell.phone.



GCSS Opp - show predictive competitive analysis, autofill incumbent, show current 'burn rate' to incumbent (USAspending), list of incumbent teammates
- should we try to look up unlinked OppNames and link them to SAM deals
Open 'Agency' (like 'DHS') - list top X contracts, view all incumbents (new GCSS subtab)



{CARLEY Vanessa 10/5/22} display pdf schedule as a 'homepage' for users (admin upload jpg/png, assign to multi users for start/end date range, overrides their start tab with new tab which shows picture)


import VP orange text left side from single column csv
try best to map to select field on right side option (or direct text copy if a text field)

try to get OAUTH to try a 2nd time if there's a stored token that doesn't work





USASpending:
2025-01-21 Hey Drew! here's some details on accessing the usa spending and fpds apis.  Most of this is documented somewhere on their websites but it took some time to parse through:
usa spending
Full database download: https://onevoicecrm.my.site.com/usaspending/s/database-download
API: https://api.usaspending.gov/
full list of columns: https://github.com/fedspendingtransparency/usaspending-api/blob/ee491474564f227bbf309066789588c0b55cc56b/usaspending_api/download/v2/download_column_historical_lookups.py#L25
example curl command to retrieve data for particular companies: and particular columns
curl -i -X POST -H 'Content-Type: application/json' \
    -d '{"filters": {"recipient_search_text": ["FAFGT2XJKM88", "J367SCZJNND6"]}, "columns": ["award_id_piid", "solicitation_identifier"]}' \
    https://api.usaspending.gov/api/v2/download/awards
FPDS
EZ search curl command (only retrieves certain records and you can only retrieve one company at a time)
curl -o fpds_ezsearch_results.csv \
    'https://www.fpds.gov/ezsearch/fpdsportal?s=FPDS&indexName=awardfull&templateName=CSV&q=++ULTIMATE_UEI%3A%22FAFGT2XJKM88%22'
To use the ATOM feed, the documentation was pretty confusing so i just used this existing python package: https://github.com/dherincx92/fpds
GitHubGitHub
usaspending-api/usaspending_api/download/v2/download_column_historical_lookups.py at ee491474564f227bbf309066789588c0b55cc56b · fedspendingtransparency/usaspending-api
Server application to serve U.S. federal spending data via a RESTful API - fedspendingtransparency/usaspending-api (55 kB)
https://github.com/fedspendingtransparency/usaspending-api/blob/ee491474564f227bbf309066789588c0b55cc56b/usaspending_api/download/v2/download_column_historical_lookups.py#L25

GitHubGitHub
GitHub - dherincx92/fpds: A parser for the Federal Procurement Data System (FPDS) ATOM feed
A parser for the Federal Procurement Data System (FPDS) ATOM feed - dherincx92/fpds (118 kB)


GovTribe RCG


{Skip 10/31/24} compare GovWin to SAM needs FedComp as well






{ODY Jack 10/24/24 email from Natalie}
- Contract Process: They would like to know if there is a way to send the “Contract Process requests” to go straight to the COO/executive email. He is not a Contracts user, but they want him to get the notification so he can see that there is something to be done and help with that process. This is a feature idea they said they’d very much appreciate.
- Contacts System: In their Contacts directory, is there a way to show in each contact who they’ve competed against? They want something very similar to the Teammate Rating in the Contacts System, but a view for how many times they completed against that specific company.
- [EASY] Contacts System: Within the “Teammate Rating” section, there is the "View Captures as a Teammate" button. They would like to see a is display of the CLOSED Stage of the opportunity – if it was won/lost/or no bid without needing to go into the Advance Stage Card to view.



{RCG Rich 11/5/24 email} Integrate to GovTribe via Zapier as new GCSS data source



{Skip AWS 10/21/2024 email} migrate to AWS govcloud - new CE logo in top left that has icon talking about govcloud security
AWS Gov Cloud (BAE, IBM) - get EC2/RDS/S3 onto gov cloud, set up "BITTESTGOV" and get it working for a demo to dad
 - get pricing for this setup



{Skip 9/3/24} copy divexec graphs from newcompany 1st 3 pages to system public pages options



{Skip 8/13/24} Contacts speed up
- precompute[3000] needs to be essentials only (# children c/p count, c name, team score) then only compute full when expanded (can only expand 1 org) or opened on right
- pages of 100 companies (not show all)
- new admin select option (like System Capture Name) for displaying contact legal/abbr


{AVIAN Josh 8/19/24 Natalie} Search Help (maybe open help fullscreen floating box with search bar to search all available help documents with matching text)

{PDSYS Paul 8/15/24 talk to Skip} Revenue Card - when a Sub, 'Cost' fields should be above CRV so that CRV has costs removed from it, create PRE and POST costs (different blue tables) for the math equation



{REDSKY 8/5/24} new Capture Card "Quote Builder" - Admin tbl of products (Name, Description, Admin Price) - create new quote on card, add products each with quantity, current price (copied from admin price initially), total. quote has date created/sent/expires, status - not sent, sent, accepted, declined (admin defined statuses), ship to, template [[codewords]] for every piece

{CHENEGA Benjamin Appleton 12/29/23 Skip email} left/right arrows in capture to move to next one in table
- U+293C U+293D


{GARUD Tina 9/11/23} Right panel for Notes (new rightpanellauncher button for My Notes (orange))
{Drew} right panel resize by dragging


*/
export class DevelopmentInitializeDataMobx {
  constructor() {
    makeObservable(this, {
      a_initialize_development_data: action
    });
  }

  //action methods
  a_initialize_development_data() {
    const product = "captureexec"; //"captureexec", "samgovtracker"
    const loginState = "in"; //"out", "sgtBuySgtEnterInfo", "sgtCaptureExecLearnMoreAd", "loadingCaptureExec", "onPremiseNotAuthenticated", "codeVersionMismatch", "licenseExpired", "codeUpdate", "adminPasswordChange", "forcedPasswordChange", "multiLogin", "in"
    const quickAcessMasterPresetIDSelected = 20;
    const longText = "Test of the system with a super long answer like wow this is a really long one out there yes. But no, it needs even more to see if we can get about three lines of wrap going on here yes. How much longer could this sentence last? It's really needing to take up quite a few line to become a good solid test for the system.";
    const testDate = "2023-04-15"; const testDateTimeUtc = "2023-04-15 15:15:58";
    const samParse = JSON.parse('{"samSearchResultObj":{"id":630302,"original_published_datetime_utc":"2024-08-13 18:20:00","last_updated_datetime_utc":"2024-08-13 18:20:00","proposal_due_datetime_utc":"2024-08-28 20:00:00","unique_solnum_or_url_jumble":"N0016424R3024"},"rawSamOppsHistoryMatrix":[],"gcssDetailResultErrorMessage":""}');

    //==CaptureExecMobx=====================================================
    //"Blank", "Test", "Capture Table View", "Stage View", "GovCon Smart Search", "My Performance", "My Surveys", "Contacts", "Marketing Docs", "Capture Import",
    //"Company/Divisions", "Users", "Details/Dates Fields", "Details Fields Options", "Deal Shaping Questions", "Capture Templates", "3rd Party Integrations", "On-Premise", "Upgrade To CaptureExec",
    //"Performance", "Waterfall Analysis", "Daily Snapshot", "Trend Analyzer", "Financial Projections", "Critical Thresholds"
    //"Contracts Workload", "Contracts Todo", "Contracts Metrics"
    CaptureExecMobx.o_leftNavTabNameSelected = "GovCon Smart Search";
    CaptureExecMobx.o_itemEditingCaptureDashCardDashItemID = undefined; //undefined, "1-3-1"
    CaptureExecMobx.o_errorMessage = null;
    CaptureExecMobx.o_phpFileIsCurrentlyRunningTF = false;
    CaptureExecMobx.o_loadingThroughPhase1CompleteTF = true;
    CaptureExecMobx.o_loadingThroughPhase2CompleteTF = true;
    CaptureExecMobx.o_loginState = loginState; //"out", "sgtBuySgtEnterInfo", "sgtCaptureExecLearnMoreAd", "loadingCaptureExec", "onPremiseNotAuthenticated", "codeVersionMismatch", "licenseExpired", "codeUpdate", "adminPasswordChange", "forcedPasswordChange", "multiLogin", "in"
    CaptureExecMobx.o_loggingInUserEmail = "reallylong.email@gmail.com";

    //==OpenCaptureMobx=====================================================
    OpenCaptureMobx.o_openCaptureID = undefined; //undefined, 1
    OpenCaptureMobx.o_openCardFullID = 6; //undefined, 1-17, 1AS 2Dates 3Tasks 4Details 5DS 6Team 7Comp 8Budget 12Docs 15Changelog 16Notepad 17Revenue
    OpenCaptureMobx.o_captureConnectedToGcssCompareFloatingBoxIsOpenTF = false; //false, true
    OpenCaptureMobx.o_captureConnectedToGcssFetchedSingleSamExpandedDetailSearchResultsObjOrUndefined = GCSSMobx.create_gcss_expanded_detail_search_results_obj_from_expanded_search_result_obj_and_op_tbl_data_rows_arrayOfObjs(samParse.samSearchResultObj, samParse.rawSamOppsHistoryMatrix);
    OpenCaptureMobx.o_userPerEmailIDsWithSameCaptureOpenComma = "1";
    OpenCaptureMobx.o_teammatesLeftTabSelected = "teammateSelection"; //"teammateSelection", "sbRequirements", "surveysCapabilitiesGapAnalysis"
    OpenCaptureMobx.o_teammatesExpandedTeammateID = 3; //undefined
    OpenCaptureMobx.o_teammatesSurveysSelectedSurveyID = 1;
    OpenCaptureMobx.o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab = "surveyResultsMatrix"; //"surveyResultsMatrix", "editSurveyQuestions", "uploadSurveyDocuments", "invitationText"
    OpenCaptureMobx.o_teammatesFloatingBoxSurveyTeammateID = undefined; //undefined
    OpenCaptureMobx.o_teammatesFloatingBoxSurveySurveyID = undefined; //undefined
    OpenCaptureMobx.o_competitorExpandedID = 1; //undefined

    //==CapturesMobx=====================================================
    CapturesMobx.o_createNewCaptureIsOpenTF = false; //false, true
    CapturesMobx.o_masterPresetEditorOpenTF = false; //false, true
    CapturesMobx.o_presetEditorViewPresetsFromOtherUsersTF = true; //false, true
    CapturesMobx.o_presetEditorOpenPresetTypeOrUndefined = "filter"; //undefined, "master", "cstColumns", "filter", "sort"
    CapturesMobx.o_presetEditorOpenPresetIDOrUndefined = 19;
    CapturesMobx.o_ganttDatesPresetEditorOpenTF = false; //false, true
    CapturesMobx.o_ganttDatesOpenPresetID = 1; //undefined, 1

    //==RightPanelMobx=====================================================
    RightPanelMobx.o_closed0_u1_t2_n3 = 0;
    RightPanelMobx.o_helpPanelIsOpenTF = false; //false, true
    RightPanelMobx.o_userTabNameOpen = null; //null, "Settings", "Message My Admin", "Change Password", "Support Ticket for BIT Solutions"
    RightPanelMobx.o_currentlyViewingTicketThreadID = -1;

    //==UserMobx=========================================================
    UserMobx.o_userPerEmailID = 1;
    UserMobx.o_userID = 1; //1 (superadmin), 2 (divexec), 7 (contractsexec), 8 (budget), 9 (budgetexec), 13 (captureRO), 14 (divexec RO)
    UserMobx.o_userCompanyCode = "BITTEST";

    //==TeammateContractsMobx=========================================================
    TeammateContractsMobx.o_openContractFromContractsTodoTF = false;
    TeammateContractsMobx.o_openContractCaptureID = undefined; //undefined, 1
    TeammateContractsMobx.o_openContractTeammateID = undefined; //undefined, 1
    TeammateContractsMobx.o_openContractTypeID = undefined; //undefined, 1

    //=AdminMobx=========================================================
    AdminMobx.o_usersViewFlag = "lastNameAlphabeticList"; //"lastNameAlphabeticList", "DivisionTreeUsersSingleEdit"
    AdminMobx.o_divisionsFindMyUeiLoadingFlag = "results";
    AdminMobx.o_divisionsFindMyUeiSamUeiCompaniesArrayOfObjs = [{company_name:"BITTEST", sam_uei:"BITFEAIVEMAIE"}, {company_name:"BITDEMO", sam_uei:"BITFDAJOFEAJOFE"}];
    AdminMobx.o_detailsFieldsOptionsSelectedSubtab = "productServiceCodesMasterTbl"; //"competitionTypeSBCertsMasterTbl", "naicsCodes", "productServiceCodesMasterTbl", "departmentsAgenciesMasterTbl", "subTiersMasterTbl", "idiqVehicleFieldOptions", "priorityFavorites"
    AdminMobx.o_allTeammateContractsArrayOfObjs = [{id:1, capture_id:1, teammate_id:1, teammate_contract_type_id:1}, {id:1, capture_id:1, teammate_id:1, teammate_contract_type_id:2}];
    AdminMobx.o_shortcutPresetsSelectedTabDbName = "documentsCard"; //"captureTeam", "documentsCard"
    AdminMobx.o_captureArchivingLoadedSlimCapturesArrayOfObjs = [{id:1,codename:"One",opportunity_name:"Arch",archive_date:"2023-08-31"},{id:2,codename:"Two",opportunity_name:"Arch",archive_date:"2023-07-31"}];

    //=AdminImportMobx=========================================================
    AdminImportMobx.o_importFlag = "capturesAnalysisTranslationAndImportOrUpdate"; //"importCapturesUploadCsv", "updateCapturesUploadCsv", "importContactCompaniesUploadCsv", "importContactPersonsUploadCsv", "capturesAnalysisTranslationAndImportOrUpdate", "contactsAnalysisAndImport"
    AdminImportMobx.o_importCapturesImportTrueUpdateFalse = false; //true, false
    AdminImportMobx.o_importContactsIsPersonTF = false; //false, true
    AdminImportMobx.o_importUploadedCsvFileName = "Test Csv.csv";
    /*var importUploadedCsvFileDataString = 'Field 1 (OppName),Field 2 (Stage),Field 3 (Contacts),Field 4 (Divisions),Field 5 (Dates),Field 6 (DateTimes),Field 7 (Numbers), Field 8 (Update Capture ID)\n';
    importUploadedCsvFileDataString += 'c1f1,Qualification,c1f3,c1f4,c1f5,c1f6,c1f7,c1f8\n';
    importUploadedCsvFileDataString += 'c2f1,Qualification,"Abbr No Legal,SAIC,SB245",,"Nov 2, 2015","Nov 2, 2015",50%,3\n';
    importUploadedCsvFileDataString += 'c3f1,Qual,Abbr No Legal,"Army Division:60,Navy Division: 10,Marine Division :25,Air Force Division : 5",2019-09-08,2019-09-08,"$1,800",2\n';
    importUploadedCsvFileDataString += 'c4f1,Pursuit,"SAIC, SB245","Fed Sales,Commercial Sales",9/6/19,9/6/19,43,1\n';
    importUploadedCsvFileDataString += 'c5f1,qualification,"Abbr",Radio Division,2022-10-10 13:13:13,2022-10-10 01:13:13,4.25%,-8\n';
    importUploadedCsvFileDataString += 'c6f1,Pursuit,"Abbreviated",Radio Division,2023-10-20 15:35:00,2023-10-20 15:35:00,a1b2c3d4,9\n';
    AdminImportMobx.o_importUploadedCsvFileDataString = importUploadedCsvFileDataString;
    AdminImportMobx.o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = -1;
    AdminImportMobx.o_importCurrentAutoAssignsMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, import_preset_id:1, field_id:5, captureexec_raw_value_string:JSFUNC.now_date()},
      {id:2, import_preset_id:1, field_id:2, captureexec_raw_value_string:"1:100"},
      {id:3, import_preset_id:1, field_id:11, captureexec_raw_value_string:"1"},
      {id:4, import_preset_id:1, field_id:12, captureexec_raw_value_string:"1"}
    ]);
    AdminImportMobx.o_importCurrentColumnAssignsMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, import_preset_id:1, column_index:0, mapping_type_id:1, field_id:15, collapsed_01:0, percent_field_multiply_100_01:0},
      {id:2, import_preset_id:1, column_index:1, mapping_type_id:1, field_id:8, collapsed_01:0, percent_field_multiply_100_01:0},
      {id:3, import_preset_id:1, column_index:2, mapping_type_id:2, field_id:-1, collapsed_01:0, percent_field_multiply_100_01:0},
      {id:4, import_preset_id:1, column_index:3, mapping_type_id:1, field_id:3, collapsed_01:1, percent_field_multiply_100_01:0},
      {id:5, import_preset_id:1, column_index:4, mapping_type_id:1, field_id:37, collapsed_01:0, percent_field_multiply_100_01:0},
      {id:6, import_preset_id:1, column_index:5, mapping_type_id:1, field_id:38, collapsed_01:0, percent_field_multiply_100_01:0},
      {id:7, import_preset_id:1, column_index:6, mapping_type_id:1, field_id:31, collapsed_01:0, percent_field_multiply_100_01:0},
      {id:8, import_preset_id:1, column_index:7, mapping_type_id:1, field_id:1, collapsed_01:0, percent_field_multiply_100_01:0}
    ]);
    AdminImportMobx.o_importCurrentCustomAssignsMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, import_preset_id:1, column_index:1, csv_value_string:"Qualification", captureexec_raw_value_string:"3", intentionally_unassigned_01:0},
      {id:2, import_preset_id:1, column_index:1, csv_value_string:"qualification", captureexec_raw_value_string:"3", intentionally_unassigned_01:0},
      {id:3, import_preset_id:1, column_index:1, csv_value_string:"Pursuit", captureexec_raw_value_string:"4", intentionally_unassigned_01:0},
      {id:4, import_preset_id:1, column_index:1, csv_value_string:"Qual", captureexec_raw_value_string:"3", intentionally_unassigned_01:0},
      {id:5, import_preset_id:1, column_index:3, csv_value_string:"Army Division:60,Navy Division: 10,Marine Division :25,Air Force Division : 5", captureexec_raw_value_string:"3:60,4:10,5:25,7:5", intentionally_unassigned_01:0},
      {id:6, import_preset_id:1, column_index:3, csv_value_string:"Fed Sales,Commercial Sales", captureexec_raw_value_string:"2:50,6:50", intentionally_unassigned_01:0},
      {id:7, import_preset_id:1, column_index:3, csv_value_string:"Radio Division", captureexec_raw_value_string:"8:100", intentionally_unassigned_01:0},
      {id:8, import_preset_id:1, column_index:3, csv_value_string:"c1f4", captureexec_raw_value_string:"", intentionally_unassigned_01:1},
      {id:9, import_preset_id:1, column_index:3, csv_value_string:"", captureexec_raw_value_string:"", intentionally_unassigned_01:1},
      {id:10, import_preset_id:1, column_index:2, csv_value_string:"c1f3", captureexec_raw_value_string:"", intentionally_unassigned_01:1},
      {id:11, import_preset_id:1, column_index:2, csv_value_string:"Abbr No Legal,SAIC,SB245", captureexec_raw_value_string:"14,1,16", intentionally_unassigned_01:0},
      {id:12, import_preset_id:1, column_index:2, csv_value_string:"Abbr No Legal", captureexec_raw_value_string:"14", intentionally_unassigned_01:0},
      {id:13, import_preset_id:1, column_index:2, csv_value_string:"SAIC, SB245", captureexec_raw_value_string:"1,16", intentionally_unassigned_01:0},
      {id:14, import_preset_id:1, column_index:2, csv_value_string:"Abbr", captureexec_raw_value_string:"14", intentionally_unassigned_01:0}
    ]);*/
    var importUploadedCsvFileDataString = 'ID,Single Field 1 (DateToDateTime)\n';
    importUploadedCsvFileDataString += '1,CE2023-12-09 11:00:00';
    AdminImportMobx.o_importUploadedCsvFileDataString = importUploadedCsvFileDataString;
    AdminImportMobx.o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = 0;
    AdminImportMobx.o_importCurrentAutoAssignsMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, import_preset_id:1, field_id:21, captureexec_raw_value_string:JSFUNC.now_date()}
    ]);
    AdminImportMobx.o_importCurrentColumnAssignsMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, import_preset_id:1, column_index:0, mapping_type_id:1, field_id:1, collapsed_01:0, percent_field_multiply_100_01:0},
      {id:2, import_preset_id:1, column_index:1, mapping_type_id:1, field_id:23, collapsed_01:0, percent_field_multiply_100_01:0}
    ]);
    AdminImportMobx.o_importCurrentCustomAssignsMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([]);
    
    //=AdminIntegrationsMobx=========================================================
    AdminIntegrationsMobx.o_selectedTabDbName = "log"; //"setup", "mapping", "specialHandling",  "log"
    AdminIntegrationsMobx.o_setupVerifyApiCredentialsFlag = "ceError"; //"start", "working", "verified", "credentialsFailed", "ceError"
    AdminIntegrationsMobx.o_mappingExampleFieldsPanelOpenTF = false;
    AdminIntegrationsMobx.o_mappingExampleFieldsIntegrationOppIDString = "";
    AdminIntegrationsMobx.o_mappingExampleFieldsFlag = "oppDoesNotExist"; //"start", "working", "verified", "credentialsFailed", "ceError", "oppDoesNotExist"
    AdminIntegrationsMobx.o_mappingExampleFieldsFetchedIntegrationOppsJsonString = '[{"vpID":"VP47893","stage":"Prospecting","custom":"Custom","solicit":"#47894327893"}]';
    AdminIntegrationsMobx.o_integrationsChangelogLoadingDataOrErrorUndefinedTFU = false; //false, true, undefined

    //=DivexecMobx=========================================================
    DivexecMobx.o_dailySnapshotSelectedPageID = 2;
    DivexecMobx.o_trendAnalyzerSelectedPageID = 3;
    DivexecMobx.o_graphsEditingGraphID = undefined; //undefined
    DivexecMobx.o_stageFlowThinTblCLogStagesArrayOfObjs = [
      {ci:1,dt:"2019-03-21 09:00:00",si:10},
      {ci:2,dt:"2019-02-21 09:00:00",si:1},{ci:2,dt:"2019-02-22 09:00:00",si:2},{ci:2,dt:"2019-02-23 09:00:00",si:3},
      {ci:3,dt:"2019-01-21 09:00:00",si:1},{ci:3,dt:"2019-01-22 09:00:00",si:2},{ci:3,dt:"2019-01-23 09:00:00",si:3},{ci:3,dt:"2019-01-24 09:00:00",si:4},
      {ci:4,dt:"2019-03-21 09:00:00",si:9},
      {ci:5,dt:"2019-04-21 09:00:00",si:9},
      {ci:6,dt:"2019-05-21 09:00:00",si:9}
    ];

    //=ContactsMobx=========================================================
    ContactsMobx.o_leftSideState = "treeWithSearch"; //"treeWithSearch", "advancedCompanySearch"
    ContactsMobx.o_rightSideState = "viewEditSingleContact"; //"empty", "viewEditSingleContact", "createNewContact", "savingNewContact", "advancedSearchCapturesAnalysis"
    ContactsMobx.o_openContactID = 1; //undefined, 1
    ContactsMobx.o_openContactIsPersonTF = false; //undefined, true, false
    ContactsMobx.o_openContactViewEditSingleContactSelectedTabDbName = "teammateRating"; //"contactInfo", "teammateRating", "contactConversations", "viewDocs"
    ContactsMobx.o_openContactPersonConversationsArrayOfObjs = [
      {id:1, capture_id:1, datetime_utc:"2024-12-23 12:12:12", contact_person_ids_comma:"1,3", notes:longText},
      {id:2, capture_id:3, datetime_utc:"2024-12-24 13:13:58", contact_person_ids_comma:"1", notes:"short notes.\n\nmulti line."},
      {id:3, capture_id:3, datetime_utc:"2024-12-25 14:14:59", contact_person_ids_comma:"1", notes:"medium notes."},
      {id:4, capture_id:3, datetime_utc:"2024-12-26 15:15:00", contact_person_ids_comma:"1", notes:"long notes."},
      {id:5, capture_id:975, datetime_utc:"2024-12-27 16:16:16", contact_person_ids_comma:"1", notes:"Fake Capture ID"}
    ];
    ContactsMobx.o_advSearchThinTblCTeammatesArrayOfObjs = [
      {ci:1, cci:1, ap:19.25, wt:1, wf:50, wn:"hey there"}, //capture_id, contact_company_id, allocation_percent, workshare_type_id, workshare_number_of_ftes, workshare_notes
      {ci:1, cci:2, ap:5, wt:1, wf:50, wn:"hey there" + longText},
      {ci:1, cci:333, ap:13, wt:1, wf:50, wn:"hey there"},
      {ci:1, cci:4, ap:2, wt:1, wf:50, wn:"hey there"},
      {ci:1, cci:5, ap:8, wt:1, wf:50, wn:"hey there"},
      {ci:1, cci:14, ap:3, wt:1, wf:50, wn:"hey there"},
      {ci:1, cci:16, ap:7, wt:1, wf:50, wn:"hey there"},
      {ci:2, cci:1, ap:22, wt:1, wf:50, wn:"hey there"},
      {ci:2, cci:-2, ap:0, wt:1, wf:50, wn:"hey there"}
    ];

    //=CaptureExecMobx Surveys=============================================================
    var mySurveysTblCTeammatesSurveysArrayOfObjs = [];
    for(let s = 1; s <= 21; s++) { mySurveysTblCTeammatesSurveysArrayOfObjs.push({id:s,capture_id:1,title:"mmmHey" + s}); }
    mySurveysTblCTeammatesSurveysArrayOfObjs.push({id:100,capture_id:2,title:"Test Survey 222"});
    CaptureExecMobx.o_mySurveysTblCTeammatesSurveysArrayOfObjs = mySurveysTblCTeammatesSurveysArrayOfObjs;

    var mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs = [];
    for(let r = 1; r <= 21; r++) { mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs.push({id:r,capture_id:1,survey_id:r,teammate_id:1,sent_by_user_per_email_id:1,sent_datetime_utc:"2020-01-01 12:00:00",first_opened_datetime_utc:"0000-00-00 00:00:00",completed_datetime_utc:"2020-02-01 12:00:00",not_interested_01:0}); }
    mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs.push({id:100,capture_id:2,survey_id:100,teammate_id:2,sent_by_user_per_email_id:1,sent_datetime_utc:"2021-01-01 12:00:00",first_opened_datetime_utc:"0000-00-00 00:00:00",completed_datetime_utc:"2021-02-01 12:00:00",not_interested_01:0});
    mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs.push({id:101,capture_id:2,survey_id:100,teammate_id:3,sent_by_user_per_email_id:2,sent_datetime_utc:"2021-01-01 12:00:00",first_opened_datetime_utc:"0000-00-00 00:00:00",completed_datetime_utc:"2021-02-01 12:00:00",not_interested_01:0});
    CaptureExecMobx.o_mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs = mySurveysTblCTeammatesSurveysResponseTimesArrayOfObjs;

    //=GCSS=============================================================
    GCSSMobx.o_gcssTabTblsHaveBeenLoadedIntoLocalMemoryTF = true; //true, false
    GCSSMobx.o_autoSearchPanelIsExpandedTF = false;
    GCSSMobx.o_manualSearchIsOpenTF = false; //false, true
    GCSSMobx.o_selectedAutoSearchIDOrUndefined = 1; //undefined, 1
    var searchResultsArrayOfObjs = [
      {id:1, model_score:0.976, original_published_datetime_utc:"2021-11-16 00:00:00", last_updated_datetime_utc:"2021-11-19 00:00:00", unique_solnum_or_url_jumble:"unique_solnum_or_url_jumble", matching_op_ids_comma:"1,2",
  		solicitation_num:"solicitation_num", sam_url:"sam_url", opportunity_name:"opportunity_name " + longText, description_of_work_opening:"description_of_work_opening " + longText, proposal_due_datetime_utc:"2022-04-15 15:00:59",
  		department_agency_bm_id:"department_agency_bm_id", subtier_bm_id:"subtier_bm_id", set_aside_bm_id:"set_aside_bm_id", contract_opportunity_type_bm_id:"contract_opportunity_type_bm_id", naics_code_bm_id:"naics_code_bm_id",
  		product_service_code_bm_id:"product_service_code_bm_id", place_of_performance_state_bm_id:2, place_of_performance_country_bm_id:3}
    ];
    var i = 2;
    for(let s = 13; s >= -3; s-=1) {
      searchResultsArrayOfObjs.push({id:i, model_score:(s/10), proposal_due_datetime_utc:"2021-11-23 15:00:59"});
      i++;
    }
    searchResultsArrayOfObjs.push({id:1818, proposal_due_datetime_utc:"2022-01-26 15:00:59"});
    GCSSMobx.o_searchResultsArrayOfObjs = searchResultsArrayOfObjs;
    GCSSMobx.o_searchResultsLoadingFlag = "loaded"; //"init", "loading", "loaded", "error"
    GCSSMobx.o_totalNumResultsMatchingSearch = 1234567;
    GCSSMobx.o_selectedSearchResultsTabDbName = "all"; //"all", "undecided", "markedForImport", "markedAsNotInterested", "alreadyInCE"
    GCSSMobx.o_cexaiPosNegAllPosNegGcssOpsNamesArrayOfObjs = [{id:1,opportunity_name:"One"},{id:2,opportunity_name:"Two"},{id:3,opportunity_name:"Three"},{id:4,opportunity_name:"Four"}];
    GCSSMobx.o_openSearchResultDetailGcssIDOrUndefined = undefined; //undefined, 1
    GCSSMobx.o_openSearchResultDetailOpTblDataRowsArrayOfObjs = [
      {id:1, rso_record_insert_datetime_utc:"2021-11-19 00:00:00", rso_original_published_datetime_utc:"2021-11-18 00:00:00", rso_proposal_due_datetime_utc:"2022-01-01 00:00:00", rso_solicitation_number:"rso_solicitation_number",
      rso_sam_url:"rso_sam_url", rso_opportunity_name:"rso_opportunity_name", rso_naics_code:"rso_naics_code", rso_set_aside_name:"rso_set_aside_name", rso_contract_opportunity_type_name:"rso_contract_opportunity_type_name",
      rso_product_service_code:"rso_product_service_code", rso_department_agency_name:"rso_department_agency_name", rso_subtier_name:"rso_subtier_name", rso_office_name:"rso_office_name", rso_pop_address:"rso_pop_address",
      rso_pop_city:"rso_pop_city", rso_pop_state:"rso_pop_state", rso_pop_zip:"rso_pop_zip", rso_pop_country:"rso_pop_country", rso_ko_city:"rso_ko_city",
      rso_ko_state:"rso_ko_state", rso_ko_zip:"rso_ko_zip", rso_ko_country:"rso_ko_country", rso_ko_poc1_textarea:"rso_ko_poc1_textarea", rso_ko_poc2_textarea:"rso_ko_poc2_textarea",
      rso_award_date:"2022-03-08", rso_award_number:"rso_award_number", rso_award_amount:123456789, rso_awardee_information:"rso_awardee_information", rso_ce_description_plaintext:"rso_ce_description_plaintext", 
      rso_ce_document_links_json:"rso_ce_document_links_json"}
    ];

    //==SamGovTrackerMobx=====================================================
    const q = "This is what I would consider the gold standard of an Opportunity Lifecycle Management System. It encompasses all the key features necessary to track an opportunity from identification through Win. It can provide your organization a birds-eye view of where you sit in terms of your position to win across all your opportunities. Skip Blackburn and his team truly understand this market and what it takes to be successful and are continuing to advance the system to levels that seemingly have no limits. We look forward to the continued partnership with BIT Solutions in the future.";
    SamGovTrackerMobx.o_testimonialsArrayOfObjs = [
      {id:1, sort:1, company_code:"LH3000", company_name:"Local Host", user_full_name:"Test Person", user_title:"Manager of Division Practices", quote_text:q, product_override_or_blank:"", num_years_override_or_auto_calc_0:0, calcProduct:"captureexec", calcUseNumYears:3},
      {id:2, sort:2, company_code:"TT", company_name:"", user_full_name:"", user_title:"", quote_text:longText, product_override_or_blank:"", num_years_override_or_auto_calc_0:0, calcProduct:"samgovtracker", calcUseNumYears:1},
      {id:3, sort:3, company_code:"M", company_name:"Miners", user_full_name:"Third", user_title:"Position", quote_text:"Test quote.", product_override_or_blank:"", num_years_override_or_auto_calc_0:0, calcProduct:"captureexec", calcUseNumYears:2}
    ];
    SamGovTrackerMobx.o_buyProcessState = "info"; //"info", "cc"
    SamGovTrackerMobx.o_allUsedCompanyCodesComma = "bit";
    SamGovTrackerMobx.o_sgtNoDocsPriceAmount = 2500;
    SamGovTrackerMobx.o_sgtNoDocsPriceCurrency = "usd";
    SamGovTrackerMobx.o_sgtWithDocsPriceAmount = 3600;
    SamGovTrackerMobx.o_sgtWithDocsPriceCurrency = "usd";
    SamGovTrackerMobx.o_customerCompanyName = "DevCo";
    SamGovTrackerMobx.o_customerCompanyCode = "dev";
    SamGovTrackerMobx.o_customerFirstName = "Dev";
    SamGovTrackerMobx.o_customerLastName = "Co";
    SamGovTrackerMobx.o_customerEmail = "dev@co.com";
    SamGovTrackerMobx.o_purchaseSGTNumUsers = 3;
    SamGovTrackerMobx.o_purchaseSGTProduct = "sgtWithDocs";

    //==Vars for fields below=========================================================
    const companyUsingCodenameTF = true;

    //==DatabaseMobx=========================================================
    DatabaseMobx.o_bitcompaniesTblRowMap = JSFUNC.map_from_obj({
      company_code: "DEVCODE",
      sgt_db_num_or_ce_0: 0,
      company_domain_dot_ext_comma: "bitsolutionsllc.com,otherbit.com",
      jumble_key: "DEVDEV",
      product: product, //"captureexec", "samgovtracker"
      site_type: "dev",
      licenses: 28,
      initial_start_date: "2012-07-12",
      license_start_date: "2019-02-01",
      license_num_months: 24,
      superadmins_max_num: -1,
      superadmins_license_cost: 1.5,
      consultants_license_cost: 0.5,
      read_only_license_cost: 0.25,
      govcon_smart_search_01: 1,
      cexai_01: 1,
      idiq_rapid_response_01: 1,
      single_capture_direct_access_links_01: 1,
      integration_system: "vantagepoint", //"", "vantagepoint"
      sgt_documents_card_upgrade_01: 1,
      sgt_stripe_customer_id: "cus_N5UNEtbDbjjnhT",
      sgt_cc_last_4_digits: 9876,
      sgt_cc_expire_date: "2024-01-01",
      on_premise_authentication_expiration_date: "0000-00-00"
    });

    DatabaseMobx.o_tbl_bit_master_contract_opportunity_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Award Notice"},
      {id:2, name:"Combined Synopsis/Solicitation"},
      {id:3, name:"Sources Sought"}
    ]);

    DatabaseMobx.o_tbl_bit_master_countries = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, a3:"XCS", name:"Coral Sea Islands", alt_names_semi:"CSI"},
      {id:2, a3:"IND", name:"India", alt_names_semi:"IN"},
      {id:3, a3:"USA", name:"United States", alt_names_semi:"U.S.;U.S.A.;U.S.A;United States of America"}
    ]);

    DatabaseMobx.o_tbl_bit_master_departments_agencies = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"DEPT OF DEFENSE"},
      {id:2, name:"NUCLEAR REGULATORY COMMISSION"},
      {id:3, name:"ENVIRONMENTAL PROTECTION AGENCY"}
    ]);

    DatabaseMobx.o_tbl_bit_master_fedcomp_contract_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Opportunity"},
      {id:2, name:"Task Order Opportunity"},
      {id:3, name:"Potential Contract Recompete"}
    ]);

    DatabaseMobx.o_tbl_bit_master_naics_codes = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, code:"12", title:"Soybean Farming2", arl0_me1:0, size:1000000, exceptions:"", made_obsolete_in_year:0, obsolete_replaced_by_code:0},
      {id:2, code:"123", title:"Soybean Farming3", arl0_me1:0, size:1000000, exceptions:"", made_obsolete_in_year:0, obsolete_replaced_by_code:0},
      {id:3, code:"334416", title:"Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing", arl0_me1:1, size:500, exceptions:"", made_obsolete_in_year:0, obsolete_replaced_by_code:0},
      {id:4, code:"1234", title:"Soybean Farming4", arl0_me1:0, size:1000000, exceptions:"", made_obsolete_in_year:0, obsolete_replaced_by_code:0},
      {id:5, code:"541511", title:"Custom Computer Programming Services", arl0_me1:0, size:30000000, exceptions:"", made_obsolete_in_year:0, obsolete_replaced_by_code:0},
      {id:6, code:"12345", title:"Soybean Farming5", arl0_me1:0, size:1000000, exceptions:longText},
      {id:7, code:"123456", title:"Soybean Farming6", arl0_me1:0, size:1000000, exceptions:"", made_obsolete_in_year:0, obsolete_replaced_by_code:0},
      {id:8, code:"541330", title:"Engineering Services", arl0_me1:0, size:22500000, exceptions:"Exception 1 Military and Aerospace Equipment and Military Weapons ($41.5M), Exception 2 Contracts and Subcontracts for Engineering Services Awarded Under the National Energy Policy Act of 1992 ($41.5M), Exception 3 Marine Engineering and Naval Architecture ($41.5M)", made_obsolete_in_year:0, obsolete_replaced_by_code:0}
    ]);

    DatabaseMobx.o_tbl_bit_master_product_service_codes = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, code:"17"},
      {id:2, code:"C216"},
      {id:3, code:"D303"}
    ]);

    DatabaseMobx.o_tbl_bit_master_set_asides = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, gcss_name:"Women-Owned Small Business (WOSB) Program Set-Aside (FAR 19.15)", is_hidden_duplicate_of_row_id_or_0:0, name:"Women-Owned Small Business (Set-Aside)", short_name:"WOSB", fedcomp_name:"Woman Owned", sort:0},
      {id:2, gcss_name:"", is_hidden_duplicate_of_row_id_or_0:0, name:"Alaskan Native Corporation Owned", short_name:"ANC", fedcomp_name:"", sort:1},
      {id:3, gcss_name:"SBA Certified Women-Owned Small Business (WOSB) Program Set-Aside (FAR 19.15)", is_hidden_duplicate_of_row_id_or_0:1, name:"", short_name:"", fedcomp_name:"", sort:0}
    ]);

    DatabaseMobx.o_tbl_bit_master_states = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, a2:"MD", name:"Maryland", alt_names_semi:""},
      {id:2, a2:"MP", name:"Northern Mariana Islands", alt_names_semi:"NMI"},
      {id:3, a2:"PA", name:"Pennsylvania", alt_names_semi:""}
    ]);

    DatabaseMobx.o_tbl_bit_master_sub_tiers = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"DEFENSE LOGISTICS AGENCY", duplicate_map_to_other_id_or_0:5, bit_manual_entry_01:0, bit_overwrite_display_name:""},
      {id:2, name:"DEPT OF THE NAVY", duplicate_map_to_other_id_or_0:0, bit_manual_entry_01:0, bit_overwrite_display_name:""},
      {id:3, name:"VETERANS AFFAIRS, DEPARTMENT OF", duplicate_map_to_other_id_or_0:0, bit_manual_entry_01:0, bit_overwrite_display_name:""},
      {id:4, name:"ENVIRONMENTAL PROTECTION AGENCY", duplicate_map_to_other_id_or_0:0, bit_manual_entry_01:0, bit_overwrite_display_name:"ENVIRONMENTAL PROTECTION AGENCY (EPA)"},
      {id:5, name:"DEFENSE LOGISTICS AGENCY (DLA)", duplicate_map_to_other_id_or_0:0, bit_manual_entry_01:0, bit_overwrite_display_name:""},
      {id:6, name:"SPACE FORCE", duplicate_map_to_other_id_or_0:0, bit_manual_entry_01:1, bit_overwrite_display_name:""}
    ]);

    DatabaseMobx.o_bitcompaniesH1HelpPanelsTblMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, sort:2, ce_page_name:"CaptureTable", panel_title:"Panel Title 2"},
      {id:2, sort:1, ce_page_name:"TopBar", panel_title:"Panel Title 1"},
      {id:3, sort:3, ce_page_name:"GanttChart", panel_title:"Panel Title 3"}
    ]);

    DatabaseMobx.o_bitcompaniesH2HelpPanelCollapsibleHeadersTblMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, help_panel_id:1, sort:2, header_title:"Collapsible Header 2 but the Title is really long and this sentence just keeps going because it's just so informative but the Title is really long and this sentence just keeps going because it's just so informative", published_01:0},
      {id:2, help_panel_id:1, sort:1, header_title:"Collapsible Header 1", published_01:0},
      {id:3, help_panel_id:2, sort:1, header_title:"Collapsible Top", published_01:1}
    ]);

    DatabaseMobx.o_bitcompaniesH3HelpPanelHeaderContentTblMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, header_id:1, sort:3, content_type_flag:2, text:"Text 2"},
      {id:2, header_id:1, sort:1, content_type_flag:2, text:"[[blue]][[/blue]]Text 1 [[red]]Red Text[[/red]] is how it goes. It's [[bold]]A bold idea[[/bold]] to do this."},
      {id:3, header_id:1, sort:2, content_type_flag:1, text:"Subheader 1"},
      {id:4, header_id:1, sort:4, content_type_flag:3, text:"image"},
      {id:5, header_id:1, sort:6, content_type_flag:4, text:"doc"},
      {id:6, header_id:1, sort:5, content_type_flag:5, text:"hyperlink"}
    ]);

    DatabaseMobx.o_tbl_a_automated_exports = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, off0_on1:1, job_name:"Test Job 1", recurrence_type_123:2, recurrence_count:3, run_time_hour_0to23:4, capture_table_view_button_preset_id:1, last_run_datetime_est:"0000-00-00 00:00:00", created_by_user_id:1, date_created:"2024-09-28", view_button_sorted_filtered_capture_ids_comma:"1,2", view_button_sorted_capture_field_db_names_comma:"3"},
      {id:2, off0_on1:0, job_name:"Test Job 2", recurrence_type_123:3, recurrence_count:5, run_time_hour_0to23:14, capture_table_view_button_preset_id:2, last_run_datetime_est:"2024-09-28 11:12:13", created_by_user_id:1, date_created:"2024-09-28", view_button_sorted_filtered_capture_ids_comma:"4,5,6", view_button_sorted_capture_field_db_names_comma:"7,8,9,10"}
    ]);

    DatabaseMobx.o_tbl_a_automated_reminders = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, date_field_id:38, num_days_prior:30, pre0_post1:0, active0_all1:0, extra_user_per_email_ids_to_notify_comma:"1,2", extra_message:"", division_ids_comma_or_all:"all", created_datetime_utc:"2025-02-03 11:00:59", created_by_user_id:1, created_by_user_name:"Drew"},
      {id:2, date_field_id:39, num_days_prior:14, pre0_post1:1, active0_all1:1, extra_user_per_email_ids_to_notify_comma:"", extra_message:longText, division_ids_comma_or_all:"1,3,5", created_datetime_utc:"2025-02-03 12:00:58", created_by_user_id:2, created_by_user_name:"Skip"}
    ]);

    DatabaseMobx.o_tbl_a_budget_categories_pool = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Capture", sort:1},
      {id:2, name:"B&P", sort:2}
    ]);

    DatabaseMobx.o_tbl_a_budget_expense_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Transportation"},
      {id:2, name:"Accomodations"}
    ]);

    DatabaseMobx.o_tbl_a_business_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Large Business", sort:1, is_sb_type_01:0},
      {id:2, name:"Small Business", sort:3, is_sb_type_01:1},
      {id:3, name:"Government Organization", sort:2, is_sb_type_01:0}
    ]);

    DatabaseMobx.o_tbl_a_capabilities = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Health"},
      {id:2, name:"Information Technology"},
      {id:3, name:"Aviation"},
      {id:4, name:"Aviation: Specialty"}
    ]);

    DatabaseMobx.o_tbl_a_capture_cards_custom_names = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:5, name:"Go/No Go Questions"},
      {id:14, name:"Custom 14 is a Really Long Name"}
    ]);

    var devCapturePriorityLevelsArrayOfObjs = [];
    for(let i = 1; i <= 3; i++) {
      var priorityColor = "9999ff";
      if(i === 1) { priorityColor = "bb0000"; }
      else if(i === 2) { priorityColor = "dd8800"; }
      else if(i === 3) { priorityColor = "eeee11"; }
      else if(i === 4) { priorityColor = "22bb22"; }
      else if(i === 5) { priorityColor = "22bbbb"; }
      devCapturePriorityLevelsArrayOfObjs.push({id:i, name:"Priority Level " + i, sort:i, color:priorityColor});
    }
    DatabaseMobx.o_tbl_a_capture_priority_levels = JSFUNC.mapOfMaps_from_arrayOfObjs(devCapturePriorityLevelsArrayOfObjs);

    DatabaseMobx.o_tbl_a_capture_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Federal Sales Prime Deals", sort:1, prime1_sub2:1, card_ids_comma:"17,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16", stage_ids_comma:"1,2,3,4,5,6,7,8,9,10,11,12,13,14", details_group_ids_comma:"1,2,3,4,5,6,7,8,9", details_snapshot_field_ids_comma:"1,2,3,4,45,7,8,-10,9,10,11,12,13,-11,14,15,16,17", budget_category_ids_comma:"1,2"},
      {id:2, name:"Federal Sales Sub Deals", sort:2, prime1_sub2:2, card_ids_comma:"1,2,4.5,6,13", stage_ids_comma:"1,2,7,8,9,10,11,12", details_group_ids_comma:"1", details_snapshot_field_ids_comma:"1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16", budget_category_ids_comma:"1,2"},
      {id:3, name:"eeeeeeeeeeeeeeeeeeeeeeeeeeeeee", sort:3, prime1_sub2:1, card_ids_comma:"1,2,3,4,5,6,7,8,9,10,11,12,13", stage_ids_comma:"3", details_group_ids_comma:"1,2,3,4,5,6", details_snapshot_field_ids_comma:"1,2,3,4,5,6,7,8", budget_category_ids_comma:"1"}
    ]);

    DatabaseMobx.o_tbl_a_capture_types_debrief_questions = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_type_id:1, question_id:6, sort:2, closed_stage_id:9},
      {id:2, capture_type_id:1, question_id:6, sort:1, closed_stage_id:10},
      {id:3, capture_type_id:1, question_id:6, sort:1, closed_stage_id:11},
      {id:4, capture_type_id:1, question_id:7, sort:1, closed_stage_id:9},
      {id:5, capture_type_id:2, question_id:6, sort:1, closed_stage_id:9},
      {id:6, capture_type_id:2, question_id:7, sort:2, closed_stage_id:9}
    ]);

    var devCaptureTypesDetailsFieldsArrayOfObjs = [];
    var devCtdfMoneyFieldIDsArray = [100,-10,101,-11];
    var devCtdfDateFieldIDsArray = JSFUNC.concat_arrays_or_values_into_new_array(JSFUNC.array_fill_incrementing_x_to_y(21,32), -11, JSFUNC.array_fill_incrementing_x_to_y(102,105));
    var devCtdfGroup1FieldIDsArray = JSFUNC.array_fill_incrementing_x_to_y(1,5);
    var devCtdfGroup2FieldIDsArray = JSFUNC.array_fill_incrementing_x_to_y(6,14);
    var devCtdfGroup3FieldIDsArray = [15];
    var devCtdfGroup4FieldIDsArray = JSFUNC.array_fill_incrementing_x_to_y(16,63);
    var devCtdfGroup5FieldIDsArray = JSFUNC.array_fill_incrementing_x_to_y(100,113);
    var devCtdfGroup6FieldIDsArray = JSFUNC.array_fill_incrementing_x_to_y(200,234);
    var devCtdfGroup7FieldIDsArray = JSFUNC.array_fill_incrementing_x_to_y(300,315);
    var devCtdfGroup8FieldIDsArray = JSFUNC.array_fill_incrementing_x_to_y(400,422);
    var devCtdfGroup9FieldIDsArray = JSFUNC.array_fill_incrementing_x_to_y(500,534);
    var devCtdfAllGroupsArrayOfArray = [devCtdfMoneyFieldIDsArray, devCtdfDateFieldIDsArray, devCtdfGroup1FieldIDsArray, devCtdfGroup2FieldIDsArray, devCtdfGroup3FieldIDsArray, devCtdfGroup4FieldIDsArray, devCtdfGroup5FieldIDsArray, devCtdfGroup6FieldIDsArray, devCtdfGroup7FieldIDsArray, devCtdfGroup8FieldIDsArray, devCtdfGroup9FieldIDsArray];
    var devID = 1;
    for(let g = 0; g < devCtdfAllGroupsArrayOfArray.length; g++) {
      for(let fieldID of devCtdfAllGroupsArrayOfArray[g]) {
        var sir = -1;
        var r1r2r3 = 1;
        var einfo01 = 0;
        var eims01 = 0;
        var eisnaic = "";
        var ud01 = 0;
        var dv = "";
        var ocmcvf01 = 0;
        if(fieldID === 5) {
          sir = 3;
          r1r2r3 = 3;
          einfo01 = 1;
          eims01 = 1;
          eisnaic = "1,3,5";
          ud01 = 1;
          dv = "1:60,3:40";
          ocmcvf01 = 1;
        }
        devCaptureTypesDetailsFieldsArrayOfObjs.push({id:devID, capture_type_id:1, group_id:(g-1), field_id:fieldID, sort:devID, stage_id_relevant:sir, rec1_reqsig2_req3:r1r2r3, error_if_not_filled_out_01:einfo01, error_if_multiple_selected_01:eims01, error_if_selected_not_allowed_ids_comma:eisnaic, use_default_01:ud01, default_value:dv, only_capture_managers_can_view_field_01:ocmcvf01});
        devID++;
      }
    }
    DatabaseMobx.o_tbl_a_capture_types_details_fields = JSFUNC.mapOfMaps_from_arrayOfObjs(devCaptureTypesDetailsFieldsArrayOfObjs);

    DatabaseMobx.o_tbl_a_capture_types_shaping_questions = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_type_id:1, question_id:1, sort:1, progress_weight:10, pwin_weight:5, stage_id_relevant:1, rec1_reqsig2_req3:1},
      {id:2, capture_type_id:1, question_id:2, sort:2, progress_weight:5, pwin_weight:10, stage_id_relevant:3, rec1_reqsig2_req3:1},
      {id:3, capture_type_id:1, question_id:3, sort:3, progress_weight:2, pwin_weight:0, stage_id_relevant:2, rec1_reqsig2_req3:1},
      {id:4, capture_type_id:1, question_id:4, sort:4, progress_weight:5, pwin_weight:0, stage_id_relevant:3, rec1_reqsig2_req3:1},
      {id:5, capture_type_id:1, question_id:5, sort:5, progress_weight:10, pwin_weight:0, stage_id_relevant:3, rec1_reqsig2_req3:1},
      {id:6, capture_type_id:1, question_id:6, sort:7, progress_weight:1, pwin_weight:0, stage_id_relevant:9, rec1_reqsig2_req3:1},
      {id:7, capture_type_id:1, question_id:7, sort:6, progress_weight:1, pwin_weight:0, stage_id_relevant:9, rec1_reqsig2_req3:1},
      {id:8, capture_type_id:1, question_id:8, sort:8, progress_weight:1, pwin_weight:0, stage_id_relevant:-1, rec1_reqsig2_req3:1},
      {id:9, capture_type_id:1, question_id:9, sort:9, progress_weight:1, pwin_weight:0, stage_id_relevant:0, rec1_reqsig2_req3:1},
      {id:10, capture_type_id:1, question_id:10, sort:10, progress_weight:1, pwin_weight:0, stage_id_relevant:99, rec1_reqsig2_req3:1}
    ]);

    DatabaseMobx.o_tbl_a_communications_banners = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, created_datetime_utc:"2025-03-10 13:27:00", created_by_user_id:1, sort:2, off0_onlyme1_on2:0, start_date: "2025-03-10", end_date:"2025-03-12", banner_display_text:"Test]", banner_full_description:"Description of the banner.", banner_height_em_decimal:1.75, banner_styling_string_comma:"", banner_font_size_multiplier_10x:10, seen_by_user_per_email_ids_comma_or_all:"all", seen_by_division_ids_comma_or_all:"all"},
      {id:2, created_datetime_utc:"2025-03-10 13:31:00", created_by_user_id:1, sort:1, off0_onlyme1_on2:0, start_date: "2025-03-10", end_date:"2025-03-17", banner_display_text:longText, banner_full_description:longText, banner_height_em_decimal:22.25, banner_styling_string_comma:"bold,italic,color#111188,highlight#eeffee", banner_font_size_multiplier_10x:13, seen_by_user_per_email_ids_comma_or_all:"1,2", seen_by_division_ids_comma_or_all:"1,2"}
    ]);

    DatabaseMobx.o_tbl_a_company = JSFUNC.map_from_obj({
      using_division_firewalling_01: 1,
      using_codename_01: ((companyUsingCodenameTF) ? (1) : (0)),
      prime_sb_certs_can_vary_per_division_01: 1,
      create_new_capture_default_capture_type_id: 0, //-1 none selected, 0 use first capture type in Admin pool, 1-N selected capture type id is default when creating new capture (if it exists)
      restrict_advance_capture_stage_01: 0,
      restrict_advance_capture_stage_user_per_email_ids_comma: "2",
      teammates_alloc_of_cov_alt_field_display_name: "",
      teammates_workshare_type_alt_field_display_name: "",
      teammates_workshare_num_ftes_alt_field_display_name: "",
      teammates_workshare_notes_alt_field_display_name: "",
      teammate_contracts_on_other_teammates_when_sub_01: 1,
      pwin_flag: "calculated", //"off", "manual", "calculated"
      pwin_calc_min_thresh_score: 40,
      pwin_calc_min_thresh_pwin: 10,
      pt_differentiators_01: 1,
      pt_win_themes_01: 1,
      pt_ghost_themes_01: 1,
      fyr_start_month: 9, //1 (Jan) - 12 (Dec)
      new_user_default_password: "sip",
      days_between_user_password_change: 90,
      capture_consultants_can_view_parent_idiqs_01: 1,
      date_format: "M j, Y",
      capture_name_format: "cn(opp)|cn|opp|id",
      performance_graphs_all_users_ignore_shared_percent_calcs_01: 1,
      tasks_assignee_can_edit_due_date_01: 1,
      shortcut_presets_capture_managers_on_cnc_01: 1,
      shortcut_presets_documents_card_folders_on_cnc_01: 1,
      teammate_surveys_notifications_to_all_capture_managers_01: 1,
      budget_manager_approval_num_days: 7,
      bit_master_set_aside_ids_to_not_include_comma: "",
      bit_master_set_asides_only_short_names_01: 0,
      bit_master_naics_codes_all0_only6digit1: 1,
      bit_master_naics_codes_ranges_comma: "110000-555555",
      bit_master_product_service_code_ids_to_not_include_comma: "2,3",
      bit_master_depts_ags_ids_to_not_include_comma: "3",
      bit_master_subtiers_ids_to_not_include_comma: "",
      idiq_vehicle_wins0_winsandactive1:1,
      capture_favorites_color:"cccc11",
      gcss_data_source_sam_shown_01: 1,
      gcss_data_source_govwin_shown_01: 1,
      gcss_data_source_fedcomp_shown_01: 1,
      gcss_blank_data_overwrite_01: 0,
      using_automated_capture_archiving_01: 1,
      capture_archiving_closed_stage_ids_comma: "1,11,12",
      capture_archiving_num_days_after_date: 360,
      capture_archiving_date_field_id: 19,
      capture_archiving_num_days_no_activity: 90,
      capture_archiving_send_gcss_update_reminders_01: 1,
      capture_archiving_send_task_reminders_01: 1,
      capture_archiving_send_automated_reminders_01: 1,
      search_fields_separate_divexec_fields_01: 1,
      integration_on_01: 0,
      integration_oauth_web_address: "https://company.com/vantagepoint/api/token",
      integration_api_web_address: "https://company.com/vantagepoint/api/product",
      integration_database_name: "VantagepointDB",
      integration_client_id_scramble: "fds789fds7a89fda789dfa78f9ds",
      integration_client_secret_scramble: "80fds890fd79fds567fd45345",
      integration_integration_unique_id_ce_field_id: 44,
      integration_log_record_successes_01: 1,
      integration_special_integration_blank_date: "0000-00-00",
      integration_special_integration_blank_datetime: "0000-00-00 00:00:00"
    });

    DatabaseMobx.o_tbl_a_competitors_extra_fields = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, input_type:"textarea", db_name:"notes", display_name:"Notes", sort:2, hover_text:"Hover Test"},
      {id:2, input_type:"text", db_name:"test", display_name:"Test Text", sort:1, hover_text:""}
    ]);

    DatabaseMobx.o_tbl_a_contacts_companies_extra_fields = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, input_type:"text", db_name:"cage_code", display_name:"Cage Code", sort:1},
      {id:2, input_type:"email", db_name:"email", display_name:"Email", sort:2},
      {id:3, input_type:"phone", db_name:"phone", display_name:"Phone", sort:3},
      {id:4, input_type:"text", db_name:"address1", display_name:"Address Line 1", sort:4},
      {id:5, input_type:"text", db_name:"address2", display_name:"Address Line 2", sort:5},
      {id:6, input_type:"text", db_name:"city", display_name:"City", sort:6},
      {id:7, input_type:"text", db_name:"state", display_name:"State", sort:7},
      {id:8, input_type:"text", db_name:"zip", display_name:"Zip", sort:8},
      {id:9, input_type:"text", db_name:"country", display_name:"Country", sort:9},
      {id:10, input_type:"text", db_name:"state_of_incorporation", display_name:"State of Incorporation", sort:10},
      {id:11, input_type:"website", db_name:"web_address", display_name:"Web Address", sort:12},
      {id:12, input_type:"textarea", db_name:"notes", display_name:"Notes", sort:11}
    ]);

    DatabaseMobx.o_tbl_a_contacts_persons_extra_fields = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, input_type:"phone", db_name:"cell_phone", display_name:"Cell Phone", sort:1},
      {id:2, input_type:"website", db_name:"social_web_address", display_name:"Social Web Address", sort:2}
    ]);

    DatabaseMobx.o_tbl_a_contract_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, sa1_idiq2_to3:1, name:"Single Award Contract", sort:1},
      {id:2, sa1_idiq2_to3:2, name:"Multi-Award IDIQ Contract", sort:2},
      {id:3, sa1_idiq2_to3:3, name:"IDIQ Task Order", sort:3},
      {id:4, sa1_idiq2_to3:1, name:"Sole Source Contracts", sort:4},
      {id:5, sa1_idiq2_to3:2, name:"Single-Award IDIQ Contract", sort:5}
    ]);

    DatabaseMobx.o_tbl_a_create_new_capture_additional_fields = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_type_id:1, capture_field_id:35, sort:2},
      {id:2, capture_type_id:1, capture_field_id:36, sort:1}
    ]);

    DatabaseMobx.o_tbl_a_date_upcoming_colors1 = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, days_before_due:-1, color:"993333", font_color:"ffffff"},
      {id:2, days_before_due:0, color:"bb6666", font_color:"ffffff"}, //d44 fff
      {id:3, days_before_due:2000000000, color:"f5f5f5", font_color:"999999"}, //f5f5f5 999
      {id:4, days_before_due:1, color:"eecc88", font_color:"2a2a2a"}, //d95 fff
      {id:5, days_before_due:7, color:"dddd77", font_color:"2a2a2a"} //aa7 fff
    ]);

    DatabaseMobx.o_tbl_a_date_upcoming_colors2 = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, days_before_due:-1, color:"f5f5f5", font_color:"999999"},
      {id:2, days_before_due:31, color:"f5aa94", font_color:"2a2a2a"},
      {id:3, days_before_due:91, color:"e8f297", font_color:"2a2a2a"},
      {id:4, days_before_due:720, color:"84f095", font_color:"2a2a2a"},
      {id:5, days_before_due:2000000000, color:"74ceec", font_color:"2a2a2a"}
    ]);

    DatabaseMobx.o_tbl_a_delete_capture_reasons = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Dead", admin_sort:2},
      {id:2, name:"Less Interesting", admin_sort:1}
    ]);

    DatabaseMobx.o_tbl_a_details_groups_pool = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Ownership", admin_sort:1, group_name_styling_string_comma:""},
      {id:2, name:"Contract Information", admin_sort:2, group_name_styling_string_comma:""},
      {id:3, name:"Description of Work", admin_sort:3, group_name_styling_string_comma:"italic,color#990000"},
      {id:4, name:"Other Information", admin_sort:4, group_name_styling_string_comma:""},
      {id:5, name:"Custom Details Fields", admin_sort:5, group_name_styling_string_comma:"presetID:1"},
      {id:6, name:"GCSS", admin_sort:6, group_name_styling_string_comma:""},
      {id:7, name:"Dev Every Field", admin_sort:7, group_name_styling_string_comma:""},
      {id:8, name:"Dev Every Date", admin_sort:8, group_name_styling_string_comma:""},
      {id:9, name:"Dev Every Select", admin_sort:9, group_name_styling_string_comma:""}
    ]);

    DatabaseMobx.o_tbl_a_divisions = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, tree_id:"00", name:"Development Environment", nickname:"DevEnv", color:"2299BB", business_type_id:2, prime_sb_certifications_bm_set_aside_ids_comma:"3,2", firewalled_division_ids_comma:"", sam_uei:"TOPSAMUEI", sam_uei_company_name:"Top Sam Uei Name", integration_new_capture_integration_unique_id_prefix:"DVE"},
      {id:2, tree_id:"0001", name:"Fed Sales", nickname:"", color:"BB9922", business_type_id:1, prime_sb_certifications_bm_set_aside_ids_comma:"", firewalled_division_ids_comma:"1,4,2", sam_uei:"", sam_uei_company_name:"", integration_new_capture_integration_unique_id_prefix:""},
      {id:3, tree_id:"000101", name:"Army Division", nickname:"Army", color:"99BB22", business_type_id:1, prime_sb_certifications_bm_set_aside_ids_comma:"", firewalled_division_ids_comma:"", sam_uei:"", sam_uei_company_name:"", integration_new_capture_integration_unique_id_prefix:""},
      {id:4, tree_id:"000102", name:"Navy Division", nickname:"Navy", color:"9922BB", business_type_id:1, prime_sb_certifications_bm_set_aside_ids_comma:"", firewalled_division_ids_comma:"", sam_uei:"", sam_uei_company_name:"", integration_new_capture_integration_unique_id_prefix:""},
      {id:5, tree_id:"000103", name:"Marine Division", nickname:"Marine", color:"22BB99", business_type_id:1, prime_sb_certifications_bm_set_aside_ids_comma:"", firewalled_division_ids_comma:"", sam_uei:"", sam_uei_company_name:"", integration_new_capture_integration_unique_id_prefix:""},
      {id:6, tree_id:"0002", name:"Commercial Sales", nickname:"Comm", color:"BB2299", business_type_id:1, prime_sb_certifications_bm_set_aside_ids_comma:"", firewalled_division_ids_comma:"", sam_uei:"MIDCOMM", sam_uei_company_name:"", integration_new_capture_integration_unique_id_prefix:""},
      {id:7, tree_id:"000202", name:"Security Division of the Farthest Northern Reaches", nickname:"", color:"CC3333", business_type_id:1, prime_sb_certifications_bm_set_aside_ids_comma:"", firewalled_division_ids_comma:"", sam_uei:"", sam_uei_company_name:"", integration_new_capture_integration_unique_id_prefix:""},
      {id:8, tree_id:"000201", name:"Radio Division", nickname:"Radio", color:"3333CC", business_type_id:1, prime_sb_certifications_bm_set_aside_ids_comma:"", firewalled_division_ids_comma:"", sam_uei:"", sam_uei_company_name:"", integration_new_capture_integration_unique_id_prefix:""}
    ]);

    DatabaseMobx.o_tbl_a_excel_report_templates_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"testfile1.xml", display_name:"Test Excel Template 1", keywords:"keywords", notes:"notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"excel"},
      {id:2, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"Test Folder", keywords:"keywords", notes:"notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"excel"},
      {id:3, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"testfile2.xml", display_name:"Test Excel 2", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"excel"}
    ]);

    DatabaseMobx.o_tbl_a_gcss_data_source_credentials = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, data_source_sam0_govwin1:1, division_ids_comma:"", username: "example1.1name@address1.com", password_scramble: "1rceSyMte1aPssdrow1", client_id_scramble: "1IHGFEDCBAKJM1LONQPRZYXWVUTS1", client_secret_scramble: "1432161587091", oauth_access_json: "18b8f0a57-e61e-49d81-1af9f-c655144a1aa31", access_token_expiration_datetime_utc: "2022-06-04 11:11:11", previous_access_successful_01: 1},
      {id:2, data_source_sam0_govwin1:2, division_ids_comma:"", username: "FEDCOMPFEDCOMP", password_scramble: "rceSyMteaPssdrow", client_id_scramble: "", client_secret_scramble: "", oauth_access_json: "", access_token_expiration_datetime_utc: "0000-00-00 00:00:00", previous_access_successful_01: 1},
      {id:3, data_source_sam0_govwin1:1, division_ids_comma:"", username: "example2.com", password_scramble: "", client_id_scramble: "3D1E857O3GPV6OFJQB2ADS9P69VIEFO7TU1F84H6HL19R", client_secret_scramble: "", oauth_access_json: "", access_token_expiration_datetime_utc: "0000-00-00 00:00:00", previous_access_successful_01: 1}
    ]);

    DatabaseMobx.o_tbl_a_gcss_not_interested_reasons = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Reason Sort 2", sort:2},
      {id:2, name:"zReason Sort 1", sort:1}
    ]);

    DatabaseMobx.o_tbl_a_import_auto_assigns = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, import_preset_id:1, field_id:5, captureexec_raw_value_string:"2019-09-08"},
      {id:2, import_preset_id:2, field_id:96, captureexec_raw_value_string:"2023-10-12"}
    ]);

    DatabaseMobx.o_tbl_a_import_column_assigns = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, import_preset_id:1, column_index:0, mapping_type_id:1, field_id:7, collapsed_01:0, percent_field_multiply_100_01:0},
      {id:2, import_preset_id:2, column_index:0, mapping_type_id:1, field_id:7, collapsed_01:0, percent_field_multiply_100_01:0}
    ]);

    DatabaseMobx.o_tbl_a_import_custom_assigns = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, import_preset_id:1, column_index:0, csv_value_string:"r1h1import", captureexec_raw_value_string:"3", intentionally_unassigned_01:0},
      {id:2, import_preset_id:2, column_index:0, csv_value_string:"r1h1update", captureexec_raw_value_string:"3", intentionally_unassigned_01:0}
    ]);

    DatabaseMobx.o_tbl_a_import_presets = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Test Import Preset", import0_update1:0, update_unique_key_field_column_index_or_m1:-1},
      {id:2, name:"Test Update Preset", import0_update1:1, update_unique_key_field_column_index_or_m1:2}
    ]);

    DatabaseMobx.o_tbl_a_integrations_credentials = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, division_ids_comma:"1,3,5", username: "example1.1name@address1.com", password_scramble: "1rceSyMte1aPssdrow1", oauth_json_access_token: '{"access_token":"4AhxNYWePF3H9vlPMiQkW-f8bBFl3rB8WueMmuFCm8JbuQKQKQ4","token_type":"bearer","expires_in":43199,"refresh_token":"Xij6k4r05SN7MGWDEYPLTo="}', oauth_json_expiration_datetime_utc: "2025-01-29 12:59:59"},
      {id:2, division_ids_comma:"", username: "example2.com", password_scramble: "rceSyMteaPssdrow", oauth_json_access_token: "", oauth_json_expiration_datetime_utc: "0000-00-00 00:00:00"},
      {id:3, division_ids_comma:"", username: "example3.com", password_scramble: "anotherScramble", oauth_json_access_token: "", oauth_json_expiration_datetime_utc: "0000-00-00 00:00:00"}
    ]);

    var integrationsLinkedFieldsArrayOfObjs = [
      {id:1, sort:2, integration_field_db_name:"Vantage Point Stage", capture_field_id:8, integration_field_using_default_value_01:0, integration_field_default_value_string:""},
      {id:2, sort:1, integration_field_db_name:"VantagePointPriority" + longText, capture_field_id:20, integration_field_using_default_value_01:1, integration_field_default_value_string:"five"},
      {id:3, sort:3, integration_field_db_name:"Org", capture_field_id:5, integration_field_using_default_value_01:0, integration_field_default_value_string:""},
      {id:4, sort:3, integration_field_db_name:"Name", capture_field_id:2, integration_field_using_default_value_01:0, integration_field_default_value_string:""},
      {id:5, sort:3, integration_field_db_name:"LongName", capture_field_id:2, integration_field_using_default_value_01:0, integration_field_default_value_string:""}
    ];
    for(let i = 6; i < 15; i++) {
      integrationsLinkedFieldsArrayOfObjs.push({id:i, sort:i, integration_field_db_name:"VP" + i, capture_field_id:i, integration_field_using_default_value_01:0, integration_field_default_value_string:""});
    }
    DatabaseMobx.o_tbl_a_integrations_linked_fields = JSFUNC.mapOfMaps_from_arrayOfObjs(integrationsLinkedFieldsArrayOfObjs);

    DatabaseMobx.o_tbl_a_integrations_linked_field_values = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, sort:1, linked_field_id:1, integration_value_string:"Prospecting " + longText, capture_field_value_string:"1", extra_capture_field_value_strings_comma:""},
      {id:2, sort:2, linked_field_id:1, integration_value_string:"Qualify", capture_field_value_string:"3", extra_capture_field_value_strings_comma:""},
      {id:3, sort:1, linked_field_id:2, integration_value_string:"1", capture_field_value_string:"1", extra_capture_field_value_strings_comma:""},
      {id:4, sort:3, linked_field_id:2, integration_value_string:"3", capture_field_value_string:"3", extra_capture_field_value_strings_comma:""},
      {id:5, sort:2, linked_field_id:2, integration_value_string:"2", capture_field_value_string:"2", extra_capture_field_value_strings_comma:""},
      {id:6, sort:1, linked_field_id:3, integration_value_string:"1.1.A5", capture_field_value_string:"1:100", extra_capture_field_value_strings_comma:""},
      {id:7, sort:2, linked_field_id:3, integration_value_string:"1.2.B7", capture_field_value_string:"1:50,2:50,3:0", extra_capture_field_value_strings_comma:""}
    ]);

    DatabaseMobx.o_tbl_a_integrations_log = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, datetime_utc:"2024-03-07 13:00:00", user_id:1, iud_action_string:"uCEuIN", ce_capture_id:1, integration_unique_id_string:"SEC-1234567890", ce_field_id:1, integration_field_db_name:"vpField1", new_ce_value_string:"Prospect", new_integration_value_string:"Prospecting", error_message:""},
      {id:2, datetime_utc:"2024-03-08 11:00:58", user_id:1, iud_action_string:"iCEiIN", ce_capture_id:1, integration_unique_id_string:"SEC-1234567890", ce_field_id:2, integration_field_db_name:"vpField2", new_ce_value_string:"Fail", new_integration_value_string:"Test", error_message:"Failed"},
      {id:3, datetime_utc:"2024-03-08 19:00:00", user_id:1, iud_action_string:"uCEuIN", ce_capture_id:1, integration_unique_id_string:"SEC-Time", ce_field_id:3, integration_field_db_name:"Time", new_ce_value_string:"Time", new_integration_value_string:"Time", error_message:""},
      {id:4, datetime_utc:"2024-03-08 09:00:00", user_id:1, iud_action_string:"uCEuIN", ce_capture_id:1, integration_unique_id_string:"SEC-Time", ce_field_id:3, integration_field_db_name:"Time", new_ce_value_string:"Time", new_integration_value_string:"Time", error_message:""},
      {id:5, datetime_utc:"2024-03-08 17:00:00", user_id:1, iud_action_string:"uCEuIN", ce_capture_id:1, integration_unique_id_string:"SEC-Time", ce_field_id:3, integration_field_db_name:"Time", new_ce_value_string:"Time", new_integration_value_string:"Time", error_message:""},
      {id:6, datetime_utc:"2024-03-08 02:00:00", user_id:1, iud_action_string:"uCEuIN", ce_capture_id:1, integration_unique_id_string:"SEC-Time", ce_field_id:3, integration_field_db_name:"Time", new_ce_value_string:"Time", new_integration_value_string:"Time", error_message:""},
      {id:7, datetime_utc:"2024-03-08 14:00:00", user_id:1, iud_action_string:"uCEuIN", ce_capture_id:1, integration_unique_id_string:"SEC-Time", ce_field_id:3, integration_field_db_name:"Time", new_ce_value_string:"Time", new_integration_value_string:"Time", error_message:""},
      {id:8, datetime_utc:"2024-03-08 18:00:00", user_id:1, iud_action_string:"uCEuIN", ce_capture_id:1, integration_unique_id_string:"SEC-Time", ce_field_id:3, integration_field_db_name:"Time", new_ce_value_string:"Time", new_integration_value_string:"Time", error_message:""},
      {id:9, datetime_utc:"2024-03-08 22:00:00", user_id:1, iud_action_string:"uCEuIN", ce_capture_id:1, integration_unique_id_string:"SEC-Time", ce_field_id:3, integration_field_db_name:"Time", new_ce_value_string:"Time", new_integration_value_string:"Time", error_message:""}
    ]);

    var devLogAdminArrayOfObjs = [
      {id:1, datetime_utc:"2019-04-15 12:00:00", user_id:5, user:"User", admin_action_code:300, field:"Field Action", value:"Value Changed"},
      {id:2, datetime_utc:"2024-01-19 12:00:59", user_id:1, user:"Drew", admin_action_code:100, field:"", value:"Delete Capture 1 - TEST"},
      {id:3, datetime_utc:"2024-01-19 13:00:58", user_id:2, user:"Skip", admin_action_code:400, field:"", value:""},
      {id:4, datetime_utc:"2024-01-19 14:00:57", user_id:-99, user:"--Cron Daily 0900 UTC--", admin_action_code:200, field:"", value:"Archived Captures: 1,17"}
    ];
    for(let i = 1; i < 28; i++) {
      devLogAdminArrayOfObjs.push({id:(i+4), datetime_utc:"2024-02-" + JSFUNC.zero_pad_integer_from_left(i, 2) + " 13:00:00", user_id:1, user:"Drew", admin_action_code:100, field:"", value:"Deleted Capture " + i});
    }
    DatabaseMobx.o_tbl_a_log_admin = JSFUNC.mapOfMaps_from_arrayOfObjs(devLogAdminArrayOfObjs);

    DatabaseMobx.o_tbl_a_pt_differentiators = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Price"},
      {id:2, name:"A Really Great Idea"},
      {id:3, name:"People"},
      {id:4, name:"Aviation: Specialty"}
    ]);

    DatabaseMobx.o_tbl_a_pwin_calc_lines = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, progress_x1:15, progress_x2:60, pwin_y1:15, pwin_y2:40},
      {id:2, progress_x1:30, progress_x2:50, pwin_y1:80, pwin_y2:70},
      {id:3, progress_x1:70, progress_x2:90, pwin_y1:30, pwin_y2:90}
    ]);

    DatabaseMobx.o_tbl_a_pwin_colors = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, color:"bb3333", percent_start:0, font_color:"ffffff"},
      {id:2, color:"bb9933", percent_start:20, font_color:"ffffff"},
      {id:3, color:"bbbb33", percent_start:30, font_color:"ffffff"},
      {id:4, color:"99bb33", percent_start:40, font_color:"ffffff"},
      {id:5, color:"33bb33", percent_start:50, font_color:"ffffff"},
      {id:6, color:"33bb99", percent_start:60, font_color:"ffffff"},
      {id:7, color:"33bbbb", percent_start:70, font_color:"ffffff"},
      {id:8, color:"3399bb", percent_start:80, font_color:"ffffff"},
      {id:9, color:"3333bb", percent_start:90, font_color:"ffffff"}
    ]);

    DatabaseMobx.o_tbl_a_reasons_won_lost = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Price", won1_lost2_both3:3},
      {id:2, name:"Techology", won1_lost2_both3:3},
      {id:3, name:"Non Compliant Technical", won1_lost2_both3:2},
      {id:4, name:"Non Compliant Past Performance", won1_lost2_both3:2},
      {id:5, name:"Did not Deliver RFP on Time", won1_lost2_both3:2},
      {id:6, name:"Won Reason", won1_lost2_both3:1},
      {id:7, name:"Lost Reason", won1_lost2_both3:2},
      {id:8, name:"Both Reason", won1_lost2_both3:3}
    ]);

    DatabaseMobx.o_tbl_a_risks_categories = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Cat 1"},
      {id:2, name:"Cat 2"}
    ]);

    DatabaseMobx.o_tbl_a_risks_impact_levels = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Insignificant", percent_0to100:10, color:"88ccbb"},
      {id:2, name:"Minor", percent_0to100:25, color:"66dd88"},
      {id:3, name:"Moderate", percent_0to100:50, color:"ccbb66"},
      {id:4, name:"Major", percent_0to100:75, color:"e69933"},
      {id:5, name:"Severe", percent_0to100:90, color:"dd4444"}
    ]);

    DatabaseMobx.o_tbl_a_risks_probability_levels = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Improbable", percent_0to100:10, color:"88ccbb"},
      {id:2, name:"Unlikely", percent_0to100:25, color:"66dd88"},
      {id:3, name:"Possible", percent_0to100:50, color:"ccbb66"},
      {id:4, name:"Likely", percent_0to100:75, color:"e69933"},
      {id:5, name:"Near Certainty", percent_0to100:90, color:"dd4444"}
    ]);

    DatabaseMobx.o_tbl_a_search_fields = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, sr0_de1:0, field_id:1, sort:2, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:3, sum_row_01:0},
      {id:2, sr0_de1:0, field_id:2, sort:4, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:3, sr0_de1:0, field_id:3, sort:6, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:4, sr0_de1:0, field_id:4, sort:8, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:5, sr0_de1:0, field_id:5, sort:10, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:6, sr0_de1:0, field_id:6, sort:1, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:7, sr0_de1:0, field_id:7, sort:3, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:8, sr0_de1:0, field_id:8, sort:5, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:9, sr0_de1:0, field_id:9, sort:7, used_in_search_01:1, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:10, sr0_de1:0, field_id:10, sort:9, used_in_search_01:0, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:11, sr0_de1:0, field_id:11, sort:11, used_in_search_01:0, width_em:11, sortcol_1no_2asc_3desc:1, sum_row_01:1},
      {id:12, sr0_de1:1, field_id:1, sort:1, used_in_search_01:1, width_em:6, sortcol_1no_2asc_3desc:2, sum_row_01:0},
      {id:13, sr0_de1:1, field_id:3, sort:2, used_in_search_01:1, width_em:10, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:14, sr0_de1:1, field_id:2, sort:3, used_in_search_01:1, width_em:20, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:15, sr0_de1:1, field_id:4, sort:4, used_in_search_01:1, width_em:20, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:16, sr0_de1:1, field_id:5, sort:5, used_in_search_01:1, width_em:20, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:17, sr0_de1:1, field_id:6, sort:6, used_in_search_01:1, width_em:12, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:18, sr0_de1:1, field_id:8, sort:7, used_in_search_01:1, width_em:12, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:19, sr0_de1:1, field_id:9, sort:8, used_in_search_01:1, width_em:12, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:20, sr0_de1:1, field_id:10, sort:9, used_in_search_01:1, width_em:12, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:21, sr0_de1:1, field_id:11, sort:10, used_in_search_01:1, width_em:12, sortcol_1no_2asc_3desc:1, sum_row_01:1},
      {id:22, sr0_de1:1, field_id:21, sort:11, used_in_search_01:1, width_em:12, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:23, sr0_de1:1, field_id:23, sort:12, used_in_search_01:1, width_em:12, sortcol_1no_2asc_3desc:1, sum_row_01:0},
      {id:24, sr0_de1:1, field_id:24, sort:13, used_in_search_01:1, width_em:12, sortcol_1no_2asc_3desc:1, sum_row_01:0}
    ]);

    DatabaseMobx.o_tbl_a_shaping_progress_colors = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, color:"dd5555", percent_start:0, font_color:"2a2a2a"},
      {id:2, color:"e4e433", percent_start:28, font_color:"2a2a2a"},
      {id:3, color:"55e455", percent_start:60, font_color:"2a2a2a"},
      {id:4, color:"7766ee", percent_start:85, font_color:"ffffff"}
    ]);

    DatabaseMobx.o_tbl_a_shaping_questions_pool = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Is there a business need for this program?", answer_select1_text2_textarea3:1, tag_ids_comma:"1", admin_sort:1, hover_text:"hover example", question_name_styling_string_comma:""},
      {id:2, name:"Explain the customer business case.", answer_select1_text2_textarea3:3, tag_ids_comma:"1,2", admin_sort:2, hover_text:"", question_name_styling_string_comma:"bold,italic,color#990000,highlight#eeeeee"},
      {id:3, name:"Select Something", answer_select1_text2_textarea3:1, tag_ids_comma:"2", admin_sort:3, hover_text:"", question_name_styling_string_comma:""},
      {id:4, name:"Type Something for Text", answer_select1_text2_textarea3:2, tag_ids_comma:"1", admin_sort:4, hover_text:"", question_name_styling_string_comma:"presetID:1"},
      {id:5, name:"Selection for the select of a long title to test out the length of a long select question selecting things selectively", answer_select1_text2_textarea3:1, tag_ids_comma:"", admin_sort:5, hover_text:"", question_name_styling_string_comma:""},
      {id:6, name:"Won Question #1 Select", answer_select1_text2_textarea3:1, tag_ids_comma:"1,3,2", admin_sort:6, hover_text:"", question_name_styling_string_comma:""},
      {id:1789, name:"Won Question #2 Textarea", answer_select1_text2_textarea3:3, tag_ids_comma:"", admin_sort:7, hover_text:"", question_name_styling_string_comma:""}
    ]);

    DatabaseMobx.o_tbl_a_shaping_questions_tags = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Strategy", hover_text:"This is the strategy"},
      {id:2, name:"Business Process Development Efforts", hover_text:""},
      {id:3, name:"DNE", hover_text:""},
      {id:4, name:"DNE2", hover_text:""}
    ]);

    DatabaseMobx.o_tbl_a_shaping_select_answers = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, question_id:1, name:"Yes, the business need is critical", score0to100:100},
      {id:2, question_id:1, name:"Sort of", score0to100:60},
      {id:3, question_id:1, name:"Not really", score0to100:30},
      {id:4, question_id:1, name:"No", score0to100:0},
      {id:5, question_id:3, name:"All of it > 100", score0to100:100},
      {id:6, question_id:3, name:"None of it < 20", score0to100:0},
      {id:7, question_id:5, name:"100", score0to100:100},
      {id:8, question_id:5, name:"75", score0to100:75},
      {id:9, question_id:5, name:"50", score0to100:50},
      {id:10, question_id:5, name:"25", score0to100:25},
      {id:11, question_id:5, name:"0", score0to100:0},
      {id:12, question_id:6, name:"WIN", score0to100:100},
      {id:13, question_id:6, name:"Not So Win", score0to100:0}
    ]);

    DatabaseMobx.o_tbl_a_shortcut_presets_capture_managers = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, sort:2, preset_name:"Second CM Preset", user_ids_colon_percents_comma:"1:100"},
      {id:2, sort:1, preset_name:"First CM Preset", user_ids_colon_percents_comma:"2:30,3:50,4:15,5:5"}
    ]);

    DatabaseMobx.o_tbl_a_shortcut_presets_documents_card_folders = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, sort:2, preset_name:"Second Docs Preset"},
      {id:2, sort:1, preset_name:"First Docs Preset"}
    ]);

    DatabaseMobx.o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, shortcut_preset_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"B(1)", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-12-27", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:2, shortcut_preset_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"A(2)", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-12-27", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:3, shortcut_preset_id:1, folder0_file1:0, parent_folder_id:2, fileupload0_onlinelink1:0, file_loc:"", display_name:"C(3)", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-12-27", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:4, shortcut_preset_id:1, folder0_file1:0, parent_folder_id:1, fileupload0_onlinelink1:0, file_loc:"", display_name:"D(4)", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-12-27", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:5, shortcut_preset_id:1, folder0_file1:0, parent_folder_id:2, fileupload0_onlinelink1:0, file_loc:"", display_name:"B(5)", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-12-27", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:6, shortcut_preset_id:1, folder0_file1:0, parent_folder_id:5, fileupload0_onlinelink1:0, file_loc:"", display_name:"E(6)", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-12-27", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:7, shortcut_preset_id:1, folder0_file1:0, parent_folder_id:2, fileupload0_onlinelink1:0, file_loc:"", display_name:"A(7)", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-12-27", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:8, shortcut_preset_id:2, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"SPDFE#2", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-12-30", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
    ]);

    DatabaseMobx.o_tbl_a_single_capture_templates_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"testfile1.xml", display_name:"Test Single Capture Template 1", keywords:"keywords", notes:"notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"ppt"},
      {id:2, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"testfile2.xml", display_name:"Test B Single Capture Template 2", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"word"},
      {id:3, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"New Templates Folder", keywords:"keywords", notes:"Notes?", content_unique_lowercase:"content", upload_date:"2019-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:4, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"testfile3.xml", display_name:"Excel Thing.xml", keywords:"keywords", notes:"notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"excel"},
      {id:5, folder0_file1:1, parent_folder_id:3, fileupload0_onlinelink1:0, file_loc:"testfile4.xml", display_name:"Test D", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"general"},
      {id:6, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"BB", keywords:"keywords", notes:"Folder Test", content_unique_lowercase:"content", upload_date:"2019-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:7, folder0_file1:0, parent_folder_id:6, fileupload0_onlinelink1:0, file_loc:"", display_name:"BBtt", keywords:"keywords", notes:"Folder Test", content_unique_lowercase:"content", upload_date:"2019-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:8, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"AA", keywords:"keywords", notes:"Folder Test", content_unique_lowercase:"content", upload_date:"2019-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:9, folder0_file1:0, parent_folder_id:6, fileupload0_onlinelink1:0, file_loc:"", display_name:"BBcc", keywords:"keywords", notes:"Folder Test", content_unique_lowercase:"content", upload_date:"2019-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:10, folder0_file1:0, parent_folder_id:8, fileupload0_onlinelink1:0, file_loc:"", display_name:"AAdd", keywords:"keywords", notes:"Folder Test", content_unique_lowercase:"content", upload_date:"2019-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:11, folder0_file1:0, parent_folder_id:9, fileupload0_onlinelink1:0, file_loc:"", display_name:"BBcc11", keywords:"keywords", notes:"Folder Test", content_unique_lowercase:"content", upload_date:"2019-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:12, folder0_file1:0, parent_folder_id:6, fileupload0_onlinelink1:0, file_loc:"", display_name:"BBff", keywords:"keywords", notes:"Folder Test", content_unique_lowercase:"content", upload_date:"2019-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""}
    ]);

    DatabaseMobx.o_tbl_a_stages_pool = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Prospect", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:1, color:"ff9999", hover_text:"This is the Prospect Stage where Captures are kept initially.\n" + longText, admin_sort:1},
      {id:2, name:"Tracking", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:2, color:"eebb88", hover_text:"", admin_sort:2},
      {id:3, name:"Qualification", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:2, color:"dddd88", hover_text:"", admin_sort:3},
      {id:4, name:"Pursuit", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:2, color:"88dd88", hover_text:"", admin_sort:4},
      {id:5, name:"Capture", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:2, color:"88dddd", hover_text:"", admin_sort:5},
      {id:6, name:"Proposal", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:2, color:"8888dd", hover_text:"", admin_sort:6},
      {id:7, name:"Submit", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:3, color:"884488", hover_text:"", admin_sort:7},
      {id:8, name:"Protest", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:3, color:"dd88dd", hover_text:"", admin_sort:8},
      {id:9, name:"Won", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:4, color:"22dd22", hover_text:"", admin_sort:9},
      {id:10, name:"Lost", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:5, color:"dd2222", hover_text:"", admin_sort:11},
      {id:11, name:"No Bid", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:6, color:"dddddd", hover_text:"", admin_sort:12},
      {id:12, name:"Government Cancelled", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:7, color:"aaaaaa", hover_text:"", admin_sort:13},
      {id:13, name:"Customer Cancelled", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:8, color:"abcdef", hover_text:"", admin_sort:14},
      {id:14, name:"Won Type 2", pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7:4, color:"00aa00", hover_text:"", admin_sort:10}
    ]);

    DatabaseMobx.o_tbl_a_styling_string_presets = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Drew Preset", styling_string_comma_list:"bold,italic,color#ff00ff,highlight#000000"}
    ]);

    DatabaseMobx.o_tbl_a_tasks_extra_capture_fields = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_field_id:4, sort:2},
      {id:2, capture_field_id:2, sort:1}
    ]);

    DatabaseMobx.o_tbl_a_teammates_contract_agreement_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Ours", sort:1},
      {id:2, name:"Teammate's", sort:2}
    ]);

    DatabaseMobx.o_tbl_a_teammates_contract_statuses = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Process Not Started", hc0_they1_we2:0, color:"bbbbbb", sort:-4},
      {id:2, name:"Cancelled", hc0_they1_we2:0, color:"888888", sort:-3},
      {id:3, name:"Generate / Send Agreement", hc0_they1_we2:0, color:"AA5577", sort:-2},
      {id:4, name:"Fully Executed", hc0_they1_we2:0, color:"66AA66", sort:-1},
      {id:5, name:"Sent / Teammate is Reviewing", hc0_they1_we2:1, color:"C09000", sort:1},
      {id:6, name:"Received / We are Reviewing", hc0_they1_we2:2, color:"1155CC", sort:1}
    ]);

    DatabaseMobx.o_tbl_a_teammates_contract_templates_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, contract_type_id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"G File 1", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"word"},
      {id:2, contract_type_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"F Folder 2", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2018-07-18", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:3, contract_type_id:1, folder0_file1:1, parent_folder_id:4, fileupload0_onlinelink1:0, file_loc:"", display_name:"E File 3", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-09-26", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:4, contract_type_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"", display_name:"D Folder 4", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-09-26", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:5, contract_type_id:1, folder0_file1:1, parent_folder_id:4, fileupload0_onlinelink1:0, file_loc:"", display_name:"C File 5", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-09-26", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:6, contract_type_id:1, folder0_file1:0, parent_folder_id:4, fileupload0_onlinelink1:0, file_loc:"", display_name:"B Folder 6", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-09-26", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:7, contract_type_id:1, folder0_file1:1, parent_folder_id:6, fileupload0_onlinelink1:0, file_loc:"", display_name:"A File 7", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2023-09-26", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:""},
      {id:8, contract_type_id:3, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"ta_file.xml", display_name:"TA_file.xml", keywords:"", notes:"", content_unique_lowercase:"", upload_date:"2024-08-21", upload_user_id:1, upload_user_name:"Drew Name", access:"", xml_type:"doc"}
    ]);

    DatabaseMobx.o_tbl_a_teammates_contract_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Non-Disclosure Agreement", short_name:"NDA", admin_sort:1},
      {id:2, name:"Subcontractor Agreement", short_name:"SubK", admin_sort:3},
      {id:3, name:"Teaming Agreement", short_name:"TA", admin_sort:2}
    ]);

    DatabaseMobx.o_tbl_a_teammates_extra_fields = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, input_type:"textarea", db_name:"notes", display_name:"Notes", sort:2, hover_text:""},
      {id:2, input_type:"text", db_name:"test_team", display_name:"Test Team", sort:1, hover_text:"Hover Test Team"}
    ]);

    DatabaseMobx.o_tbl_a_teammates_ratings_questions = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Responsiveness", sort:2, weight:5},
      {id:3, name:"Proposal Support", sort:1, weight:3},
      {id:6, name:"Reliability", sort:3, weight:8}
    ]);

    DatabaseMobx.o_tbl_a_teammates_surveys_scale05_colors = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, color0:"ffcccc", color1:"ffddcc", color2:"ffffcc", color3:"cccccc", color4:"ccccff", color5:"ccffcc"},
      {id:999, color0:"111111", color1:"333333", color2:"555555", color3:"777777", color4:"999999", color5:"bbbbbb"}
    ]);

    DatabaseMobx.o_tbl_a_teammates_workshare_types = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Fixed # FTEs", sort:1},
      {id:2, name:"Fixed $ of Contract Overall Value", sort:2},
      {id:3, name:"% of FTEs", sort:3},
      {id:4, name:"% of Contract Overall Value", sort:4}
    ]);

    DatabaseMobx.o_tbl_a_users_per_email = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {
        id: 1,
        email: "andrew.c.blackburn@test.com",
        first_name: "Drew",
        last_name: "UPE",
        disabled: 0,
        lockedout: 0,
        no_bit_mass_emails_01: 0,
        can_create_public_01: 1,
        code_update_01: 0,
        font_size_px: 12,
        left_nav_0collapsed_1expanded: 1,
        right_panel_width_em: 28,
        notification_email_01: 1,
        archived_captures_preference: "noLoadArchivedNoPrompt", //"alwaysLoadArchived", "noLoadArchivedWithPrompt", "noLoadArchivedNoPrompt"
        capture_card_min_height_em: 20,
        capture_card_max_height_em: 35,
        dates_card_three_cols_0_single_col_1: 0,
        recently_visited_capture_ids_comma: "5,3,1,4,2",
        favorited_capture_ids_comma: "1,2",
        cst_row_height_em: 5,
        cst_edit_cells_mode_01: 1,
        stage_view_box_size: 3,
        stage_view_selected_capture_type_ids_comma: "1,3",
        stage_view_minimized_stage_ids_comma: "2,7",
        priority_view_minimized_priority_ids_comma: "1",
        capture_chart_start_date: "2016-12-31",
        capture_chart_end_date: "2024-01-01",
        capture_chart_progress_size: 10,
        capture_chart_gantt_size: 2,
        notepad_selected_tab_db_name: "noteStamps",
        notepad_note_stamps_view_flag: "cards3col",
        gcss_search_results_compact0_large1: 0,
        gcss_search_results_sort_gcss_field_db_name: "",
        gcss_search_results_sort_desc0_asc1: 1,
        gcss_ids_marked_for_import_comma: "1,3,5",
        gcss_govwin_ids_marked_for_import_comma: "FBO3345971,FBO3345236",
        gcss_fedcomp_ids_marked_for_import_comma: "f89089089fds87f7s89fd7s89fds890fds789fds80,fs890fds890fds890fdsfds890fds890fds890fds089",
        gcss_sam0_govwin1: 3,
        gcss_cexai_filters_and0_or1: 1,
        gcss_cexai_selected_filters_auto_search_ids_comma: "9,7",
        gcss_cexai_positive_gcss_ids_comma: "6,2,22",
        gcss_cexai_negative_gcss_ids_comma: "3,5",
        sync_user_ce_actions_in_integration_system_01: 1,
        password: "SECRET",
        password_last_changed_date: "2018-01-27",
        welcome_email_sent_01: 1,
        last_refresh_datetime_utc: "2021-12-07",
        refresh_data_comma: "tbl_name:1",
        currently_open_capture_id: 0
      },
      {
        id: 2,
        email: "skip@test.com",
        first_name: "Skip",
        last_name: "UPE",
        disabled: 0,
        lockedout: 1,
        no_bit_mass_emails_01: 1,
        can_create_public_01: 1,
        code_update_01: 0,
        font_size_px: 12,
        left_nav_0collapsed_1expanded: 0,
        right_panel_width_em: 22,
        notification_email_01: 1,
        archived_captures_preference: "alwaysLoadArchived",
        capture_card_min_height_em: 20,
        capture_card_max_height_em: 35,
        dates_card_three_cols_0_single_col_1: 0,
        recently_visited_capture_ids_comma: "5,3,1,4,2",
        favorited_capture_ids_comma: "",
        cst_row_height_em: 5,
        cst_edit_cells_mode_01: 0,
        stage_view_selected_capture_type_ids_comma: "",
        stage_view_minimized_stage_ids_comma: "",
        priority_view_minimized_priority_ids_comma: "",
        capture_chart_start_date: "2018-01-05",
        capture_chart_end_date: "2018-01-05",
        capture_chart_progress_size: 15,
        capture_chart_gantt_size: 1,
        password_last_changed_date: "2018-01-27",
        welcome_email_sent_01: 0
      },
      {
        id: 3,
        email: "mike@test.com",
        first_name: "Mike",
        last_name: "UPE",
        disabled: 1,
        lockedout: 0,
        no_bit_mass_emails_01: 0,
        can_create_public_01: 1,
        code_update_01: 0,
        font_size_px: 12,
        left_nav_0collapsed_1expanded: 1,
        right_panel_width_em: 22,
        notification_email_01: 1,
        archived_captures_preference: "noLoadArchivedNoPrompt",
        capture_card_min_height_em: 20,
        capture_card_max_height_em: 35,
        dates_card_three_cols_0_single_col_1: 0,
        recently_visited_capture_ids_comma: "5,3,1,4,2",
        favorited_capture_ids_comma: "",
        cst_row_height_em: 5,
        cst_edit_cells_mode_01: 0,
        stage_view_selected_capture_type_ids_comma: "",
        stage_view_minimized_stage_ids_comma: "",
        priority_view_minimized_priority_ids_comma: "",
        capture_chart_start_date: "2018-01-05",
        capture_chart_end_date: "2018-01-05",
        capture_chart_progress_size: 20,
        capture_chart_gantt_size: 1,
        password_last_changed_date: "2018-01-27",
        welcome_email_sent_01: 1
      }
    ]);
    for(let id = 1; id <= 15; id++) {
      DatabaseMobx.o_tbl_a_users_per_email.set((id+3), JSFUNC.map_from_obj({id:(id+3), email:"test" + (id+3) + "@test.com", first_name:"Test" + JSFUNC.zero_pad_integer_from_left(id+3, 2), last_name:"Zesterlastname", disabled:0, lockedout:0, no_bit_mass_emails_01:0, can_create_public_01:0, welcome_email_sent_01:1}));
    }

    var devUsersArrayOfObjs = [
      {id: 1, user_per_email_id: 1, division_id: 1, power_name: "superadmin"},
      {id: 2, user_per_email_id: 1, division_id: 1, power_name: "divexec"},
      {id: 3, user_per_email_id: 2, division_id: 1, power_name: "captureexec"},
      {id: 4, user_per_email_id: 2, division_id: 1, power_name: "contractsexec"},
      {id: 5, user_per_email_id: 1, division_id: 1, power_name: "captureexec"},
      {id: 6, user_per_email_id: 1, division_id: 1, power_name: "contracts"},
      {id: 7, user_per_email_id: 1, division_id: 1, power_name: "contractsexec"},
      {id: 8, user_per_email_id: 1, division_id: -1, power_name: "budget"},
      {id: 9, user_per_email_id: 1, division_id: 99, power_name: "budgetexec"},
      {id: 10, user_per_email_id: 1, division_id: 1, power_name: "captureconsultant"},
      {id: 11, user_per_email_id: 3, division_id: 6, power_name: "captureexec"},
      {id: 12, user_per_email_id: 1, division_id: 2, power_name: "divexec"},
      {id: 13, user_per_email_id: 1, division_id: 1, power_name: "captureexecreadonly"},
      {id: 14, user_per_email_id: 1, division_id: 1, power_name: "divexecreadonly"},
      {id: 15, user_per_email_id: 2, division_id: 2, power_name: "divexecreadonly"}
    ];
    for(let id = 1; id <= 15; id++) {
      devUsersArrayOfObjs.push({id:(id+15), user_per_email_id:(id+3), division_id:1, power_name:"captureexec"});
    }
    for(let devUserObj of devUsersArrayOfObjs) {
      devUserObj.bit_non_billable_user_01 = ((devUserObj.id === 2) ? (1) : (0));
      devUserObj.alt_full_name = ((devUserObj.id === 1) ? ("DrewAdmin") : (""));
      devUserObj.num_logins = devUserObj.id;
      devUserObj.last_login_datetime_utc = "2020-10-27 10:09:00";
      devUserObj.last_login_timezone_string = "Eastern";
      devUserObj.last_login_browser_type = "Chrome";
      devUserObj.last_login_mediaqueryflag = 5;
      devUserObj.start_tab_name = "Contacts";
      devUserObj.quick_access_master_preset_ids_comma = "3,1,2,-101,10,11,-103,12,13,14,15,16,17,18,19,20";
      devUserObj.quick_access_master_preset_id_selected = quickAcessMasterPresetIDSelected;
      devUserObj.gantt_date_preset_id_selected = 1;
      devUserObj.divexec_filter_division_ids_comma = "1,3,5,7,9";
      devUserObj.divexec_goals_latest_year = 0;
      devUserObj.divexec_goals_num_years = 10;
      devUserObj.divexec_graphs_start_date = "2017-02-27";
      devUserObj.divexec_graphs_end_date = "9002-01-04";
      devUserObj.divexec_performance_money_field_id = 11;
      devUserObj.divexec_finproj_custom0_manual1 = 0;
      devUserObj.divexec_finproj_filter_preset_id = -1;
      devUserObj.divexec_finproj_division_ids_comma = "";
      devUserObj.divexec_finproj_stage_ids_comma = "";
      devUserObj.divexec_finproj_money_field_id = 11;
      devUserObj.divexec_hotbits_fixed0_calendar1 = 1;
      devUserObj.divexec_critthresh_money_field_id = 11;
    }
    DatabaseMobx.o_tbl_a_users = JSFUNC.mapOfMaps_from_arrayOfObjs(devUsersArrayOfObjs);

    var devTblCapturesFieldsArrayOfObjs = [
      {id:1, display_name:"Capture ID",                                 db_name:"id",                                             input_type:"int_positive",                    select_tbl_name:""},
      {id:2, display_name:"Opportunity Name",                           db_name:"opportunity_name",                               input_type:"text",                            select_tbl_name:""},
      {id:3, display_name:"Codename",                                   db_name:"codename",                                       input_type:"text",                            select_tbl_name:""},
      {id:4, display_name:"Capture Team",                               db_name:"capture_managers_ids_colon_percent_comma",       input_type:"sharedpercent",                   select_tbl_name:"tbl_a_users"},
      {id:5, display_name:"Division Owners",                            db_name:"division_owners_ids_colon_percent_comma",        input_type:"sharedpercent",                   select_tbl_name:"tbl_a_divisions"},
      {id:6, display_name:"Capture Type",                               db_name:"capture_type_id",                                input_type:"select",                          select_tbl_name:"tbl_a_capture_types"},
      {id:7, display_name:"Prime / Sub",                                db_name:"tbl_a_capture_types_prime_sub",                  input_type:"select",                          select_tbl_name:"tbl_a_capture_types_prime_sub"},
      {id:8, display_name:"Stage Custom Name",                          db_name:"stage_id",                                       input_type:"select",                          select_tbl_name:"tbl_a_stages_pool"},
      {id:9, display_name:"Contract Type",                              db_name:"contract_type_id",                               input_type:"select",                          select_tbl_name:"tbl_a_contract_types"},
      {id:10,display_name:"IDIQ Vehicle",                               db_name:"idiq_capture_id_TO_link",                        input_type:"select",                          select_tbl_name:"tbl_captures_idiq"},
      {id:11,display_name:"Contract Overall Value",                     db_name:"contract_overall_value",                         input_type:"money",                           select_tbl_name:""},
      {id:12,display_name:"PoP (Months)",                               db_name:"period_of_performance",                          input_type:"int_positive",                    select_tbl_name:""},
      {id:13,display_name:"Contracts Manager",                          db_name:"contracts_manager_user_id",                      input_type:"select",                          select_tbl_name:"tbl_a_users_contracts"},
      {id:14,display_name:"Budget Manager",                             db_name:"budget_manager_user_id",                         input_type:"select",                          select_tbl_name:"tbl_a_users_budget"},
      {id:15,display_name:"Description of Work",                        db_name:"description_of_work",                            input_type:"textarea",                        select_tbl_name:""},
      {id:16,display_name:"Solicitation #",                             db_name:"solicitation_num",                               input_type:"text",                            select_tbl_name:""},
      {id:17,display_name:"NAICS Code",                                 db_name:"naics_code_ids_comma",                           input_type:"selectmulti",                     select_tbl_name:"tbl_bit_master_naics_codes"},
      {id:18,display_name:"Competition Type",                           db_name:"competition_type_bm_set_aside_ids_comma",        input_type:"selectmulti",                     select_tbl_name:"tbl_bit_master_set_asides"},
      {id:19,display_name:"Favorite",                                   db_name:"capture_favorites_upe_x",                        input_type:"capturefavorite",                 select_tbl_name:""},
      {id:20,display_name:"Priority",                                   db_name:"capture_priority_level_id",                      input_type:"captureprioritylevel",            select_tbl_name:"tbl_a_capture_priority_levels"},
      {id:21,display_name:"Added Date",                                 db_name:"added_date",                                     input_type:"date",                            select_tbl_name:""},
      {id:22,display_name:"RFP Date",                                   db_name:"rfp_date",                                       input_type:"date",                            select_tbl_name:""},
      {id:23,display_name:"Proposal Due Date/Time",                     db_name:"proposal_due_datetime_utc",                      input_type:"datetime_dayMdyDaysUntil2Overdue",select_tbl_name:""},
      {id:24,display_name:"Award Date",                                 db_name:"award_date",                                     input_type:"date",                            select_tbl_name:""},
      {id:25,display_name:"Contract Start Date",                        db_name:"contract_start_date",                            input_type:"date",                            select_tbl_name:""},
      {id:26,display_name:"Contract End Date",                          db_name:"contract_end_date",                              input_type:"date",                            select_tbl_name:""},
      {id:27,display_name:"Archive Date",                               db_name:"archive_date",                                   input_type:"date",                            select_tbl_name:""},
      {id:28,display_name:"Last Changed Date",                          db_name:"last_changed_date",                              input_type:"date",                            select_tbl_name:""},
      {id:29,display_name:"Last Stage Date",                            db_name:"last_stage_date",                                input_type:"date",                            select_tbl_name:""},
      {id:30,display_name:"Last Progress Date",                         db_name:"last_progress_date",                             input_type:"date",                            select_tbl_name:""},
      {id:31,display_name:"Last PWin Date",                             db_name:"last_pwin_date",                                 input_type:"date",                            select_tbl_name:""},
      {id:32,display_name:"Last RFP Date",                              db_name:"last_rfp_date",                                  input_type:"date",                            select_tbl_name:""},
      {id:33,display_name:"Reasons Won/Lost",                           db_name:"reasons_won_lost_ids_colon_percent_comma",       input_type:"sharedpercent",                   select_tbl_name:"tbl_a_reasons_won_lost"},
      {id:34,display_name:"Contract Revenue Value",                     db_name:"contract_revenue_value",                         input_type:"money",                           select_tbl_name:""},
      {id:35,display_name:"Allocated Revenue Value",                    db_name:"allocated_revenue_value",                        input_type:"money",                           select_tbl_name:""},
      {id:36,display_name:"Allocated Net Value",                        db_name:"allocated_net_value",                            input_type:"money",                           select_tbl_name:""},
      {id:37,display_name:"Allocated Revenue Per Month",                db_name:"allocated_revenue_per_month",                    input_type:"money",                           select_tbl_name:""},
      {id:38,display_name:"Allocated Net Per Month",                    db_name:"allocated_net_per_month",                        input_type:"money",                           select_tbl_name:""},
      {id:39,display_name:"PWin Adjusted Contract Overall Value",       db_name:"pwin_adjusted_contract_overall_value",           input_type:"money",                           select_tbl_name:""},
      {id:40,display_name:"PWin Adjusted Contract Revenue Value",       db_name:"pwin_adjusted_contract_revenue_value",           input_type:"money",                           select_tbl_name:""},
      {id:41,display_name:"PWin Adjusted Allocated Revenue Value",      db_name:"pwin_adjusted_allocated_revenue_value",          input_type:"money",                           select_tbl_name:""},
      {id:42,display_name:"PWin Adjusted Allocated Net Value",          db_name:"pwin_adjusted_allocated_net_value",              input_type:"money",                           select_tbl_name:""},
      {id:43,display_name:"IDIQ TO Anticipated Value",                  db_name:"idiq_to_anticipated_value",                      input_type:"money",                           select_tbl_name:""},
      {id:44,display_name:"Total Shaping Progress",                     db_name:"shaping_total_progress",                         input_type:"progress_bar_shaping",            select_tbl_name:""},
      {id:45,display_name:"Current Stage Progress",                     db_name:"shaping_stage_progress",                         input_type:"progress_bar_shaping",            select_tbl_name:""},
      {id:46,display_name:"PWin",                                       db_name:"pwin",                                           input_type:"progress_bar_pwin",               select_tbl_name:""},
      {id:47,display_name:"Our Prime/Sub Teammate Division",            db_name:"our_prime_sub_teammate_division_id",             input_type:"select",                          select_tbl_name:"tbl_a_divisions"},
      {id:48,display_name:"Our Prime/Sub Teammate Allocation %",        db_name:"our_prime_sub_teammate_allocation",              input_type:"percent_decimal_0to100",          select_tbl_name:""},
      {id:49,display_name:"Prime Company",                              db_name:"prime_contact_company_id",                       input_type:"select",                          select_tbl_name:"tbl_g_contacts_companies"},
      {id:50,display_name:"Total Small Business Allocation %",          db_name:"req_sb_allocation",                              input_type:"percent_decimal_0to100",          select_tbl_name:""},
      {id:51,display_name:"# Teammates",                                db_name:"num_teammates",                                  input_type:"int_positive",                    select_tbl_name:""},
      {id:52,display_name:"# LB Teammates",                             db_name:"num_lb_teammates",                               input_type:"int_positive",                    select_tbl_name:""},
      {id:53,display_name:"# SB Teammates",                             db_name:"num_sb_teammates",                               input_type:"int_positive",                    select_tbl_name:""},
      {id:54,display_name:"Total Team Allocation %",                    db_name:"total_team_allocation",                          input_type:"percent_decimal_0to100",          select_tbl_name:""},
      {id:55,display_name:"Total LB Team Allocation %",                 db_name:"total_lb_allocation",                            input_type:"percent_decimal_0to100",          select_tbl_name:""},
      {id:56,display_name:"Total SB Team Allocation %",                 db_name:"total_sb_allocation",                            input_type:"percent_decimal_0to100",          select_tbl_name:""},
      {id:57,display_name:"Total Team Allocation $",                    db_name:"total_team_allocation_cov",                      input_type:"money",                           select_tbl_name:""},
      {id:58,display_name:"Total LB Team Allocation $",                 db_name:"total_lb_allocation_cov",                        input_type:"money",                           select_tbl_name:""},
      {id:59,display_name:"Total SB Team Allocation $",                 db_name:"total_sb_allocation_cov",                        input_type:"money",                           select_tbl_name:""},
      {id:60,display_name:"Our Incumbent Competitor Division",          db_name:"our_incumbent_competitor_division_id",           input_type:"select",                          select_tbl_name:"tbl_a_divisions"},
      {id:61,display_name:"Incumbent Company",                          db_name:"incumbent_contact_company_ids_comma",            input_type:"selectmulti",                     select_tbl_name:"tbl_g_contacts_companies"},
      {id:62,display_name:"Notepad",                                    db_name:"notepad",                                        input_type:"textarea",                        select_tbl_name:""},
      {id:63,display_name:"Note Stamp (Recent/Pinned)",                 db_name:"recent_pinned_notestamp",                        input_type:"notestamp",                       select_tbl_name:""},
      {id:64,display_name:"Next Active Task",                           db_name:"next_active_task",                               input_type:"task",                            select_tbl_name:""},
      {id:100,display_name:"Example Revenue Cost Field 1",              db_name:"example_revenue_cost_field_1",                   input_type:"money",                           select_tbl_name:""},
      {id:101,display_name:"Example Revenue Cost Field 2",              db_name:"example_revenue_cost_field_2",                   input_type:"money",                           select_tbl_name:""},
      {id:102,display_name:"RFI Date",                                  db_name:"rfi_date",                                       input_type:"date",                            select_tbl_name:""},
      {id:103,display_name:"Industry Date",                             db_name:"industry_date",                                  input_type:"date",                            select_tbl_name:""},
      {id:104,display_name:"Bidder Conference Date But the date name is super long",db_name:"bidder_conference_date",             input_type:"date",                            select_tbl_name:""},
      {id:105,display_name:"Draft RFP Date",                            db_name:"draft_rfp_date",                                 input_type:"date",                            select_tbl_name:""},
      {id:106,display_name:"Opportunity Link",                          db_name:"opportunity_link",                               input_type:"website",                         select_tbl_name:""},
      {id:107,display_name:"Award Type",                                db_name:"award_type",                                     input_type:"select",                          select_tbl_name:"tbl_cap_s_award_type"},
      {id:108,display_name:"Lead Source",                               db_name:"lead_source",                                    input_type:"select",                          select_tbl_name:"tbl_cap_n_lead_source"},
      {id:109,display_name:"Recompete",                                 db_name:"recompete",                                      input_type:"select",                          select_tbl_name:"tbl_cap_s_recompete"},
      {id:110,display_name:"Security",                                  db_name:"security",                                       input_type:"select",                          select_tbl_name:"tbl_cap_s_security"},
      {id:111,display_name:"Delivery Location",                         db_name:"delivery_location",                              input_type:"select",                          select_tbl_name:"tbl_cap_s_delivery_location"},
      {id:112,display_name:"Delivery Solutions",                        db_name:"delivery_solutions",                             input_type:"selectmultiadd",                  select_tbl_name:"tbl_cap_n_delivery_solutions"},
      {id:113,display_name:"# Prime Awards",                            db_name:"num_prime_awards",                               input_type:"int_positive",                    select_tbl_name:""},
      {id:200,display_name:"GCSS Automatic Updates Overwrite Data?",    db_name:"gcss_overwrite_data_with_updates_01",            input_type:"verticalswitch",                  select_tbl_name:"gcss_overwrite_data_with_updates_01"},
      {id:201,display_name:"SAM GCSS ID",                               db_name:"gcss_id",                                        input_type:"int_positive",                    select_tbl_name:""},
      {id:202,display_name:"GovWin ID",                                 db_name:"gcss_govwin_id_string",                          input_type:"text",                            select_tbl_name:""},
      {id:203,display_name:"Federal Compass ID",                        db_name:"gcss_fedcomp_id_string",                         input_type:"text",                            select_tbl_name:""},
      {id:204,display_name:"GCSS Original Published Date/Time",         db_name:"gcss_original_published_datetime_utc",           input_type:"datetime",                        select_tbl_name:""},
      {id:205,display_name:"GCSS Last Updated Date/Time",               db_name:"gcss_last_updated_datetime_utc",                 input_type:"datetime",                        select_tbl_name:""},
      {id:206,display_name:"SAM Link",                                  db_name:"gcss_sam_link",                                  input_type:"website",                         select_tbl_name:""},
      {id:207,display_name:"GovWin Link",                               db_name:"gcss_govwin_link",                               input_type:"website",                         select_tbl_name:""},
      {id:208,display_name:"Federal Compass Link",                      db_name:"gcss_fedcomp_link",                              input_type:"website",                         select_tbl_name:""},
      {id:209,display_name:"Department/Agency",                         db_name:"gcss_bm_department_agency_id",                   input_type:"select",                          select_tbl_name:"tbl_bit_master_departments_agencies"},
      {id:210,display_name:"Sub-tier",                                  db_name:"gcss_bm_sub_tier_id",                            input_type:"select",                          select_tbl_name:"tbl_bit_master_sub_tiers"},
      {id:211,display_name:"Office",                                    db_name:"gcss_office",                                    input_type:"text",                            select_tbl_name:""},
      {id:212,display_name:"Product Service Code",                      db_name:"gcss_bm_product_service_code_ids_comma",         input_type:"selectmulti",                     select_tbl_name:"tbl_bit_master_product_service_codes"},
      {id:213,display_name:"GCSS Contract Opportunity Type",            db_name:"gcss_bm_contract_opportunity_type_id",           input_type:"select",                          select_tbl_name:"tbl_bit_master_contract_opportunity_types"},
      {id:214,display_name:"GovWin Procurement Comment",                db_name:"gcss_govwin_procurement_comment",                input_type:"textarea",                        select_tbl_name:""},
      {id:215,display_name:"GovWin Duration",                           db_name:"gcss_govwin_duration",                           input_type:"text",                            select_tbl_name:""},
      {id:216,display_name:"GovWin Primary Work Required",              db_name:"gcss_govwin_primary_work_required",              input_type:"text",                            select_tbl_name:""},
      {id:217,display_name:"GovWin Priority (1-5)",                     db_name:"gcss_govwin_priority_1to5",                      input_type:"int_0to9",                        select_tbl_name:""},
      {id:218,display_name:"GCSS Federal Compass Contract Type",        db_name:"gcss_bm_fedcomp_contract_type_id",               input_type:"select",                          select_tbl_name:"tbl_bit_master_fedcomp_contract_types"},
      {id:219,display_name:"Award Number",                              db_name:"gcss_award_number",                              input_type:"text",                            select_tbl_name:""},
      {id:220,display_name:"Performance Address",                       db_name:"gcss_place_of_performance_address",              input_type:"text",                            select_tbl_name:""},
      {id:221,display_name:"Performance City",                          db_name:"gcss_place_of_performance_city",                 input_type:"text",                            select_tbl_name:""},
      {id:222,display_name:"Performance State",                         db_name:"gcss_place_of_performance_bm_state_ids_comma",   input_type:"selectmulti",                     select_tbl_name:"tbl_bit_master_states"},
      {id:223,display_name:"Performance Zip",                           db_name:"gcss_place_of_performance_zip",                  input_type:"text",                            select_tbl_name:""},
      {id:224,display_name:"Performance Province",                      db_name:"gcss_place_of_performance_province",             input_type:"text",                            select_tbl_name:""},
      {id:225,display_name:"Performance Country",                       db_name:"gcss_place_of_performance_bm_country_ids_comma", input_type:"selectmulti",                     select_tbl_name:"tbl_bit_master_countries"},
      {id:226,display_name:"Contracting Office City",                   db_name:"gcss_contracting_office_city",                   input_type:"text",                            select_tbl_name:""},
      {id:227,display_name:"Contracting Office State",                  db_name:"gcss_contracting_office_bm_state_id",            input_type:"select",                          select_tbl_name:"tbl_bit_master_states"},
      {id:228,display_name:"Contracting Office Zip",                    db_name:"gcss_contracting_office_zip",                    input_type:"text",                            select_tbl_name:""},
      {id:229,display_name:"Contracting Office Province",               db_name:"gcss_contracting_office_province",               input_type:"text",                            select_tbl_name:""},
      {id:230,display_name:"Contracting Office Country",                db_name:"gcss_contracting_office_bm_country_id",          input_type:"select",                          select_tbl_name:"tbl_bit_master_countries"},
      {id:231,display_name:"Contracting Office POC#1",                  db_name:"gcss_contracting_office_poc1_name_email_phone",  input_type:"textarea",                        select_tbl_name:""},
      {id:232,display_name:"Contracting Office POC#2",                  db_name:"gcss_contracting_office_poc2_name_email_phone",  input_type:"textarea",                        select_tbl_name:""},
      {id:233,display_name:"Awardee Information",                       db_name:"gcss_awardee_information",                       input_type:"textarea",                        select_tbl_name:""},
      {id:234,display_name:"GCSS Document Links",                       db_name:"gcss_document_links_json_string",                input_type:"documentlinks",                   select_tbl_name:""},
      {id:300,display_name:"Test Text",                                 db_name:"test_text",                                      input_type:"text",                            select_tbl_name:""},
      {id:301,display_name:"Test Textarea",                             db_name:"test_textarea",                                  input_type:"textarea",                        select_tbl_name:""},
      {id:302,display_name:"Test Int",                                  db_name:"test_int",                                       input_type:"int",                             select_tbl_name:""},
      {id:303,display_name:"Test Int Positive",                         db_name:"test_int_positive",                              input_type:"int_positive",                    select_tbl_name:""},
      {id:304,display_name:"Test Decimal",                              db_name:"test_decimal",                                   input_type:"decimal",                         select_tbl_name:""},
      {id:305,display_name:"Test Decimal Positive",                     db_name:"test_decimal_positive",                          input_type:"decimal_positive",                select_tbl_name:""},
      {id:306,display_name:"Test Percent Decimal",                      db_name:"test_percent_decimal",                           input_type:"percent_decimal",                 select_tbl_name:""},
      {id:307,display_name:"Test Percent Decimal 0to100",               db_name:"test_percent_decimal_0to100",                    input_type:"percent_decimal_0to100",          select_tbl_name:""},
      {id:308,display_name:"Test Percent Int",                          db_name:"test_percent_int",                               input_type:"percent_int",                     select_tbl_name:""},
      {id:309,display_name:"Test Percent Int 0to100",                   db_name:"test_percent_int_0to100",                        input_type:"percent_int_0to100",              select_tbl_name:""},
      {id:310,display_name:"Test Money",                                db_name:"test_money",                                     input_type:"money",                           select_tbl_name:""},
      {id:311,display_name:"Test Money Cents",                          db_name:"test_money_cents",                               input_type:"money_cents",                     select_tbl_name:""},
      {id:312,display_name:"Test Email",                                db_name:"test_email",                                     input_type:"email",                           select_tbl_name:""},
      {id:313,display_name:"Test Phone",                                db_name:"test_phone",                                     input_type:"phone",                           select_tbl_name:""},
      {id:314,display_name:"Test Website",                              db_name:"test_website",                                   input_type:"website",                         select_tbl_name:""},
      {id:315,display_name:"Test Color",                                db_name:"test_color",                                     input_type:"color",                           select_tbl_name:""},
      {id:400,display_name:"Test Date (company format)",                db_name:"test_date",                                      input_type:"date",                            select_tbl_name:""},
      {id:401,display_name:"Test Date Ymd",                             db_name:"test_date_Ymd",                                  input_type:"date_Ymd",                        select_tbl_name:""},
      {id:402,display_name:"Test Date MjY",                             db_name:"test_date_MjY",                                  input_type:"date_MjY",                        select_tbl_name:""},
      {id:403,display_name:"Test Date DMjY",                            db_name:"test_date_DMjY",                                 input_type:"date_DMjY",                       select_tbl_name:""},
      {id:404,display_name:"Test Date dayMdyDaysUntil1",                db_name:"test_date_dayMdyDaysUntil1",                     input_type:"date_dayMdyDaysUntil1",           select_tbl_name:""},
      {id:405,display_name:"Test Date dayMdyDaysUntil1Overdue",         db_name:"test_date_dayMdyDaysUntil1Overdue",              input_type:"date_dayMdyDaysUntil1Overdue",    select_tbl_name:""},
      {id:406,display_name:"Test Date dayMdyDaysUntil2",                db_name:"test_date_dayMdyDaysUntil2",                     input_type:"date_dayMdyDaysUntil2",           select_tbl_name:""},
      {id:407,display_name:"Test Date dayMdyDaysUntil2Overdue",         db_name:"test_date_dayMdyDaysUntil2Overdue",              input_type:"date_dayMdyDaysUntil2Overdue",    select_tbl_name:""},
      {id:408,display_name:"Test Date dayMdyDaysUntil2Overdue [fromRawDateTimeUtc]",db_name:"test_date_dayMdyDaysUntil2Overdue_fromRawDateTimeUtc",input_type:"date_dayMdyDaysUntil2Overdue_fromRawDateTimeUtc",select_tbl_name:""},
      {id:409,display_name:"Test DateTime (company format)",            db_name:"test_datetime",                                  input_type:"datetime",                        select_tbl_name:""},
      {id:410,display_name:"Test DateTime (company format) [natural]",  db_name:"test_datetime_natural",                          input_type:"datetime_natural",                select_tbl_name:""},
      {id:411,display_name:"Test DateTime YmdgiA",                      db_name:"test_datetime_YmdgiA",                           input_type:"datetime_YmdgiA",                 select_tbl_name:""},
      {id:412,display_name:"Test DateTime YmdgiA [natural]",            db_name:"test_datetime_YmdgiA_natural",                   input_type:"datetime_YmdgiA_natural",         select_tbl_name:""},
      {id:413,display_name:"Test DateTime MjYgiA",                      db_name:"test_datetime_MjYgiA",                           input_type:"datetime_MjYgiA",                 select_tbl_name:""},
      {id:414,display_name:"Test DateTime MjYgiA [natural]",            db_name:"test_datetime_MjYgiA_natural",                   input_type:"datetime_MjYgiA_natural",         select_tbl_name:""},
      {id:415,display_name:"Test DateTime DMjYgiA",                     db_name:"test_datetime_DMjYgiA",                          input_type:"datetime_DMjYgiA",                select_tbl_name:""},
      {id:416,display_name:"Test DateTime DMjYgiA [natural]",           db_name:"test_datetime_DMjYgiA_natural",                  input_type:"datetime_DMjYgiA_natural",        select_tbl_name:""},
      {id:417,display_name:"Test DateTime dayMdyDaysUntil1",            db_name:"test_datetime_dayMdyDaysUntil1",                 input_type:"datetime_dayMdyDaysUntil1",       select_tbl_name:""},
      {id:418,display_name:"Test DateTime dayMdyDaysUntil1Overdue",     db_name:"test_datetime_dayMdyDaysUntil1Overdue",          input_type:"datetime_dayMdyDaysUntil1Overdue",select_tbl_name:""},
      {id:419,display_name:"Test DateTime dayMdyDaysUntil2",            db_name:"test_datetime_dayMdyDaysUntil2",                 input_type:"datetime_dayMdyDaysUntil2",       select_tbl_name:""},
      {id:420,display_name:"Test DateTime dayMdyDaysUntil2Overdue",     db_name:"test_datetime_dayMdyDaysUntil2Overdue",          input_type:"datetime_dayMdyDaysUntil2Overdue",select_tbl_name:""},
      {id:421,display_name:"Test Date WithRelativeDate",                db_name:"test_dateWithRelativeDate",                      input_type:"dateWithRelativeDate",            select_tbl_name:""},
      {id:422,display_name:"Test DateWithDuration",                     db_name:"test_dateWithDuration",                          input_type:"dateWithDuration",                select_tbl_name:""},
      {id:500,display_name:"Test Select Contact Person",                db_name:"test_select_contact_person",                     input_type:"select",                          select_tbl_name:"tbl_g_contacts_persons"},
      {id:501,display_name:"Test SelectMulti Contact Persons",          db_name:"test_selectmulti_contact_persons",               input_type:"selectmulti",                     select_tbl_name:"tbl_g_contacts_persons"},
      {id:502,display_name:"Test Select Contact Company",               db_name:"test_select_contact_company",                    input_type:"select",                          select_tbl_name:"tbl_g_contacts_companies"},
      {id:503,display_name:"Test SelectMulti Contact Companies",        db_name:"test_selectmulti_contact_companies",             input_type:"selectmulti",                     select_tbl_name:"tbl_g_contacts_companies"},
      {id:504,display_name:"Test Select Name (TblCap)",                 db_name:"test_select_name",                               input_type:"select",                          select_tbl_name:"tbl_cap_n_test_select_name"},
      {id:505,display_name:"Test SelectMulti Name (TblCap)",            db_name:"test_selectmulti_name",                          input_type:"selectmulti",                     select_tbl_name:"tbl_cap_n_test_selectmulti_name"},
      {id:506,display_name:"Test Select Sort (TblCap)",                 db_name:"test_select_sort",                               input_type:"select",                          select_tbl_name:"tbl_cap_s_test_select_sort"},
      {id:507,display_name:"Test SelectMulti Sort (TblCap)",            db_name:"test_selectmulti_sort",                          input_type:"selectmulti",                     select_tbl_name:"tbl_cap_s_test_selectmulti_sort"},
      {id:508,display_name:"Test Select User",                          db_name:"test_select_user",                               input_type:"select",                          select_tbl_name:"tbl_a_users"},
      {id:509,display_name:"Test SelectMulti Users",                    db_name:"test_selectmulti_users",                         input_type:"selectmulti",                     select_tbl_name:"tbl_a_users"},
      {id:510,display_name:"Test Select Contracts User",                db_name:"test_select_contracts_user",                     input_type:"select",                          select_tbl_name:"tbl_a_users_contracts"},
      {id:511,display_name:"Test SelectMulti Contracts Users",          db_name:"test_selectmulti_contracts_users",               input_type:"selectmulti",                     select_tbl_name:"tbl_a_users_contracts"},
      {id:512,display_name:"Test Select Budget User",                   db_name:"test_select_budget_user",                        input_type:"select",                          select_tbl_name:"tbl_a_users_budget"},
      {id:513,display_name:"Test SelectMulti Budget Users",             db_name:"test_selectmulti_budget_users",                  input_type:"selectmulti",                     select_tbl_name:"tbl_a_users_budget"},
      {id:514,display_name:"Test Select Division",                      db_name:"test_select_division",                           input_type:"select",                          select_tbl_name:"tbl_a_divisions"},
      {id:515,display_name:"Test SelectMulti Divisions",                db_name:"test_selectmulti_divisions",                     input_type:"selectmulti",                     select_tbl_name:"tbl_a_divisions"},
      {id:516,display_name:"Test Select Capture Type",                  db_name:"test_select_capture_type",                       input_type:"select",                          select_tbl_name:"tbl_a_capture_types"},
      {id:517,display_name:"Test SelectMulti Capture Types",            db_name:"test_selectmulti_capture_types",                 input_type:"selectmulti",                     select_tbl_name:"tbl_a_capture_types"},
      {id:518,display_name:"Test Select Contract Type",                 db_name:"test_select_contract_type",                      input_type:"select",                          select_tbl_name:"tbl_a_contract_types"},
      {id:519,display_name:"Test SelectMulti Contract Types",           db_name:"test_selectmulti_contract_types",                input_type:"selectmulti",                     select_tbl_name:"tbl_a_contract_types"},
      {id:520,display_name:"Test Select IDIQ Capture",                  db_name:"test_select_idiq_capture",                       input_type:"select",                          select_tbl_name:"tbl_captures_idiq"},
      {id:521,display_name:"Test SelectMulti IDIQ Captures",            db_name:"test_selectmulti_idiq_captures",                 input_type:"selectmulti",                     select_tbl_name:"tbl_captures_idiq"},
      {id:522,display_name:"Test Select Contacts Business Type",        db_name:"test_select_contacts_business_type",             input_type:"select",                          select_tbl_name:"tbl_a_business_types"},
      {id:523,display_name:"Test SelectMulti Contacts Business Types",  db_name:"test_selectmulti_contacts_business_types",       input_type:"selectmulti",                     select_tbl_name:"tbl_a_business_types"},
      {id:524,display_name:"Test Select BIT Master Set Aside",          db_name:"test_select_bit_master_set_aside",               input_type:"select",                          select_tbl_name:"tbl_bit_master_set_asides"},
      {id:525,display_name:"Test SelectMulti BIT Master Set Asides",    db_name:"test_selectmulti_bit_master_set_asides",         input_type:"selectmulti",                     select_tbl_name:"tbl_bit_master_set_asides"},
      {id:526,display_name:"Test Select Contacts Capability",           db_name:"test_select_contacts_capability",                input_type:"select",                          select_tbl_name:"tbl_a_capabilities"},
      {id:527,display_name:"Test SelectMulti Contacts Capabilities",    db_name:"test_selectmulti_contacts_capabilities",         input_type:"selectmulti",                     select_tbl_name:"tbl_a_capabilities"},
      {id:528,display_name:"Test Select BIT Master NAICS Code",         db_name:"test_select_bit_master_naics_code",              input_type:"select",                          select_tbl_name:"tbl_bit_master_naics_codes"},
      {id:529,display_name:"Test SelectMulti BIT Master NAICS Codes",   db_name:"test_selectmulti_bit_master_naics_codes",        input_type:"selectmulti",                     select_tbl_name:"tbl_bit_master_naics_codes"},
      {id:530,display_name:"Test Select Reason Won",                    db_name:"test_select_reason_won",                         input_type:"select",                          select_tbl_name:"tbl_a_reasons_won"},
      {id:531,display_name:"Test SelectMulti Reasons Won",              db_name:"test_selectmulti_reasons_won",                   input_type:"selectmulti",                     select_tbl_name:"tbl_a_reasons_won"},
      {id:532,display_name:"Test Select Reason Lost",                   db_name:"test_select_reason_lost",                        input_type:"select",                          select_tbl_name:"tbl_a_reasons_lost"},
      {id:533,display_name:"Test SelectMulti Reasons Lost",             db_name:"test_selectmulti_reasons_lost",                  input_type:"selectmulti",                     select_tbl_name:"tbl_a_reasons_lost"},
      {id:534,display_name:"Test SharedPercentAdd",                     db_name:"test_sharedpercentadd",                          input_type:"sharedpercentadd",                select_tbl_name:"tbl_cap_s_test_selectmulti_sort"},
      {id:535,display_name:"Test SharedPercent Contact Companies",      db_name:"test_sharedpercent_contact_companies",           input_type:"sharedpercent",                   select_tbl_name:"tbl_g_contacts_companies"},
      {id:536,display_name:"Test SharedPercent Contact Persons",        db_name:"test_sharedpercent_contact_persons",             input_type:"sharedpercent",                   select_tbl_name:"tbl_g_contacts_persons"},
      {id:537,display_name:"Test Select Captures",                      db_name:"test_select_captures",                           input_type:"select",                          select_tbl_name:"tbl_captures"},
      {id:538,display_name:"Test SelectMulti Captures",                 db_name:"test_selectmulti_captures",                      input_type:"selectmulti",                     select_tbl_name:"tbl_captures"},
      {id:539,display_name:"Test SharedPercent Captures",               db_name:"test_sharedpercent_captures",                    input_type:"sharedpercent",                   select_tbl_name:"tbl_captures"},
      {id:540,display_name:"Test VerticalSwitch Delivery Solutions",    db_name:"test_verticalswitch_delivery_solutions",         input_type:"verticalswitch",                  select_tbl_name:"tbl_cap_n_delivery_solutions"}
    ];
    var adminSort = 1;
    for(let devFieldObj of devTblCapturesFieldsArrayOfObjs) {
      var textareaDetailsMaxLines = 0;
      if(JSFUNC.in_array(devFieldObj.db_name, ["description_of_work", "notepad"])) {
        textareaDetailsMaxLines = 15;
      }
      else if(devFieldObj.db_name === "gcss_overwrite_data_with_updates_01") {
        textareaDetailsMaxLines = 2;
      }

      var numDecimals = 0;
      if(JSFUNC.in_array(devFieldObj.db_name, ["id"])) {
        numDecimals = 5;
      }
      else if(JSFUNC.in_array(devFieldObj.db_name, ["our_prime_sub_teammate_division_id", "our_prime_sub_teammate_allocation", "req_sb_allocation", "total_team_allocation", "total_lb_allocation", "total_sb_allocation", "test_decimal", "test_decimal_positive", "test_percent_decimal", "test_percent_decimal_0to100"])) {
        numDecimals = 2;
      }

      var hoverText = "";
      if(devFieldObj.db_name === "reasons_won_lost_ids_colon_percent_comma") { hoverText = "The Reason Won or Lost. This can be a multitude of drop down items tabulated in the Divexec rollups."; }
      else if(devFieldObj.db_name === "period_of_performance") { hoverText = "The Capture Period of Performance (given in months)"; }
      else if(devFieldObj.db_name === "description_of_work") { hoverText = "Enter the description of the capture. Keep it updated. Make it shine."; }

      var displayNameStylingStringComma = "";
      if(devFieldObj.id === 200) { displayNameStylingStringComma = "bold"; }
      else if(devFieldObj.id === 201) { displayNameStylingStringComma = "italic"; }
      else if(devFieldObj.id === 202) { displayNameStylingStringComma = "color#eeaa22"; }
      else if(devFieldObj.id === 203) { displayNameStylingStringComma = "bold,italic"; }
      else if(devFieldObj.id === 204) { displayNameStylingStringComma = "bold,color#22aaee"; }
      else if(devFieldObj.id === 205) { displayNameStylingStringComma = "italic,color#aaee22"; }
      else if(devFieldObj.id === 206) { displayNameStylingStringComma = "bold,italic,color#aa22ee"; }
      else if(devFieldObj.id === 207) { displayNameStylingStringComma = "highlight#ffff33"; }
      else if(devFieldObj.id === 208) { displayNameStylingStringComma = "highlight#ffff33,bold"; }
      else if(devFieldObj.id === 209) { displayNameStylingStringComma = "highlight#ffff33,italic,bold"; }
      else if(devFieldObj.id === 210) { displayNameStylingStringComma = "highlight#ffff33,italic,color#aa0000,bold"; }
      else if(devFieldObj.id === 211) { displayNameStylingStringComma = "presetID:1"; }

      var codewordNamesComma = "";
      if(devFieldObj.db_name === "contract_overall_value") { codewordNamesComma = "cov"; }

      devFieldObj.admin_sort = adminSort;
      devFieldObj.textarea_details_max_lines = textareaDetailsMaxLines;
      devFieldObj.num_decimals = numDecimals;
      devFieldObj.hover_text = hoverText;
      devFieldObj.display_name_styling_string_comma = displayNameStylingStringComma;
      devFieldObj.codeword_names_comma = codewordNamesComma;

      adminSort++;
    }
    DatabaseMobx.o_tbl_captures_fields = JSFUNC.mapOfMaps_from_arrayOfObjs(devTblCapturesFieldsArrayOfObjs);

    DatabaseMobx.o_tbl_captures = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {
        id:1,
        direct_access_link:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789AB",
        capture_managers_ids_colon_percent_comma:"3:80,12:5,1:15",
        division_owners_ids_colon_percent_comma:"1:40,2:25,3:35",
        opportunity_name:"Development Capture for Testing",
        codename:"DevTest",
        capture_type_id:1,
        stage_id:3,
        contract_overall_value:40000000,
        idiq_to_anticipated_value:0,
        contract_type_id:3,
        idiq_capture_id_TO_link:2,
        added_date:"2022-01-01",
        last_changed_date:"2018-02-01",
        last_stage_date:"2018-03-01",
        last_progress_date:"2024-04-01",
        last_pwin_date:"2019-05-01",
        last_rfp_date:"2019-06-01",
        archive_date:"0000-00-00",
        shaping_total_progress:-10.23478943,
        shaping_stage_progress:130.5,
        contracts_manager_user_id:7,
        budget_manager_user_id:8,
        reasons_won_lost_ids_colon_percent_comma:"",
        period_of_performance:48,
        pwin:-1,//38.59843893,
        naics_code_ids_comma:"1,8",
        competition_type_bm_set_aside_ids_comma:"2,4,6",
        our_prime_sub_teammate_division_id:1,
        our_prime_sub_teammate_allocation:55,
        req_sb_allocation:20,
        total_lb_allocation:59.654,
        total_sb_allocation:13,
        num_lb_teammates:66,
        num_sb_teammates:99,
        rfp_date:"2018-03-19",
        proposal_due_datetime_utc:"2024-04-15 15:15:00",
        award_date:"",
        contract_start_date:"",
        contract_end_date:"",
        notepad:"This is only the first 100 chars because it cuts...",
        description_of_work:"NGC 1427A {0,1,2,3,4,5,6,7,8,9	,10X,11,12,13X,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32 ,33!,34"+'"'+",35#,36$,37%,38&,39',40(,41),42*,43+} is an irregular galaxy in the constellation Eridanus. Its distance modulus has been estimated using the globu...",
        capture_priority_level_id:2,
        award_type:1,
        num_prime_awards:0,
        solicitation_num:"7849302-8943",
        gcss_overwrite_data_with_updates_01: 1,
        gcss_id:1818,
        gcss_govwin_id_string: "WIN1234567",
        gcss_fedcomp_id_string: "8baf40d772812457feb0acfc00af743c1cf0f81a018170eb386138bb54ddee8f",
        gcss_original_published_datetime_utc:"",
        gcss_last_updated_datetime_utc:"",
        gcss_sam_link:"",
        gcss_govwin_link:"",
        gcss_fedcomp_link:"",
        gcss_bm_department_agency_id:2,
        gcss_bm_sub_tier_id:1,
        gcss_office:"",
        gcss_bm_product_service_code_ids_comma:"",
        gcss_bm_contract_opportunity_type_id:2,
        gcss_govwin_procurement_comment:"",
        gcss_govwin_duration:"",
        gcss_govwin_primary_work_required:"",
        gcss_govwin_priority_1to5:0,
        gcss_bm_fedcomp_contract_type_id:1,
        gcss_award_number:"",
        gcss_place_of_performance_address:"",
        gcss_place_of_performance_city:"",
        gcss_place_of_performance_bm_state_ids_comma:"",
        gcss_place_of_performance_zip:"",
        gcss_place_of_performance_province:"",
        gcss_place_of_performance_bm_country_ids_comma:"",
        gcss_contracting_office_city:"",
        gcss_contracting_office_bm_state_id:2,
        gcss_contracting_office_zip:"",
        gcss_contracting_office_province:"",
        gcss_contracting_office_bm_country_id:3,
        gcss_contracting_office_poc1_name_email_phone:"",
        gcss_contracting_office_poc2_name_email_phone:"",
        gcss_awardee_information:"Awardee Info",
        gcss_document_links_json_string:'[{"d":"2022-01-25 19:25:58","n":"BSA_Commercial_Items_Rev20.0_Dec_2020_plus_other_attachments.pdf","l":"https://www.neco.navy.mil/biz_ops/840-v5soln.aspx?soln=N0010422QBL27","o":28},{"d":"2022-04-05 09:00:59","n":"Doc2 ' + longText + '","l":"http://opp.com","o":1}]',
        opportunity_link:"www.fbo.gov/fdu89fdu90fdsu90fdsjkfdsjklfdajiofhi8ofewu90fwu90fu9f0dsiofdjsailfjieoajf90dsau9f0eua90fejiofdsjkvjiofdsjaiofdsjafeu902349023u94032u9f0eau90fdusa90fdasu90fds",
        recompete:1,
        security:2,
        delivery_location:1,
        prime_contact_company_id:1,
        incumbent_contact_company_ids_comma:"1,3,5,-2,9",
        our_incumbent_competitor_division_id:-1,
        delivery_solutions:"1,2",
        lead_source:1,
        rfi_date:"",
        industry_date:"",
        bidder_conference_date:"",
        draft_rfp_date:"0000-00-00",
        example_revenue_cost_field_1:300000,
        example_revenue_cost_field_2:400000,
        test_text:longText,
        test_textarea:longText,
        test_int:3,
        test_int_positive:4,
        test_decimal:20.916,
        test_decimal_positive:1.000,
        test_percent_decimal:105.26,
        test_percent_decimal_0to100:3.23,
        test_percent_int:7,
        test_percent_int_0to100:8,
        test_money:40000,
        test_money_cents:56.78,
        test_email:"a@b.com",
        test_phone:"123-456-7890",
        test_website:"[[Link Mask]]https://link.mask.com",
        test_color:"0369cf",
        test_date:testDate,
        test_date_Ymd:testDate,
        test_date_MjY:testDate,
        test_date_DMjY:testDate,
        test_date_dayMdyDaysUntil1:testDate,
        test_date_dayMdyDaysUntil1Overdue:testDate,
        test_date_dayMdyDaysUntil2:testDate,
        test_date_dayMdyDaysUntil2Overdue:testDate,
        test_date_dayMdyDaysUntil2Overdue_fromRawDateTimeUtc:testDateTimeUtc,
        test_datetime:testDateTimeUtc,
        test_datetime_natural:testDateTimeUtc,
        test_datetime_YmdgiA:testDateTimeUtc,
        test_datetime_YmdgiA_natural:testDateTimeUtc,
        test_datetime_MjYgiA:testDateTimeUtc,
        test_datetime_MjYgiA_natural:testDateTimeUtc,
        test_datetime_DMjYgiA:testDateTimeUtc,
        test_datetime_DMjYgiA_natural:testDateTimeUtc,
        test_datetime_dayMdyDaysUntil1:testDateTimeUtc,
        test_datetime_dayMdyDaysUntil1Overdue:testDateTimeUtc,
        test_datetime_dayMdyDaysUntil2:testDateTimeUtc,
        test_datetime_dayMdyDaysUntil2Overdue:testDateTimeUtc,
        test_dateWithRelativeDate:"9025-01-01",
        test_dateWithDuration:"2024-07-31 00:20:03",
        test_select_contact_person:1,
        test_selectmulti_contact_persons:"1,2,3",
        test_select_contact_company:1,
        test_selectmulti_contact_companies:"1,2,3,4,5,6,7",
        test_select_name:1,
        test_selectmulti_name:"6,3,2,4",
        test_select_sort:1,
        test_selectmulti_sort:"1",
        test_select_user:1,
        test_selectmulti_users:"1",
        test_select_contracts_user:"",
        test_selectmulti_contracts_users:"",
        test_select_budget_user:"",
        test_selectmulti_budget_users:"",
        test_select_division:"",
        test_selectmulti_divisions:"",
        test_select_capture_type:"",
        test_selectmulti_capture_types:"",
        test_select_contract_type:"",
        test_selectmulti_contract_types:"",
        test_select_idiq_capture:"",
        test_selectmulti_idiq_captures:"",
        test_select_contacts_business_type:1,
        test_selectmulti_contacts_business_types:"2,3",
        test_select_bit_master_set_aside:1,
        test_selectmulti_bit_master_set_asides:"2,3",
        test_select_contacts_capability:1,
        test_selectmulti_contacts_capabilities:"2,3",
        test_select_bit_master_naics_code:1,
        test_selectmulti_bit_master_naics_codes:"2,3",
        test_select_reason_won:"",
        test_selectmulti_reasons_won:"",
        test_select_reason_lost:"",
        test_selectmulti_reasons_lost:"",
        test_sharedpercentadd:"",
        test_sharedpercent_contact_companies: "",
        test_sharedpercent_contact_persons: "",
        test_select_captures: 8,
        test_selectmulti_captures: "3,555,9",
        test_sharedpercent_captures: "3:15,555:25,9:60",
        test_verticalswitch_delivery_solutions: 0
      },
      {
        id:2,
        capture_managers_ids_colon_percent_comma:"12:100",
        division_owners_ids_colon_percent_comma:"6:100",
        opportunity_name:"Advancement Capture for Testing2",
        codename:"AdevTest2",
        capture_type_id:2,
        stage_id:9,
        contract_overall_value:250000000,
        idiq_to_anticipated_value:200000,
        contract_type_id:2,
        idiq_capture_id_TO_link:0,
        added_date:"2019-03-21",
        last_changed_date:"2018-03-01",
        last_rfp_date:"2018-02-01",
        archive_date:"2021-04-15",
        shaping_total_progress:60,
        shaping_stage_progress:90,
        contracts_manager_user_id:7,
        budget_manager_user_id:2,
        reasons_won_lost_ids_colon_percent_comma:"1:85,2:15",
        period_of_performance:12,
        pwin:40,
        naics_code_ids_comma:"2",
        competition_type_bm_set_aside_ids_comma:"",
        our_prime_sub_teammate_division_id:2,
        our_prime_sub_teammate_allocation:1.75,
        req_sb_allocation:0,
        total_lb_allocation:59.654,
        total_sb_allocation:13,
        num_lb_teammates:66,
        num_sb_teammates:99,
        rfp_date:"2030-03-21",
        proposal_due_datetime_utc:"2024-04-15 19:19:59",
        award_date:"2032-03-21",
        contract_start_date:"2033-03-21",
        description_of_work:"This development test capture has a description of work here.",
        capture_priority_level_id:4,
        award_type:1,
        num_prime_awards:0,
        solicitation_num:"7849302-8943",
        opportunity_link:"[[Google]]www.google.com",
        recompete:0,
        security:1,
        delivery_location:1,
        prime_contact_company_id:2,
        incumbent_contact_company_ids_comma:"-2",
        our_incumbent_competitor_division_id:3,
        delivery_solutions:"1,2",
        lead_source:1,
        rfi_date:"2018-08-01",
        industry_date:"2018-10-23",
        bidder_conference_date:"2019-01-01",
        draft_rfp_date:"2019-07-01",
        example_revenue_cost_field_1:3000000,
        gcss_id:0
      },
      {
        id:3,
        capture_managers_ids_colon_percent_comma:"",
        division_owners_ids_colon_percent_comma:"",
        codename:"Unassigned Capture",
        opportunity_name:"Unassigned Capture",
        capture_type_id:3,
        capture_priority_level_id:0,
        added_date:"2024-07-18"
      },
      {
        id:4,
        capture_managers_ids_colon_percent_comma:"1:100,11:0",
        division_owners_ids_colon_percent_comma:"1:100",
        codename:"Active IDIQ",
        opportunity_name:"Active IDIQ",
        capture_type_id:1,
        contract_type_id:2,
        stage_id:3,
        contract_overall_value:678987,
        capture_priority_level_id:66,
        shaping_total_progress: -1
      }
    ]);
    var stageCapturesAddedDateYear = 2018;
    var stageCapturesAddedDateMonth1to12 = 5;
    for(let c=5; c<21; c++){
      DatabaseMobx.o_tbl_captures.set(c, JSFUNC.map_from_obj(
        {
          id:c,
          capture_managers_ids_colon_percent_comma:"1:100",
          division_owners_ids_colon_percent_comma: "1",
          opportunity_name: "Stage"+c,
          codename: "S"+c,
          capture_type_id: 1,
          stage_id: ((c < 14) ? ((c < 9) ? (11) : (10)) : (9)),
          contract_overall_value: 2200000*(c-3),
          pwin: ((c*5)-5),
          shaping_total_progress: ((c*5)-5),
          security: c-2,
          added_date:stageCapturesAddedDateYear + "-" + JSFUNC.zero_pad_integer_from_left(stageCapturesAddedDateMonth1to12, 2) + "-03",
          proposal_due_datetime_utc: (2013 + c) + "-08-08 00:00:58",
          award_date: (2013 + c) + "-08-08",
          period_of_performance: 60,
          contract_start_date: "2018-09-09",
          our_prime_sub_teammate_division_id:1,
          our_prime_sub_teammate_allocation:100
        }
      ));
      stageCapturesAddedDateMonth1to12++;
      if(stageCapturesAddedDateMonth1to12 > 12) {
        stageCapturesAddedDateMonth1to12 = 1;
        stageCapturesAddedDateYear++;
      }
    }

    DatabaseMobx.o_singleCaptureFullTextareaFieldsMap = JSFUNC.map_from_obj({
      zznotepad: "This is the full version of the notepad notes using o_singleCaptureFullTextareaFieldsMap where it runs until it is done",
      zzdescription_of_work: "NGC 1427A is an irregular galaxy in the constellation Eridanus. Its distance modulus has been estimated using the globular cluster luminosity function to be 31.01 ± 0.21 which is about 52 Mly.[2] It is the brightest dwarf irregular member of the Fornax cluster and is in the foreground of the cluster's central galaxy NGC 1399.",
      zztest_textarea:"full text of test textarea"
    });

    DatabaseMobx.o_tbl_cap.set("tbl_cap_s_award_type", JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"FFP", sort:1},
      {id:2, name:"CPFF", sort:2},
      {id:3, name:"LPTA", sort:3},
      {id:4, name:"T&M", sort:4},
      {id:5, name:"Mixed", sort:5},
      {id:6, name:"PPA", sort:6}
    ]));

    DatabaseMobx.o_tbl_cap.set("tbl_cap_s_delivery_location", JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"CONUS", sort:1},
      {id:2, name:"OCONUS", sort:2}
    ]));

    var deliverySolutionsArrayOfObjs = [
      {id:1, name:"Cloudshield"},
      {id:2, name:"Data Center"},
      {id:3, name:"iT Solutions"},
      {id:4, name:"Health Care"},
      {id:5, name:"TeleCommunications"},
      {id:6, name:"A truly astoundingly long Delivery Solutions is a promising way to show off the system handling ways in which the text can be wrapped and otherwise handled in certains situations through the CaptureExec system"}
    ];
    for(let c = 6; c < 1; c++) { deliverySolutionsArrayOfObjs.push({id:c, name:String.fromCharCode(c)}); }
    DatabaseMobx.o_tbl_cap.set("tbl_cap_n_delivery_solutions", JSFUNC.mapOfMaps_from_arrayOfObjs(deliverySolutionsArrayOfObjs));

    DatabaseMobx.o_tbl_cap.set("tbl_cap_s_security", JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Unclassified", sort:1},
      {id:2, name:"Restricted", sort:2},
      {id:3, name:"Confidential", sort:3},
      {id:4, name:"Secret", sort:4},
      {id:5, name:"Top Secret", sort:5},
      {id:6, name:"TS/SCI", sort:6}
    ]));

    DatabaseMobx.o_tbl_cap.set("tbl_cap_n_test_select_name", JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Name 2"},
      {id:2, name:"Name 1"}
    ]));

    DatabaseMobx.o_tbl_cap.set("tbl_cap_s_test_select_sort", JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Sort 2", sort:2},
      {id:2, name:"Sort 1", sort:1}
    ]));

    DatabaseMobx.o_tbl_cap.set("tbl_cap_n_test_selectmulti_name", JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Multi Name 2"},
      {id:2, name:"Multi Name 1"}
    ]));

    DatabaseMobx.o_tbl_cap.set("tbl_cap_s_test_selectmulti_sort", JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Multi Sort 2", sort:2},
      {id:2, name:"Multi Sort 1", sort:1}
    ]));

    DatabaseMobx.o_tbl_c_budget_expenses = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, budget_category_id:1, funding_request_id:-1, date:"2019-08-01", expense_type_id:1, description:"Expense #1", value:100},
      {id:2, capture_id:1, budget_category_id:1, funding_request_id:-1, date:"2019-08-02", expense_type_id:2, description:"Expense #2", value:197778},
      {id:3, capture_id:1, budget_category_id:1, funding_request_id:-1, date:"2019-08-03", expense_type_id:1, description:"Expense #3", value:200},
      {id:4, capture_id:1, budget_category_id:1, funding_request_id:1, date:"2019-08-04", expense_type_id:1, description:"Funding Request Estimate", value:44000},
      {id:5, capture_id:1, budget_category_id:1, funding_request_id:2, date:"2019-08-04", expense_type_id:1, description:longText, value:5000}
    ]);

    DatabaseMobx.o_tbl_c_budget_funding_requests = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, budget_category_id:1, nr0_rq1_rj2_rs3_ap4:4, rq_date:"2019-08-01", rq_user_id:1, rj_date:"0000-00-00", rj_user_id:0, rs_date:"0000-00-00", rs_user_id:0, ap_date:"2019-08-04", ap_user_id:8, cm_notes:"Capture Manager Notes", bm_notes:"Budget Manager Notes 4"},
      {id:2, capture_id:1, budget_category_id:1, nr0_rq1_rj2_rs3_ap4:3, rq_date:"2019-08-15", rq_user_id:1, rj_date:"0000-00-00", rj_user_id:0, rs_date:"0000-00-00", rs_user_id:0, ap_date:"2019-08-04", ap_user_id:0, cm_notes:"Capture Manager Notes", bm_notes:"Budget Manager Notes 3"},
      {id:3, capture_id:1, budget_category_id:1, nr0_rq1_rj2_rs3_ap4:2, rq_date:"2019-08-15", rq_user_id:1, rj_date:"0000-00-00", rj_user_id:0, rs_date:"0000-00-00", rs_user_id:0, ap_date:"2019-08-04", ap_user_id:0, cm_notes:"Capture Manager Notes", bm_notes:"Budget Manager Notes 2"},
      {id:4, capture_id:1, budget_category_id:1, nr0_rq1_rj2_rs3_ap4:1, rq_date:"2019-08-15", rq_user_id:1, rj_date:"0000-00-00", rj_user_id:0, rs_date:"0000-00-00", rs_user_id:0, ap_date:"2019-08-04", ap_user_id:0, cm_notes:"Capture Manager Notes", bm_notes:"Budget Manager Notes 1"},
      {id:5, capture_id:1, budget_category_id:1, nr0_rq1_rj2_rs3_ap4:0, rq_date:"2019-08-15", rq_user_id:1, rj_date:"0000-00-00", rj_user_id:0, rs_date:"0000-00-00", rs_user_id:0, ap_date:"2019-08-04", ap_user_id:0, cm_notes:"Capture Manager Notes", bm_notes:"Budget Manager Notes 0"}
    ]);

    DatabaseMobx.o_tbl_c_competitors = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, sort:2, contact_company_id:1, teammates_contact_company_ids_comma:"3,2", strengths:"Strengths", weaknesses:"Weaknesses", opportunities:"Opportunities", threats:"Threats but just  with a ton of text to test the sentence wrapping capability of the ticket with a maximum of 3 lines", notes:longText, test:""},
      {id:2, capture_id:1, sort:1, contact_company_id:2, teammates_contact_company_ids_comma:"3", strengths:"Strengths123", weaknesses:"Weaknesses123", opportunities:"Opportunities123", threats:longText, notes:longText, test:"hye hey hey"}
    ]);

    DatabaseMobx.o_tbl_c_conversations = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, datetime_utc:"2018-11-25 16:00:00", contact_person_ids_comma:"1,3,2", notes:"Test Message"},
      {id:2, capture_id:1, datetime_utc:"2017-11-21 09:00:00", contact_person_ids_comma:"3,1", notes:"Another Test with a ton of text to test the sentence wrapping capability of the ticket with a maximum of 3 lines which still needs even more text than this."},
      {id:3, capture_id:1, datetime_utc:"2017-11-20 09:00:00", contact_person_ids_comma:"2", notes:"Another Test with a ton of text to test the sentence wrapping capability of the ticket with a maximum of 3 lines which still needs even more text than this. Another Test with a ton of text to test the sentence wrapping capability of the ticket with a maximum of 3 lines which still needs even more text than this."},
      {id:4, capture_id:1, datetime_utc:"2019-02-02 09:00:00", contact_person_ids_comma:"1", notes:"Notes 2019-02-02"},
      {id:5, capture_id:1, datetime_utc:"2019-06-02 09:00:00", contact_person_ids_comma:"1", notes:"Notes 2019-06-02"},
      {id:6, capture_id:1, datetime_utc:"2019-09-02 09:00:00", contact_person_ids_comma:"1", notes:"Notes 2019-09-02"},
      {id:7, capture_id:1, datetime_utc:"2019-12-02 09:00:00", contact_person_ids_comma:"1", notes:"Notes 2019-12-02"},
      {id:8, capture_id:1, datetime_utc:"2020-03-02 09:00:00", contact_person_ids_comma:"1", notes:"Notes 2020-03-02"}
    ]);

    DatabaseMobx.o_tbl_c_documents_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_folder", display_name:"Test Folder", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:2, capture_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_folder_3", display_name:"Test Folder 3", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:3, capture_id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"/dev/docs/cID1/file_for_upload.txt", display_name:"my Doc", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:4, capture_id:1, folder0_file1:1, parent_folder_id:5, fileupload0_onlinelink1:0, file_loc:"/dev/docs/cID1/but_this_time_another.txt", display_name:"Subfolder Doc", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-08-18", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:5, capture_id:1, folder0_file1:0, parent_folder_id:1, fileupload0_onlinelink1:0, file_loc:"test_subfolder", display_name:"Test Subfolder", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:6, capture_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_folder_2", display_name:"Test Folder 2", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:7, capture_id:1, folder0_file1:0, parent_folder_id:5, fileupload0_onlinelink1:0, file_loc:"test_subsub_twice", display_name:"Test SubSub Twice", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:8, capture_id:1, folder0_file1:0, parent_folder_id:5, fileupload0_onlinelink1:0, file_loc:"test_subsub_once", display_name:"Test SubSub Once", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:9, capture_id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:1, file_loc:"https://website.com/onlinedoc.pdf", display_name:"Test Online Doc", keywords:"", notes:"Notes", content_unique_lowercase:"", upload_date:"2023-04-21", upload_user_id:1, upload_user_name:"Drew Name"}
    ]);

    DatabaseMobx.o_tbl_c_log_budget = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, funding_request_id__or_expm1:1, datetime_utc:"2017-11-25 16:00:00", user_id:2, user:"Skip", field:"Bduget Foeld", value:"value"},
      {id:2, capture_id:1, funding_request_id__or_expm1:-1, datetime_utc:"2017-11-27 16:00:00", user_id:1, user:"Drew", field:"Field", value:"value"}
    ]);

    DatabaseMobx.o_tbl_c_log_details = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, datetime_utc:"2017-11-25 16:00:00", user_id:1, user:"Drew", card_id:1, field_id:"1", field:"Field 1", value:"Test Message"},
      {id:2, capture_id:1, datetime_utc:"2017-11-27 16:00:00", user_id:1, user:"Drew", card_id:2, field_id:"workshare_type_id", field:"Field 2", value:"2"},
      {id:3, capture_id:1, datetime_utc:"2017-11-29 16:00:00", user_id:2, user:"Skip", card_id:3, field_id:"2", field:"Field 3", value:longText},
      {id:4, capture_id:1, datetime_utc:"2017-11-30 16:00:00", user_id:1, user:"Drew", card_id:4, field_id:"5", field:"Field 4", value:"hry"},
      {id:5, capture_id:1, datetime_utc:"2017-11-30 16:00:00", user_id:1, user:"Drew", card_id:1000, field_id:"5", field:"Field 4", value:"hry"},
      {id:6, capture_id:1, datetime_utc:"2017-11-30 16:00:00", user_id:1, user:"Drew", card_id:1001, field_id:"5", field:"Field 4", value:"hry"},
      {id:7, capture_id:1, datetime_utc:"2017-11-30 16:00:00", user_id:1, user:"Drew", card_id:1002, field_id:"5", field:"Field 4", value:"hry"},
      {id:8, capture_id:1, datetime_utc:"2017-11-30 16:00:00", user_id:1, user:"Drew", card_id:1003, field_id:"5", field:"Field 4", value:"hry"},
      {id:9, capture_id:1, datetime_utc:"2017-11-30 16:01:56", user_id:1, user:"Drew", card_id:1004, field_id:"5", field:"Field 4", value:"hry"},
      {id:10, capture_id:1, datetime_utc:"2017-11-30 16:01:57", user_id:1, user:"Drew", card_id:1005, field_id:"5", field:"Field 4", value:"hry"},
      {id:11, capture_id:1, datetime_utc:"2017-11-30 16:01:58", user_id:1, user:"Drew", card_id:1006, field_id:"5", field:"Field 4", value:"hry"},
      {id:12, capture_id:1, datetime_utc:"2017-11-30 16:01:59", user_id:1, user:"Drew", card_id:4, field_id:"5", field:"Field 4", value:"hry"},
      {id:13, capture_id:1, datetime_utc:"2017-11-30 16:02:00", user_id:1, user:"Drew", card_id:-4, field_id:"5", field:"Field 4", value:"hry"}
    ]);

    DatabaseMobx.o_tbl_c_log_pwin = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, datetime_utc:"2017-11-25 16:00:00", user_id:2, user:"Skip", pwin:35, is_calc_01:0},
      {id:2, capture_id:1, datetime_utc:"2017-11-27 16:00:00", user_id:1, user:"Drew", pwin:68, is_calc_01:1}
    ]);

    DatabaseMobx.o_tbl_c_log_shaping = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, datetime_utc:"2017-11-25 16:00:00", user_id:1, user:"Drew", question_id:1, question:"Question 1", answer_id:1, answer:"Test Message", total_progress:1},
      {id:2, capture_id:1, datetime_utc:"2017-11-27 16:00:00", user_id:2, user:"Skip", question_id:2, question:"Question 2", answer_id:1, answer:"Test Answer", total_progress:12}
    ]);

    DatabaseMobx.o_tbl_c_log_stages = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, datetime_utc:"2014-11-19 19:52:06", user_id:1, user:"Drew", stage_id:-1, stage_name:"Pursuit"},
      {id:2, capture_id:1, datetime_utc:"2015-01-26 20:42:36", user_id:1, user:"Drew", stage_id:6, stage_name:"Proposal"},
      {id:3, capture_id:1, datetime_utc:"2015-03-01 22:16:29", user_id:1, user:"Drew", stage_id:13, stage_name:"Submit"},
      {id:4, capture_id:1, datetime_utc:"2015-04-07 17:55:36", user_id:1, user:"Drew", stage_id:8, stage_name:"Won"},
      {id:5, capture_id:5, datetime_utc:"2022-10-06 12:00:00", user_id:1, user:"Drew", stage_id:1, stage_name:"Stage1"},
      {id:6, capture_id:5, datetime_utc:"2022-10-06 12:00:00", user_id:1, user:"Drew", stage_id:2, stage_name:"Stage2"},
      {id:7, capture_id:5, datetime_utc:"2022-10-06 12:00:00", user_id:1, user:"Drew", stage_id:3, stage_name:"Stage3"}
    ]);

    DatabaseMobx.o_tbl_c_log_teammate_contracts = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, teammate_id:1, contract_type_id:1, datetime_utc:"2017-11-25 16:01:57", user_id:2, user:"Skip", field:"Changed Teammate Contract Status", value:"Now", notes:"Nownotes", from_status_id:0, to_status_id:1},
      {id:2, capture_id:1, teammate_id:1, contract_type_id:1, datetime_utc:"2018-11-27 16:02:58", user_id:1, user:"Drew", field:"Changed Teammate Contract Status", value:"Later", notes:"Laternotes", from_status_id:0, to_status_id:1},
      {id:3, capture_id:2, teammate_id:1, contract_type_id:2, datetime_utc:"2019-11-25 16:03:59", user_id:1, user:"Drew", field:"Changed Teammate Contract Status", value:"Now", notes:"Nownotes", from_status_id:0, to_status_id:1},
      {id:4, capture_id:2, teammate_id:1, contract_type_id:3, datetime_utc:"2020-11-27 16:04:00", user_id:1, user:"Drew", field:"Changed Teammate Contract Status", value:"Later", notes:"Laternotes", from_status_id:0, to_status_id:1}
    ]);

    DatabaseMobx.o_tbl_c_notepad_note_stamps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, pinned_01:0, sort:0, created_datetime_utc:"2023-08-24 17:02:59", created_by_user_id:1, created_by_user_name:"Drew", last_edited_datetime_utc:"2023-08-25 16:01:58", last_edited_by_user_id:2, last_edited_by_user_name:"Skip", title:"Stamp Title", body:"Stamp body"},
      {id:2, capture_id:1, pinned_01:0, sort:0, created_datetime_utc:"2023-08-23 15:00:00", created_by_user_id:1, created_by_user_name:"Drew", last_edited_datetime_utc:"2023-08-26 17:03:00", last_edited_by_user_id:1, last_edited_by_user_name:"Drew", title:"Title #2" + longText, body:"Body #2" + longText},
      {id:3, capture_id:1, pinned_01:0, sort:0, created_datetime_utc:"2023-08-28 17:02:59", created_by_user_id:1, created_by_user_name:"Drew", last_edited_datetime_utc:"2023-08-25 16:01:58", last_edited_by_user_id:2, last_edited_by_user_name:"Skip", title:"Stamp Title", body:"Stamp body"},
      {id:4, capture_id:1, pinned_01:0, sort:0, created_datetime_utc:"2023-08-29 17:02:59", created_by_user_id:1, created_by_user_name:"Drew", last_edited_datetime_utc:"2023-08-25 16:01:58", last_edited_by_user_id:2, last_edited_by_user_name:"Skip", title:"Stamp Title", body:"Stamp body"},
      {id:5, capture_id:1, pinned_01:0, sort:0, created_datetime_utc:"2023-08-30 17:02:59", created_by_user_id:1, created_by_user_name:"Drew", last_edited_datetime_utc:"2023-08-25 16:01:58", last_edited_by_user_id:2, last_edited_by_user_name:"Skip", title:"Stamp Title", body:"Stamp body"}
    ]);

    DatabaseMobx.o_tbl_c_pt_differentiators = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, sort:1, differentiator_id:1, justification:longText},
      {id:2, capture_id:1, sort:2, differentiator_id:2, justification:"An answer."}
    ]);

    DatabaseMobx.o_tbl_c_pt_ghost_themes = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, sort:1, theme:longText, justification:longText},
      {id:2, capture_id:1, sort:2, theme:"A theme.", justification:longText}
    ]);

    DatabaseMobx.o_tbl_c_pt_win_themes = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, sort:1, theme:longText, justification:longText},
      {id:2, capture_id:1, sort:2, theme:"A theme.", justification:longText}
    ]);

    DatabaseMobx.o_tbl_c_risks = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, sort:3, risk_category_id:1, probability_risk_level_id:1, impact_risk_level_id:2, identified_risk:"Risk statement", mitigation_approach:"migitate this."},
      {id:2, capture_id:1, sort:1, risk_category_id:-1, probability_risk_level_id:3, impact_risk_level_id:4, identified_risk:"Risk statement2", mitigation_approach:longText},
      {id:3, capture_id:1, sort:2, risk_category_id:1, probability_risk_level_id:5, impact_risk_level_id:99, identified_risk:longText, mitigation_approach:"migitate this3."},
      {id:4, capture_id:1, sort:4, risk_category_id:2, probability_risk_level_id:1, impact_risk_level_id:1, identified_risk:"test", mitigation_approach:"test"},
      {id:5, capture_id:1, sort:5, risk_category_id:1, probability_risk_level_id:2, impact_risk_level_id:2, identified_risk:"test", mitigation_approach:"test"},
      {id:6, capture_id:1, sort:6, risk_category_id:3, probability_risk_level_id:3, impact_risk_level_id:3, identified_risk:"test", mitigation_approach:"test"},
      {id:7, capture_id:1, sort:7, risk_category_id:1, probability_risk_level_id:4, impact_risk_level_id:4, identified_risk:"test", mitigation_approach:"test"},
      {id:8, capture_id:1, sort:8, risk_category_id:5, probability_risk_level_id:5, impact_risk_level_id:5, identified_risk:"test", mitigation_approach:"test"},
      {id:9, capture_id:1, sort:9, risk_category_id:-1, probability_risk_level_id:-1, impact_risk_level_id:-1, identified_risk:"test", mitigation_approach:"test"}
    ]);

    DatabaseMobx.o_tbl_c_shaping_answers_select = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, question_id:1, answer_id:2}
    ]);

    DatabaseMobx.o_tbl_c_shaping_answers_textarea = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, question_id:2, answer_text:longText, score0to100:20}
    ]);

    DatabaseMobx.o_tbl_c_teammates = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, contact_company_id:1, prime_01:0, selected_01:1, allocation_percent:19.25, workshare_type_id:1, workshare_number_of_ftes:55, workshare_notes:"Workshare Notes", teammate_contracts_manager_contact_person_id:1, contact_person_ids_comma:"1,3,2", surveys_contact_person_ids_comma:"1,3,2",  notes:longText, test_team:"go there"},
      {id:2, capture_id:1, contact_company_id:-2, prime_01:0, selected_01:0, allocation_percent:5, workshare_type_id:2, workshare_number_of_ftes:2, workshare_notes:"", teammate_contracts_manager_contact_person_id:11, contact_person_ids_comma:"", surveys_contact_person_ids_comma:"", notes:"", test_team:""},
      {id:3, capture_id:1, contact_company_id:333, prime_01:0, selected_01:1, allocation_percent:13, workshare_type_id:3, workshare_number_of_ftes:0, workshare_notes:"Workshare Notes", teammate_contracts_manager_contact_person_id:1, contact_person_ids_comma:"", surveys_contact_person_ids_comma:"", notes:"", test_team:""},
      {id:4, capture_id:1, contact_company_id:4, prime_01:0, selected_01:0, allocation_percent:2, workshare_type_id:0, workshare_number_of_ftes:3, workshare_notes:"", teammate_contracts_manager_contact_person_id:-1, contact_person_ids_comma:"", surveys_contact_person_ids_comma:"", notes:"", test_team:""},
      {id:5, capture_id:1, contact_company_id:5, prime_01:0, selected_01:1, allocation_percent:8, workshare_type_id:-1, workshare_number_of_ftes:0, workshare_notes:"", teammate_contracts_manager_contact_person_id:-1, contact_person_ids_comma:"", surveys_contact_person_ids_comma:"", notes:"", test_team:""},
      {id:6, capture_id:1, contact_company_id:14, prime_01:0, selected_01:1, allocation_percent:3, workshare_type_id:-1, workshare_number_of_ftes:0, workshare_notes:"", teammate_contracts_manager_contact_person_id:-1, contact_person_ids_comma:"", surveys_contact_person_ids_comma:"", notes:"", test_team:""},
      {id:7, capture_id:1, contact_company_id:16, prime_01:0, selected_01:1, allocation_percent:7, workshare_type_id:-1, workshare_number_of_ftes:0, workshare_notes:"", teammate_contracts_manager_contact_person_id:-1, contact_person_ids_comma:"", surveys_contact_person_ids_comma:"", notes:"", test_team:""},
      {id:8, capture_id:2, contact_company_id:1, prime_01:0, selected_01:0, allocation_percent:8, workshare_type_id:1, workshare_number_of_ftes:55, workshare_notes:"Workshare Notes", teammate_contracts_manager_contact_person_id:1, contact_person_ids_comma:"1,3,2", surveys_contact_person_ids_comma:"",  notes:longText, test_team:"go there"},
      {id:9, capture_id:2, contact_company_id:-2, prime_01:0, selected_01:1, allocation_percent:1.75, workshare_type_id:0, workshare_number_of_ftes:0, workshare_notes:"", teammate_contracts_manager_contact_person_id:0, contact_person_ids_comma:"", surveys_contact_person_ids_comma:"", notes:"", test_team:""},
      {id:10, capture_id:2, contact_company_id:3, prime_01:1, selected_01:1, allocation_percent:98.25, workshare_type_id:0, workshare_number_of_ftes:0, workshare_notes:"", teammate_contracts_manager_contact_person_id:0, contact_person_ids_comma:"", surveys_contact_person_ids_comma:"", notes:"", test_team:""}
    ]);

    DatabaseMobx.o_tbl_c_teammates_contracts = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, teammate_id:1, teammate_contract_type_id:1, agreement_type_id:1, status_id:3, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2019-05-05", date_completed:""},
      {id:2, capture_id:1, teammate_id:1, teammate_contract_type_id:2, agreement_type_id:2, status_id:1, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2019-06-06", date_completed:""},
      {id:3, capture_id:1, teammate_id:1, teammate_contract_type_id:3, agreement_type_id:1, status_id:2, requested_by_user_id:2, date_requested:"2018-04-15", date_needed:"2019-07-07", date_completed:""},
      {id:4, capture_id:1, teammate_id:1, teammate_contract_type_id:4, agreement_type_id:1, status_id:2, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2019-08-08", date_completed:""},
      {id:5, capture_id:1, teammate_id:2, teammate_contract_type_id:1, agreement_type_id:1, status_id:3, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2019-09-09", date_completed:""},
      {id:6, capture_id:1, teammate_id:2, teammate_contract_type_id:2, agreement_type_id:1, status_id:3, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2019-10-10", date_completed:""},
      {id:7, capture_id:1, teammate_id:3, teammate_contract_type_id:3, agreement_type_id:1, status_id:4, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2019-11-11", date_completed:""},
      {id:8, capture_id:1, teammate_id:3, teammate_contract_type_id:1, agreement_type_id:1, status_id:4, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2019-12-12", date_completed:""},
      {id:9, capture_id:2, teammate_id:1, teammate_contract_type_id:1, agreement_type_id:1, status_id:1, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2020-01-13", date_completed:""},
      {id:10, capture_id:2, teammate_id:2, teammate_contract_type_id:1, agreement_type_id:1, status_id:2, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2020-02-14", date_completed:""},
      {id:11, capture_id:2, teammate_id:9, teammate_contract_type_id:1, agreement_type_id:1, status_id:3, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2020-03-15", date_completed:""},
      {id:12, capture_id:2, teammate_id:4, teammate_contract_type_id:1, agreement_type_id:1, status_id:4, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2020-04-16", date_completed:""},
      {id:13, capture_id:2, teammate_id:5, teammate_contract_type_id:1, agreement_type_id:1, status_id:5, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2020-05-17", date_completed:""},
      {id:14, capture_id:2, teammate_id:6, teammate_contract_type_id:1, agreement_type_id:1, status_id:6, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2020-06-18", date_completed:""},
      {id:15, capture_id:2, teammate_id:7, teammate_contract_type_id:1, agreement_type_id:1, status_id:7, requested_by_user_id:1, date_requested:"2018-04-15", date_needed:"2020-07-19", date_completed:""}
    ]);

    DatabaseMobx.o_tbl_c_teammates_contracts_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, teammate_id:1, contract_type_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_folder", display_name:"Test Folder", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:2, capture_id:1, teammate_id:1, contract_type_id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"dev/folder/file.ext", display_name:"Test File", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-08-17", upload_user_id:1, upload_user_name:"Drew Name"}
    ]);

    DatabaseMobx.o_tbl_c_teammates_ratings_questionnaire_submissions = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, teammate_id:1, contact_company_id:1, reviewed_by_user_id:1, datetime_utc:"2020-11-11 09:09:09", q1a:-1, q1c:"comment1", q3a:3, q3c:"comment3", q6a:5, q6c:"comment6"},
      {id:2, capture_id:1, teammate_id:1, contact_company_id:1, reviewed_by_user_id:2, datetime_utc:"2025-03-27 09:09:09", q1a:5, q1c:"comment1", q3a:5, q3c:"comment3", q6a:4, q6c:""}
    ]);

    DatabaseMobx.o_tbl_c_teammates_sb_certification_allocations = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, sb_certification_bm_set_aside_id:1, allocation_percent:4.1},
      {id:2, capture_id:1, sb_certification_bm_set_aside_id:5, allocation_percent:2},
      {id:3, capture_id:1, sb_certification_bm_set_aside_id:3, allocation_percent:5}
    ]);

    DatabaseMobx.o_tbl_c_teammates_surveys = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, sort:1, title:"New Survey 1", invitation_text:"Heyyyyy"},
      {id:2, capture_id:1, sort:2, title:"New Survey 2", invitation_text:""},
      {id:3, capture_id:1, sort:3, title:"New Survey 3 but with an Extra Long Title hiding here in the back", invitation_text:""},
      {id:4, capture_id:1, sort:4, title:"New Survey 4", invitation_text:""},
      {id:5, capture_id:1, sort:5, title:"New Survey 5", invitation_text:""},
      {id:6, capture_id:2, sort:1, title:"Subby Survey", invitation_text:"A new invitation text"},
      {id:7, capture_id:2, sort:2, title:"Survey with No Questions", invitation_text:"No Questions"},
      {id:8, capture_id:2, sort:3, title:"Survey with No Invitation Text", invitation_text:""}
    ]);

    DatabaseMobx.o_tbl_c_teammates_surveys_answers = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, teammate_id:1, question_id:1, rating:0, comment:""},
      {id:2, capture_id:1, teammate_id:1, question_id:2, rating:0, comment:"Answer comment 2 here"},
      {id:3, capture_id:1, teammate_id:2, question_id:1, rating:1, comment:"Answer comment 3 here"},
      {id:4, capture_id:1, teammate_id:2, question_id:2, rating:1, comment:"Answer comment 4 here"},
      {id:5, capture_id:1, teammate_id:2, question_id:3, rating:0, comment:""},
      {id:6, capture_id:1, teammate_id:3, question_id:1, rating:-1, comment:"Answer comment 6 here"},
      {id:7, capture_id:1, teammate_id:3, question_id:2, rating:2, comment:""},
      {id:8, capture_id:1, teammate_id:3, question_id:3, rating:0, comment:"Answer comment 8 here"},
      {id:9, capture_id:1, teammate_id:4, question_id:1, rating:0, comment:"Answer comment 9 here"},
      {id:10, capture_id:1, teammate_id:4, question_id:2, rating:3, comment:"Answer comment 10 here"},
      {id:11, capture_id:1, teammate_id:5, question_id:2, rating:4, comment:"Answer comment 11 here"},
      {id:12, capture_id:1, teammate_id:6, question_id:2, rating:-1, comment:"Answer comment 12 here"},
      {id:13, capture_id:1, teammate_id:-2, question_id:1, rating:1, comment:"Answer comment 13 here"},
      {id:14, capture_id:2, teammate_id:9, question_id:5, rating:1, comment:"Yes for Us Subby"}
    ]);

    DatabaseMobx.o_tbl_c_teammates_surveys_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, survey_id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_survey_file.pdf", display_name:"Test Survey File", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:2, capture_id:1, survey_id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_survey_file2.pdf", display_name:"Test Survey File2", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"}
    ]);

    DatabaseMobx.o_tbl_c_teammates_surveys_questions = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, survey_id:1, sort:1, question_type_id:1, name:"Question 1 Yes/No with comments"},
      {id:2, capture_id:1, survey_id:1, sort:2, question_type_id:2, name:"Question 2 Scale 0-5 with comments"},
      {id:3, capture_id:1, survey_id:1, sort:3, question_type_id:3, name:"Question 3 Text Response" + longText},
      {id:4, capture_id:1, survey_id:1, sort:4, question_type_id:4, name:"Question 4 Instructions Block"},
      {id:5, capture_id:2, survey_id:6, sort:1, question_type_id:1, name:"Subby Q1"},
      {id:6, capture_id:2, survey_id:8, sort:1, question_type_id:2, name:"Subby No Inv Q1"},
      {id:7, capture_id:1, survey_id:2, sort:1, question_type_id:1, name:"Survey 2 Q1"},
      {id:8, capture_id:1, survey_id:2, sort:2, question_type_id:3, name:"Survey 2 Q2"},
      {id:9, capture_id:1, survey_id:2, sort:3, question_type_id:2, name:"Survey 2 Q3"}
    ]);

    DatabaseMobx.o_tbl_c_teammates_surveys_response_times = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, survey_id:1, teammate_id:1, sent_by_user_per_email_id:1, file_ids_comma:"1,2", sent_datetime_utc:"2020-06-30 16:00:00", due_datetime_utc:"2023-07-30 16:00:00", first_opened_datetime_utc:"0000-00-00 00:00:00", completed_datetime_utc:"0000-00-00 00:00:00", not_interested_01:0},
      {id:2, capture_id:1, survey_id:1, teammate_id:2, sent_by_user_per_email_id:1, file_ids_comma:"", sent_datetime_utc:"2020-06-30 16:00:00", due_datetime_utc:"2023-09-30 16:00:00", first_opened_datetime_utc:"2022-03-01 00:00:00", completed_datetime_utc:"0000-00-00 00:00:00", not_interested_01:0},
      {id:3, capture_id:1, survey_id:1, teammate_id:3, sent_by_user_per_email_id:1, file_ids_comma:"2", sent_datetime_utc:"2020-06-30 16:00:00", due_datetime_utc:"2023-12-30 16:00:00", first_opened_datetime_utc:"0000-00-00 00:00:00", completed_datetime_utc:"", not_interested_01:0}
    ]);

    DatabaseMobx.o_tbl_d_daily_snapshot_graphs = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, page_id:1, sort:1, created_by_user_id:1, public_01:1, replica_of_graph_id:-1, filter_preset_id:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:1, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, y_axis_log_01:0, graph_title:"Shared Percent Proportional"},
      {id:2, page_id:1, sort:2, created_by_user_id:1, public_01:1, replica_of_graph_id:-1, filter_preset_id:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:1, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:1, y_axis_log_01:0, graph_title:"Shared Percent Whole"},
      {id:3, page_id:1, sort:3, created_by_user_id:1, public_01:1, replica_of_graph_id:-1, filter_preset_id:4, categories_field_id:4, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:1, legend_01:1, legend_hide_zero_categories_01:1, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:1, y_axis_log_01:0, graph_title:"All Captures"},
      {id:4, page_id:1, sort:4, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:6, categories_field_id:4, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:3, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:11, ignore_shared_percent_calcs_01:0, y_axis_log_01:1, graph_title:"Skip User Only"},
      {id:5, page_id:1, sort:5, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:7, categories_field_id:4, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:0, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, y_axis_log_01:0, graph_title:"Skip User and Army Div Only"},
      {id:6, page_id:2, sort:1, created_by_user_id:3, public_01:1, replica_of_graph_id:-1, filter_preset_id:4, categories_field_id:10, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, y_axis_log_01:0, graph_title:"Pubby By Skip"},
      {id:7, page_id:1, sort:6, created_by_user_id:-1, public_01:-1, replica_of_graph_id:4, filter_preset_id:-1, categories_field_id:-1, all_categories_01:-1, categories_option_ids_comma:"", categories_include_not_set_01:-1, categories_custom_colors_01:-1, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:-1, legend_01:-1, legend_hide_zero_categories_01:-1, num_captures_m2_or_money_field_id:-1, ignore_shared_percent_calcs_01:-1, y_axis_log_01:-1, graph_title:""},
      {id:8, page_id:1, sort:7, created_by_user_id:-1, public_01:-1, replica_of_graph_id:999, filter_preset_id:-1, categories_field_id:-1, all_categories_01:-1, categories_option_ids_comma:"", categories_include_not_set_01:-1, categories_custom_colors_01:-1, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:-1, legend_01:-1, legend_hide_zero_categories_01:-1, num_captures_m2_or_money_field_id:-1, ignore_shared_percent_calcs_01:-1, y_axis_log_01:-1, graph_title:""},
      {id:9, page_id:4, sort:1, created_by_user_id:3, public_01:0, replica_of_graph_id:-1, filter_preset_id:1, categories_field_id:1, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:11, ignore_shared_percent_calcs_01:0, y_axis_log_01:1, graph_title:"Skip Graph"},
      {id:10, page_id:2, sort:2, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:1, categories_field_id:18, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", graph_type_p0_vb1_hb2_f3:0, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, y_axis_log_01:0, graph_title:"Competition Type Set-Asides"}
    ]);

    DatabaseMobx.o_tbl_d_daily_snapshot_pages = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, user_id:1, sort:1, created_by_user_id:1, public_01:0, replica_of_page_id:-1, name:"Test Page #1", num_graphs_per_row:3, graph_height_em:28},
      {id:2, user_id:1, sort:2, created_by_user_id:1, public_01:1, replica_of_page_id:-1, name:"Test Page #2 but it's a really really long name like really long", num_graphs_per_row:3, graph_height_em:28},
      {id:3, user_id:1, sort:3, created_by_user_id:-1, public_01:-1, replica_of_page_id:2, name:"", num_graphs_per_row:-1, graph_height_em:-1},
      {id:4, user_id:3, sort:1, created_by_user_id:3, public_01:1, replica_of_page_id:-1, name:"Skip Page", num_graphs_per_row:4, graph_height_em:40},
      {id:5, user_id:1, sort:4, created_by_user_id:-1, public_01:-1, replica_of_page_id:4, name:"", num_graphs_per_row:-1, graph_height_em:-1}
    ]);

    DatabaseMobx.o_tbl_d_quota = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, user0_division1:1, userdiv_id:1, money_field_id:19, fyr:2024, submitted_quota:10000000, won_quota:5000000},
      {id:2, user0_division1:1, userdiv_id:1, money_field_id:19, fyr:2026, submitted_quota:20000000, won_quota:7500000},
      {id:3, user0_division1:1, userdiv_id:1, money_field_id:19, fyr:2028, submitted_quota:25000000, won_quota:10000000},
      {id:4, user0_division1:1, userdiv_id:1, money_field_id:19, fyr:2029, submitted_quota:50000000, won_quota:15000000},
      {id:5, user0_division1:1, userdiv_id:1, money_field_id:19, fyr:2030, submitted_quota:60000000, won_quota:40000000},
      {id:6, user0_division1:0, userdiv_id:1, money_field_id:20, fyr:2025, submitted_quota:9876543, won_quota:8765432}
    ]);

    DatabaseMobx.o_tbl_d_trend_analyzer_graphs = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, page_id:1, sort:1, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:0, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:0, graph_title:"Line/Bar Type 0"},
      {id:2, page_id:1, sort:2, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:1, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:0, graph_title:"Line/Bar Type 1"},
      {id:3, page_id:1, sort:3, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:0, graph_title:"Line/Bar Type 2"},
      {id:4, page_id:1, sort:4, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:3, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:0, graph_title:"Line/Bar Type 3"},
      {id:5, page_id:1, sort:5, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:0, graph_title:"Yearly"},
      {id:6, page_id:1, sort:6, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"quarterly", y_axis_log_01:0, graph_title:"Quarterly"},
      {id:7, page_id:1, sort:7, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"monthly", y_axis_log_01:0, graph_title:"Monthly"},
      {id:8, page_id:1, sort:8, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"weekly", y_axis_log_01:0, graph_title:"Weekly"},
      {id:9, page_id:1, sort:9, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:0, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:1, graph_title:"# Captures (Y-log)"},
      {id:10, page_id:1, sort:10, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:0, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:11, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:1, graph_title:"Capture $ (Y-log)"},
      {id:11, page_id:1, sort:11, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:1, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:0, graph_title:"Shared Percent Proportional"},
      {id:12, page_id:1, sort:12, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:1, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:1, time_bins:"yearly", y_axis_log_01:0, graph_title:"Shared Percent Whole"},
      {id:13, page_id:2, sort:1, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:-2, ignore_shared_percent_calcs_01:0, time_bins:"quarterly", y_axis_log_01:0, graph_title:"Quarterly Bars Test"},
      {id:14, page_id:3, sort:1, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:0, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:11, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:1, graph_title:"Capture $ - Line (Y-log)"},
      {id:15, page_id:3, sort:2, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:1, categories_option_ids_comma:"", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:2, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:11, ignore_shared_percent_calcs_01:0, time_bins:"yearly", y_axis_log_01:1, graph_title:"Capture $ - Bar (Y-log)"},
      {id:16, page_id:3, sort:3, created_by_user_id:1, public_01:0, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:5, all_categories_01:0, categories_option_ids_comma:"1,2,3,4", categories_include_not_set_01:0, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:0, legend_01:0, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:11, ignore_shared_percent_calcs_01:0, time_bins:"weekly", y_axis_log_01:1, graph_title:"Wiiiig"},
      {id:17, page_id:3, sort:4, created_by_user_id:2, public_01:1, replica_of_graph_id:-1, filter_preset_id:4, date_field_id:21, use_categories_field_01:1, categories_field_id:4, all_categories_01:0, categories_option_ids_comma:"", categories_include_not_set_01:1, categories_custom_colors_01:0, categories_custom_colors_colon_comma_list:"", line0_bar1:0, legend_01:1, legend_hide_zero_categories_01:0, num_captures_m2_or_money_field_id:11, ignore_shared_percent_calcs_01:0, time_bins:"monthly", y_axis_log_01:1, graph_title:"Pubby"},
      {id:18, page_id:3, sort:5, created_by_user_id:1, public_01:-1, replica_of_graph_id:3, filter_preset_id:-1, date_field_id:-1, use_categories_field_01:-1, categories_field_id:-1, all_categories_01:-1, categories_option_ids_comma:"", categories_include_not_set_01:-1, categories_custom_colors_01:-1, categories_custom_colors_colon_comma_list:"", line0_bar1:-1, legend_01:-1, legend_hide_zero_categories_01:-1, num_captures_m2_or_money_field_id:-1, ignore_shared_percent_calcs_01:-1, time_bins:"", y_axis_log_01:-1, graph_title:""}
    ]);

    DatabaseMobx.o_tbl_d_trend_analyzer_pages = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, user_id:1, sort:1, created_by_user_id:1, public_01:0, replica_of_page_id:-1, name:"Trend Test Page 1 but it's a really really long name like really long", num_graphs_per_row:2, graph_height_em:26, start_date:"2016-01-01", end_date:"9007-01-01"},
      {id:2, user_id:1, sort:2, created_by_user_id:1, public_01:0, replica_of_page_id:-1, name:"Page #2", num_graphs_per_row:1, graph_height_em:40, start_date:"2017-07-01", end_date:"2023-01-01"},
      {id:3, user_id:1, sort:3, created_by_user_id:1, public_01:0, replica_of_page_id:-1, name:"Page #3", num_graphs_per_row:2, graph_height_em:40, start_date:"2017-02-02", end_date:"2024-02-02"}
    ]);

    DatabaseMobx.o_tbl_f_capture_table_presets = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Dev Trifecta Every Col {1}", description:"Dev description", public_01:1, user_id:1, cst_column_preset_id:4, filter_preset_id:3, sort_preset_id:111},
      {id:2, name:"Skips Master (Progress > 25%) {2}", description:"Dev description", public_01:1, user_id:3, cst_column_preset_id:2, filter_preset_id:-301, sort_preset_id:3},
      {id:3, name:"All Captures {3}", description:"", public_01:1, user_id:2, cst_column_preset_id:1, filter_preset_id:4, sort_preset_id:1},
      {id:4, name:"Filter Army Div {4}", description:"", public_01:1, user_id:2, cst_column_preset_id:1, filter_preset_id:3, sort_preset_id:1},
      {id:5, name:"Filter Skip User {5}", description:"", public_01:1, user_id:2, cst_column_preset_id:1, filter_preset_id:6, sort_preset_id:1},
      {id:6, name:"filter Skip User and Army Div {6}", description:"", public_01:1, user_id:2, cst_column_preset_id:1, filter_preset_id:7, sort_preset_id:1},
      {id:7, name:"Filter Skip User Applied Twice {7}", description:"", public_01:1, user_id:2, cst_column_preset_id:1, filter_preset_id:8, sort_preset_id:1},
      {id:8, name:"Sample X4 {8}", description:"", public_01:1, user_id:2, cst_column_preset_id:1, filter_preset_id:1, sort_preset_id:1},
      {id:9, name:"Sample X5 {9}", description:"", public_01:1, user_id:2, cst_column_preset_id:1, filter_preset_id:1, sort_preset_id:1},
      {id:10, name:"DateTime Filter {10}", description:"", public_01:1, user_id:1, cst_column_preset_id:1, filter_preset_id:10, sort_preset_id:1},
      {id:11, name:"Only Mike Captures {11}", description:"", public_01:1, user_id:1, cst_column_preset_id:1, filter_preset_id:11, sort_preset_id:1},
      {id:12, name:"Captures I Favorited {12}", description:"", public_01:1, user_id:1, cst_column_preset_id:1, filter_preset_id:12, sort_preset_id:1},
      {id:13, name:"Captures I Didn't Favorite {13}", description:"", public_01:1, user_id:1, cst_column_preset_id:1, filter_preset_id:13, sort_preset_id:1},
      {id:14, name:"GCSS ID is Set {14}", description:"", public_01:1, user_id:1, cst_column_preset_id:4, filter_preset_id:14, sort_preset_id:1},
      {id:15, name:"Custom Columns Test {15}", description:"", public_01:1, user_id:1, cst_column_preset_id:5, filter_preset_id:4, sort_preset_id:1},
      {id:16, name:"OppName Does Not Contain test {16}", description:"", public_01:1, user_id:1, cst_column_preset_id:1, filter_preset_id:16, sort_preset_id:1},
      {id:17, name:"Capture Type Does Not Contain test {17}", description:"", public_01:1, user_id:1, cst_column_preset_id:1, filter_preset_id:17, sort_preset_id:1},
      {id:18, name:"Capture Manager Contains test {18}", description:"", public_01:1, user_id:1, cst_column_preset_id:1, filter_preset_id:18, sort_preset_id:1},
      {id:19, name:"System Filter test {19}", description:"", public_01:0, user_id:1, cst_column_preset_id:-200, filter_preset_id:-300, sort_preset_id:-400},
      {id:20, name:"Only Websites {20}", description:"", public_01:0, user_id:1, cst_column_preset_id:6, filter_preset_id:1, sort_preset_id:1}
    ]);

    var cstColumnsArrayOfObjs = [
      {id:100, cst_column_preset_id:1, capture_field_id:19, width_em:6, sort:1, cst_sum_row_01:1},
      {id:101, cst_column_preset_id:1, capture_field_id:20, width_em:6, sort:2, cst_sum_row_01:1},
      {id:102, cst_column_preset_id:1, capture_field_id:44, width_em:6, sort:3, cst_sum_row_01:1},
      {id:103, cst_column_preset_id:1, capture_field_id:45, width_em:6, sort:4, cst_sum_row_01:1},
      {id:104, cst_column_preset_id:1, capture_field_id:46, width_em:6, sort:5, cst_sum_row_01:1},
      {id:301, cst_column_preset_id:3, capture_field_id:1, width_em:6, sort:1, cst_sum_row_01:1},
      {id:302, cst_column_preset_id:3, capture_field_id:2, width_em:6, sort:2, cst_sum_row_01:1},
      {id:303, cst_column_preset_id:3, capture_field_id:3, width_em:6, sort:3, cst_sum_row_01:1},
      {id:601, cst_column_preset_id:6, capture_field_id:1, width_em:10, sort:1, cst_sum_row_01:0},
      {id:602, cst_column_preset_id:6, capture_field_id:2, width_em:10, sort:2, cst_sum_row_01:0},
      {id:603, cst_column_preset_id:6, capture_field_id:3, width_em:10, sort:3, cst_sum_row_01:0},
      {id:604, cst_column_preset_id:6, capture_field_id:106, width_em:20, sort:4, cst_sum_row_01:0},
      {id:605, cst_column_preset_id:6, capture_field_id:206, width_em:20, sort:5, cst_sum_row_01:0},
      {id:606, cst_column_preset_id:6, capture_field_id:207, width_em:20, sort:6, cst_sum_row_01:0},
      {id:607, cst_column_preset_id:6, capture_field_id:208, width_em:20, sort:7, cst_sum_row_01:0},
      {id:608, cst_column_preset_id:6, capture_field_id:314, width_em:20, sort:8, cst_sum_row_01:0}
    ];
    for(let c = 1; c <= 18; c++){
      cstColumnsArrayOfObjs.push({id:(c), cst_column_preset_id:1, capture_field_id:c, width_em:8, sort:(c+10), cst_sum_row_01:1});
    }
    for(let c = 0; c < devTblCapturesFieldsArrayOfObjs.length; c++) {
      var fieldObj = devTblCapturesFieldsArrayOfObjs[c];
      cstColumnsArrayOfObjs.push({id:(fieldObj.id+1000), cst_column_preset_id:4, capture_field_id:fieldObj.id, width_em:9, sort:(fieldObj.id+1000), cst_sum_row_01:0});
    }
    var customFieldIDsArray = [1,2,3,4,15,62,63];
    for(let c = 0; c < customFieldIDsArray.length; c++) {
      var customFieldID = customFieldIDsArray[c];
      cstColumnsArrayOfObjs.push({id:(c+1000), cst_column_preset_id:5, capture_field_id:customFieldID, width_em:((customFieldID > 10) ? (20) : (6)), sort:(c+1), cst_sum_row_01:0});
    }
    DatabaseMobx.o_tbl_f_cst_columns = JSFUNC.mapOfMaps_from_arrayOfObjs(cstColumnsArrayOfObjs);

    DatabaseMobx.o_tbl_f_cst_column_presets = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Drew's Capture Columns", public_01:1, user_id:1},
      {id:2, name:"Skip's Capture Columns", public_01:1, user_id:3},
      {id:3, name:"drew lowercase", public_01:1, user_id:1},
      {id:4, name:"Every Col", public_01:1, user_id:1},
      {id:5, name:"Custom Columns Test", public_01:1, user_id:1},
      {id:6, name:"Columns Only Websites", public_01:1, user_id:1}
    ]);

    DatabaseMobx.o_tbl_f_filters = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, filter_preset_id:1, capture_field_id:22, operator:"gte", value:"2017-09-09"},
      {id:2, filter_preset_id:2, capture_field_id:5, operator:"gte", value:"25"},
      {id:3, filter_preset_id:3, capture_field_id:4, operator:"e", value:"3"},
      {id:4, filter_preset_id:5, capture_field_id:4, operator:"e", value:"1,2,3"},
      {id:5, filter_preset_id:6, capture_field_id:1, operator:"e", value:"3"},
      {id:6, filter_preset_id:7, capture_field_id:1, operator:"e", value:"3"},
      {id:7, filter_preset_id:7, capture_field_id:4, operator:"e", value:"3"},
      {id:8, filter_preset_id:8, capture_field_id:1, operator:"e", value:"3"},
      {id:9, filter_preset_id:8, capture_field_id:1, operator:"e", value:"3"},
      {id:10, filter_preset_id:9, capture_field_id:44, operator:"lt", value:"50"},
      {id:11, filter_preset_id:10, capture_field_id:23, operator:"gte", value:"2020-01-01"},
      {id:12, filter_preset_id:11, capture_field_id:4, operator:"e", value:"11"},
      {id:13, filter_preset_id:12, capture_field_id:19, operator:"is", value:""},
      {id:14, filter_preset_id:13, capture_field_id:19, operator:"ins", value:""},
      {id:15, filter_preset_id:14, capture_field_id:201, operator:"is", value:""},
      {id:16, filter_preset_id:15, capture_field_id:1, operator:"e", value:"1"},
      {id:17, filter_preset_id:16, capture_field_id:2, operator:"dnc", value:"stage"},
      {id:18, filter_preset_id:17, capture_field_id:6, operator:"dnc", value:"fed"},
      {id:19, filter_preset_id:18, capture_field_id:4, operator:"c", value:"Skip,Mike"},
      {id:20, filter_preset_id:19, capture_field_id:21, operator:"gte", value:"8002-01-04"}
    ]);

    DatabaseMobx.o_tbl_f_filter_presets = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"All Active Captures in Company", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:2, name:"Pubby Filter", public_01:1, user_id:3, excel_report_writer_codeword:"pubby"},
      {id:3, name:"Army Div Only Filter (ID3)", public_01:1, user_id:2, excel_report_writer_codeword:""},
      {id:4, name:"All Captures", public_01:1, user_id:2, excel_report_writer_codeword:"all"},
      {id:5, name:"Dev/Fed/Army Divs Only", public_01:1, user_id:2, excel_report_writer_codeword:""},
      {id:6, name:"Skip User Only Filter (ID3)", public_01:1, user_id:2, excel_report_writer_codeword:""},
      {id:7, name:"skip User and Army Div Only Filter", public_01:1, user_id:2, excel_report_writer_codeword:""},
      {id:8, name:"Skip User Applied Twice Filter", public_01:1, user_id:2, excel_report_writer_codeword:""},
      {id:9, name:"Total Shaping Progress < 50%", public_01:1, user_id:2, excel_report_writer_codeword:""},
      {id:10, name:"Test DateTime >= 2020", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:11, name:"Filter Only Mike Captures", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:12, name:"Filter Favorites", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:13, name:"Filter Not Favs", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:14, name:"Filter GCSS ID is Set", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:15, name:"Filter Only CaptureID #1", public_01:1, user_id:1, excel_report_writer_codeword:"filtercaptureid1"},
      {id:16, name:"Filter OppName Does Not Contain test", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:17, name:"Filter Capture Type Does Not Contain test", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:18, name:"Filter Capture Manager Contains test", public_01:1, user_id:1, excel_report_writer_codeword:""},
      {id:19, name:"Filter Relative Date test", public_01:1, user_id:1, excel_report_writer_codeword:""}
    ]);

    DatabaseMobx.o_tbl_f_gantt_dates = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, gantt_date_preset_id:1, capture_field_id:21, color:"cc3333"},
      {id:2, gantt_date_preset_id:1, capture_field_id:22, color:"cc9933"},
      {id:3, gantt_date_preset_id:1, capture_field_id:23, color:"cccc33"},
      {id:4, gantt_date_preset_id:2, capture_field_id:24, color:"cccc33"},
      {id:5, gantt_date_preset_id:2, capture_field_id:25, color:"33cc33"},
      {id:6, gantt_date_preset_id:2, capture_field_id:26, color:"33cccc"},
      {id:7, gantt_date_preset_id:2, capture_field_id:27, color:"3333cc"}
    ]);

    DatabaseMobx.o_tbl_f_gantt_date_presets = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Dev Gantt", public_01:0, user_id:1},
      {id:2, name:"Pubby", public_01:1, user_id:3},
      {id:3, name:"Privvy", public_01:0, user_id:3},
      {id:4, name:"My Other Login Public", public_01:1, user_id:2},
      {id:5, name:"My Other Login Private", public_01:0, user_id:2}
    ]);

    DatabaseMobx.o_tbl_f_sorts = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, sort_preset_id:1, capture_field_id:11, is_asc_01:0, sort:1},
      {id:2, sort_preset_id:1, capture_field_id:8, is_asc_01:1, sort:2},
      {id:3, sort_preset_id:2, capture_field_id:21, is_asc_01:0, sort:1}
    ]);

    DatabaseMobx.o_tbl_f_sort_presets = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, name:"Stage, COV", public_01:1, user_id:1, excel_report_writer_codeword:"stagecov"},
      {id:2, name:"Pubby Sorty", public_01:1, user_id:3, excel_report_writer_codeword:"pubby"},
      {id:3, name:"Privvy Sorty", public_01:0, user_id:3, excel_report_writer_codeword:""},
      {id:4, name:"lowercase sort", public_01:1, user_id:3, excel_report_writer_codeword:""}
    ]);

    DatabaseMobx.o_tbl_g_contacts_companies = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, org_topdiv_id:-1, tree_id:"", legal_name:"Calvert County Commission for Nuclear Sales and Nonproliferation", abbreviated_name:"", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"1", capability_ids_comma:"", naics_code_ids_comma:"1,2,3", cage_code:"8N9042", email:"contact.company@test.com", phone:"222-222-2222", address1:"Address Line 1", address2:"Address Line 2", city:"O'Fallon", state:"IL", zip:"62269", country:"USA", state_of_incorporation:"Ireland", web_address:"[[mask]]www.website.com", notes:"notes field", tr_total:5, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:2, org_topdiv_id:1, tree_id:"01", legal_name:"Div01 of Test", abbreviated_name:"Div01", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"1,2,3,4,5", capability_ids_comma:"", naics_code_ids_comma:"4,5", cage_code:"", email:"division.company@test.com", phone:"333-333-3333", address1:"Division Line 1", address2:"Division Line 2", city:"Arlington", state:"VA", zip:"22222", country:"USA", state_of_incorporation:"", web_address:"", notes:"", tr_total:4.5, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:3, org_topdiv_id:1, tree_id:"03", legal_name:"Div03 of Test", abbreviated_name:"Div03", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"6", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:4, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:4, org_topdiv_id:1, tree_id:"02", legal_name:"Div02 of Test", abbreviated_name:"Div02", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:3.5, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:5, org_topdiv_id:1, tree_id:"0101", legal_name:"Div0101 of Test", abbreviated_name:"Div0101 Invalid Business Type", business_type_id:99, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:3, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:6, org_topdiv_id:1, tree_id:"0102", legal_name:"Div0102 of Test", abbreviated_name:"Div0102", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:2.5, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:7, org_topdiv_id:1, tree_id:"010101", legal_name:"Div010101 of Test", abbreviated_name:"Div010101", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:2, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:8, org_topdiv_id:1, tree_id:"010201", legal_name:"Div010201 of Test", abbreviated_name:"Div010201", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:1.5, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:9, org_topdiv_id:1, tree_id:"01010101", legal_name:"Div01010101 of Test", abbreviated_name:"Div01010101", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:1, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:10, org_topdiv_id:1, tree_id:"0201", legal_name:"Div0201 of Test", abbreviated_name:"Div0201", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:0.5, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:11, org_topdiv_id:1, tree_id:"0202", legal_name:"Div0202 of Test", abbreviated_name:"Div0202", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:0, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:12, org_topdiv_id:1, tree_id:"020201", legal_name:"Div020201 of Test", abbreviated_name:"Div020201", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"", phone:"", address1:"", address2:"", city:"", state:"", zip:"", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:-0.5, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:13, org_topdiv_id:-1, tree_id:"", legal_name:"Large Business No Abbr", abbreviated_name:"", business_type_id:1, sb_certifications_bm_set_aside_ids_comma:"", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"a@a", phone:"123", address1:"1", address2:"2", city:"A", state:"MD", zip:"11111", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:-1, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:14, org_topdiv_id:-1, tree_id:"", legal_name:"", abbreviated_name:"Abbr No Legal", business_type_id:2, sb_certifications_bm_set_aside_ids_comma:"1", capability_ids_comma:"2,4", email:"a@a", phone:"123", address1:"1", address2:"2", city:"A", state:"MD", zip:"11111", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:4.75, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:15, org_topdiv_id:-1, tree_id:"", legal_name:"", abbreviated_name:"", business_type_id:2, sb_certifications_bm_set_aside_ids_comma:"2,3", capability_ids_comma:"", naics_code_ids_comma:"", cage_code:"", email:"a@a", phone:"123", address1:"1", address2:"2", city:"A", state:"MD", zip:"11111", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:3.75, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1},
      {id:16, org_topdiv_id:-1, tree_id:"", legal_name:"SB245", abbreviated_name:"SB245", business_type_id:2, sb_certifications_bm_set_aside_ids_comma:"2,4,5", capability_ids_comma:"3", naics_code_ids_comma:"", cage_code:"", email:"a@a", phone:"123", address1:"1", address2:"2", city:"A", state:"MD", zip:"11111", country:"", state_of_incorporation:"", web_address:"", notes:"", tr_total:2.75, tr_num_reviews:14, tr_q1:3.3, tr_q3:2.2, tr_q6:1.1}
    ]);
    for(let c=17; c<20; c++){DatabaseMobx.o_tbl_g_contacts_companies.set(c, JSFUNC.map_from_obj({id:c, org_topdiv_id:-1, tree_id:"", legal_name:JSFUNC.num2str(Math.round(Math.random()*1000000))}));}

    DatabaseMobx.o_tbl_g_contacts_companies_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, contact_company_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_folder", display_name:"Test Folder", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:2, contact_company_id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_file_1", display_name:"Test File 1", keywords:"keywords", notes:"Notes here", content_unique_lowercase:"content", upload_date:"2020-07-17", upload_user_id:1, upload_user_name:"Drew Name"}
    ]);

    DatabaseMobx.o_tbl_g_contacts_persons = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, contact_company_id:9, first_name:"Contact", last_name:"Test", title:"Title Testing", email:"", phone:"410-410-4101", cell_phone:"410-410-4101", social_web_address:"[linkedIn]www.mysite.com"},
      {id:2, contact_company_id:6789, first_name:"Skip", last_name:"Blackburn", title:"CEO", email:"skip@bitsolutionsllc.com", phone:"222-222-2222", cell_phone:"410-410-4101", social_web_address:"[linkedIn2]www.mysite.com"},
      {id:3, contact_company_id:2, first_name:"Drew", last_name:"Blackburn", title:"CTO", email:"andrew.c.blackburn@gmail.com", phone:"222-222-2222", cell_phone:"410-410-4101", social_web_address:"[linkedI3n]www.mysite.com"},
      {id:4, contact_company_id:-2, first_name:"GCSS", last_name:"Test", title:"", email:"gcssimportemail@sam.gov", phone:"524-798-0090", cell_phone:"", social_web_address:""},
      {id:5, contact_company_id:-1, first_name:"Minus", last_name:"OneTest", title:"", email:"minusone@test.com", phone:"", cell_phone:"", social_web_address:""}
    ]);

    DatabaseMobx.o_tbl_g_contacts_persons_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, contact_person_id:1, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_folder", display_name:"Test Folder", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:2, contact_person_id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test_file_1", display_name:"Test File 1", keywords:"keywords", notes:"Notes here", content_unique_lowercase:"content", upload_date:"2020-07-17", upload_user_id:1, upload_user_name:"Drew Name"}
    ]);

    DatabaseMobx.o_tbl_g_gcss_not_interested = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, gcss_id:1, gcss_govwin_id_string:"", gcss_fedcomp_id_string:"", solicitation_number:"ADBCADB-2890", user_per_email_id:1, undecided0_notinterested1:1, reason_ids_comma:"1", explanation:"Custom Professional Explanation"},
      {id:2, gcss_id:2, gcss_govwin_id_string:"", gcss_fedcomp_id_string:"", solicitation_number:"", user_per_email_id:1, undecided0_notinterested1:1, reason_ids_comma:"2,3", explanation:longText},
      {id:3, gcss_id:0, gcss_govwin_id_string:"FBO3348551", gcss_fedcomp_id_string:"", solicitation_number:"", user_per_email_id:1, undecided0_notinterested1:1, reason_ids_comma:"2", explanation:"GovWin Explanation"},
      {id:4, gcss_id:0, gcss_govwin_id_string:"", gcss_fedcomp_id_string:"8baf40d772812457feb0acfc00af743c1cf0f81a018170eb386138bb54ddee8f", solicitation_number:"", user_per_email_id:1, undecided0_notinterested1:1, reason_ids_comma:"3", explanation:"Federal Compass Explanation"}
    ]);

    DatabaseMobx.o_tbl_g_general_documents_filefoldersystem = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test/file", display_name:"Test General File1", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:2, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test/file.pptx", display_name:"Test General File2", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:3, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test/file.xlsx", display_name:"Test General File3", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:111, upload_user_name:"Drew Name"},
      {id:4, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test/file.docx", display_name:"Test General File4", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:-33, upload_user_name:"Drew Name"},
      {id:5, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test/file.pdf", display_name:"Test General File5", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:6, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test/file.txt", display_name:"Test General File6", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:7, folder0_file1:1, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test/file.xml", display_name:"Test General File7", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:8, folder0_file1:0, parent_folder_id:-1, fileupload0_onlinelink1:0, file_loc:"test/file.xml", display_name:"Test Folder8", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"},
      {id:9, folder0_file1:1, parent_folder_id:8, fileupload0_onlinelink1:0, file_loc:"test/file.xml", display_name:"Test General File9", keywords:"keywords", notes:"Notes", content_unique_lowercase:"content", upload_date:"2018-07-17", upload_user_id:1, upload_user_name:"Drew Name"}
    ]);

    DatabaseMobx.o_tbl_u_gcss_auto_searches = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {
        id: 1,
        user_per_email_id: 1,
        auto_search_sam0_govwin1: 0,
        name: "My New Search 1",
        sort: 1,
        cron_last_fetch_datetime_utc: "0000-00-00 00:00:00",
        cron_days_betweeen_fetches_or_off_m1: 3,
        include_expired_sam_opps_01: 0,
        include_already_in_ce_results_01: 0,
        include_not_interested_results_01: 1,
        filter_department_agency_bm_ids_comma: "1,2",
        filter_sub_tier_bm_ids_comma: "1,2",
        filter_set_aside_bm_ids_comma: "1,2",
        filter_contract_opportunity_type_bm_ids_comma: "1,2",
        filter_fedcomp_contract_type_bm_ids_comma:"1,2",
        filter_naics_code_bm_ids_comma: "1,2",
        filter_product_service_code_bm_ids_comma: "1,2",
        filter_place_of_performance_state_bm_ids_comma: "1,2",
        filter_place_of_performance_country_bm_ids_comma: "1,2",
        filter_original_published_date_min: "9001-01-03",
        filter_original_published_date_max: "9001-01-04",
        filter_last_updated_date_min: "9001-01-03",
        filter_last_updated_date_max: "9001-01-04",
        filter_proposal_due_date_min: "9001-01-01",
        filter_proposal_due_date_max: "9001-01-02",
        filter_opportunity_name_search: "these,terms,first",
        filter_keyword_search_opportunity_name_description_of_work: "try,these,terms",
        filter_solicitation_num_search: "Army",
        import_default_capture_managers_ids_colon_percent_comma: "",
        import_default_division_owners_ids_colon_percent_comma: "",
        import_default_capture_type_id: "",
        import_default_stage_id: ""
      },
      {id:2, auto_search_sam0_govwin1:0, user_per_email_id:1, name:"My New Search 3" + longText, sort:3, cron_last_fetch_datetime_utc:"0000-00-00 00:00:00", cron_days_betweeen_fetches_or_off_m1:7},
      {id:3, auto_search_sam0_govwin1:0, user_per_email_id:1, name:"My New Search 2", sort:2, cron_last_fetch_datetime_utc:"0000-00-00 00:00:00", cron_days_betweeen_fetches_or_off_m1:-1},
      {id:4, auto_search_sam0_govwin1:1, user_per_email_id:1, name:"GovWin Search 1", sort:1, cron_last_fetch_datetime_utc:"0000-00-00 00:00:00", cron_days_betweeen_fetches_or_off_m1:-1},
      {id:5, auto_search_sam0_govwin1:2, user_per_email_id:1, name:"Federal Compass Search 1", sort:1, cron_last_fetch_datetime_utc:"0000-00-00 00:00:00", cron_days_betweeen_fetches_or_off_m1:-1},
      {id:6, auto_search_sam0_govwin1:2, user_per_email_id:1, name:"Federal Compass Search 2", sort:2, cron_last_fetch_datetime_utc:"0000-00-00 00:00:00", cron_days_betweeen_fetches_or_off_m1:-1},
      {id:7, auto_search_sam0_govwin1:3, user_per_email_id:1, name:"CE.A.I. Filter #1 with a long name", sort:1, cron_last_fetch_datetime_utc:"0000-00-00 00:00:00", cron_days_betweeen_fetches_or_off_m1:-1},
      {id:8, auto_search_sam0_govwin1:3, user_per_email_id:1, name:"CE.A.I. Filter #3", sort:3, cron_last_fetch_datetime_utc:"0000-00-00 00:00:00", cron_days_betweeen_fetches_or_off_m1:-1},
      {id:9, auto_search_sam0_govwin1:3, user_per_email_id:1, name:"CE.A.I. Filter #2", sort:2, cron_last_fetch_datetime_utc:"0000-00-00 00:00:00", cron_days_betweeen_fetches_or_off_m1:-1}
    ]);

    DatabaseMobx.o_tbl_u_notifications = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, user_per_email_id:1, date:"2018-04-01", message:"Noti message", click_action:"adminDeleteCapture:2", read_01:0},
      {id:2, user_per_email_id:1, date:"2019-08-01", message:longText, click_action:"adminDeleteCapture:1", read_01:1},
      {id:3, user_per_email_id:1, date:"2019-08-01", message:"Taaaaaaaaask", click_action:"openTask:1", read_01:1},
      {id:4, user_per_email_id:1, date:"2019-08-01", message:"Tiiiiiiik", click_action:"openTicket:1", read_01:1},
      {id:5, user_per_email_id:1, date:"2020-08-01", message:"This is a Broadcast Test", click_action:"readBroadcast", read_01:0}
    ]);

    DatabaseMobx.o_tbl_u_tasks = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, capture_id:1, assigner_user_id:1, assigned_to_user_ids_comma:"1,2", date_created:"2018-04-01", date_due:"2018-11-11", date_completed:"0000-00-00", important_01:1, viewed_0123:1, description:"Descrip", pre_reminder_days:7, pre_reminder_sent_01:0, post_reminder_days:3, post_reminder_sent_date:"0000-00-00"},
      {id:2, capture_id:1, assigner_user_id:2, assigned_to_user_ids_comma:"1", date_created:"2018-06-23", date_due:"2018-07-31", date_completed:"0000-00-00", important_01:0, viewed_0123:1, description:"A really long description to test out the multi line feature I mean like this one is really long it needs a good test for it", pre_reminder_days:7, pre_reminder_sent_01:0, post_reminder_days:3, post_reminder_sent_date:"0000-00-00"},
      {id:3, capture_id:1, assigner_user_id:3, assigned_to_user_ids_comma:"1", date_created:"2018-05-15", date_due:"0000-00-00", date_completed:"0000-00-00", important_01:0, viewed_0123:1, description:"A not set example of due date", pre_reminder_days:7, pre_reminder_sent_01:0, post_reminder_days:3, post_reminder_sent_date:"0000-00-00"},
      {id:4, capture_id:1, assigner_user_id:1, assigned_to_user_ids_comma:"2", date_created:"2018-05-15", date_due:"2018-11-11", date_completed:"0000-00-00", important_01:0, viewed_0123:1, description:"A not set example of due date", pre_reminder_days:7, pre_reminder_sent_01:0, post_reminder_days:3, post_reminder_sent_date:"0000-00-00"},
      {id:5, capture_id:1, assigner_user_id:1, assigned_to_user_ids_comma:"3", date_created:"2018-05-15", date_due:"0000-00-00", date_completed:"0000-00-00", important_01:0, viewed_0123:1, description:"A not set example of due date", pre_reminder_days:7, pre_reminder_sent_01:0, post_reminder_days:3, post_reminder_sent_date:"0000-00-00"},
      {id:6, capture_id:1, assigner_user_id:1, assigned_to_user_ids_comma:"3", date_created:"2018-05-15", date_due:"0000-00-00", date_completed:"2018-09-09", important_01:0, viewed_0123:1, description:"A not set example of due date", pre_reminder_days:7, pre_reminder_sent_01:0, post_reminder_days:3, post_reminder_sent_date:"0000-00-00"}
    ]);

    DatabaseMobx.o_tbl_z_ticket_initial_threads = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, user_per_email_id:1, datetime_utc:"2017-11-25 16:00:00", priority_code:3, initial_message:"Test Message", closed0_open1:1, bitexec_viewed_last_user_response_01:1},
      {id:2, user_per_email_id:1, datetime_utc:"2017-11-21 09:00:00", priority_code:1, initial_message:longText, closed0_open1:0, bitexec_viewed_last_user_response_01:1},
      {id:3, user_per_email_id:1, datetime_utc:"2017-11-21 08:00:00", priority_code:1, initial_message:"C test.", closed0_open1:0, bitexec_viewed_last_user_response_01:1},
      {id:4, user_per_email_id:1, datetime_utc:"2017-11-21 11:00:00", priority_code:1, initial_message:"B test.", closed0_open1:0, bitexec_viewed_last_user_response_01:1},
      {id:5, user_per_email_id:1, datetime_utc:"2017-11-21 06:00:00", priority_code:1, initial_message:"F test.", closed0_open1:0, bitexec_viewed_last_user_response_01:1}
    ]);

    DatabaseMobx.o_tbl_z_ticket_responses = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, ticket_thread_id:1, datetime_utc:"2017-11-26 05:08:45", user0_bit1_bitviewed2:1, message:"This is a test response unread by the user from BIT. This is a test response unread by the user from BIT. This is a test response unread by the user from BIT"},
      {id:2, ticket_thread_id:1, datetime_utc:"2017-11-26 06:08:45", user0_bit1_bitviewed2:0, message:"D Test"},
      {id:3, ticket_thread_id:1, datetime_utc:"2017-11-26 07:08:45", user0_bit1_bitviewed2:2, message:"A Test"},
      {id:4, ticket_thread_id:1, datetime_utc:"2017-11-26 04:08:45", user0_bit1_bitviewed2:1, message:"C Test"},
      {id:5, ticket_thread_id:1, datetime_utc:"2017-11-26 09:08:45", user0_bit1_bitviewed2:0, message:"B Test"},
      {id:6}
    ]);

    DatabaseMobx.o_allCapturesNamesAndArchiveDateMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([
      {id:1, codename:"Codename1", opportunity_name:"Opportunity Name1", archive_date:"0000-00-00"},
      {id:2, codename:"Codename2", opportunity_name:"Opportunity Name2", archive_date:"2025-03-05"},
      {id:3, codename:"Codename3", opportunity_name:"Opportunity Name3", archive_date:"0000-00-00"},
      {id:4, codename:"Codename4", opportunity_name:"Opportunity Name4", archive_date:"2025-01-11"}
    ]);

    var logPwinMapOfArrayOfObjs = new Map();
    logPwinMapOfArrayOfObjs.set(1, [
      {d:"2018-03-15 16:00:00", v:15},
      {d:"2018-07-15 16:00:00", v:15},
      {d:"2018-07-25 16:00:00", v:15},
      {d:"2018-08-01 12:32:00", v:30},
      {d:"2018-08-08 12:32:00", v:30},
      {d:"2018-08-18 12:32:00", v:30},
      {d:"2018-08-28 12:32:00", v:33},
      {d:"2018-09-05 12:32:00", v:35},
      {d:"2018-09-22 09:08:52", v:72},
      {d:"2018-10-22 09:08:52", v:72}
    ]);
    DatabaseMobx.o_progressChartLogPwinMapOfArrayOfObjs = logPwinMapOfArrayOfObjs;

    var logShapingMapOfArrayOfObjs = new Map();
    logShapingMapOfArrayOfObjs.set(1, [
      {d:"2018-04-15 16:00:00", v:15},
      {d:"2018-09-01 12:32:00", v:30},
      {d:"2018-10-22 09:08:52", v:72}
    ]);
    DatabaseMobx.o_progressChartLogShapingMapOfArrayOfObjs = logShapingMapOfArrayOfObjs;

    var logStagesMapOfArrayOfObjs = new Map();
    logStagesMapOfArrayOfObjs.set(1, [
      {d:"2018-06-15 16:00:00", v:-1},
      {d:"2018-08-01 12:32:00", v:3},
      {d:"2018-08-01 13:32:00", v:4},
      {d:"2018-08-01 14:32:00", v:5},
      {d:"2018-08-01 15:32:00", v:6},
      {d:"2018-11-12 09:08:52", v:9}
    ]);
    DatabaseMobx.o_progressChartLogStagesMapOfArrayOfObjs = logStagesMapOfArrayOfObjs;


    DatabaseMobx.o_stripeCustomerDataObjOrUndefined = {
      upcomingInvoiceObj: {},
      pastInvoicesArrayOfObjs: [
        {chargeDateTimeUtc:"2023-02-01 10:00:00", totalDueFormatted:"$105.00", paid01String:1},
        {chargeDateTimeUtc:"2023-03-01 10:00:00", totalDueFormatted:"$105.00", paid01String:0},
        {chargeDateTimeUtc:"2023-04-01 10:00:00", totalDueFormatted:"$105.00", paid01String:1}
      ]
    };
  }


}
export default new DevelopmentInitializeDataMobx();
/*
naics: 221119,226000,230000,235310,236110,314911,315221,316991,323122,331111,332214,322222,325410,333298,333313,333412,333512,333518,336321,336610,333990,334414,335000,335212,336000,336311,336312,336321,336322,336410,339932,339990,
421430,421690,443112,443130,454312,454319,488990,512220,513200,514190,514340,517211,517212,517310,517400,517910,518111,518112,531000,540000,541000,541190,541300,541600,541610,541700,561000,561200,611700,811300, pcs: AR


//Teammate prime to sub to prime
1. BITTEST going Prime to Sub
2. BITTEST going Sub to Prime
3. (ISC2) going Prime to Sub
4. (ISC2) going Sub to Prime
Teammate dots on right, new first option 'Make this Company the Prime', opens a floating box
 - title: 'Change 'ISC2' from Sub to Prime'
 - - 'ISC2' will become the Prime Company
 - - 'BITTEST' will become a Sub Company [unless selecting from prime company, then need to pick who if anyone to swap with to become prime]
 - You must select a Capture Type for BITTEST to be a Sub (only need this if 'Us' is moving, if Sub and moving contact to contact, no need to change capture type)
 - [Select of all Sub Capture Types]


{STRONGBRIDGE DeButts 2/2/24} GCSS - create GCSS docs folder in docs card, uneditable


{AGEISS Brian 12/7/23} - [waiting for Skip convo about removing per capture overwrite switch in favor of global switch with multiselect per gcss field for overwrite] delete gcss_overwrite_data_with_updates_01 from GCSS import and remove everywhere, new 19. GCSS tab select option for 'capture fields to not overwrite'
- remove overwrite field
- admin overwrite and not if blank triple switch for every GCSS field, make this work in php nightly updates
- Divexec Performance, move start/end date to top, have it affect 7 graphs
- ability to delete goals in div performance

{AHA Beth 9/13/23} Admin Deal Shaping Questions - print every question in the pool (not just per Capture Type) [button click has tiny floating box with 2 buttons for old way and this way]


{QEDEI Angie 1/9/24} enter dates with fixed timezones


{TBC 5/13/24 [Skip list]}
- select drop-down filter selection of divisions/users/capture templates (permission per division (or 'all') is selected for folders) [same for divexec excel report folders] based on firewalling
- Direct Access Link - needs to have a timeout feature - make 64char jumble have datetime expiration and creator userID as 20240503120000u00000014 at end of jumble, creating new one UI tells you someone else already created one that expires XXX do you want to make a new one with a new expiration?, new system must have expirations
{REI Ed 1/24/24} - Open Capture dots Direct Access Link, new option in Admin to make this an approval system, request DAL from Admin, they get notification, they can generate it themselves
 - make New Company default the new restricted request based setting



Contacts BIT system is SLOW!!!!


Contacts System
{Skip 1/3/2025 Qrelease} add to Contact Conversations, new tab for Companies, includes all Persons under company tree and merges all convos together
- view all contracts for a company {}
- {ODY James 5/3/23} show when each contact company has been a competitor
- [VAE 11/7/23 Skip meet] contacts system - docs - able to view all contracts NDAs/TAs/SubKs ever done (sorted by capture or by date, etc)
{CINTEOT Chip 1/24/24} Contacts create new Contact - Admin customize which fields are required when creating (hardcoded now is Legal Name and SmBusSetAside)
{AVIAN 7/12/24 Tyler (Skip call)} - Contacts click on a person - thread of every conversation with them, also 'add new convo' button right there, can select a capture for relevance
{QEDEI Angie 1/26/24} Contact Companies - (Admins only) button to create/send survey link to contact email address with invitation to edit their own contact info in /team/ CE restricted portal (+ upload contact docs)
{QEDEI Angie 1/31/24} Contact Persons/Companies - reorder custom fields among system fields
{STRONGBRIDGE DeButts 2/2/24} Contacts - show all deals connected to prime/sub, also conversations



recalc pwin min threshold if user has just a single green line, needs to be left edge of that line, not 0
 - get rid of red switch min pwin threshold in system
create new capture - no PWin changelog inserted when set to 0, but shifted up to 10 because of the min threshold
add_all_new_capture_changelogs_from_new_capture_field_and_log_arrays_obj()

set csd+pop in csv import, didn't see ced value in changelog
- if edit pop, should see 2 changelog entries for pop and ced



{APERIO Mark 1/18/24} Admin changelog
 - more UPDATE statements to backfill other codes



{QSL Wendy 1/19/24} Gantt - new column after capture name for Capture Team (color inside border of box with User color), able to assign User colors in Admin Setup Users




For a delegated Taks w/ Post Reminder set, WHO get notified?? {email 5/15/23 Skio for Larry Fetzer}
 - put a help bubble next to post reminder in task edit to explain this


{VAE Cathy 1/12/24 ticket} all date type combinations, can edit type from tab 7.
 - change her PPD/T to only show m/d/Y [upcoming]


{VAE Cathy 1/17/24 email} Capture Table excel-like filtering, also right panel to quickly edit columns or filter components
{VAE Cathy 1/17/24 email} Gantt Charts - could have Capture Type ribbon? (probably not doing this)
{VAE Cathy 1/17/24 email} Integrate to Cost Point (opportunity information project management)






{STRONGBRIDGE DeButts 2/2/24}
 * SAM Entity database search, import/auto updates into Contacts, Teammate search
 * Contact Companies - export win/loss debrief data
 * Integration with Outlook into Conversations
 * GCSS - import KO or company makes new or updates Contact person/company, attaches to Capture

{CINTEOT Chip 1/16/24} prevent contracts requests until POC is filled out (teammate contact company name/address AND teammate contracts manager contact person name/email) (also put teammate and teammate contracts manager on request form)

{FLATTER Brittany 5/16/23} Notifications - Admin priroitize certain nofification types, panel reorganize, no notifications for closed stages
{EDJ Nichelle 11/4/22} print all teammate survey responses
{TES Larry 5/10/23} GCSS autosearches public
{BITDEMO Natalie ticket} notifications dismiss easier
{CCG Carmen 6/6/22} GCSS autosearch public, GCSS export results
{INALAB Arathi 9/7/22} contracts works like survey system to external teammates
{INALAB Arathi 11/8/22} send whole folders instead of individual docs on teammate survey
{INALAB Arathi 5/9/23} deal shaping questions multi-select (score yourself?)
{OMI Dan 3/7/23} limit list of subtiers when a dept is selected
{OMI Walter 8/15/23} integrate outlook calendar with conversations card
{GARUD Sharad 4/28/23} way to share graphs with whole company, customize capture cards for each user, deal shaping questions unique per capture, field 'work still to be completed'
 - search box recent captures (show 10 instead of 5), default graphs delivered with new systems, protest stages [solved], FPDS/USA Spending integrations, divexec budget templates
{GARUD Sharad 8/3/23} excel-like filter Capture Table columns
{GARUD Tina 8/23/23} Our Division SWOT on Competitor Card, put budget calcs on revenue card
{GARUD Tina 9/11/23} Deal Shaping new question type text without score
{CINTEOT Tom 2/17/22} light CRM system
{AGEISS Brain 7/22/19} finproj user definable columns
{AGEISS Brain 11/21/19} contracts todo export to excel
{AGEISS Brain 4/7/20} contacts - captures as teammate and as competitor
{AGEISS Brain 11/29/22} Divexec performance - start/end dates filter
{CULMEN Caitlin 9/2/20} Contacts custom multiselect fields, then add to advanced search
{CULMEN Caitlin 10/29/20} Contacts advanced search - any vs all AND/OR logic
{CULMEN Caitlin 11/3/20} Surveys export results matrix to .csv
{CULMEN Caitlin 12/4/20} View tasks on calendar (Capture Plan)
{CULMEN Caitlin 8/6/21} Revenue Card - base/option years breakdown PoP
{CULMEN Caitlin 11/18/21} Contacts select as partner/competitor/client/vendor
{CULMEN Caitlin 1/19/23} GCSS autosearches public
{CULMEN Caitlin 2/3/23} Contacts 'As Teammate' and 'As Competitor' tabs, split those selected for team (rather than just added but not selected)
{CULMEN Caitlin 6/6/23} GCSS SAM RFP Release Date (already in GovWin), look again in SAM for that field
{CULMEN Caitlin 6/9/23} new [[codeword]] to list all competitors in comma list
{CULMEN Cheryl 11/16/23} solicitation type field


Skip/Drew/?? - Revenue Card way to split money in a custom way over different time periods (affects finproj) rather than standard OCV over PoP months


{CHENGA Bryan 10/3/23} Teammate Surveys - Create Question - 2 new Question Types "Select Response" and "Select Response Scale 0-5"
{CHENEGA Bryan 10/3/23} Risk Card
  - not mitigated risk prevents adv stage
  - filter risks to not show on matrix (checkboxes)
  - single risk cell has (6) in it, clicking it shows only those 6 on the left (title above left says "Now Showing Near Certainty/Impossible Risks [4]" with a (clear filter) button next to it)
  - need 1 new risk category for 3 total (probability [pre/post mititgation], impact pre mitigation, impact post mit) - show different matrix after mitigations applied (or maybe have 2 shapes per cell for pre/post)
  - (Skip) also add "Cost to Mitigate" field, have a sum of all mitigations applied at top as grand total
  - (Drew) also add "Doing Mitigation" checkbox field above each mitigation, either moves the impact/prob down or it stays the same, unchecking it doesn't add the money value to the total
{CJSETO Chet 9/8/23} Risk Matrix needs an average of all the risks



shortcut presets - default folder structure at bottom of create new capture
 - new feature 'new capture automatic default' switch for all shortcut presets, overrides normal new capture default with single selected shortcut preset setting (these need to affect Capture Import as well for import and GCSS)
    * when force is specified for Team, add to 'green' autoassign tbl in top right of capture import with preset selected
 - HELP panel for shortcut presets tabs

SGT - expired company need to not allow logins

===LATER:===
SAM GCSS ID (+GovWin/FC) make new field 'input_type' for these that edit opens big floating box with unconnect or 'connect' by searching by ID or link address, getting resutlt(s), and merging the selected one
 - new feature connect direct from GovWin (or SAM from direct web link?) into GCSS ID field on details card to merge capture]
 - 'connect' link when not filled out, new floating box to put in link or ID and search GCSS, get result(s), pick one to merge
 - 'connect' (and unconnect) mechanisms need to work from inline cell edit


SIG (Paul 11/7/23) - finproj, each cell (and right side totals) should be a click, filter the charts and table at the bottom, selected cell stays highlighted, clicking again unhighlights (or top right 'Total' cell)
 - also able to export filtered view


{I3 Don 1/4/23} converstations notify to attend
{OSC Gene 7/26/23} import emails into conversations card {also other above}
{OSC Gene 7/27/23} CCR data of Fed companies


[CHENEGA]
 //changelog new userID codes (might be negative for 1000+ for cardIDs?)
-10 - [User Create New Capture]
-11 - [GCSS User Import New Capture]
-12 - [GCSS System Update Existing Capture]
-13 - [Admin Import New Capture]
-14 - [Admin Update Existing Capture]
-15 - [Third-Party CRM Create New Capture]    (this title is in the 'Card' column, but in the User column is the name of the system integration job set up in that integration Admin tab)
-16 - [Third-Party CRM Update Existing Capture]
- change the size of the 'Card' column on the changelog card to accommodate these longer names
***modify stage/pwin/etc logs to be able to have these different 1000 level cards?*** 1010,1011,... for these new 'Card' update codes



2. map subtiers to depts with new column, then new admin option to limit subtiers shown when dept is selected
 - no dept selected, show all subtiers
 - select dept, subtier uses FFS have 1 folder at top saying which dept is selected (only Open Capture field for SubTier) filter list all other options hiddenUnlessChecked






calculated fields, separate lines of A and B operands surrounding a single operator (+,-,*,/), [add this feature to every field like Style, could potentially calculate OCV]
Equation for 'My Calculated Field':
1. [[field_a]] * [[field_b]] = [[?Result1]]
2. [[?Result1]] / 100  = [[?Result2]]
3. [[?Result2]] + [[field_c]] = [[my_calculated_field]]
(Add Another Calculation Line) [button]
//need to prevent 'cycle' errors (if computing a field, and itself comes up while looking up equation parts, stop loops and put blank value)
OCV -> [A] + [B]
B -> [C] * 100
C -> [OCV] + 30


===FUTURE:===
Dad meeting 1/2/24 - Users can set 'default column preset', use that when opening graph segments, show actual Capture Table with inline edit capability in floating box

//{Jenn Pham design} Master Buttons -> rename to View Buttons (Capture Table View Buttons), put View Buttons on Ribbons, Ribbons onto Tapestry
4/2/24 Jenn notes:
- lock private/public icon, make this 1 person/3 persons icon instead
- when right panel is open, new right panel launcher button in top right for "View Button Editor"
[VAE 11/7/23 Skip meet] lots of ideas for column/filter/master edit in right panel
 - drag/drop all fields, add fields to any column set immediately creates new unsaved column set for user
 - maybe 2 ways: master buttons, and can add 'filters' up in the master ribbon (have to then pick a column and sort that is fixed while changing selected filters)


3. clean up all duplicate SGT/CE help pictures on servers, Bitexec tool to search for dead pictures in both systems

change Bitexec diagnostic on GovWin to fetch all json from every 'links' field?

//custom atom model selects (tbl_cap_ tbls should have 4 columns, id/name/sort/color, apply to every cap tbl?)
//[graph segments] search column capture lists, editing a value that goes outside the filtering, capture does not leave the list (very difficult to change code to get this to update)


//conditional 'value' styling (like OCV negative/positive green/red)

[bug - not important] CST column sums, need in editor master green switch to turn off all sums (maybe make turned on green switches a very dark gray)
[bug - not important] document.execCommand() is obsolete for copy to clipboard function

mass edit - if Capture Type is changed from prime to not prime, doesn't run the teammate count recalc for each capture changed


Capture Updater/Import
 - make date/time input more flexible for time/timezones
 - also build Time injector option when mapping to a date/time

----SGT------
edit main 'Account' admin subtab
Users subtab, when licenses run out, put button that links to My Account page in place of Add New User button
success.php styling

AdminReact - upcomingInvoiceObj
AdminMobx - a_my_account_load_stripe_data
sgt_stripe_api.php - get_invoice_obj_from_stripe_invoice









$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
$$$$$$$$    ON-PREMISE CUSTOMERS    $$$$$$$$
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
^^^^^^Pending CE/SGT cloud database upgrades (already applied to BITTEST), paste in bitexec 'Upgrade Databases' tab to apply to all other cloud CE/SGT customers^^^^^^
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! export `tbl_a_automated_exports` once exports is restored/finished !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

//Finished BITEXEC Database Upgrades for On Premise customers
//  1. build code changing commented blocks in CaptureExedMobx.js and general_functions.php (also CETeammateSurveyMobx.js for /team/ build only)
//  2. save database update statements below for db_a_companies, db_a_logs, and db_c_COMPANYCODE to apply in SQL tab
//  3. export (with DROP) db_a_companies help/BIT Master/code version tbls (make sure code versions match)
//  4. give them the latest On Premise Authentication .txt file to upload (update on-prem BIT tbl_companies auth expiration date)
//  5. if any new graphics were uploaded, copy to customer /graphics folder (contains graphics + helppanels, customer logos not sent from /team/)

//[[db_a_companies]]


//[[db_a_logs]]


//[[db_c_COMPANYCODE]] {always execute "UPDATE `tbl_a_users_per_email` SET `code_update_01`='1';" at end for on-prem}


//[[Uploaded '/graphics/' files]]

//[[Manual Admin Work]]
!convert contracts log tbl old data with php! (net yet, after the Metrics/Stages tabs are written)

$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
*/