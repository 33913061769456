import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from '../../CaptureExecLocalDatabaseMobx/JSPHP.js';

import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import CapturesMobx from '../Captures/CapturesMobx.js';
import ContactsMobx from '../Contacts/ContactsMobx.js';
import OpenCaptureMobx from '../OpenCapture/OpenCaptureMobx.js';

class AdminMobx {
  //constants
  k_blueItemCollectionShellItemStylingObj = {
    rowHeightEm: 1.9,
    outerPadClass: "microBottomMargin",
    lastItemExtraDropZoneHeightEm: 1
  };


  //User Activity
  o_activityShowTicketsUserIDsArray = [];

  //System Setup
  o_divisionsFindMyUeiLoadingFlag = "init";
  o_divisionsFindMyUeiPreviousCompanyNameSearchTerm = "";
  o_divisionsFindMyUeiSamUeiCompaniesArrayOfObjs = [];
  o_usersViewFlag = "lastNameAlphabeticList"; //"lastNameAlphabeticList", "DivisionTreeUsersSingleEdit"
  o_selectedCaptureTypeID = -1;
  o_questionsSelectedReceivingStageID = -1;
  o_fieldsSelectedReceivingDetailsCardGroupID = -1;
  o_fieldsSelectedTabSnapshotGroupsDates = "detailsCardGroups"; //"detailsCardGroups", "detailsCardSnapshot", "datesCard", "revenueCard"
  o_fieldsSearchFilterText = "";
  o_detailsFieldsOptionsSelectedSubtab = "competitionTypeSBCertsMasterTbl"; //"competitionTypeSBCertsMasterTbl", "naicsCodes", "departmentsAgenciesMasterTbl", "subTiersMasterTbl", "idiqVehicleFieldOptions", "priorityFavorites"
  o_allTeammateContractsArrayOfObjs = [];
  o_companyCommunicationsSelectedTabDbName = "banners"; //"banners", "broadcast"
  o_shortcutPresetsSelectedTabDbName = "captureTeam"; //"captureTeam", "documentsCard"
  o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined = undefined;

  o_captureArchivingSearchText = "";
  o_captureArchivingLoadedSlimCapturesArrayOfObjs = undefined;

  o_adminChangelogLoadingDataOrErrorUndefinedTFU = false;
  o_adminChangelogFilterObj = {
    numItemsPerPage: 25,
    currentPageNumber: 1,
    adminActionCodeCategoryCodesComma: "",
    userID: -1,
    dateMin: JSFUNC.blank_date(),
    dateMax: JSFUNC.blank_date(),
    sortColumnDbName: "dateTimeUtc",
    sortIsAscTF: true
  };

  o_myAccountStripeDataLoadingTF = false;
  o_myAccountStripeDataErrorMessageOrUndefined = undefined;

  constructor() {
    makeObservable(this, {
      o_activityShowTicketsUserIDsArray: observable,
      o_divisionsFindMyUeiLoadingFlag: observable,
      o_divisionsFindMyUeiPreviousCompanyNameSearchTerm: observable,
      o_divisionsFindMyUeiSamUeiCompaniesArrayOfObjs: observable,
      o_usersViewFlag: observable,
      o_selectedCaptureTypeID: observable,
      o_questionsSelectedReceivingStageID: observable,
      o_fieldsSelectedReceivingDetailsCardGroupID: observable,
      o_fieldsSelectedTabSnapshotGroupsDates: observable,
      o_fieldsSearchFilterText: observable,
      o_detailsFieldsOptionsSelectedSubtab: observable,
      o_allTeammateContractsArrayOfObjs: observable,
      o_companyCommunicationsSelectedTabDbName: observable,
      o_shortcutPresetsSelectedTabDbName: observable,
      o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined: observable,
      o_captureArchivingSearchText: observable,
      o_captureArchivingLoadedSlimCapturesArrayOfObjs: observable,
      o_adminChangelogLoadingDataOrErrorUndefinedTFU: observable,
      o_adminChangelogFilterObj: observable,
      o_myAccountStripeDataLoadingTF: observable,
      o_myAccountStripeDataErrorMessageOrUndefined: observable,

      c_activityUsersArrayOfObjs: computed,
      c_adminAllDivisionsArrayOfObjs: computed,
      c_divisionsUnassignedMatchingCapturesObj: computed,
      c_allUserPowersWithinUsersPerEmailsArrayOfObjs: computed,
      c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs: computed,
      c_usersNumLicensesUsed: computed,
      c_usersTotalNumNotDeactivatedSuperAdminsAdded: computed,
      c_usersUsingMaxNumSuperAdminsTF: computed,
      c_usersMaxNumSuperAdminsAddedTF: computed,
      c_usersNumNotDeactivatedCaptureConsultantsUsed: computed,
      c_usersNumLicensesRemaining: computed,
      c_usersAddNewUserPerEmailPowerDbNamesToNotIncludeArray: computed,
      c_usersCanAddNewUserTF: computed,
      c_usersPerEmailNotDeactivatedArrayOfObjs: computed,
      c_usersPerEmailDeactivatedArrayOfObjs: computed,
      c_usersAllUserEmailsArray: computed,
      c_userNotDeactivatedUsersPerDivisionTreeArrayOfObjs: computed,
      c_usersSelectMyAndOtherAdminPublicColumnPresetsFieldTypeObj: computed,
      c_usersPreselectedColumnPresetIDIfOnly1ChoiceOrm1: computed,
      c_usersSelectMyAndOtherAdminPublicSortPresetsFieldTypeObj: computed,
      c_usersPreselectedSortPresetIDIfOnly1ChoiceOrm1: computed,
      c_usersUnassignedMatchingCapturesObj: computed,
      c_usersSettingsSelectCaptureConsultantsCanViewParentIDIQsFieldTypeObj: computed,
      c_adminCaptureTypesWithMatchingCapturesArrayOfObjs: computed,
      c_captureTypesUnassignedMatchingCapturesObj: computed,
      c_selectedCaptureTypeExistsTF: computed,
      c_selectedCaptureTypeObj: computed,
      c_selectedCaptureTypeCaptureCardsArrayOfObjs: computed,
      c_selectedCaptureTypeCardIDsArray: computed,
      c_notSelectedCaptureTypeDetailsCardsArrayOfObjs: computed,
      c_selectedCaptureTypeStageIDsArray: computed,
      c_selectedCaptureTypeDetailsSnapshotFieldIDsArray: computed,
      c_stagesPoolArrayOfObjs: computed,
      c_selectedCaptureTypeStagesArrayOfObjs: computed,
      c_selectStageWithinSelectedCaptureTypeFieldTypeObj: computed,
      c_questionsPoolArrayOfObjs: computed,
      c_questionsReceivingStageObj: computed,
      c_selectedCaptureTypeQuestionsPerStageObj: computed,
      c_detailsCardGroupsPoolArrayOfObjs: computed,
      c_selectedCaptureTypeDetailsCardGroupIDsArray: computed,
      c_selectedCaptureTypeDetailsCardGroupsArrayOfObjs: computed,
      c_fieldsOnDetailsCardCustomArrayOfObjs: computed,
      c_fieldsOnDetailsCardMandatoryArrayOfObjs: computed,
      c_fieldsOnDetailsCardGcssArrayOfObjs: computed,
      c_fieldsOnAdvanceStageCardArrayOfObjs: computed,
      c_fieldsOnDealShapingCardArrayOfObjs: computed,
      c_fieldsOnTeammatesCardArrayOfObjs: computed,
      c_fieldsOnCompetitorsCardArrayOfObjs: computed,
      c_fieldsOnNotepadCardArrayOfObjs: computed,
      c_filteredFieldsOnDetailsCardCustomArrayOfObjs: computed,
      c_filteredFieldsOnDetailsCardMandatoryArrayOfObjs: computed,
      c_filteredFieldsOnDetailsCardGcssArrayOfObjs: computed,
      c_filteredFieldsOnAdvanceStageCardArrayOfObjs: computed,
      c_filteredFieldsOnDealShapingCardArrayOfObjs: computed,
      c_filteredFieldsOnTeammatesCardArrayOfObjs: computed,
      c_filteredFieldsOnCompetitorsCardArrayOfObjs: computed,
      c_filteredFieldsOnNotepadCardArrayOfObjs: computed,
      c_allFieldDbNamesArray: computed,
      c_fieldsAddedToDetailsSnapshotObj: computed,
      c_fieldsAddedToDetailsCardGroupsObj: computed,
      c_fieldsAddedToDatesCardObj: computed,
      c_fieldsAddedToRevenueCardObj: computed,
      c_allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs: computed,
      c_contractTypesArrayOfObjs: computed,
      c_reasonsWonLostArrayOfObjs: computed,
      c_bitMasterSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs: computed,
      c_bitMasterNaicsCombinedNamesNotHiddenArray: computed,
      c_bitMasterProductServiceCodesArrayOfObjs: computed,
      c_bitMasterDepartmentsAgenciesArrayOfObjs: computed,
      c_bitMasterSubTiersArrayOfObjs: computed,
      c_teammatesWorkshareTypesArrayOfObjs: computed,
      c_teammatesContractOtherStatusesArrayOfObjs: computed,
      c_teammatesContractThemStatusesArrayOfObjs: computed,
      c_teammatesContractWeStatusesArrayOfObjs: computed,
      c_teammatesContractAgreementTypesArrayOfObjs: computed,
      c_ptDifferentiatorsArrayOfObjs: computed,
      c_riskCategoriesArrayOfObjs: computed,
      c_risksProbabilityRiskLevelsArrayOfObjs: computed,
      c_risksImpactRiskLevelsArrayOfObjs: computed,
      c_budgetCategoriesPoolArrayOfObjs: computed,
      c_selectedCaptureTypeBudgetCategoryIDsArray: computed,
      c_selectedCaptureTypeBudgetCategoriesArrayOfObjs: computed,
      c_templateCodewordsExamplesArrayOfObjs: computed,
      c_adminSelectSingleCaptureTemplateFFSFieldTypeObj: computed,
      c_adminSelectExcelReportTemplateFFSFieldTypeObj: computed,
      c_adminCompanyCommunicationsArrayOfObjs: computed,
      c_adminCompanyCommunicationsSelectBannerOff0OnlyMe1On2FieldTypeObj: computed,
      c_adminCompanyCommunicationsSelectFontSizeMultiplier10xFieldTypeObj: computed,
      c_automatedRemindersArrayOfObjs: computed,
      c_automatedExportsArrayOfObjs: computed,
      c_automatedExportsSelectRecurrenceFieldTypeObj: computed,
      c_shortcutPresetsCaptureManagersArrayOfObjs: computed,
      c_shortcutPresetsDocumentsCardFoldersPresetsArrayOfObjs: computed,
      c_shortcutPresetsDocumentsCardFoldersEditingPresetObj: computed,
      c_captureArchivingAllLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined: computed,
      c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined: computed,
      c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs: computed,
      c_createNewCaptureAdditionalFieldMapsArray: computed,
      c_createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs: computed,
      c_createNewCaptureAllUsedFieldIDsArray: computed,
      c_selectNewCaptureAdditionalFieldForCaptureTypeFieldTypeObj: computed,
      c_searchFieldsSelectUsedInSearchFieldTypeObj: computed,
      c_searchFieldsSelectSortColumnFieldTypeObj: computed,
      c_searchFieldsSelectShowSumFieldTypeObj: computed,
      c_gcssGovWinCredentialsArrayOfObjs: computed,
      c_gcssFedCompCredentialsArrayOfObjs: computed,
      c_gcssNotInterestedReasonsArrayOfObjs: computed,
      c_businessTypesArrayOfObjs: computed,
      c_capabilitiesArrayOfObjs: computed,
      c_deleteCaptureReasonsArrayOfObjs: computed,
      c_shapingQuestionTagsArrayOfObjs: computed,
      c_expandedAdminChangelogArrayOfObjs: computed,
      c_selectMultiAdminCategoryCodesFieldTypeObj: computed,
      c_filteredSortedExpandedAdminChangelogArrayOfObjs: computed,
      c_adminChangelogFilteredTotalNumItems: computed,
      c_adminChangelogCurrentPageFirstItemNumber: computed,
      c_adminChangelogCurrentPageLastItemNumber: computed,
      c_adminChangelogCanIncrementCurrentPageNumberTF: computed,
      c_adminChangelogCanDecrementCurrentPageNumberTF: computed,
      c_filteredSortedItemsOnSelectedPageExpandedAdminChangelogArrayOfObjs: computed,

      a_activity_set_show_tickets_user_ids_array: action,
      a_update_tbl_a_company_fields: action,
      a_divisions_update_company_prime_sb_certs_can_vary_per_division: action,
      a_divisions_update_company_using_division_firewalling: action,
      a_set_divisions_find_my_uei_loading_flag: action,
      a_set_divisions_find_my_uei_previous_company_name_seach_term: action,
      a_set_divisions_find_my_uei_sam_uei_companies_arrayOfObjs: action,
      a_divisions_find_my_uei_search_sam_companies: action,
      a_set_users_view_flag: action,
      a_set_selected_capture_type_id: action,
      a_ensure_selected_capture_type_exists_or_select_first_capture_type: action,
      a_capture_type_create_new_from_copy: action,
      a_fields_set_selected_tab_snapshot_groups_dates: action,
      a_fields_set_search_filter_text: action,
      a_fields_set_receiving_details_card_group_id: action,
      a_fields_initialize_selected_receiving_details_card_group: action,
      a_fields_add_field_to_receiving_details_snapshot: action,
      a_fields_remove_field_from_details_snapshot: action,
      a_fields_add_field_to_receiving_details_card_group: action,
      a_fields_remove_field_from_details_card_group: action,
      a_set_details_fields_options_selected_subtab: action,
      a_set_receiving_item_collection_id: action,
      a_initialize_questions_pool_selected_receiving_stage: action,
      a_add_pool_question_to_receiving_stage: action,
      a_remove_question_from_capture_type_stage: action,
      a_teammates_ratings_recompute_all_contact_teammate_ratings: action,
      a_teammate_contracts_load_all_contracts_from_database: action,
      a_teammate_contracts_set_all_teammate_contracts_arrayOfObjs: action,
      a_admin_contacts_reassign: action,
      a_automated_exports_insert_new_job: action,
      a_automated_exports_update_single_field: action,
      a_automated_exports_delete_job_and_server_files: action,
      a_set_company_communications_selected_tab_db_name: action,
      a_shortcut_presets_set_selected_tab_db_name: action,
      a_shortcut_presets_documents_create_new_preset: action,
      a_shortcut_presets_set_documents_card_folders_editing_preset_id_or_undefined: action,
      a_shortcut_presets_rename_documents_card_folders_preset: action,
      a_shortcut_presets_delete_documents_card_folders_preset: action,
      a_set_capture_archiving_search_text: action,
      a_capture_archving_set_loaded_slim_captures_arrayOfObjs: action,
      a_admin_changelog_set_loading_data_or_error_undefined_tfu: action,
      a_load_admin_changelog_data: action,
      a_my_account_load_stripe_data: action
    });
  }



  //User Activity
  get c_activityUsersArrayOfObjs() {
    var activityUsersArrayOfObjs = [];
    for(let combinedUserMap of DatabaseMobx.c_tbl_a_users.values()) {
      var activityUserObj = JSFUNC.obj_from_map(combinedUserMap);

      var lastLoginJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(activityUserObj.last_login_datetime_utc);
      var lastLoginLocalYmdDate = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(lastLoginJsDateObj, false);
      var lastLoginIsTodayTF = (lastLoginLocalYmdDate === CaptureExecMobx.o_nowDate);

      var lastLoginDateTimeMjYgiA = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(activityUserObj.last_login_datetime_utc, DatabaseMobx.c_genericDateTimeMjYgiAFieldTypeObj);

      var ticketsArrayOfObjs = [];
      for(let ticketInitialThreadMap of DatabaseMobx.o_tbl_z_ticket_initial_threads.values()) {
        if(ticketInitialThreadMap.get("user_per_email_id") === activityUserObj.user_per_email_id) {
          var ticketObj = JSFUNC.obj_from_map(ticketInitialThreadMap);

          var initialDateJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(ticketObj.datetime_utc);
          var initialDateLocalYmdDate = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(initialDateJsDateObj, false);

          var responsesArrayOfObjs = [];
          for(let responseMap of DatabaseMobx.o_tbl_z_ticket_responses.values()) {
            if(responseMap.get("ticket_thread_id") === ticketObj.id) {
              var responseObj = JSFUNC.obj_from_map(responseMap);

              var responseDateJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(responseObj.datetime_utc);
              var responseDateLocalYmdDate = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(responseDateJsDateObj, false);

              responseObj.responseDateLocalYmdDate = responseDateLocalYmdDate;
              responseObj.isBITTF = (responseObj.user0_bit1_bitviewed2 === 1 || responseObj.user0_bit1_bitviewed2 === 2);

              responsesArrayOfObjs.push(responseObj);
            }
          }
          JSFUNC.sort_arrayOfObjs(responsesArrayOfObjs, "datetime_utc", true);

          ticketObj.initialDateLocalYmdDate = initialDateLocalYmdDate;
          ticketObj.responsesArrayOfObjs = responsesArrayOfObjs;

          ticketsArrayOfObjs.push(ticketObj);
        }
      }
      JSFUNC.sort_arrayOfObjs(ticketsArrayOfObjs, "datetime_utc", false);
      var numTickets = ticketsArrayOfObjs.length;
      var openTicketsArrayOfObjs = JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(ticketsArrayOfObjs, "closed0_open1", 1);
      var closedTicketsArrayOfObjs = JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(ticketsArrayOfObjs, "closed0_open1", 0);

      activityUserObj.lastLoginIsTodayTF = lastLoginIsTodayTF;
      activityUserObj.lastLoginDateTimeMjYgiA = lastLoginDateTimeMjYgiA;
      activityUserObj.numTickets = numTickets;
      activityUserObj.hasTicketTF = (numTickets > 0);
      activityUserObj.openTicketsArrayOfObjs = openTicketsArrayOfObjs;
      activityUserObj.numOpenTickets = openTicketsArrayOfObjs.length;
      activityUserObj.closedTicketsArrayOfObjs = closedTicketsArrayOfObjs;
      activityUserObj.numClosedTickets = closedTicketsArrayOfObjs.length;

      activityUsersArrayOfObjs.push(activityUserObj);
    }
    JSFUNC.sort_arrayOfObjs(activityUsersArrayOfObjs, "last_login_datetime_utc", false);
    return(activityUsersArrayOfObjs);
  }






  //divisions subtab
  get c_adminAllDivisionsArrayOfObjs() {
    const c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs = this.c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs;
    const divisionOwnersFieldID = DatabaseMobx.c_fieldMapOfDivisionOwners.get("id");

    const divisionsMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_divisions");
    var divisionsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(divisionsMapOfMaps, "tree_id", true);
    for(let divisionObj of divisionsArrayOfObjs) {
      //compute # of captures assigned to this division
      var divisionFiltersArrayOfObjs = [{capture_field_id:divisionOwnersFieldID, operator:"e", value:JSFUNC.num2str(divisionObj.id)}]; //single filter for this division
      var matchingCapturesObj = CapturesMobx.compute_matching_captures_obj_from_filters_arrayOfObjs(divisionFiltersArrayOfObjs);

      var numUsersAssigned = 0;
      for(let userPerEmailObj of c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs) {
        if(!userPerEmailObj.upeDeactivatedTF) {
          for(let upeCombinedUserObj of userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs) {
            if(upeCombinedUserObj.division_id === divisionObj.id) {
              numUsersAssigned++;
            }
          }
        }
      }

      var selfChildrenAndSubchildrenIDsArray = [divisionObj.id];
      for(let findChildrenDivisionObj of divisionsArrayOfObjs) {
        if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(divisionObj.tree_id, findChildrenDivisionObj.tree_id, false)) {
          selfChildrenAndSubchildrenIDsArray.push(findChildrenDivisionObj.id);
        }
      }

      divisionObj.indentsArray = DatabaseMobx.compute_division_indents_array_from_division_obj(divisionObj);
      divisionObj.numCapturesOwnedByDivision = matchingCapturesObj.partialNumMatchingCaptures;
      divisionObj.capturesOwnedByDivisionCaptureIDsArray = matchingCapturesObj.matchingCaptureIDsArray;
      divisionObj.numUsersAssigned = numUsersAssigned;
      divisionObj.selfChildrenAndSubchildrenIDsArray = selfChildrenAndSubchildrenIDsArray;
    }
    return(divisionsArrayOfObjs);
  }

  get c_divisionsUnassignedMatchingCapturesObj() {
    var unassignedDivisionsFiltersArrayOfObjs = [{capture_field_id:DatabaseMobx.c_fieldMapOfDivisionOwners.get("id"), operator:"ins", value:""}];
    return(CapturesMobx.compute_matching_captures_obj_from_filters_arrayOfObjs(unassignedDivisionsFiltersArrayOfObjs));
  }



  //users subtab
  get c_allUserPowersWithinUsersPerEmailsArrayOfObjs() { //includes deactivated users
    const allUsersPerEmailArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.c_tbl_a_users_per_email, "sortName", true);
    var allUserPowersWithinUsersPerEmailsArrayOfObjs = [];
    for(let userPerEmailObj of allUsersPerEmailArrayOfObjs) {
      var expandedUserPerEmailObj = JSFUNC.copy_obj(userPerEmailObj);

      var upeMultiLoginCombinedUsersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.c_tbl_a_users, "user_per_email_id", userPerEmailObj.id, ["powerSortNumber", "divisionTreeID"], [true, true]);
      
      var upeNumLicensesUsed = 0;
      var upeNumSuperAdmins = 0; //counted so that on reactivation of this particular user that has superadmins, math can be done to see if it can be reactivated without exceeding the current superadmins max number limit
      var upeNumAdmins = 0;
      var upeNumConsultants = 0;
      var upeAllUserDivisionIDsPerPowerNameObj = {}; //prevent adding duplicate powers using the same division
      for(let upeCombinedUserObj of upeMultiLoginCombinedUsersArrayOfObjs) {
        upeNumLicensesUsed += upeCombinedUserObj.powerLicenseCost;

        if(upeCombinedUserObj.powerIsSuperAdminTF) {
          upeNumSuperAdmins++;
        }
        else if(upeCombinedUserObj.powerIsAdminTF) {
          upeNumAdmins++;
        }
        else if(upeCombinedUserObj.powerIsCaptureConsultantTF) {
          upeNumConsultants++;
        }

        if(upeAllUserDivisionIDsPerPowerNameObj[upeCombinedUserObj.power_name] === undefined) {
          upeAllUserDivisionIDsPerPowerNameObj[upeCombinedUserObj.power_name] = [upeCombinedUserObj.division_id];
        }
        else {
          upeAllUserDivisionIDsPerPowerNameObj[upeCombinedUserObj.power_name].push(upeCombinedUserObj.division_id);
        }
      }

      const upeNumLicensesUsedRounded = JSFUNC.remove_javascript_precision_decimal_float_math_errors(upeNumLicensesUsed, 3);
      
      expandedUserPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs = upeMultiLoginCombinedUsersArrayOfObjs;
      expandedUserPerEmailObj.upeNumLicensesUsed = upeNumLicensesUsedRounded;
      expandedUserPerEmailObj.upeNumSuperAdmins = upeNumSuperAdmins;
      expandedUserPerEmailObj.anyUserMultiLoginPowerIsSuperAdminTF = (upeNumSuperAdmins > 0);
      expandedUserPerEmailObj.upeNumAdmins = upeNumAdmins;
      expandedUserPerEmailObj.anyUserMultiLoginPowerIsAdminTF = (upeNumAdmins > 0);
      expandedUserPerEmailObj.upeNumConsultants = upeNumConsultants;
      expandedUserPerEmailObj.upeAllUserDivisionIDsPerPowerNameObj = upeAllUserDivisionIDsPerPowerNameObj;
      
      allUserPowersWithinUsersPerEmailsArrayOfObjs.push(expandedUserPerEmailObj);
    }
    return(allUserPowersWithinUsersPerEmailsArrayOfObjs);
  }

  get c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs() { //includes deactivated users
    const c_allUserPowersWithinUsersPerEmailsArrayOfObjs = this.c_allUserPowersWithinUsersPerEmailsArrayOfObjs;
    const c_usersTotalNumNotDeactivatedSuperAdminsAdded = this.c_usersTotalNumNotDeactivatedSuperAdminsAdded;
    const c_usersUsingMaxNumSuperAdminsTF = this.c_usersUsingMaxNumSuperAdminsTF;
    const c_usersNumLicensesRemaining = this.c_usersNumLicensesRemaining;
    const c_bitEnterpriseLicenseTF = DatabaseMobx.c_bitEnterpriseLicenseTF;
    const c_bitSuperAdminsMaxNum = DatabaseMobx.c_bitSuperAdminsMaxNum;
    const c_fieldMapOfCaptureManagers = DatabaseMobx.c_fieldMapOfCaptureManagers;

    const captureManagersFieldID = c_fieldMapOfCaptureManagers.get("id");

    var allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs = [];
    for(let userPerEmailObj of c_allUserPowersWithinUsersPerEmailsArrayOfObjs) {
      var expandedUserPerEmailObj = JSFUNC.copy_obj(userPerEmailObj);

      //loop over each multilogin user under this userPerEmail
      var upeExpandedMultiLoginCombinedUsersArrayOfObjs = [];
      for(let upeCombinedUserObj of userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs) {
        var upeExpandedCombinedUserObj = JSFUNC.copy_obj(upeCombinedUserObj);

        //count the number of captures owned by this user power
        var userPowerFiltersArrayOfObjs = [{capture_field_id:captureManagersFieldID, operator:"e", value:JSFUNC.num2str(upeCombinedUserObj.user_id)}]; //single filter for this user power
        var matchingCapturesObj = CapturesMobx.compute_matching_captures_obj_from_filters_arrayOfObjs(userPowerFiltersArrayOfObjs);
        upeExpandedCombinedUserObj.numCapturesOwnedByUserPower = matchingCapturesObj.partialNumMatchingCaptures;
        upeExpandedCombinedUserObj.capturesOwnedByUserPowerCaptureIDsArray = matchingCapturesObj.matchingCaptureIDsArray;

        //edit existing user, powers to not have available (superadmin costs 1 license extra above the 1 currently in use)
        var editUserSelectPowersToNotIncludeArray = this.admin_users_compute_add_or_edit_new_user_user_power_db_names_to_not_include_array(upeCombinedUserObj.powerLicenseCost);
        if(userPerEmailObj.anyUserMultiLoginPowerIsSuperAdminTF) {
          editUserSelectPowersToNotIncludeArray = JSFUNC.unique(JSFUNC.concat_arrays_or_values_into_new_array(editUserSelectPowersToNotIncludeArray, "superadmin"));
        }
        
        if(userPerEmailObj.anyUserMultiLoginPowerIsAdminTF) {
          editUserSelectPowersToNotIncludeArray = JSFUNC.unique(JSFUNC.concat_arrays_or_values_into_new_array(editUserSelectPowersToNotIncludeArray, "admin"));
        }
        editUserSelectPowersToNotIncludeArray = JSFUNC.remove_all_values_from_array(upeCombinedUserObj.power_name, editUserSelectPowersToNotIncludeArray); //remove current User Power of edit from not include list so that if you are currently a superadmin and are already at the max number of superadmins, superadmin is still an option which when selected keeps you where you were before editing
        upeExpandedCombinedUserObj.editUserSelectPowersToNotIncludeArray = editUserSelectPowersToNotIncludeArray;

        upeExpandedMultiLoginCombinedUsersArrayOfObjs.push(upeExpandedCombinedUserObj);
      }
      expandedUserPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs = upeExpandedMultiLoginCombinedUsersArrayOfObjs;
      
      //if creating a new user power within existing UPE, powers to not have available in list of choices
      var createNewUserSelectPowersToNotIncludeArray = this.admin_users_compute_add_or_edit_new_user_user_power_db_names_to_not_include_array();
      if(userPerEmailObj.anyUserMultiLoginPowerIsSuperAdminTF || userPerEmailObj.anyUserMultiLoginPowerIsAdminTF) { //if user is already an admin/superadmin, don't let them create more admins/superadmin Users under their UPE
        createNewUserSelectPowersToNotIncludeArray = JSFUNC.unique(JSFUNC.concat_arrays_or_values_into_new_array(createNewUserSelectPowersToNotIncludeArray, ["superadmin", "admin"]));
      }
      expandedUserPerEmailObj.createNewUserSelectPowersToNotIncludeArray = createNewUserSelectPowersToNotIncludeArray;

      //if userPerEmail is currently deactivated, calculate if there are enough licenses to reactivate this user
      var userCannotBeReactivatedReasonTextOrUndefined = undefined;
      if(userPerEmailObj.upeDeactivatedTF) { //reactivation calculation only done for currently deactivated users
        if(!c_bitEnterpriseLicenseTF) { //can always reactivate a user under the Enterprise license
          var notEnoughLicensesToActivateTF = (userPerEmailObj.upeNumLicensesUsed > c_usersNumLicensesRemaining); //if this user has too many licenses for its user powers or too many super admins, don't allow reactivation
          
          var tooManyExistingSuperAdminsToActivateTF = false;
          if(c_usersUsingMaxNumSuperAdminsTF) {
            tooManyExistingSuperAdminsToActivateTF = (userPerEmailObj.upeNumSuperAdmins > (c_bitSuperAdminsMaxNum - c_usersTotalNumNotDeactivatedSuperAdminsAdded));
          }

          if(notEnoughLicensesToActivateTF) {
            userCannotBeReactivatedReasonTextOrUndefined = "There are not enough licenses remaining to reactivate User '" + userPerEmailObj.firstNameLastName + "' (requires " + userPerEmailObj.upeNumLicensesUsed + " " + JSFUNC.plural(userPerEmailObj.upeNumLicensesUsed, "license", "licenses") + " to reactivate)";
          }
          else if(tooManyExistingSuperAdminsToActivateTF) {
            userCannotBeReactivatedReasonTextOrUndefined = "There are not enough Super Admin profiles remaining to reactivate User '" + userPerEmailObj.firstNameLastName + "' (requires " + userPerEmailObj.upeNumSuperAdmins + " " + JSFUNC.plural(userPerEmailObj.upeNumSuperAdmins, "Super Admin", "Super Admins") + " to reactivate)";
          }
        }
      }
      expandedUserPerEmailObj.userCannotBeReactivatedReasonTextOrUndefined = userCannotBeReactivatedReasonTextOrUndefined;
      
      allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs.push(expandedUserPerEmailObj);
    }
    return(allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs);
  }

  get c_usersNumLicensesUsed() {
    const c_allUserPowersWithinUsersPerEmailsArrayOfObjs = this.c_allUserPowersWithinUsersPerEmailsArrayOfObjs;

    var numLicensesUsed = 0;
    for(let userPerEmailObj of c_allUserPowersWithinUsersPerEmailsArrayOfObjs) {
      if(!userPerEmailObj.upeDeactivatedTF) {
        numLicensesUsed += userPerEmailObj.upeNumLicensesUsed; //includes superadmin, capture consultant, and read only license counts
      }
    }
    const numLicensesUsedRounded = JSFUNC.remove_javascript_precision_decimal_float_math_errors(numLicensesUsed, 3);
    return(numLicensesUsedRounded);
  }

  get c_usersTotalNumNotDeactivatedSuperAdminsAdded() {
    const c_allUserPowersWithinUsersPerEmailsArrayOfObjs = this.c_allUserPowersWithinUsersPerEmailsArrayOfObjs;

    var totalNumSuperAdminsAdded = 0;
    for(let userPerEmailObj of c_allUserPowersWithinUsersPerEmailsArrayOfObjs) {
      if(!userPerEmailObj.upeDeactivatedTF) {
        totalNumSuperAdminsAdded += userPerEmailObj.upeNumSuperAdmins;
      }
    }
    const totalNumSuperAdminsAddedRounded = JSFUNC.remove_javascript_precision_decimal_float_math_errors(totalNumSuperAdminsAdded, 3);
    return(totalNumSuperAdminsAddedRounded);
  }

  get c_usersUsingMaxNumSuperAdminsTF() {
    const c_bitSuperAdminsMaxNum = DatabaseMobx.c_bitSuperAdminsMaxNum;
    return(JSFUNC.is_number_not_nan_gte_0(c_bitSuperAdminsMaxNum));
  }

  get c_usersMaxNumSuperAdminsAddedTF() {
    const c_usersTotalNumNotDeactivatedSuperAdminsAdded = this.c_usersTotalNumNotDeactivatedSuperAdminsAdded;
    const c_usersUsingMaxNumSuperAdminsTF = this.c_usersUsingMaxNumSuperAdminsTF;
    const c_bitSuperAdminsMaxNum = DatabaseMobx.c_bitSuperAdminsMaxNum;

    if(c_usersUsingMaxNumSuperAdminsTF) {
      return(c_usersTotalNumNotDeactivatedSuperAdminsAdded >= c_bitSuperAdminsMaxNum);
    }
    return(false); //max number of super admins is never reached if not using it as a limit (when c_bitSuperAdminsMaxNum is less than 0)
  }

  get c_usersNumNotDeactivatedCaptureConsultantsUsed() {
    const c_allUserPowersWithinUsersPerEmailsArrayOfObjs = this.c_allUserPowersWithinUsersPerEmailsArrayOfObjs;

    var numCaptureConsultantsUsed = 0;
    for(let userPerEmailObj of c_allUserPowersWithinUsersPerEmailsArrayOfObjs) {
      if(!userPerEmailObj.upeDeactivatedTF) {
        numCaptureConsultantsUsed += userPerEmailObj.upeNumConsultants;
      }
    }
    const numCaptureConsultantsUsedRounded = JSFUNC.remove_javascript_precision_decimal_float_math_errors(numCaptureConsultantsUsed, 3);
    return(numCaptureConsultantsUsedRounded);
  }

  get c_usersNumLicensesRemaining() {
    const numLicensesRemaining = (DatabaseMobx.c_bitNumLicensesPurchased - this.c_usersNumLicensesUsed);
    const numLicensesRemainingRounded = JSFUNC.remove_javascript_precision_decimal_float_math_errors(numLicensesRemaining, 3);
    return(numLicensesRemainingRounded);
  }

  get c_usersAddNewUserPerEmailPowerDbNamesToNotIncludeArray() {
    return(this.admin_users_compute_add_or_edit_new_user_user_power_db_names_to_not_include_array(undefined,"new"));
  }

  admin_users_compute_add_or_edit_new_user_user_power_db_names_to_not_include_array(i_editingExistingUserCurrentUserPowerLicenseCount=undefined,i) {
    //computes which user powers you cannot add new (or switch to in edit) based on restrictions from licenses remaining and superadmin max num, is computed for:
    //1. create brand new userPerEmail + user
    //2. create new user power on existing UPE (also removes admins/divexec if user already has those types/divisions)
    //3. edit existing user's power (input i_editingExistingUserCurrentUserPowerLicenseCount of editing User Power type license count to factor in switching away from that original count to the new type's license count)
    //4. reactivate user (different computation than this)

    const c_usersMaxNumSuperAdminsAddedTF = this.c_usersMaxNumSuperAdminsAddedTF;
    const c_usersNumLicensesRemaining = this.c_usersNumLicensesRemaining;
    const c_bitEnterpriseLicenseTF = DatabaseMobx.c_bitEnterpriseLicenseTF;
    const c_allUserPowersDataArrayOfObjs = DatabaseMobx.c_allUserPowersDataArrayOfObjs;

    //if using the enterprise license, any User Power can always be added regardless of license count
    if(c_bitEnterpriseLicenseTF) {
      return([]);
    }

    //loop through each type of User Power (superadmin, admin, divexec, etc), check for overused license counts or number of superadmins
    var userPowerDbNamesToNotIncludeArray = [];
    for(let userPowerDataObj of c_allUserPowersDataArrayOfObjs) {
      //first test if this power is superadmin and the max num superadmins has already been added
      var excludeUserPowerTF = (userPowerDataObj.isSuperAdminTF && c_usersMaxNumSuperAdminsAddedTF);

      //if not excluded by max superadmins, check if there's enough remaining licenses to either add (or switch from existing user edit) this power type
      if(!excludeUserPowerTF) {
        var licenseCostOrSwitchingDifferenceWhenEditing = userPowerDataObj.licenseCost;
        if(JSFUNC.is_number_not_nan_gt_0(i_editingExistingUserCurrentUserPowerLicenseCount)) { //if provided with an initial edit license cost, subtract that from the 'switch' total license cost of the new potential power type to switch to to see if it exceeds the license limit
          licenseCostOrSwitchingDifferenceWhenEditing -= i_editingExistingUserCurrentUserPowerLicenseCount;
        }

        excludeUserPowerTF = (licenseCostOrSwitchingDifferenceWhenEditing > c_usersNumLicensesRemaining); //check that the license cost of this power type doesn't exceed the licenses remaining, if so add it to the list
      }
      
      if(excludeUserPowerTF) {
        userPowerDbNamesToNotIncludeArray.push(userPowerDataObj.dbName);
      }
    }

    return(userPowerDbNamesToNotIncludeArray);
  }

  get c_usersCanAddNewUserTF() {
    const c_usersAddNewUserPerEmailPowerDbNamesToNotIncludeArray = this.c_usersAddNewUserPerEmailPowerDbNamesToNotIncludeArray;
    const c_allUserPowersDataArrayOfObjs = DatabaseMobx.c_allUserPowersDataArrayOfObjs;
    return(c_usersAddNewUserPerEmailPowerDbNamesToNotIncludeArray.length < c_allUserPowersDataArrayOfObjs.length); //if every User Power type is listed to not include, then remove the buttons to add a new user (since 0 types can be added)
  }

  get c_usersPerEmailNotDeactivatedArrayOfObjs() {
    var usersPerEmailNotDeactivatedArrayOfObjs = [];
    for(let expandedUserPerEmailObj of this.c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs) {
      if(!expandedUserPerEmailObj.upeDeactivatedTF) {
        usersPerEmailNotDeactivatedArrayOfObjs.push(expandedUserPerEmailObj);
      }
    }
    return(usersPerEmailNotDeactivatedArrayOfObjs);
  }

  get c_usersPerEmailDeactivatedArrayOfObjs() {
    var usersPerEmailDeactivatedArrayOfObjs = [];
    for(let expandedUserPerEmailObj of this.c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs) {
      if(expandedUserPerEmailObj.upeDeactivatedTF) {
        usersPerEmailDeactivatedArrayOfObjs.push(expandedUserPerEmailObj);
      }
    }
    return(usersPerEmailDeactivatedArrayOfObjs);
  }

  get c_usersAllUserEmailsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs, "email"));
  }

  get c_userNotDeactivatedUsersPerDivisionTreeArrayOfObjs() {
    const c_adminAllDivisionsArrayOfObjs = this.c_adminAllDivisionsArrayOfObjs;
    const c_usersPerEmailNotDeactivatedArrayOfObjs = this.c_usersPerEmailNotDeactivatedArrayOfObjs;

    var notDeactivatedUsersPerDivisionTreeArrayOfObjs = [];
    for(let expandedDivisionObj of c_adminAllDivisionsArrayOfObjs) {
      var expandedDivisionWithUsersObj = JSFUNC.copy_obj(expandedDivisionObj);

      var userLicensesUsed = 0;
      var superExpandedUsersArrayOfObjs = [];
      for(let expandedUserPerEmailObj of c_usersPerEmailNotDeactivatedArrayOfObjs) {
        for(let userObj of expandedUserPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs) {
          if(userObj.division_id === expandedDivisionObj.id) {
            var expandedUserObj = JSFUNC.copy_obj(userObj);

            userLicensesUsed += expandedUserObj.powerLicenseCost;
            expandedUserObj.userPerEmailObj = expandedUserPerEmailObj;

            superExpandedUsersArrayOfObjs.push(expandedUserObj);
          }
        }
      }

      expandedDivisionWithUsersObj.userLicensesUsed = JSFUNC.remove_javascript_precision_decimal_float_math_errors(userLicensesUsed, 8);
      expandedDivisionWithUsersObj.superExpandedUsersArrayOfObjs = superExpandedUsersArrayOfObjs;

      notDeactivatedUsersPerDivisionTreeArrayOfObjs.push(expandedDivisionWithUsersObj);
    }

    //look for any user that is not assigned to a valid division (either Unassigned -1 division or to a division that was deleted)
    const allDivisionIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(notDeactivatedUsersPerDivisionTreeArrayOfObjs, "id");

    var unassignedUserLicensesUsed = 0;
    var superExpandedUsersInUnassignedDivisionArrayOfObjs = [];
    for(let expandedUserPerEmailObj of c_usersPerEmailNotDeactivatedArrayOfObjs) {
      for(let userObj of expandedUserPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs) {
        if(!JSFUNC.in_array(userObj.division_id, allDivisionIDsArray)) {
          var expandedUserObj = JSFUNC.copy_obj(userObj);

          unassignedUserLicensesUsed += expandedUserObj.powerLicenseCost;
          expandedUserObj.userPerEmailObj = expandedUserPerEmailObj;

          superExpandedUsersInUnassignedDivisionArrayOfObjs.push(expandedUserObj);
        }
      }
    }
    const numUnassignedUsers = superExpandedUsersInUnassignedDivisionArrayOfObjs.length;

    //if there are any unassigned users, add a fake 'Invalid' division as treeID "01"
    if(numUnassignedUsers > 0) {
      var unassignedDivisionObj = {
        id: -1,
        tree_id: "01",
        name: "Unassigned Division",
        color: "333"
      };
      unassignedDivisionObj.indentsArray = DatabaseMobx.compute_division_indents_array_from_division_obj(unassignedDivisionObj);
      unassignedDivisionObj.userLicensesUsed = JSFUNC.remove_javascript_precision_decimal_float_math_errors(unassignedUserLicensesUsed, 8);
      unassignedDivisionObj.superExpandedUsersArrayOfObjs = superExpandedUsersInUnassignedDivisionArrayOfObjs;

      notDeactivatedUsersPerDivisionTreeArrayOfObjs.push(unassignedDivisionObj);
    }

    //loop over all divisions to count up all licenses used in all tree subdivisions
    for(let expandedDivisionObj of notDeactivatedUsersPerDivisionTreeArrayOfObjs) {
      var allSubdivisionUserLicensesUsed = 0;
      for(let subExpandedDivisionObj of notDeactivatedUsersPerDivisionTreeArrayOfObjs) {
        var isChildTF = JSFUNC.tree_id_is_child_of_parent_tree_id_tf(expandedDivisionObj.tree_id, subExpandedDivisionObj.tree_id);
        if(isChildTF || (expandedDivisionObj.id === subExpandedDivisionObj.id)) {
          allSubdivisionUserLicensesUsed += subExpandedDivisionObj.userLicensesUsed
        }
      }
      expandedDivisionObj.allSubdivisionUserLicensesUsed = JSFUNC.remove_javascript_precision_decimal_float_math_errors(allSubdivisionUserLicensesUsed, 8);
    }

    return(notDeactivatedUsersPerDivisionTreeArrayOfObjs);
  }

  get c_usersSelectMyAndOtherAdminPublicColumnPresetsFieldTypeObj() {
    const o_tbl_f_cst_column_presets = DatabaseMobx.o_tbl_f_cst_column_presets;
    const c_userIDsOfAllAdminPowerUsersNotDeactivatedArray = DatabaseMobx.c_userIDsOfAllAdminPowerUsersNotDeactivatedArray;
    const o_userID = UserMobx.o_userID;

    var myAndOtherAdminPublicColumnPresetsArrayOfObjs = [];
    for(let cstColumnPresetMap of o_tbl_f_cst_column_presets.values()) {
      var ownerUserID = cstColumnPresetMap.get("user_id");
      var presetIsPublicTF = (cstColumnPresetMap.get("public_01") === 1);
      var myPresetTF = (ownerUserID === o_userID);
      if(JSFUNC.in_array(ownerUserID, c_userIDsOfAllAdminPowerUsersNotDeactivatedArray) && presetIsPublicTF) { //all admin public presets
        var presetID = cstColumnPresetMap.get("id");
        var presetNameWithOwnerName = cstColumnPresetMap.get("name");
        if(!myPresetTF) {
          presetNameWithOwnerName += " [created by " + DatabaseMobx.user_name_mask_plaintext_from_user_id(ownerUserID) + "]";
        }
        var presetNameWithOwnerNameLowercase = presetNameWithOwnerName.toLowerCase();

        myAndOtherAdminPublicColumnPresetsArrayOfObjs.push({
          id: presetID,
          name: presetNameWithOwnerName,
          sort: presetNameWithOwnerNameLowercase
        });
      }
    }
    JSFUNC.sort_arrayOfObjs(myAndOtherAdminPublicColumnPresetsArrayOfObjs, "sort", true);

    const valueArray = JSFUNC.get_column_vector_from_arrayOfObjs(myAndOtherAdminPublicColumnPresetsArrayOfObjs, "id");
    const displayArray = JSFUNC.get_column_vector_from_arrayOfObjs(myAndOtherAdminPublicColumnPresetsArrayOfObjs, "name");
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Capture Table Column Preset", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_usersPreselectedColumnPresetIDIfOnly1ChoiceOrm1() {
    const c_usersSelectMyAndOtherAdminPublicColumnPresetsFieldTypeObj = this.c_usersSelectMyAndOtherAdminPublicColumnPresetsFieldTypeObj;
    const selectWithSearchDataObj = c_usersSelectMyAndOtherAdminPublicColumnPresetsFieldTypeObj.selectWithSearchDataObj;
    if(selectWithSearchDataObj !== undefined) {
      if(JSFUNC.is_array(selectWithSearchDataObj.valueArray)) {
        if(selectWithSearchDataObj.valueArray.length === 1) {
          return(selectWithSearchDataObj.valueArray[0]);
        }
      }
    }
    return(-1);
  }

  get c_usersSelectMyAndOtherAdminPublicSortPresetsFieldTypeObj() {
    const o_tbl_f_sort_presets = DatabaseMobx.o_tbl_f_sort_presets;
    const c_userIDsOfAllAdminPowerUsersNotDeactivatedArray = DatabaseMobx.c_userIDsOfAllAdminPowerUsersNotDeactivatedArray;
    const o_userID = UserMobx.o_userID;

    var myAndOtherAdminPublicSortPresetsArrayOfObjs = [];
    for(let sortPresetMap of o_tbl_f_sort_presets.values()) {
      var ownerUserID = sortPresetMap.get("user_id");
      var presetIsPublicTF = (sortPresetMap.get("public_01") === 1);
      var myPresetTF = (ownerUserID === o_userID);
      if(JSFUNC.in_array(ownerUserID, c_userIDsOfAllAdminPowerUsersNotDeactivatedArray) && presetIsPublicTF) { //all admin public presets
        var presetID = sortPresetMap.get("id");
        var presetNameWithOwnerName = sortPresetMap.get("name");
        if(!myPresetTF) {
          presetNameWithOwnerName += " [created by " + DatabaseMobx.user_name_mask_plaintext_from_user_id(ownerUserID) + "]";
        }
        var presetNameWithOwnerNameLowercase = presetNameWithOwnerName.toLowerCase();

        myAndOtherAdminPublicSortPresetsArrayOfObjs.push({
          id: presetID,
          name: presetNameWithOwnerName,
          sort: presetNameWithOwnerNameLowercase
        });
      }
    }
    JSFUNC.sort_arrayOfObjs(myAndOtherAdminPublicSortPresetsArrayOfObjs, "sort", true);

    const valueArray = JSFUNC.get_column_vector_from_arrayOfObjs(myAndOtherAdminPublicSortPresetsArrayOfObjs, "id");
    const displayArray = JSFUNC.get_column_vector_from_arrayOfObjs(myAndOtherAdminPublicSortPresetsArrayOfObjs, "name");
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Capture Table Sort Preset", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_usersPreselectedSortPresetIDIfOnly1ChoiceOrm1() {
    const c_usersSelectMyAndOtherAdminPublicSortPresetsFieldTypeObj = this.c_usersSelectMyAndOtherAdminPublicSortPresetsFieldTypeObj;
    const selectWithSearchDataObj = c_usersSelectMyAndOtherAdminPublicSortPresetsFieldTypeObj.selectWithSearchDataObj;
    if(selectWithSearchDataObj !== undefined) {
      if(JSFUNC.is_array(selectWithSearchDataObj.valueArray)) {
        if(selectWithSearchDataObj.valueArray.length === 1) {
          return(selectWithSearchDataObj.valueArray[0]);
        }
      }
    }
    return(-1);
  }

  get c_usersUnassignedMatchingCapturesObj() {
    var unassignedUsersFiltersArrayOfObjs = [{capture_field_id:DatabaseMobx.c_fieldMapOfCaptureManagers.get("id"), operator:"ins", value:""}];
    return(CapturesMobx.compute_matching_captures_obj_from_filters_arrayOfObjs(unassignedUsersFiltersArrayOfObjs));
  }

  get c_usersSettingsSelectCaptureConsultantsCanViewParentIDIQsFieldTypeObj() {
    const itemName = "Consultant Can View Parent IDIQ Option";
    const valueArray = [0, 1]
    const valuesAreStringsTF = false;
    const displayArray = ["[default] Capture Consultants can only view/edit Captures they are assigned to", "Capture Consultants can also view/edit the parent IDIQ Capture of any assigned Task Orders"];
    const optionsHeightEm = 4.2;
    return(DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array(itemName, valueArray, valuesAreStringsTF, displayArray, optionsHeightEm));
  }






  //capture types subtab
  get c_adminCaptureTypesWithMatchingCapturesArrayOfObjs() {
    const captureTypeFieldID = DatabaseMobx.c_fieldMapOfCaptureType.get("id");
    const mapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_capture_types");
    var captureTypesArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(mapOfMaps, "sort", true);
    for(let captureTypeObj of captureTypesArrayOfObjs) {
      var captureTypeFiltersArrayOfObjs = [{capture_field_id:captureTypeFieldID, operator:"e", value:JSFUNC.num2str(captureTypeObj.id)}];
      captureTypeObj.matchingCapturesObj = CapturesMobx.compute_matching_captures_obj_from_filters_arrayOfObjs(captureTypeFiltersArrayOfObjs);
    }
    return(captureTypesArrayOfObjs);
  }

  get c_captureTypesUnassignedMatchingCapturesObj() {
    var unassignedCaptureTypeFiltersArrayOfObjs = [{capture_field_id:DatabaseMobx.c_fieldMapOfCaptureType.get("id"), operator:"ins", value:""}];
    return(CapturesMobx.compute_matching_captures_obj_from_filters_arrayOfObjs(unassignedCaptureTypeFiltersArrayOfObjs));
  }

  get c_selectedCaptureTypeExistsTF() {
    return((this.c_adminCaptureTypesWithMatchingCapturesArrayOfObjs.length > 0) && DatabaseMobx.o_tbl_a_capture_types.has(this.o_selectedCaptureTypeID));
  }

  get c_selectedCaptureTypeObj() {
    const selectedCaptureTypeMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_capture_types", this.o_selectedCaptureTypeID);
    return(JSFUNC.obj_from_map(selectedCaptureTypeMap));
  }

  get c_selectedCaptureTypeCaptureCardsArrayOfObjs() {
    const c_selectedCaptureTypeObj = this.c_selectedCaptureTypeObj;
    const c_availableCaptureCardsArrayOfObjs = DatabaseMobx.c_availableCaptureCardsArrayOfObjs;

    //capture type capture cards need to be in the order they appear in the comma list
    const selectedCaptureTypeCardIDsArray = JSFUNC.convert_comma_list_to_int_array(c_selectedCaptureTypeObj.card_ids_comma);

    var selectedCaptureTypeCaptureCardsArrayOfObjs = [];
    for(let captureCardID of selectedCaptureTypeCardIDsArray) {
      var captureCardObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_availableCaptureCardsArrayOfObjs, "cardID", captureCardID);
      if(captureCardObj !== undefined) {
        selectedCaptureTypeCaptureCardsArrayOfObjs.push(captureCardObj);
      }
    }
    return(selectedCaptureTypeCaptureCardsArrayOfObjs);
  }

  get c_selectedCaptureTypeCardIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_selectedCaptureTypeCaptureCardsArrayOfObjs, "cardID"));
  }

  get c_notSelectedCaptureTypeDetailsCardsArrayOfObjs() {
    const c_availableCaptureCardsArrayOfObjs = DatabaseMobx.c_availableCaptureCardsArrayOfObjs;

    const selectedCaptureTypeCardIDsArray = JSFUNC.convert_comma_list_to_int_array(this.c_selectedCaptureTypeObj.card_ids_comma);
    var selectedCaptureTypeCaptureCardsArrayOfObjs = [];
    for(let captureCardObj of c_availableCaptureCardsArrayOfObjs) {
      if(!JSFUNC.in_array(captureCardObj.cardID, selectedCaptureTypeCardIDsArray)) {
        selectedCaptureTypeCaptureCardsArrayOfObjs.push(captureCardObj);
      }
    }
    return(selectedCaptureTypeCaptureCardsArrayOfObjs);
  }

  get c_selectedCaptureTypeStageIDsArray() {
    return(JSFUNC.convert_comma_list_to_int_array(this.c_selectedCaptureTypeObj.stage_ids_comma));
  }

  get c_selectedCaptureTypeDetailsSnapshotFieldIDsArray() {
    return(JSFUNC.convert_comma_list_to_int_array(this.c_selectedCaptureTypeObj.details_snapshot_field_ids_comma));
  }



  //stages subtab
  get c_stagesPoolArrayOfObjs() {
    const mapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_stages_pool");
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(mapOfMaps, "admin_sort", true));
  }

  get c_selectedCaptureTypeStagesArrayOfObjs() {
    var stagesArrayOfObjs = [];
    for(let stageID of this.c_selectedCaptureTypeStageIDsArray) { //starts within selected capture type are already in order from c_selectedCaptureTypeObj.stage_ids_comma field comma list of stageIDs
      var stageMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_stages_pool", stageID);
      var stageObj = JSFUNC.obj_from_map(stageMap);
      stagesArrayOfObjs.push(stageObj);
    }
    return(stagesArrayOfObjs);
  }

  //details/dates fields - edit single field stage which field is required
  get c_selectStageWithinSelectedCaptureTypeFieldTypeObj() {
    return(DatabaseMobx.create_select_stages_field_type_obj_within_capture_type_id(this.o_selectedCaptureTypeID));
  }



  //deal shaping subtab
  get c_questionsPoolArrayOfObjs() {
    const selectAnswersTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_shaping_select_answers");
    const questionsPoolTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_shaping_questions_pool");

    var questionsPoolArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(questionsPoolTblRef, "admin_sort", true);
    for(let questionObj of questionsPoolArrayOfObjs) {
      var answerTypeShort = "";
      var answersArrayOfObjs = undefined;
      var numAnswers = 0;
      if(questionObj.answer_select1_text2_textarea3 === 1) {
        answerTypeShort = "Select";
        var answersMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(selectAnswersTblRef, "question_id", questionObj.id);
        answersArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(answersMapOfMaps, "score0to100", false);
        numAnswers = answersArrayOfObjs.length;
      }
      else {
        if(questionObj.answer_select1_text2_textarea3 === 2) {
          answerTypeShort = "Text";
        }
        else {
          answerTypeShort = "Textarea";
        }
        numAnswers = DatabaseMobx.not_filled_out_font_html("N/A");
      }

      questionObj.answerTypeShort = answerTypeShort;
      questionObj.answersArrayOfObjs = answersArrayOfObjs;
      questionObj.numAnswers = numAnswers;
      questionObj.requiredTF = (questionObj.rec1_reqsig2_req3 === 3);
    }
    return(questionsPoolArrayOfObjs);
  }

  get c_questionsReceivingStageObj() {
    const receivingStageMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_stages_pool", this.o_questionsSelectedReceivingStageID);
    return(JSFUNC.obj_from_map(receivingStageMap));
  }

  get c_selectedCaptureTypeQuestionsPerStageObj() {
    const selectedCaptureTypeID = this.c_selectedCaptureTypeObj.id;

    //depending on the stage currently selected as receiving, compile the questionIDs already added to the stage(s) (all stages combined for active stages, only questions from the selected stage for closed stages)
    var activeQuestionIDsAlreadyAddedArray = [];
    var selectedReceivingStageQuestionIDsAlreadyAddedArray = [];
    var selectedReceivingStageIsActiveTF = false;

    //get all active stages for this selected capture type (loop through all stages and only keep active ones, they are already in order)
    const activeCaptureTypeQuestionsTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_capture_types_shaping_questions");
    var activeStagesArrayOfObjs = [];
    for(let stageObj of this.c_selectedCaptureTypeStagesArrayOfObjs) {
      if(!stageObj.isClosedStageTF) { //active stage
        var activeStageQuestionsMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(activeCaptureTypeQuestionsTblRef, ["capture_type_id", "stage_id_relevant"], [selectedCaptureTypeID, stageObj.id]);
        var activeStageQuestionsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(activeStageQuestionsMapOfMaps, "sort", true);

        //load the question record for each capture type question
        for(let questionObj of activeStageQuestionsArrayOfObjs) {
          var questionPoolMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shaping_questions_pool", questionObj.question_id);
          questionObj.name = questionPoolMap.get("name");
          questionObj.requiredTF = (questionObj.rec1_reqsig2_req3 === 3);
        }

        activeStagesArrayOfObjs.push({
          stageObj: stageObj,
          questionsArrayOfObjs: activeStageQuestionsArrayOfObjs
        });

        //add all active stageIDs to the 'already added' list of questionIDs (disables the 'Add ->' button in the questions pool table and disables dropping that question)
        var activeStageQuestionIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(activeStageQuestionsArrayOfObjs, "question_id");
        activeQuestionIDsAlreadyAddedArray = JSFUNC.merge_unique(activeQuestionIDsAlreadyAddedArray, activeStageQuestionIDsArray);

        //check if this stage is the selected receiving stage
        if(this.o_questionsSelectedReceivingStageID === stageObj.id) {
          selectedReceivingStageIsActiveTF = true;
        }
      }
    }

    //add the catch all active stage for 'stage independent' questions that have a stage_id_relevant of -1 or a number that is not within this timeline
    var activeStageIndependentQuestionsArrayOfObjs = [];
    for(let captureTypeQuestionMap of activeCaptureTypeQuestionsTblRef.values()) {
      var questionCaptureTypeID = captureTypeQuestionMap.get("capture_type_id");
      if(questionCaptureTypeID === selectedCaptureTypeID) {
        var questionStageIDRelevant = captureTypeQuestionMap.get("stage_id_relevant");
        if(!JSFUNC.in_array(questionStageIDRelevant, this.c_selectedCaptureTypeStageIDsArray)) {
          var questionObj = JSFUNC.obj_from_map(captureTypeQuestionMap);

          var questionPoolMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shaping_questions_pool", questionObj.question_id);
          questionObj.name = questionPoolMap.get("name");

          activeStageIndependentQuestionsArrayOfObjs.push(questionObj);

          activeQuestionIDsAlreadyAddedArray.push(questionObj.question_id);
        }
      }
    }
    JSFUNC.sort_arrayOfObjs(activeStageIndependentQuestionsArrayOfObjs, "sort", true);

    activeStagesArrayOfObjs.push({
      stageObj: {id:-1, name:"Stage Independent Questions"},
      questionsArrayOfObjs: activeStageIndependentQuestionsArrayOfObjs
    });

    //if the selected receiving stage is active (or stage independent -1), set the receiving added questionIDs to the already compiled activeQuestionIDs
    if(selectedReceivingStageIsActiveTF || (this.o_questionsSelectedReceivingStageID === -1)) {
      selectedReceivingStageQuestionIDsAlreadyAddedArray = activeQuestionIDsAlreadyAddedArray;
    }

    //add each type of closed stage
    const closedCaptureTypeQuestionsTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_capture_types_debrief_questions");
    var closedStageTypeNumbersArrayOfArrays = [[4], [5], [6], [7,8]];
    var closedStageTypeNamesArray = ["Won Type Closed Stages", "Lost Type Closed Stages", "No Bid Type Closed Stages", "Cancelled Type Closed Stages"];
    var closedStageTypesArrayOfObjs = [];
    for(let t = 0; t < closedStageTypeNumbersArrayOfArrays.length; t++) {
      var stageTypeNumbersArray = closedStageTypeNumbersArrayOfArrays[t];
      var stageTypeName = closedStageTypeNamesArray[t];

      var closedStagesArrayOfObjs = [];
      for(let stageObj of this.c_selectedCaptureTypeStagesArrayOfObjs) {
        if(JSFUNC.in_array(stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7, stageTypeNumbersArray)) {
          //get all questions added to this closed stage (they are assigned in multiple comma lists)
          var closedStageQuestionsArrayOfObjs = [];
          var closedStageQuestionIDsAlreadyAddedArray = [];
          for(let captureTypeQuestionMap of closedCaptureTypeQuestionsTblRef.values()) {
            var questionCaptureTypeID = captureTypeQuestionMap.get("capture_type_id");
            if(questionCaptureTypeID === selectedCaptureTypeID) {
              var questionStageIDRelevant = captureTypeQuestionMap.get("closed_stage_id");
              if(stageObj.id === questionStageIDRelevant) {
                var questionObj = JSFUNC.obj_from_map(captureTypeQuestionMap);

                var questionPoolMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shaping_questions_pool", questionObj.question_id);
                questionObj.name = questionPoolMap.get("name");

                closedStageQuestionsArrayOfObjs.push(questionObj);
                closedStageQuestionIDsAlreadyAddedArray.push(questionObj.question_id);
              }
            }
          }
          JSFUNC.sort_arrayOfObjs(closedStageQuestionsArrayOfObjs, "sort", true);

          closedStagesArrayOfObjs.push({
            stageObj: stageObj,
            questionsArrayOfObjs: closedStageQuestionsArrayOfObjs,
            closedStageQuestionIDsAlreadyAddedArray: closedStageQuestionIDsAlreadyAddedArray //only added questions to this particular single closed stage
          });

          //if this stage is the selected receiving stage, set the receiving collection of questionIDs to be the ids for this closed stage
          if(this.o_questionsSelectedReceivingStageID === stageObj.id) {
            selectedReceivingStageQuestionIDsAlreadyAddedArray = closedStageQuestionIDsAlreadyAddedArray;
          }
        }
      }

      closedStageTypesArrayOfObjs.push({
        stageTypeName: stageTypeName,
        closedStagesArrayOfObjs: closedStagesArrayOfObjs
      });
    }

    //return the full object including all active stages and each individual closed stage
    return({
      activeStagesArrayOfObjs: activeStagesArrayOfObjs,
      activeQuestionIDsAlreadyAddedArray: activeQuestionIDsAlreadyAddedArray, //all added questions for all active stages plus the stage independent questions
      closedStageTypesArrayOfObjs: closedStageTypesArrayOfObjs,
      selectedReceivingStageQuestionIDsAlreadyAddedArray: selectedReceivingStageQuestionIDsAlreadyAddedArray
    });
  }


  //details card groups subtab
  get c_detailsCardGroupsPoolArrayOfObjs() {
    const mapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_details_groups_pool");
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(mapOfMaps, "admin_sort", true));
  }

  get c_selectedCaptureTypeDetailsCardGroupIDsArray() {
    return(JSFUNC.convert_comma_list_to_int_array(this.c_selectedCaptureTypeObj.details_group_ids_comma));
  }

  get c_selectedCaptureTypeDetailsCardGroupsArrayOfObjs() {
    var detailsCardGroupsArrayOfObjs = [];
    for(let detailsCardGroupID of this.c_selectedCaptureTypeDetailsCardGroupIDsArray) {
      var detailsCardGroupMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_details_groups_pool", detailsCardGroupID);
      var detailsCardGroupObj = JSFUNC.obj_from_map(detailsCardGroupMap);
      detailsCardGroupsArrayOfObjs.push(detailsCardGroupObj);
    }
    return(detailsCardGroupsArrayOfObjs);
  }



  //details/dates fields card
  get c_fieldsOnDetailsCardCustomArrayOfObjs() {
    return(this.filter_fields_by_card_arrayOfObjs("detailsCardCustomTF"));
  }
  get c_fieldsOnDetailsCardMandatoryArrayOfObjs() {
    return(this.filter_fields_by_card_arrayOfObjs("detailsCardMandatoryTF"));
  }
  get c_fieldsOnDetailsCardGcssArrayOfObjs() {
    return(this.filter_fields_by_card_arrayOfObjs("detailsCardGcssTF"));
  }
  get c_fieldsOnAdvanceStageCardArrayOfObjs() {
    return(this.filter_fields_by_card_arrayOfObjs("advanceStageCardTF"));
  }
  get c_fieldsOnDealShapingCardArrayOfObjs() {
    return(this.filter_fields_by_card_arrayOfObjs("dealShapingCardTF"));
  }
  get c_fieldsOnTeammatesCardArrayOfObjs() {
    return(this.filter_fields_by_card_arrayOfObjs("teammatesCardTF"));
  }
  get c_fieldsOnCompetitorsCardArrayOfObjs() {
    return(this.filter_fields_by_card_arrayOfObjs("competitorsCardTF"));
  }
  get c_fieldsOnNotepadCardArrayOfObjs() {
    return(this.filter_fields_by_card_arrayOfObjs("notepadCardTF"));
  }

  filter_fields_by_card_arrayOfObjs(i_tfFieldString) {
    var fakeSort = 1;
    var cardExpandedCaptureFieldsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.c_tbl_captures_fields, i_tfFieldString, true, "admin_sort", true);
    for(let expandedCaptureFieldObj of cardExpandedCaptureFieldsArrayOfObjs) {
      var fieldTypeObj = expandedCaptureFieldObj.fieldTypeObj;

      if(fieldTypeObj !== undefined) {
        if(!(fieldTypeObj.textareaTF || fieldTypeObj.verticalSwitchTF)) {
          expandedCaptureFieldObj.textarea_details_max_lines = false; //set this as a flag that this field shows "N/A" in the table
        }

        if(!fieldTypeObj.hasTrimDecimalsOptionTF && !fieldTypeObj.hasZeroPaddingOptionTF) {
          expandedCaptureFieldObj.num_decimals = false; //set this as a flag that this field shows "N/A" in the table
        }

        //compute the Options arrayOfObjs for select field types (undefined for other fields)
        if(fieldTypeObj.requiresSelectWithSearchDataObjTF) {
          if(fieldTypeObj.selectWithSearchDataObj !== undefined && JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.valueArray)) {
            expandedCaptureFieldObj.numOptions = fieldTypeObj.selectWithSearchDataObj.valueArray.length;
          }
          else {
            expandedCaptureFieldObj.numOptions = "-";
          }
        }
        else {
          expandedCaptureFieldObj.numOptions = false; //false to display "N/A" in the pool
        }
      }

      expandedCaptureFieldObj.fakeSort = fakeSort;
      fakeSort++;
    }

    return(cardExpandedCaptureFieldsArrayOfObjs);
  }

  get c_filteredFieldsOnDetailsCardCustomArrayOfObjs() {
    return(this.filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(this.c_fieldsOnDetailsCardCustomArrayOfObjs));
  }
  get c_filteredFieldsOnDetailsCardMandatoryArrayOfObjs() {
    return(this.filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(this.c_fieldsOnDetailsCardMandatoryArrayOfObjs));
  }
  get c_filteredFieldsOnDetailsCardGcssArrayOfObjs() {
    return(this.filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(this.c_fieldsOnDetailsCardGcssArrayOfObjs));
  }
  get c_filteredFieldsOnAdvanceStageCardArrayOfObjs() {
    return(this.filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(this.c_fieldsOnAdvanceStageCardArrayOfObjs));
  }
  get c_filteredFieldsOnDealShapingCardArrayOfObjs() {
    return(this.filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(this.c_fieldsOnDealShapingCardArrayOfObjs));
  }
  get c_filteredFieldsOnTeammatesCardArrayOfObjs() {
    return(this.filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(this.c_fieldsOnTeammatesCardArrayOfObjs));
  }
  get c_filteredFieldsOnCompetitorsCardArrayOfObjs() {
    return(this.filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(this.c_fieldsOnCompetitorsCardArrayOfObjs));
  }
  get c_filteredFieldsOnNotepadCardArrayOfObjs() {
    return(this.filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(this.c_fieldsOnNotepadCardArrayOfObjs));
  }

  filter_fields_arrayOfObjs_for_search_or_dates_or_revenue(i_fieldsArrayOfObjs) {
    const searchFilterTF = (this.o_fieldsSearchFilterText !== "");
    const dateFilterTF = (this.o_fieldsSelectedTabSnapshotGroupsDates === "datesCard");
    const revenueFilterTF = (this.o_fieldsSelectedTabSnapshotGroupsDates === "revenueCard");

    //no filters, return full array
    if(!searchFilterTF && !dateFilterTF && !revenueFilterTF) {
      return(i_fieldsArrayOfObjs);
    }

    var filterSearchTermLower = undefined;
    if(searchFilterTF) {
      filterSearchTermLower = this.o_fieldsSearchFilterText.toLowerCase();
    }

    var filteredFieldsArrayOfObjs = [];
    for(let fieldObj of i_fieldsArrayOfObjs) {
      var fieldMatchesFiltersTF = false;

      if(searchFilterTF) {
        if(fieldObj.display_name.toLowerCase().indexOf(filterSearchTermLower) >= 0) {
          fieldMatchesFiltersTF = true;
        }
      }

      if(!fieldMatchesFiltersTF && dateFilterTF) {
        if(fieldObj.fieldTypeObj.valueDisplayIsDateOrDateTimeTF) {
          fieldMatchesFiltersTF = true;
        }
      }

      if(!fieldMatchesFiltersTF && revenueFilterTF) {
        if(fieldObj.fieldTypeObj.moneyTF && fieldObj.detailsCardCustomTF) {
          fieldMatchesFiltersTF = true;
        }
      }

      if(fieldMatchesFiltersTF) {
        filteredFieldsArrayOfObjs.push(fieldObj);
      }
    }
    return(filteredFieldsArrayOfObjs);
  }


  get c_allFieldDbNamesArray() {
    return(JSFUNC.get_column_vector_from_mapOfMaps(DatabaseMobx.o_tbl_captures_fields, "db_name"));
  }

  get c_fieldsAddedToDetailsSnapshotObj() {
    var snapshotFieldsArrayOfObjs = [];
    var snapshotExampleCaptureFieldValuesArrayOfObjs = [];
    for(let fieldID of this.c_selectedCaptureTypeDetailsSnapshotFieldIDsArray) {
      var expandedCaptureFieldMap = DatabaseMobx.tbl_row_map_from_id("tbl_captures_fields", fieldID);
      snapshotFieldsArrayOfObjs.push({
        fieldID: fieldID,
        fieldDisplayName: expandedCaptureFieldMap.get("display_name"),
        fieldTypeObj: expandedCaptureFieldMap.get("fieldTypeObj")
      });

      snapshotExampleCaptureFieldValuesArrayOfObjs.push({
        valueRaw: "Example Value",
        valueMaskPlainText: "Example Value",
        valueMask: "Example Value",
        valueMaskNoClickLinks: "Example Value",
        valueMaskCsvXmlPlainText: "Example Value",
        valueSort: "Example Value",
        isFilledOutTF: true,
        canEditValueTF: false,
        expandedCaptureFieldMap: expandedCaptureFieldMap
      });
    }

    return({
      snapshotFieldsArrayOfObjs: snapshotFieldsArrayOfObjs,
      snapshotExampleCaptureFieldValuesArrayOfObjs: snapshotExampleCaptureFieldValuesArrayOfObjs,
      fieldIDsAlreadyAddedArray: this.c_selectedCaptureTypeDetailsSnapshotFieldIDsArray
    });
  }

  get c_fieldsAddedToDetailsCardGroupsObj() {
    const c_selectedCaptureTypeObj = this.c_selectedCaptureTypeObj;
    const c_tbl_a_capture_types_details_fields = DatabaseMobx.c_tbl_a_capture_types_details_fields;

    var fieldsPerGroupArrayOfObjs = [];
    var fieldIDsAlreadyAddedArray = [];
    for(let detailsCardGroupObj of this.c_selectedCaptureTypeDetailsCardGroupsArrayOfObjs) {
      var expandedCaptureTypeDetailsFieldsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "group_id"], [c_selectedCaptureTypeObj.id, detailsCardGroupObj.id], "sort", true);
      
      for(let expandedCaptureTypeDetailsFieldObj of expandedCaptureTypeDetailsFieldsArrayOfObjs) {
        fieldIDsAlreadyAddedArray.push(expandedCaptureTypeDetailsFieldObj.field_id);
      }

      fieldsPerGroupArrayOfObjs.push({
        detailsCardGroupObj: detailsCardGroupObj,
        expandedCaptureTypeDetailsFieldsArrayOfObjs: expandedCaptureTypeDetailsFieldsArrayOfObjs
      });
    }

    return({
      fieldsPerGroupArrayOfObjs: fieldsPerGroupArrayOfObjs,
      fieldIDsAlreadyAddedArray: fieldIDsAlreadyAddedArray
    });
  }

  get c_fieldsAddedToDatesCardObj() {
    const c_selectedCaptureTypeObj = this.c_selectedCaptureTypeObj;
    const c_tbl_a_capture_types_details_fields = DatabaseMobx.c_tbl_a_capture_types_details_fields;
    const c_cardNameDates = DatabaseMobx.c_cardNameDates;

    const expandedCaptureTypeDetailsFieldsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "group_id"], [c_selectedCaptureTypeObj.id, 0], "sort", true);
    const fieldIDsAlreadyAddedArray = JSFUNC.get_column_vector_from_arrayOfObjs(expandedCaptureTypeDetailsFieldsArrayOfObjs, "field_id");

    return({
      detailsCardGroupObj: {id:0, name:c_cardNameDates + " Card"},
      expandedCaptureTypeDetailsFieldsArrayOfObjs: expandedCaptureTypeDetailsFieldsArrayOfObjs,
      fieldIDsAlreadyAddedArray: fieldIDsAlreadyAddedArray
    });
  }

  get c_fieldsAddedToRevenueCardObj() {
    const c_selectedCaptureTypeObj = this.c_selectedCaptureTypeObj;
    const c_tbl_a_capture_types_details_fields = DatabaseMobx.c_tbl_a_capture_types_details_fields;
    const c_cardNameRevenue = DatabaseMobx.c_cardNameRevenue;

    const expandedCaptureTypeDetailsFieldsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "group_id"], [c_selectedCaptureTypeObj.id, -1], "sort", true);
    const fieldIDsAlreadyAddedArray = JSFUNC.get_column_vector_from_arrayOfObjs(expandedCaptureTypeDetailsFieldsArrayOfObjs, "field_id");

    return({
      detailsCardGroupObj: {id:-1, name:c_cardNameRevenue + " Card Costs"},
      expandedCaptureTypeDetailsFieldsArrayOfObjs: expandedCaptureTypeDetailsFieldsArrayOfObjs,
      fieldIDsAlreadyAddedArray: fieldIDsAlreadyAddedArray
    });
  }

  get c_allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs() {
    const fieldsAddedToDetailsCardGroupsObj = this.c_fieldsAddedToDetailsCardGroupsObj;
    const fieldsAddedToDatesCardObj = this.c_fieldsAddedToDatesCardObj;
    const fieldsAddedToRevenueCardObj = this.c_fieldsAddedToRevenueCardObj;

    var allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs = [];

    //details card (all groups)
    for(let fieldsPerGroupObj of fieldsAddedToDetailsCardGroupsObj.fieldsPerGroupArrayOfObjs) {
      for(let expandedCaptureTypeDetailsFieldObj of fieldsPerGroupObj.expandedCaptureTypeDetailsFieldsArrayOfObjs) {
        allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs.push(expandedCaptureTypeDetailsFieldObj);
      }
    }

    //dates card (groupID 0)
    for(let expandedCaptureTypeDetailsFieldObj of fieldsAddedToDatesCardObj.expandedCaptureTypeDetailsFieldsArrayOfObjs) {
      allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs.push(expandedCaptureTypeDetailsFieldObj);
    }

    //revenue card (groupID -1)
    for(let expandedCaptureTypeDetailsFieldObj of fieldsAddedToRevenueCardObj.expandedCaptureTypeDetailsFieldsArrayOfObjs) {
      allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs.push(expandedCaptureTypeDetailsFieldObj);
    }

    return(allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs);
  }

  get c_contractTypesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_contract_types, "sort", true));
  }
  get c_reasonsWonLostArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_reasons_won_lost, "name", true));
  }



  //details fields options subtab
  get c_bitMasterSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs() {
    //put all set-asides together is custom sort order
    var bmSetAsidesSamAlphabeticTopCustomSortedBottomArrayOfObjs = [];
    for(let bmSetAsideMap of DatabaseMobx.c_tbl_bit_master_set_asides.values()) {
      if(bmSetAsideMap.get("gcss_name") !== "") {
        bmSetAsidesSamAlphabeticTopCustomSortedBottomArrayOfObjs.push(JSFUNC.obj_from_map(bmSetAsideMap));
      }
    }
    JSFUNC.sort_arrayOfObjs(bmSetAsidesSamAlphabeticTopCustomSortedBottomArrayOfObjs, "nameSort", true);

    var bmSetAsidesCustomSortedBottomArrayOfObjs = [];
    for(let bmSetAsideMap of DatabaseMobx.c_tbl_bit_master_set_asides.values()) {
      if(bmSetAsideMap.get("gcss_name") === "") {
        bmSetAsidesCustomSortedBottomArrayOfObjs.push(JSFUNC.obj_from_map(bmSetAsideMap));
      }
    }
    JSFUNC.sort_arrayOfObjs(bmSetAsidesSamAlphabeticTopCustomSortedBottomArrayOfObjs, "sort", true);

    for(let bmSetAsideCustomSortedBottomObj of bmSetAsidesCustomSortedBottomArrayOfObjs) {
      bmSetAsidesSamAlphabeticTopCustomSortedBottomArrayOfObjs.push(bmSetAsideCustomSortedBottomObj);
    }

    //remove entries that are hidden duplicates
    var bmSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs = [];
    for(let bmSetAsideObj of bmSetAsidesSamAlphabeticTopCustomSortedBottomArrayOfObjs) {
      if(!bmSetAsideObj.isHiddenDuplicateRowTF) {
        bmSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs.push(bmSetAsideObj);
      }
    }

    return(bmSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs);
  }

  get c_bitMasterNaicsCombinedNamesNotHiddenArray() {
    var bitMasterNaicsCombinedNamesNotHiddenArray = [];
    for(let naicsCodeMap of DatabaseMobx.c_tbl_bit_master_naics_codes.values()) {
      if(!naicsCodeMap.get("naicsCodeHiddenUnlessCheckedTF")) {
        bitMasterNaicsCombinedNamesNotHiddenArray.push(naicsCodeMap.get("combinedName"));
      }
    }
    bitMasterNaicsCombinedNamesNotHiddenArray.sort();
    return(bitMasterNaicsCombinedNamesNotHiddenArray);
  }

  get c_bitMasterProductServiceCodesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.c_tbl_bit_master_product_service_codes, "name", true));
  }

  get c_bitMasterDepartmentsAgenciesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.c_tbl_bit_master_departments_agencies, "name", true));
  }

  get c_bitMasterSubTiersArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.c_tbl_bit_master_sub_tiers, "isDuplicateEntryTF", false, "displayName", true));
  }





  //teammates subtab
  get c_teammatesWorkshareTypesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_teammates_workshare_types, "sort", true));
  }
  get c_teammatesContractOtherStatusesArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_a_teammates_contract_statuses, "hc0_they1_we2", 0, "sort", true));
  }
  get c_teammatesContractThemStatusesArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_a_teammates_contract_statuses, "hc0_they1_we2", 1, "sort", true));
  }
  get c_teammatesContractWeStatusesArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_a_teammates_contract_statuses, "hc0_they1_we2", 2, "sort", true));
  }
  get c_teammatesContractAgreementTypesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_teammates_contract_agreement_types, "sort", true));
  }






  //proposal themes subtab
  get c_ptDifferentiatorsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_pt_differentiators, "name", true));
  }





  //risk levels subtab
  get c_riskCategoriesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_risks_categories, "name", true));
  }
  get c_risksProbabilityRiskLevelsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_risks_probability_levels, "percent_0to100", true));
  }
  get c_risksImpactRiskLevelsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_risks_impact_levels, "percent_0to100", true));
  }



  //budgets subtab
  get c_budgetCategoriesPoolArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.c_tbl_a_budget_categories_pool, "sort", true));
  }

  get c_selectedCaptureTypeBudgetCategoryIDsArray() {
    return(JSFUNC.convert_comma_list_to_int_array(this.c_selectedCaptureTypeObj.budget_category_ids_comma));
  }

  get c_selectedCaptureTypeBudgetCategoriesArrayOfObjs() {
    var budgetCategoriesArrayOfObjs = [];
    for(let budgetCategoryID of this.c_selectedCaptureTypeBudgetCategoryIDsArray) {
      var budgetCategoryMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_budget_categories_pool", budgetCategoryID);
      var budgetCategoryObj = JSFUNC.obj_from_map(budgetCategoryMap);
      budgetCategoriesArrayOfObjs.push(budgetCategoryObj);
    }
    return(budgetCategoriesArrayOfObjs);
  }




  //template codewords
  get c_templateCodewordsExamplesArrayOfObjs() {
    return(this.create_template_codewords_examples_arrayOfObjs());
  }

  create_template_codewords_examples_arrayOfObjs() {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const c_cardNameTeammates = DatabaseMobx.c_cardNameTeammates;
    const c_teammatesAllocOfCovFieldDisplayName = DatabaseMobx.c_teammatesAllocOfCovFieldDisplayName;
    const c_teammatesWorkshareTypeFieldDisplayName = DatabaseMobx.c_teammatesWorkshareTypeFieldDisplayName;
    const c_teammatesWorkshareNumFtesFieldDisplayName = DatabaseMobx.c_teammatesWorkshareNumFtesFieldDisplayName;
    const c_teammatesWorkshareNotesFieldDisplayName = DatabaseMobx.c_teammatesWorkshareNotesFieldDisplayName;

    const captureFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.c_tbl_captures_fields, "display_name", true);
    const teammatesExtraFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_teammates_extra_fields, "sort", true);
    const competitorsExtraFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_competitors_extra_fields, "sort", true);
    const shapingQuestionsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_shaping_questions_pool, "id", true);
    const contactCompanyFieldsArrayOfObjs = ContactsMobx.contact_fields_arrayOfObjs(false);
    const contactPersonFieldsArrayOfObjs = ContactsMobx.contact_fields_arrayOfObjs(true);

    var templateCodewordsExamplesArrayOfObjs = [];

    //Capture Fields
    var captureFieldsCodewordRowsArrayOfObjs = [];
    for(let captureFieldObj of captureFieldsArrayOfObjs) {
      captureFieldsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:captureFieldObj.lowercaseCodewordsArray, description:captureFieldObj.display_name});
    }

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Details/Dates Fields",
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: undefined,
      rowsArrayOfObjs: captureFieldsCodewordRowsArrayOfObjs
    });

    //Deal Shaping Questions/Answers
    var dealShapingExamplesCodewordRowsArrayOfObjs = [];
    dealShapingExamplesCodewordRowsArrayOfObjs.push({subtitle:"Deal Shaping Questions codeword formatting by Question ID number"});
    dealShapingExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@ds_q15", description:"The text for the question with ID number 15"});
    dealShapingExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@ds_q15a", description:"For this capture, the text of the answer given to the question with ID 15"});
    dealShapingExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@ds_q15p", description:"For this capture, the percent completion for the answer given to the question with ID 15"});

    var dealShapingCodewordRowsArrayOfObjs = [];
    dealShapingCodewordRowsArrayOfObjs.push({subtitle:"All Questions from the Questions Pool for all Capture Types"});
    for(let shapingQuestionObj of shapingQuestionsArrayOfObjs) {
      var dealShapingCodewordOrCodewordsArray = ["@ds_q" + shapingQuestionObj.id, "@ds_q" + shapingQuestionObj.id + "a", "@ds_q" + shapingQuestionObj.id + "p"];
      dealShapingCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:dealShapingCodewordOrCodewordsArray, description:shapingQuestionObj.name, codewordsHorizontalTF: true});
    }

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Deal Shaping Questions/Answers",
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: dealShapingExamplesCodewordRowsArrayOfObjs,
      rowsArrayOfObjs: dealShapingCodewordRowsArrayOfObjs
    });

    //Teammates (LB/SB)
    var teammatesExamplesCodewordRowsArrayOfObjs = [];
    teammatesExamplesCodewordRowsArrayOfObjs.push({subtitle:"Teammate codeword prefixes to reference single teammate"});
    teammatesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_...", description:"Gets the 1st Large Business Teammate (ordered by highest percent allocation of Contract Overall Value) selected for the team on the capture"});
    teammatesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb2_...", description:"Gets the 2nd Large Business Teammate"});
    teammatesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_sb1_...", description:"Gets the 1st Small Business Teammate"});
    teammatesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_...", description:"Gets information from the 1st agreement (NDA in default system) for the 1st Large Business Teammate"});
    teammatesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement2_...", description:"Gets information from the 2nd agreement (TA in default system) for the 1st Large Business Teammate"});

    var teammatesCodewordRowsArrayOfObjs = [];
    teammatesCodewordRowsArrayOfObjs.push({subtitle:"Teammate codewords using 'lb1' (and 'agreement1') as the example prefix"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_allocation_percent", description:"Teammate " + c_teammatesAllocOfCovFieldDisplayName});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_allocation_cov", description:"Teammate Allocation Percent multiplied by the capture Contract Overall Value"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_allocation_cov_short", description:"Allocation Contract Overall Value, but in 'short' format ('$18.4M' instead of '$18,430,000')"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_workshare_type", description:"Teammate " + c_teammatesWorkshareTypeFieldDisplayName});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_workshare_number_of_ftes", description:"Teammate " + c_teammatesWorkshareNumFtesFieldDisplayName});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_workshare_notes", description:"Teammate " + c_teammatesWorkshareNotesFieldDisplayName});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_contact_persons_names_comma", description:"Contact Persons added to this Teammate (their full names in a list separated by commas)"});
    for(let teammatesExtraFieldObj of teammatesExtraFieldsArrayOfObjs) {
      teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_" + teammatesExtraFieldObj.db_name, description:"Teammate " + teammatesExtraFieldObj.display_name});
    }
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_contactcompany_name", description:"Name of the Company selected for this Teammate (Abbr Name or full Legal Name)"});
    for(let contactCompanyFieldObj of contactCompanyFieldsArrayOfObjs) {
      if(contactCompanyFieldObj.db_name !== "tree_id") {
        teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_contactcompany_" + contactCompanyFieldObj.db_name, description:"Teammate Company " + contactCompanyFieldObj.display_name});
      }
    }
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_contractsmanager_name", description:"Teammate Contracts Manager full name"});
    for(let contactPersonFieldObj of contactPersonFieldsArrayOfObjs) {
      teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_contractsmanager_" + contactPersonFieldObj.db_name, description:"Teammate Contracts Manager " + contactPersonFieldObj.display_name});
    }
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_contract_type_name", description:"Selected Contract Name (Non-Disclosure Agreement, etc)"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_contract_type_short_name", description:"Selected Contract Short Name (NDA, etc)"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_agreement_type", description:"Selected Contract Agreement Type (Teammate's/Ours)"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_status", description:"Selected Contract Current Status Name (Generate / Send Agreement, Fully Executed, etc)"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_requested_by_user", description:"Selected Contract User who requested the contract to be executed"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_date_requested", description:"Selected Contract Date Requested"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_date_needed", description:"Selected Contract Date Needed"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_lb1_agreement1_date_completed", description:"Selected Contract Date Completed"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Teammates (Single Teammate LB/SB)",
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: teammatesExamplesCodewordRowsArrayOfObjs,
      rowsArrayOfObjs: teammatesCodewordRowsArrayOfObjs
    });

    //All Teammates
    var allTeammatesCodewordRowsArrayOfObjs = [];
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_sort_alphabetic", description:"Comma list of all selected Teammate names on a Capture, sorted by name alphabetically"});
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_sort_allocation", description:"Comma list of all selected Teammate names on a Capture, sorted by allocation percent from most to least"});
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_sort_lb_sb_allocation", description:"Comma list of all selected Teammate names on a Capture, sorted by allocation percent but for all Large Businesses first, then all Small Businesses (same order listed in Capture " + c_cardNameTeammates + " card)"});
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_sort_lb_allocation", description:"Comma list of all selected Large Business Teammate names on a Capture, sorted by allocation percent"});
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_sort_sb_allocation", description:"Comma list of all selected Small Business Teammate names on a Capture, sorted by allocation percent"});
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_and_allocation_sort_allocation", description:"Teammate names (with allocation %)"});
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_and_allocation_sort_lb_sb_allocation", description:"Teammate names (with allocation %), sort LB then SB"});
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_and_allocation_sort_lb_allocation", description:"Teammate names (with allocation %), only LB"});
    allTeammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_all_selected_teammates_list_names_and_allocation_sort_sb_allocation", description:"Teammate names (with allocation %), only SB"});
    
    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "All Teammates Lists",
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: [],
      rowsArrayOfObjs: allTeammatesCodewordRowsArrayOfObjs
    });

    //Teammate Contracts
    var teammatesExamplesCodewordRowsArrayOfObjs = [];
    teammatesExamplesCodewordRowsArrayOfObjs.push({subtitle:"Teammate Contract codewords only used in single contract templates"});
    teammatesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_...", description:"Uses the Teammate and Contract selected when generating a template from within a particular Teammate/Contract"});
    teammatesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_...", description:"Uses the Teammate and Contract selected when generating a template from within a particular Teammate/Contract"});

    var teammatesCodewordRowsArrayOfObjs = [];
    teammatesCodewordRowsArrayOfObjs.push({subtitle:"Teammate codewords using 'con' (and 'agreement') as the example prefix"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_allocation_percent", description:"Teammate " + c_teammatesAllocOfCovFieldDisplayName});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_allocation_cov", description:"Teammate Allocation Percent multiplied by the capture Contract Overall Value"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_allocation_cov_short", description:"Allocation Contract Overall Value, but in 'short' format ('$18.4M' instead of '$18,430,000')"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_workshare_type", description:"Teammate " + c_teammatesWorkshareTypeFieldDisplayName});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_workshare_number_of_ftes", description:"Teammate " + c_teammatesWorkshareNumFtesFieldDisplayName});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_workshare_notes", description:"Teammate " + c_teammatesWorkshareNotesFieldDisplayName});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_contact_persons_names_comma", description:"Contact Persons added to this Teammate (their full names in a list separated by commas)"});
    for(let teammatesExtraFieldObj of teammatesExtraFieldsArrayOfObjs) {
      teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_" + teammatesExtraFieldObj.db_name, description:"Teammate " + teammatesExtraFieldObj.display_name});
    }
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_contactcompany_name", description:"Name of the Company selected for this Teammate (Abbr Name or full Legal Name)"});
    for(let contactCompanyFieldObj of contactCompanyFieldsArrayOfObjs) {
      if(contactCompanyFieldObj.db_name !== "tree_id") {
        teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_contactcompany_" + contactCompanyFieldObj.db_name, description:"Teammate Company " + contactCompanyFieldObj.display_name});
      }
    }
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_contractsmanager_name", description:"Teammate Contracts Manager full name"});
    for(let contactPersonFieldObj of contactPersonFieldsArrayOfObjs) {
      teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_contractsmanager_" + contactPersonFieldObj.db_name, description:"Teammate Contracts Manager " + contactPersonFieldObj.display_name});
    }
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_contract_type_name", description:"Selected Contract Name (Non-Disclosure Agreement, etc)"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_contract_type_short_name", description:"Selected Contract Short Name (NDA, etc)"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_agreement_type", description:"Selected Contract Agreement Type (Teammate's/Ours)"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_status", description:"Selected Contract Current Status Name (Generate / Send Agreement, Fully Executed, etc)"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_requested_by_user", description:"Selected Contract User who requested the contract to be executed"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_date_requested", description:"Selected Contract Date Requested"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_date_needed", description:"Selected Contract Date Needed"});
    teammatesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_agreement_date_completed", description:"Selected Contract Date Completed"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Teammate Single Contract Template",
      inExcelReportsTF: false,
      exampleRowsArrayOfObjs: teammatesExamplesCodewordRowsArrayOfObjs,
      rowsArrayOfObjs: teammatesCodewordRowsArrayOfObjs
    });

    //Competitors
    var competitorsExamplesCodewordRowsArrayOfObjs = [];
    competitorsExamplesCodewordRowsArrayOfObjs.push({subtitle:"Competitor codeword prefixes to reference single competitor"});
    competitorsExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_...", description:"Gets the 1st sorted Competitor on the capture"});
    competitorsExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp2_...", description:"Gets the 2nd Competitor"});

    var competitorsCodewordRowsArrayOfObjs = [];
    competitorsCodewordRowsArrayOfObjs.push({subtitle:"Competitor codewords using 'comp1' as the example prefix"});
    competitorsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_teammate_names_comma", description:"Competitor's teammate company names in a list separated by commas"});
    competitorsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_strengths", description:"Competitor Strengths"});
    competitorsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_weaknesses", description:"Competitor Weaknesses"});
    competitorsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_opportunities", description:"Competitor Opportunities"});
    competitorsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_threats", description:"Competitor Threats"});
    for(let competitorsExtraFieldObj of competitorsExtraFieldsArrayOfObjs) {
      competitorsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_" + competitorsExtraFieldObj.db_name, description:"Competitor " + competitorsExtraFieldObj.display_name});
    }
    competitorsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_contactcompany_name", description:"Name of the Company selected for this Competitor (Abbr Name or full Legal Name)"});
    for(let contactCompanyFieldObj of contactCompanyFieldsArrayOfObjs) {
      if(contactCompanyFieldObj.db_name !== "tree_id") {
        competitorsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@comp1_contactcompany_" + contactCompanyFieldObj.db_name, description:"Competitor Company " + contactCompanyFieldObj.display_name});
      }
    }

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Competitors",
      inExcelReportsTF: false,
      exampleRowsArrayOfObjs: competitorsExamplesCodewordRowsArrayOfObjs,
      rowsArrayOfObjs: competitorsCodewordRowsArrayOfObjs
    });

    //Proposal Themes
    var proposalThemesExamplesCodewordRowsArrayOfObjs = [];
    proposalThemesExamplesCodewordRowsArrayOfObjs.push({subtitle:"Proposal Themes (Differentiators, Win Themes, Ghost Themes) codeword prefixes"});
    proposalThemesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_diff1_...", description:"Gets the 1st sorted Differentiator on the capture"});
    proposalThemesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_diff2_...", description:"Gets the 2nd Differentiator"});
    proposalThemesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_win1_...", description:"Gets the 1st sorted Win Theme on the capture"});
    proposalThemesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_win2_...", description:"Gets the 2nd Win Theme"});
    proposalThemesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_ghost1_...", description:"Gets the 1st sorted Ghost Theme on the capture"});
    proposalThemesExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_ghost2_...", description:"Gets the 2nd Ghost Theme"});

    var proposalThemesCodewordRowsArrayOfObjs = [];
    proposalThemesCodewordRowsArrayOfObjs.push({subtitle:"Differentiator codewords using 'theme_diff1' as the example prefix"});
    proposalThemesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_diff1_differentiator", description:"Differentiator text"});
    proposalThemesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_diff1_justification", description:"Justification text"});
    proposalThemesCodewordRowsArrayOfObjs.push({subtitle:"Win Theme codewords using 'theme_win1' as the example prefix"});
    proposalThemesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_win1_theme", description:"Win Theme text"});
    proposalThemesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_win1_justification", description:"Justification text"});
    proposalThemesCodewordRowsArrayOfObjs.push({subtitle:"Ghost Theme codewords using 'theme_ghost1' as the example prefix"});
    proposalThemesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_ghost1_theme", description:"Ghost Theme text"});
    proposalThemesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@theme_ghost1_justification", description:"Justification text"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Proposal Themes",
      inExcelReportsTF: false,
      exampleRowsArrayOfObjs: proposalThemesExamplesCodewordRowsArrayOfObjs,
      rowsArrayOfObjs: proposalThemesCodewordRowsArrayOfObjs
    });

    //Risk Assessment
    var risksExamplesCodewordRowsArrayOfObjs = [];
    risksExamplesCodewordRowsArrayOfObjs.push({subtitle:"Risk Assessment codeword prefixes to reference single risk item"});
    risksExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@risk1_...", description:"Gets the 1st sorted Risk Item on the capture"});
    risksExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@risk2_...", description:"Gets the 2nd Risk Item"});

    var risksCodewordRowsArrayOfObjs = [];
    risksCodewordRowsArrayOfObjs.push({subtitle:"Risk Assessment codewords using 'risk1' as the example prefix"});
    risksCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@risk1_category", description:"Risk Category"});
    risksCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@risk1_probability", description:"Risk Probability of Occurance level selected"});
    risksCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@risk1_impact", description:"Potential Risk Impact level selected"});
    risksCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@risk1_risk", description:"Risk description text"});
    risksCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@risk1_mitigation", description:"Risk Mitigation text"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Risk Assessment",
      inExcelReportsTF: false,
      exampleRowsArrayOfObjs: risksExamplesCodewordRowsArrayOfObjs,
      rowsArrayOfObjs: risksCodewordRowsArrayOfObjs
    });

    //Budget
    var budgetExamplesCodewordRowsArrayOfObjs = [];
    budgetExamplesCodewordRowsArrayOfObjs.push({subtitle:"Budget codeword prefixes to reference a particular Budget category"});
    for(let expandedBudgetCategoryObj of this.c_budgetCategoriesPoolArrayOfObjs) {
      budgetExamplesCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@budget_" + expandedBudgetCategoryObj.dbNameFromName + "_...", description:"Gets a value for Budget '" + expandedBudgetCategoryObj.name + "'"});
    }

    var budgetCodewordRowsArrayOfObjs = [];
    if(this.c_budgetCategoriesPoolArrayOfObjs.length > 0) {
      var firstBudgetCategoryObj = this.c_budgetCategoriesPoolArrayOfObjs[0];
      budgetCodewordRowsArrayOfObjs.push({subtitle:"Budget codewords using '" + firstBudgetCategoryObj.name + "' as the example Budget"});
      budgetCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@budget_" + firstBudgetCategoryObj.dbNameFromName + "_total_approved_funding", description:"Total approved funding sum from all approved Funding Requests"});
      budgetCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@budget_" + firstBudgetCategoryObj.dbNameFromName + "_remaining", description:"Approved funding remaining with expenses subtracted (positive or negative)"});
      budgetCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@budget_" + firstBudgetCategoryObj.dbNameFromName + "_pending_approval", description:"Sum of all Funding Requests currently pending approval"});
      budgetCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@budget_" + firstBudgetCategoryObj.dbNameFromName + "_spent", description:"Sum of all added Spent Expenses"});
    }
    else {
      budgetCodewordRowsArrayOfObjs.push({subtitle:"There are 0 Budget categories defined"});
    }

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Budget",
      inExcelReportsTF: false,
      exampleRowsArrayOfObjs: budgetExamplesCodewordRowsArrayOfObjs,
      rowsArrayOfObjs: budgetCodewordRowsArrayOfObjs
    });

    //Notepad
    var notepadExamplesCodewordRowsArrayOfObjs = [];

    var notepadCodewordRowsArrayOfObjs = [];
    notepadCodewordRowsArrayOfObjs.push({subtitle:"Notepad card codewords"});
    notepadCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"notepad", description:"All text inside the 'Notepad' large area of text"});
    notepadCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@notepad_notestamps_firstpinnedorrecent", description:"First Note Stamp for that Capture, either pinned #1 or the most recently edited if none are pinned"});
    notepadCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@notepad_notestamps_mostrecentlycreated", description:"Most recently created Note Stamp (regardless of pins) for that Capture"});
    notepadCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@notepad_notestamps_mostrecentlyedited", description:"Most recently edited Note Stamp (regardless of pins) for that Capture"});
    notepadCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@notepad_notestamps", description:"All dates/titles/content from every created 'Note Stamp' for that Capture"});
    
    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Notepad",
      inExcelReportsTF: false,
      exampleRowsArrayOfObjs: notepadExamplesCodewordRowsArrayOfObjs,
      rowsArrayOfObjs: notepadCodewordRowsArrayOfObjs
    });

    //Other Fields
    var otherFieldsCodewordRowsArrayOfObjs = [];
    otherFieldsCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"#today_date", description:"Today's date in the company format (set in 'Settings' tab)"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Other Fields",
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: undefined,
      rowsArrayOfObjs: otherFieldsCodewordRowsArrayOfObjs
    });

    //Codeword Optional Modifiers
    var modifiersExamplesCodewordRowsArrayOfObjs = [];
    modifiersExamplesCodewordRowsArrayOfObjs.push({subtitle:"[[codeword|maxchars##]] - Maximum # of characters to print into the template for any codeword. Enter the entire codeword as normal, followed by the '|' divider, the word 'maxchars' and the number of characters"});

    var modifiersCodewordRowsArrayOfObjs = [];
    modifiersCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"description_of_work", description:"Prints entire description of work for this capture"});
    modifiersCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"description_of_work|maxchars200", description:"Prints only the first 200 characters of the description of work for this capture"});
    modifiersCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@ds_q15", description:"Prints entire question text of the deal shaping question with unique ID 15"});
    modifiersCodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@ds_q15|maxchars50", description:"Prints only the first 50 characters of question with ID 15"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: "Codeword Optional Modifiers",
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: modifiersExamplesCodewordRowsArrayOfObjs,
      exampleBgColor: "green",
      rowsArrayOfObjs: modifiersCodewordRowsArrayOfObjs
    });

    var modifiers2ExamplesCodewordRowsArrayOfObjs = [];
    modifiers2ExamplesCodewordRowsArrayOfObjs.push({subtitle:"[[codeword|blankifblank]] - If a codeword results in a blank entry, usually something like '--No Text Entered--' is printed, this 'blankifblank' modifier changes it so that if the value is blank, an actual blank value is returned"});
    modifiers2ExamplesCodewordRowsArrayOfObjs.push({subtitle:"[[codeword|dashifblank]] - If a blank entry, 'dashifblank' changes it to '-'"});

    var modifiers2CodewordRowsArrayOfObjs = [];
    modifiers2CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_contactcompany_address2", description:"Actual value of 'Address 2' field for this example LB teammate is not filled in (blank), so this returns '--No Text Entered--'"});
    modifiers2CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_contactcompany_address2|blankifblank", description:"Actually returns a blank value with this modifier, instead of '--No Text Entered--'"});
    modifiers2CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"@team_con_contactcompany_address2|dashifblank", description:"Returns '-', instead of '--No Text Entered--' 'useful for columns of dates in Excel'"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: undefined,
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: modifiers2ExamplesCodewordRowsArrayOfObjs,
      exampleBgColor: "green",
      rowsArrayOfObjs: modifiers2CodewordRowsArrayOfObjs
    });

    var modifiers3ExamplesCodewordRowsArrayOfObjs = [];
    modifiers3ExamplesCodewordRowsArrayOfObjs.push({subtitle:"[[codeword|websitemask]] or [[codeword|websiteaddress]] can be applied to 'Website' type " + c_productStylingObj.productName + " fields"});

    var modifiers3CodewordRowsArrayOfObjs = [];
    modifiers3CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"opportunity_link", description:"An input of '[[SAM Link]]https://sam.gov/deal123456789' would output 'SAM Link' because the provided Mask overrides the link address by default"});
    modifiers3CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"opportunity_link|websitemask", description:"An input of '[[SAM Link]]https://sam.gov/deal123456789' would output 'SAM Link' because the 'websitemask' modifier always outputs the Mask"});
    modifiers3CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"opportunity_link|websiteaddress", description:"An input of '[[SAM Link]]https://sam.gov/deal123456789' would output 'https://sam.gov/deal123456789' using this Address modifier"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: undefined,
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: modifiers3ExamplesCodewordRowsArrayOfObjs,
      exampleBgColor: "green",
      rowsArrayOfObjs: modifiers3CodewordRowsArrayOfObjs
    });

    var modifiers4ExamplesCodewordRowsArrayOfObjs = [];
    modifiers4ExamplesCodewordRowsArrayOfObjs.push({subtitle:"[[codeword|naicscodedesconly]] or [[codeword|naicscodeonly|]] can be applied to 'Select' or 'Multi-Select' type " + c_productStylingObj.productName + " fields that reference the 'NAICS Codes' BIT Master table"});

    var modifiers4CodewordRowsArrayOfObjs = [];
    modifiers4CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"naics_code_ids_comma", description:"Full NAICS showing code, description, and exceptions: '541330 - Engineering Services ($22.5M) [Exception 1 Military and Aerospace Equipment and Military Weapons ($41.5M), Exception 2 Contracts and Subcontracts for Engineering Services Awarded Under the National Energy Policy Act of 1992 ($41.5M), Exception 3 Marine Engineering and Naval Architecture ($41.5M)]'"});
    modifiers4CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"naics_code_ids_comma|naicscodedesconly", description:"NAICS code and description (exceptions removed): '541330 - Engineering Services ($22.5M)'"});
    modifiers4CodewordRowsArrayOfObjs.push({codewordOrCodewordsArray:"naics_code_ids_comma|naicscodeonly", description:"NAICS code only: '541330'"});

    templateCodewordsExamplesArrayOfObjs.push({
      sectionTitle: undefined,
      inExcelReportsTF: true,
      exampleRowsArrayOfObjs: modifiers4ExamplesCodewordRowsArrayOfObjs,
      exampleBgColor: "green",
      rowsArrayOfObjs: modifiers4CodewordRowsArrayOfObjs
    });

    //for every codeword example, create a lowercase string for the search filter
    for(let templateCodewordExampleObj of templateCodewordsExamplesArrayOfObjs) {
      if(JSFUNC.is_array(templateCodewordExampleObj.exampleRowsArrayOfObjs)) {
        for(let exampleRowObj of templateCodewordExampleObj.exampleRowsArrayOfObjs) {
          exampleRowObj.codewordsAndDescriptionLowercase = this.compute_codewords_and_description_lowercase_from_template_codewords_row_obj(exampleRowObj);
        }
      }

      if(JSFUNC.is_array(templateCodewordExampleObj.rowsArrayOfObjs)) {
        for(let rowObj of templateCodewordExampleObj.rowsArrayOfObjs) {
          rowObj.codewordsAndDescriptionLowercase = this.compute_codewords_and_description_lowercase_from_template_codewords_row_obj(rowObj);
        }
      }
    }

    return(templateCodewordsExamplesArrayOfObjs);
  }

  compute_codewords_and_description_lowercase_from_template_codewords_row_obj(i_templateCodewordsRowObj) {
    var codewordsAndDescriptionLowercase = "";
    if(JSFUNC.is_string(i_templateCodewordsRowObj.codewordOrCodewordsArray)) {
      codewordsAndDescriptionLowercase = i_templateCodewordsRowObj.codewordOrCodewordsArray;
    }
    else if(JSFUNC.is_array(i_templateCodewordsRowObj.codewordOrCodewordsArray)) {
      codewordsAndDescriptionLowercase = i_templateCodewordsRowObj.codewordOrCodewordsArray.join(" "); //"codeword1 codeword2 codeword3"
    }

    if(JSFUNC.is_string(i_templateCodewordsRowObj.description)) {
      codewordsAndDescriptionLowercase += " " + i_templateCodewordsRowObj.description.toLowerCase();
    }

    return(codewordsAndDescriptionLowercase);
  }

  get c_adminSelectSingleCaptureTemplateFFSFieldTypeObj() {
    const o_tbl_a_single_capture_templates_filefoldersystem = DatabaseMobx.o_tbl_a_single_capture_templates_filefoldersystem;
    return(DatabaseMobx.create_filefoldersystem_field_type_obj_from_tbl_name(o_tbl_a_single_capture_templates_filefoldersystem, "Single Capture Template", false, true, true));
  }

  get c_adminSelectExcelReportTemplateFFSFieldTypeObj() {
    const o_tbl_a_excel_report_templates_filefoldersystem = DatabaseMobx.o_tbl_a_excel_report_templates_filefoldersystem;
    return(DatabaseMobx.create_filefoldersystem_field_type_obj_from_tbl_name(o_tbl_a_excel_report_templates_filefoldersystem, "Excel Report Template", false, true, true));
  }



  //tasks options subtab



  //company communications subtab
  get c_adminCompanyCommunicationsArrayOfObjs() {
    const o_tbl_a_communications_banners = DatabaseMobx.o_tbl_a_communications_banners;
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(o_tbl_a_communications_banners));
  }

  get c_adminCompanyCommunicationsSelectBannerOff0OnlyMe1On2FieldTypeObj() {
    const valueArray = [2, 1, 0];
    const displayArray = ["On", "Only Me [Test]", "Off"];
    return(DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Banner On/Off", valueArray, false, displayArray, 1.5));
  }

  get c_adminCompanyCommunicationsSelectFontSizeMultiplier10xFieldTypeObj() {
    const fontSizeMultiplier10xValueArray = [9, 10, 11, 12, 13, 14, 15];
    const fontSizeMultiplier10xDisplayArray = ["9", "10 [default]", "11", "12", "13", "14", "15"];
    const swsOptionsObj = {};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Font Size", fontSizeMultiplier10xValueArray, false, fontSizeMultiplier10xDisplayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }



  //automated reminders subtab
  get c_automatedRemindersArrayOfObjs() {
    //sort these alphabetic by field display name, then by prior category with days desc followed by post category with days asc
    var automatedRemindersArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_automated_reminders);
    for(let automatedReminderObj of automatedRemindersArrayOfObjs) {
      var captureDateFieldDisplayNamePlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(automatedReminderObj.date_field_id, DatabaseMobx.c_selectCaptureDateFieldFieldTypeObj);
      var selectedDivisionsOrAllDisplayName = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(automatedReminderObj.division_ids_comma_or_all, DatabaseMobx.c_selectMultiDivisionsOrAllFieldTypeObj);

      var daysPriorPosNegSort = automatedReminderObj.num_days_prior;
      if(automatedReminderObj.pre0_post1 === 1) {
        daysPriorPosNegSort *= -1;
      }

      automatedReminderObj.selectedDivisionsOrAllDisplayName = selectedDivisionsOrAllDisplayName;
      automatedReminderObj.captureDateFieldDisplayNamePlainText = captureDateFieldDisplayNamePlainText;
      automatedReminderObj.daysPriorPosNegSort = daysPriorPosNegSort;
    }

    JSFUNC.sort_arrayOfObjs(automatedRemindersArrayOfObjs, ["selectedDivisionsOrAllDisplayName", "captureDateFieldDisplayNamePlainText", "daysPriorPosNegSort"], [true, false]);

    return(automatedRemindersArrayOfObjs);
  }



  
  //automated exports subtab
  get c_automatedExportsArrayOfObjs() {
    const c_automatedExportsSelectRecurrenceFieldTypeObj = this.c_automatedExportsSelectRecurrenceFieldTypeObj;
    const o_websiteAddressWithDevIfDevAndEndingSlash = CaptureExecMobx.o_websiteAddressWithDevIfDevAndEndingSlash; //"https://captureexec.com/" or "https://captureexec.com/dev/" or "https://samgpvtracker.com/"
    const c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj = CapturesMobx.c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj;
    const c_bitJumbleKey = DatabaseMobx.c_bitJumbleKey;
    const c_genericTextFieldTypeObj = DatabaseMobx.c_genericTextFieldTypeObj;
    const c_genericIntPositiveFieldTypeObj = DatabaseMobx.c_genericIntPositiveFieldTypeObj;
    const c_genericDateFieldTypeObj = DatabaseMobx.c_genericDateFieldTypeObj;
    const c_selectUserFieldTypeObj = DatabaseMobx.c_selectUserFieldTypeObj;
    const c_selectDayOfTheWeekFieldTypeObj = DatabaseMobx.c_selectDayOfTheWeekFieldTypeObj;
    const c_selectDateOfTheMonth1stTo31stFieldTypeObj = DatabaseMobx.c_selectDateOfTheMonth1stTo31stFieldTypeObj;
    const c_selectTimeHour1to12AmPmFieldTypeObj = DatabaseMobx.c_selectTimeHour1to12AmPmFieldTypeObj;

    var automatedExportsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_automated_exports, "id", true);
    for(let automatedExportObj of automatedExportsArrayOfObjs) {
      var hasRunOnceTF = JSFUNC.datetime_is_filled_out_tf(automatedExportObj.last_run_datetime_est);

      var recurrenceCountFieldTypeObj = c_genericIntPositiveFieldTypeObj; //daily # days
      if(automatedExportObj.recurrence_type_123 === 2) { recurrenceCountFieldTypeObj = c_selectDayOfTheWeekFieldTypeObj; } //weekly day of the week
      else if(automatedExportObj.recurrence_type_123 === 3) { recurrenceCountFieldTypeObj = c_selectDateOfTheMonth1stTo31stFieldTypeObj; } //monthly date of the month
      
      const jobNameDbName = JSFUNC.db_name_from_display_name(automatedExportObj.job_name);
      const latestFileNameAndExt = "latest_" + jobNameDbName + ".csv";

      var lastRunDateTimeEstValueMaskPlainText = "";
      var lastRunDateTimeEstValueMask = "";
      if(hasRunOnceTF) {
        lastRunDateTimeEstValueMaskPlainText = automatedExportObj.last_run_datetime_est;
        lastRunDateTimeEstValueMask = lastRunDateTimeEstValueMaskPlainText;
      }
      else {
        lastRunDateTimeEstValueMaskPlainText = "--Not Yet Run--";
        lastRunDateTimeEstValueMask = DatabaseMobx.not_filled_out_font_html(lastRunDateTimeEstValueMaskPlainText);
      }

      automatedExportObj.isOnTF = (automatedExportObj.off0_on1 === 1);
      automatedExportObj.hasRunOnceTF = hasRunOnceTF;
      automatedExportObj.latestFileNameAndExt = latestFileNameAndExt;
      automatedExportObj.latestFileUrl = o_websiteAddressWithDevIfDevAndEndingSlash + "autoexports/" + c_bitJumbleKey + "/jobid" + automatedExportObj.id + "/" + latestFileNameAndExt;
      automatedExportObj.jobNameValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(automatedExportObj.job_name, c_genericTextFieldTypeObj);
      automatedExportObj.recurrenceValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(automatedExportObj.recurrence_type_123, c_automatedExportsSelectRecurrenceFieldTypeObj);
      automatedExportObj.recurrenceCountValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(automatedExportObj.recurrence_count, recurrenceCountFieldTypeObj);
      automatedExportObj.rumTimeHourValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(automatedExportObj.run_time_hour_0to23, c_selectTimeHour1to12AmPmFieldTypeObj);
      automatedExportObj.captureTableViewButtonPresetValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(automatedExportObj.capture_table_view_button_preset_id, c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj);
      automatedExportObj.lastRunDateTimeEstValueMaskSortIfoObj = {valueMaskPlainText:lastRunDateTimeEstValueMaskPlainText, valueMask:lastRunDateTimeEstValueMask};
      automatedExportObj.createdByUserValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(automatedExportObj.created_by_user_id, c_selectUserFieldTypeObj);
      automatedExportObj.dateCreatedValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(automatedExportObj.date_created, c_genericDateFieldTypeObj);
    }
    return(automatedExportsArrayOfObjs);
  }

  get c_automatedExportsSelectRecurrenceFieldTypeObj() {
    const selectRecurrenceSelectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Recurrence", [1,2,3], false, ["Daily", "Weekly", "Monthly"], undefined);
    return(DatabaseMobx.create_field_type_obj("select", selectRecurrenceSelectWithSearchDataObj));
  }




  //shortcut presets subtab
  get c_shortcutPresetsCaptureManagersArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.c_tbl_a_shortcut_presets_capture_managers, "sort", true));
  }
  
  get c_shortcutPresetsDocumentsCardFoldersPresetsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.c_tbl_a_shortcut_presets_documents_card_folders, "sort", true));
  }

  get c_shortcutPresetsDocumentsCardFoldersEditingPresetObj() {
    const o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined = this.o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined;

    var shortcutPresetID = -1;
    if(o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined !== undefined) {
      shortcutPresetID = o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined;
    }
    const editingPresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_shortcut_presets_documents_card_folders", shortcutPresetID);
    const editingPresetObj = JSFUNC.obj_from_map(editingPresetMap);
    return(editingPresetObj);
  }




  //capture archiving subtab
  get c_captureArchivingAllLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined() {
    const o_captureArchivingLoadedSlimCapturesArrayOfObjs = this.o_captureArchivingLoadedSlimCapturesArrayOfObjs;
    const c_searchFieldsSearchResultsExpandedCstColumnsArrayOfObjs = CapturesMobx.c_searchFieldsSearchResultsExpandedCstColumnsArrayOfObjs;
    
    if(JSFUNC.is_array(o_captureArchivingLoadedSlimCapturesArrayOfObjs)) {
      const sortCaptureFieldIDsArray = [];
      const includeMaskPlainTextLowercaseTF = true;
      return(CapturesMobx.create_filtered_cst_masked_capture_values_arrayOfObjs_from_filtered_captures_arrayOfObjs_and_expanded_cst_columns_arrayOfObjs_and_sort_capture_field_ids_array(o_captureArchivingLoadedSlimCapturesArrayOfObjs, c_searchFieldsSearchResultsExpandedCstColumnsArrayOfObjs, sortCaptureFieldIDsArray, includeMaskPlainTextLowercaseTF));
    }
    return(undefined);
  }

  get c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined() {
    const o_captureArchivingSearchText = this.o_captureArchivingSearchText;
    const c_captureArchivingAllLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined = this.c_captureArchivingAllLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined;

    //archived captures not loaded
    if(!JSFUNC.is_array(c_captureArchivingAllLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined)) {
      return(undefined);
    }

    //nothing typed into the filter search means full list of archived captures is listed
    if(!JSFUNC.string_is_filled_out_tf(o_captureArchivingSearchText)) {
      return(c_captureArchivingAllLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined);
    }

    //find all capture values objs that match the lowercase search term
    const searchTextLowercase = o_captureArchivingSearchText.toLowerCase();
    const searchCapturesExactTF = false;

    var filteredArchivedSearchResultsCapturesArrayOfObjs = [];
    for(let cstMaskedCaptureValuesObj of c_captureArchivingAllLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined) {
      if(CapturesMobx.any_search_fields_cst_capture_value_obj_field_matches_lowercase_search_term_tf(cstMaskedCaptureValuesObj, searchTextLowercase, searchCapturesExactTF)) {
        filteredArchivedSearchResultsCapturesArrayOfObjs.push(cstMaskedCaptureValuesObj);
      }
    }

    return(filteredArchivedSearchResultsCapturesArrayOfObjs);
  }




  //create new capture subtab
  get c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs() {
    const o_selectedCaptureTypeID = this.o_selectedCaptureTypeID;
    const o_tbl_a_create_new_capture_additional_fields = DatabaseMobx.o_tbl_a_create_new_capture_additional_fields;

    var createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_create_new_capture_additional_fields, "capture_type_id", o_selectedCaptureTypeID, "sort", true);
    for(let cncAdditionalFieldObj of createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs) {
      var expandedCaptureFieldMap = DatabaseMobx.fetch_expanded_capture_field_map_from_field_id(cncAdditionalFieldObj.capture_field_id);

      cncAdditionalFieldObj.expandedCaptureFieldMap = expandedCaptureFieldMap;
      cncAdditionalFieldObj.fieldDisplayName = expandedCaptureFieldMap.get("display_name");
    }
    return(createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs);
  }

  get c_createNewCaptureAdditionalFieldMapsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs, "expandedCaptureFieldMap"));
  }

  get c_createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs() {
    const c_createNewCaptureAdditionalFieldMapsArray = this.c_createNewCaptureAdditionalFieldMapsArray;
    const c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF = DatabaseMobx.c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF;
    const c_cardNameDocuments = DatabaseMobx.c_cardNameDocuments;
    const c_fieldMapOfCaptureManagers = DatabaseMobx.c_fieldMapOfCaptureManagers;
    const c_fieldMapOfDivisionOwners = DatabaseMobx.c_fieldMapOfDivisionOwners;
    const c_fieldMapOfCodename = DatabaseMobx.c_fieldMapOfCodename;
    const c_fieldMapOfOpportunityName = DatabaseMobx.c_fieldMapOfOpportunityName;
    const c_fieldMapOfCaptureType = DatabaseMobx.c_fieldMapOfCaptureType;
    const c_fieldMapOfStage = DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfContractType = DatabaseMobx.c_fieldMapOfContractType;
    const c_fieldMapOfContractOverallValue = DatabaseMobx.c_fieldMapOfContractOverallValue;
    const c_fieldMapOfIdiqCaptureIDTOLink = DatabaseMobx.c_fieldMapOfIdiqCaptureIDTOLink;
    const c_fieldMapOfPeriodOfPerformance = DatabaseMobx.c_fieldMapOfPeriodOfPerformance;

    var createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs = [
      [
        {sectionLabel:"New Capture Name", fieldMapsArray:[c_fieldMapOfOpportunityName, c_fieldMapOfCodename]}
      ],
      [
        {sectionLabel:c_fieldMapOfCaptureManagers.get("display_name"), fieldMapsArray:[c_fieldMapOfCaptureManagers]},
        {sectionLabel:c_fieldMapOfDivisionOwners.get("display_name"), fieldMapsArray:[c_fieldMapOfDivisionOwners]},
      ],
      [
        {sectionLabel:c_fieldMapOfCaptureType.get("display_name"), fieldMapsArray:[c_fieldMapOfCaptureType, c_fieldMapOfStage]},
        {sectionLabel:c_fieldMapOfContractType.get("display_name"), fieldMapsArray:[c_fieldMapOfContractType, c_fieldMapOfContractOverallValue, c_fieldMapOfIdiqCaptureIDTOLink, c_fieldMapOfPeriodOfPerformance]},
      ]
    ];

    var cncNewCaptureAdditionalFieldsObjOrUndefined = undefined;
    if(c_createNewCaptureAdditionalFieldMapsArray.length > 0) {
      cncNewCaptureAdditionalFieldsObjOrUndefined = {sectionLabel:"New Capture Additional Fields", fieldMapsArray:c_createNewCaptureAdditionalFieldMapsArray};
    }

    var cncDocumentsObjOrUndefined = undefined;
    if(c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF) {
      cncDocumentsObjOrUndefined = {sectionLabel:c_cardNameDocuments + " Card Preset Folders", fieldMapsArray:[]};
    }

    if((cncNewCaptureAdditionalFieldsObjOrUndefined !== undefined) || (cncDocumentsObjOrUndefined !== undefined)) {
      var cnc4thRowArrayOfObjs = [];
      if((cncNewCaptureAdditionalFieldsObjOrUndefined !== undefined) && (cncDocumentsObjOrUndefined === undefined)) {
        cnc4thRowArrayOfObjs = [cncNewCaptureAdditionalFieldsObjOrUndefined, undefined];
      }
      else if((cncNewCaptureAdditionalFieldsObjOrUndefined === undefined) && (cncDocumentsObjOrUndefined !== undefined)) {
        cnc4thRowArrayOfObjs = [cncDocumentsObjOrUndefined, undefined];
      }
      else {
        cnc4thRowArrayOfObjs = [cncNewCaptureAdditionalFieldsObjOrUndefined, cncDocumentsObjOrUndefined];
      }
      createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs.push(cnc4thRowArrayOfObjs);
    }

    return(createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs);
  }

  get c_createNewCaptureAllUsedFieldIDsArray() {
    const c_createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs = this.c_createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs;

    var createNewCaptureAllUsedFieldIDsArray = [];
    for(let cncFieldsArrayOfObjs of c_createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs) {
      for(let cncFieldObj of cncFieldsArrayOfObjs) {
        if(cncFieldObj !== undefined) {
          for(let fieldMap of cncFieldObj.fieldMapsArray) {
            createNewCaptureAllUsedFieldIDsArray.push(fieldMap.get("id"));
          }
        }
      }
    }
    return(createNewCaptureAllUsedFieldIDsArray);
  }

  get c_selectNewCaptureAdditionalFieldForCaptureTypeFieldTypeObj() {
    const c_createNewCaptureAllUsedFieldIDsArray = this.c_createNewCaptureAllUsedFieldIDsArray;
    const o_tbl_captures_fields = DatabaseMobx.o_tbl_captures_fields;

    const sortedCaptureFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(o_tbl_captures_fields, "display_name", true);

    const itemName = "Create New Capture Additional Field";
    var valueArray = [];
    const valuesAreStringsTF = false;
    var displayArray = [];
    const treeIDArray = undefined;
    const colorArray = undefined;
    var bgColorArray = [];
    const hiddenUnlessCheckedTFArray = undefined;
    var unableToHighlightOrClickTFArray = [];
    const optionDisplayOverwriteStringsArray = undefined
    const multiIDsCheckedWhenCheckedArrayOfArrays = undefined;
    for(let captureFieldObj of sortedCaptureFieldsArrayOfObjs) {
      var expandedCaptureFieldMap = DatabaseMobx.fetch_expanded_capture_field_map_from_field_id(captureFieldObj.id);
      
      var fieldID = captureFieldObj.id;
      var fieldDisplayName = captureFieldObj.display_name;
      var bgColor = "ffffff";
      var unableToHighlightOrClickTF = false;
      if(JSFUNC.in_array(fieldID, c_createNewCaptureAllUsedFieldIDsArray)) {
        fieldDisplayName = "[added] " + fieldDisplayName;
        bgColor = "dddddd";
        unableToHighlightOrClickTF = true;
      }
      else if(!expandedCaptureFieldMap.get("canEditFieldTF")) {
        fieldDisplayName = "[uneditable] " + fieldDisplayName;
        bgColor = "edd";
        unableToHighlightOrClickTF = true;
      }

      valueArray.push(fieldID);
      displayArray.push(fieldDisplayName);
      bgColorArray.push(bgColor);
      unableToHighlightOrClickTFArray.push(unableToHighlightOrClickTF);
    }

    const additionalFieldsValueDisplayArraysObj = DatabaseMobx.create_value_display_arrays_obj_from_value_array_and_display_array(itemName, valueArray, valuesAreStringsTF, displayArray, treeIDArray, colorArray, bgColorArray, hiddenUnlessCheckedTFArray, unableToHighlightOrClickTFArray, optionDisplayOverwriteStringsArray, multiIDsCheckedWhenCheckedArrayOfArrays);
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_display_arrays_obj(additionalFieldsValueDisplayArraysObj, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }





  //search fields
  get c_searchFieldsSelectUsedInSearchFieldTypeObj() {
    return(DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Used In Search", [1, 0], false, ["Yes", "No"], undefined));
  }
  get c_searchFieldsSelectSortColumnFieldTypeObj() {
    return(DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Sort Column", [1, 2, 3], false, ["No", "Yes - Ascending", "Yes - Descending"], undefined));
  }
  get c_searchFieldsSelectShowSumFieldTypeObj() {
    return(DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Show Sum", [1, 0], false, ["Yes", "No"], undefined));
  }





  //gcss subtab
  get c_gcssGovWinCredentialsArrayOfObjs() {
    const o_tbl_a_gcss_data_source_credentials = DatabaseMobx.o_tbl_a_gcss_data_source_credentials;
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_gcss_data_source_credentials, "data_source_sam0_govwin1", 1, "id", true));
  }

  get c_gcssFedCompCredentialsArrayOfObjs() {
    const o_tbl_a_gcss_data_source_credentials = DatabaseMobx.o_tbl_a_gcss_data_source_credentials;
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_gcss_data_source_credentials, "data_source_sam0_govwin1", 2, "id", true));
  }

  get c_gcssNotInterestedReasonsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_gcss_not_interested_reasons, "sort", true));
  }




  //contacts subtab
  get c_businessTypesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_business_types, "sort", true));
  }

  get c_capabilitiesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_capabilities, "name", true));
  }



  //delete capture reasons subtab
  get c_deleteCaptureReasonsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_delete_capture_reasons, "admin_sort", true));
  }



  //question tags subtab
  get c_shapingQuestionTagsArrayOfObjs() {
    const mapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_shaping_questions_tags");
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(mapOfMaps, "name", true));
  }


  //admin changelog subtab
  get c_expandedAdminChangelogArrayOfObjs() {
    const o_tbl_a_log_admin = DatabaseMobx.o_tbl_a_log_admin;

    var expandedAdminChangelogArrayOfObjs = [];
    for(let logAdminMap of o_tbl_a_log_admin.values()) {
      var expandedAdminChangelogObj = JSFUNC.obj_from_map(logAdminMap);

      var dateLocalYmd = JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(expandedAdminChangelogObj.datetime_utc);
      var dateTimeLocalMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(expandedAdminChangelogObj.datetime_utc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj);
      var userNameMaskPlainText = DatabaseMobx.user_name_mask_plaintext_from_user_id_and_alt_user_name_if_not_filled_out(expandedAdminChangelogObj.user_id, expandedAdminChangelogObj.user);
      var expandedActionCodeObj = this.admin_changelog_compute_expanded_action_code_obj_from_action_code(expandedAdminChangelogObj.admin_action_code);

      var actionDescription = expandedAdminChangelogObj.field;
      if(expandedActionCodeObj.validActionCodeTF) {
        actionDescription = expandedActionCodeObj.actionDescription;
      }

      expandedAdminChangelogObj.key = expandedAdminChangelogObj.id;
      expandedAdminChangelogObj.dateLocalYmd = dateLocalYmd;
      expandedAdminChangelogObj.dateTimeLocalMask = dateTimeLocalMask;
      expandedAdminChangelogObj.userNameMaskPlainText = userNameMaskPlainText;
      expandedAdminChangelogObj.actionDescription = actionDescription;
      expandedAdminChangelogObj.categoryCode = expandedActionCodeObj.categoryCode;
      expandedAdminChangelogObj.categoryDescription = expandedActionCodeObj.categoryDescription;

      expandedAdminChangelogArrayOfObjs.push(expandedAdminChangelogObj);
    }

    return(expandedAdminChangelogArrayOfObjs);
  }

  admin_changelog_compute_expanded_action_code_obj_from_action_code(i_adminActionCode) {
    var validActionCodeTF = true;

    var actionDescription = "--Admin Code " + i_adminActionCode + "--";
    if(i_adminActionCode === 100) { actionDescription = "Admin Deleted Capture"; }
    else if(i_adminActionCode === 101) { actionDescription = "Admin Deleted Multiple Captures from Capture Table"; }
    else if(i_adminActionCode === 200) { actionDescription = "Capture(s) Archived from Automated Rules"; }
    else if(i_adminActionCode === 201) { actionDescription = "User Manually Archived Capture"; }
    else if(i_adminActionCode === 300) { actionDescription = "Admin Imported Captures from .csv"; }
    else if(i_adminActionCode === 301) { actionDescription = "Admin Updated Captures from .csv"; }
    else if(i_adminActionCode === 400) { actionDescription = "Admin Imported Contact Companies from .csv"; }
    else if(i_adminActionCode === 401) { actionDescription = "Admin Imported Contact Persons from .csv"; }
    else if(i_adminActionCode === 500) { actionDescription = "Admin Mass Edited a Field across multiple Captures"; }
    else if(i_adminActionCode === 1000) { actionDescription = "Admin Created New Division"; }
    else if(i_adminActionCode === 1001) { actionDescription = "Admin Deleted Division"; }
    else if(i_adminActionCode === 1100) { actionDescription = "Admin Created New User"; }
    else if(i_adminActionCode === 1101) { actionDescription = "Admin Deleted User"; }
    else if(i_adminActionCode === 1102) { actionDescription = "Admin Deactivated User"; }
    else if(i_adminActionCode === 1103) { actionDescription = "Admin Reactivated User"; }
    else { validActionCodeTF = false; }

    const categoryCode = Math.floor(i_adminActionCode / 100);
    var categoryDescription = "--No Action Category--";
    if(categoryCode === 1) { categoryDescription = "Deleted Captures"; }
    else if(categoryCode === 2) { categoryDescription = "Archived Captures"; }
    else if(categoryCode === 3) { categoryDescription = "Import/Update Captures from .csv"; }
    else if(categoryCode === 4) { categoryDescription = "Import Contacts from .csv"; }
    else if(categoryCode === 5) { categoryDescription = "Mass Edit Captures"; }
    else if(categoryCode === 10) { categoryDescription = "Company/Divisions"; }
    else if(categoryCode === 11) { categoryDescription = "Users"; }

    return({
      validActionCodeTF: validActionCodeTF,
      actionDescription: actionDescription,
      categoryCode: categoryCode,
      categoryDescription: categoryDescription
    });
  }

  get c_selectMultiAdminCategoryCodesFieldTypeObj() {
    const adminChangelogCategoryCodesArray = [1, 2, 3, 4, 5];
    var adminChangelogCategoryDescriptionsArray = [];
    for(let categoryCode of adminChangelogCategoryCodesArray) {
      var baseLevelActionCode = (categoryCode * 100);
      var expandedActionCodeObj = this.admin_changelog_compute_expanded_action_code_obj_from_action_code(baseLevelActionCode);
      adminChangelogCategoryDescriptionsArray.push(expandedActionCodeObj.categoryDescription);
    }

    const valueDisplayArraysObjAdminChangelogCategories = DatabaseMobx.create_value_display_arrays_obj_from_value_array_and_display_array("Admin Changelog Category", adminChangelogCategoryCodesArray, false, adminChangelogCategoryDescriptionsArray);
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjAdminChangelogCategories, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_filteredSortedExpandedAdminChangelogArrayOfObjs() {
    const o_adminChangelogFilterObj = this.o_adminChangelogFilterObj; //numItemsPerPage, currentPageNumber, adminActionCodeCategoryCodesComma, userID, dateMin, dateMax, sortColumnDbName, sortIsAscTF
    const c_expandedAdminChangelogArrayOfObjs = this.c_expandedAdminChangelogArrayOfObjs;
    
    const filterCategoryCodeIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_adminChangelogFilterObj.adminActionCodeCategoryCodesComma);
    const filterUserIsFilledOutTF = JSFUNC.select_int_is_filled_out_tf(o_adminChangelogFilterObj.userID);
    const filterDateMinIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_adminChangelogFilterObj.dateMin);
    const filterDateMaxIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_adminChangelogFilterObj.dateMax);

    const adminActionCodeCategoryCodesArray = JSFUNC.convert_comma_list_to_int_array(o_adminChangelogFilterObj.adminActionCodeCategoryCodesComma);

    //filter the changelog items
    var changelogFilteredSortedItemsArrayOfObjs = [];
    for(let expandedAdminChangelogObj of c_expandedAdminChangelogArrayOfObjs) {
      var includeChangelogItemTF = true;
      if(includeChangelogItemTF && filterCategoryCodeIsFilledOutTF && !JSFUNC.in_array(expandedAdminChangelogObj.categoryCode, adminActionCodeCategoryCodesArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterUserIsFilledOutTF && (expandedAdminChangelogObj.userID !== o_adminChangelogFilterObj.userID)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterDateMinIsFilledOutTF && (expandedAdminChangelogObj.dateLocalYmd < o_adminChangelogFilterObj.dateMin)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterDateMaxIsFilledOutTF && (expandedAdminChangelogObj.dateLocalYmd > o_adminChangelogFilterObj.dateMax)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF) {
        changelogFilteredSortedItemsArrayOfObjs.push(expandedAdminChangelogObj);
      }
    }

    //sort the changelog items
    var sortColumnDbNamesArray = [o_adminChangelogFilterObj.sortColumnDbName];
    var sortIsAscTFArray = [o_adminChangelogFilterObj.sortIsAscTF];
    if(sortColumnDbNamesArray !== "dateTimeUtc") { //if sorting by a column other than date
      sortColumnDbNamesArray.push("dateTimeUtc"); //do a secondary sort by date desc (so that sorting by field groups all fields together sorted by latest on top)
      sortIsAscTFArray.push(false);
      sortColumnDbNamesArray.push("id"); //tertiary sort on log id
      sortIsAscTFArray.push(false);
    }
    else { //primary sort is dateTimeUtc, add a secondary sort in the same direction for id
      sortColumnDbNamesArray.push("id");
      sortIsAscTFArray.push(o_adminChangelogFilterObj.sortIsAscTF);
    }
    JSFUNC.sort_arrayOfObjs(changelogFilteredSortedItemsArrayOfObjs, sortColumnDbNamesArray, sortIsAscTFArray);

    return(changelogFilteredSortedItemsArrayOfObjs);
  }

  get c_adminChangelogFilteredTotalNumItems() {
    return(this.c_filteredSortedExpandedAdminChangelogArrayOfObjs.length);
  }

  get c_adminChangelogCurrentPageFirstItemNumber() {
    const o_adminChangelogFilterObj = this.o_adminChangelogFilterObj;
    if(this.c_adminChangelogFilteredTotalNumItems === 0) {
      return(0);
    }
    return(((o_adminChangelogFilterObj.currentPageNumber - 1) * o_adminChangelogFilterObj.numItemsPerPage) + 1);
  }

  get c_adminChangelogCurrentPageLastItemNumber() {
    const o_adminChangelogFilterObj = this.o_adminChangelogFilterObj;
    var changelogCurrentPageLastItemNumber = (o_adminChangelogFilterObj.currentPageNumber * o_adminChangelogFilterObj.numItemsPerPage);
    if(this.c_adminChangelogFilteredTotalNumItems < changelogCurrentPageLastItemNumber) {
      changelogCurrentPageLastItemNumber = this.c_adminChangelogFilteredTotalNumItems;
    }
    return(changelogCurrentPageLastItemNumber);
  }

  get c_adminChangelogCanIncrementCurrentPageNumberTF() {
    return(this.c_adminChangelogCurrentPageLastItemNumber < this.c_adminChangelogFilteredTotalNumItems);
  }

  get c_adminChangelogCanDecrementCurrentPageNumberTF() {
    const o_adminChangelogFilterObj = this.o_adminChangelogFilterObj;
    return(o_adminChangelogFilterObj.currentPageNumber > 1);
  }

  get c_filteredSortedItemsOnSelectedPageExpandedAdminChangelogArrayOfObjs() {
    const c_filteredSortedExpandedAdminChangelogArrayOfObjs = this.c_filteredSortedExpandedAdminChangelogArrayOfObjs;
    const c_adminChangelogCurrentPageFirstItemNumber = this.c_adminChangelogCurrentPageFirstItemNumber;
    const c_adminChangelogCurrentPageLastItemNumber = this.c_adminChangelogCurrentPageLastItemNumber;

    var changelogFilteredSortedItemsOnSelectedPageArrayOfObjs = [];
    var itemNumber = 1;
    for(let expandedAdminChangelogObj of c_filteredSortedExpandedAdminChangelogArrayOfObjs) {
      if((itemNumber >= c_adminChangelogCurrentPageFirstItemNumber) && (itemNumber <= c_adminChangelogCurrentPageLastItemNumber)) {
        changelogFilteredSortedItemsOnSelectedPageArrayOfObjs.push(expandedAdminChangelogObj);
      }
      itemNumber++;
    }
    return(changelogFilteredSortedItemsOnSelectedPageArrayOfObjs);
  }










  //========================================================================================
  //action methods
  a_activity_set_show_tickets_user_ids_array(i_userIDsArray) {
    this.o_activityShowTicketsUserIDsArray = i_userIDsArray;
  }




  a_update_tbl_a_company_fields(i_fieldDbNameOrNamesArray, i_newValueOrValuesArray, i_idsbOrIdsbArray) {
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_update_tbl_a_company_fields", ["i_fieldDbNameOrNamesArray", "i_newValueOrValuesArray", "i_idsbOrIdsbArray"], [i_fieldDbNameOrNamesArray, i_newValueOrValuesArray, i_idsbOrIdsbArray]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_company", 1, i_fieldDbNameOrNamesArray, i_newValueOrValuesArray, i_idsbOrIdsbArray);
    C_CallPhpTblUID.execute();
  }




  //divisions
  a_divisions_update_company_prime_sb_certs_can_vary_per_division(i_newValue01) {
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_divisions_update_company_prime_sb_certs_can_vary_per_division", ["i_newValue01"], [i_newValue01]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_update("tbl_a_company", 1, "prime_sb_certs_can_vary_per_division_01", i_newValue01, "i");

    //if setting this option from "can't vary (0)" to "can vary (1)", update every division with the business type and sb certs values from the top 00 division
    if(i_newValue01 === 1) {
      const fieldNamesArray = ["business_type_id", "prime_sb_certifications_bm_set_aside_ids_comma"];
      const valuesArray = [DatabaseMobx.c_topDivision00BusinessTypeID, DatabaseMobx.c_topDivision00PrimeSBCertificationsBmSetAsideIDsComma];
      const idsbArray = ["i", "s"];
      for(let divisionMap of DatabaseMobx.o_tbl_a_divisions.values()) {
        var divisionID = divisionMap.get("id");
        if(divisionID !== DatabaseMobx.c_topDivision00ID) { //don't update the top level division because it already has the value being copied from
          C_CallPhpTblUID.add_update("tbl_a_divisions", divisionID, fieldNamesArray, valuesArray, idsbArray);
        }
      }
    }

    //always recalculate every teammate count for every capture using every our division when this switch is changed
    const functionOnSuccess = (i_parseResponse) => {
      JSPHP.recalculate_and_update_capture_teammate_counts_and_allocations_from_division_id_or_ids_array(DatabaseMobx.c_allDivisionIDsArray);
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }


  a_divisions_update_company_using_division_firewalling(i_newValue01) {
    const allDivisionsArrayOfObjs = DatabaseMobx.c_allDivisionsArrayOfObjs;

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_divisions_update_company_prime_sb_certs_can_vary_per_division", ["i_newValue01"], [i_newValue01]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_update("tbl_a_company", 1, "using_division_firewalling_01", i_newValue01, "i");

    //if turning on division firewalling, initialize all divisions with default values selected (for each division select every division within the 2nd level tree downwards)
    if(i_newValue01 === 1) {
      //loop over all divisions to find each level 2 division
      const includeInputParentIDInOutputArrayTF = true;
      for(let divisionObj of allDivisionsArrayOfObjs) {
        if(divisionObj.tree_id === "00") { //top level company
          C_CallPhpTblUID.add_update("tbl_a_divisions", divisionObj.id, "firewalled_division_ids_comma", "-1", "s");
        }
        else if(divisionObj.tree_id.length === 4) { //level 2
          var level2ParentAndAllChildrenDivisionIDsArray = JSFUNC.get_all_children_ids_array_from_parent_tree_id(allDivisionsArrayOfObjs, divisionObj.tree_id, includeInputParentIDInOutputArrayTF);
          var level2ParentAndAllChildrenDivisionIDsComma = JSFUNC.convert_array_to_comma_list(level2ParentAndAllChildrenDivisionIDsArray);
          for(let level2ParentAndAllChildrenDivisionID of level2ParentAndAllChildrenDivisionIDsArray) {
            C_CallPhpTblUID.add_update("tbl_a_divisions", level2ParentAndAllChildrenDivisionID, "firewalled_division_ids_comma", level2ParentAndAllChildrenDivisionIDsComma, "s");
          }
        }
      }
    }

    C_CallPhpTblUID.execute();
  }

  a_set_divisions_find_my_uei_loading_flag(i_newValue) {
    this.o_divisionsFindMyUeiLoadingFlag = i_newValue; //"init", "loading", "error", "results"
  }

  a_set_divisions_find_my_uei_previous_company_name_seach_term(i_newValue) {
    this.o_divisionsFindMyUeiPreviousCompanyNameSearchTerm = i_newValue;
  }

  a_set_divisions_find_my_uei_sam_uei_companies_arrayOfObjs(i_samUeiCompaniesArrayOfObjs) {
    this.o_divisionsFindMyUeiSamUeiCompaniesArrayOfObjs = i_samUeiCompaniesArrayOfObjs;
  }

  a_divisions_find_my_uei_search_sam_companies(i_companyNameSearchTerm) {
    this.a_set_divisions_find_my_uei_loading_flag("loading");
    this.a_set_divisions_find_my_uei_previous_company_name_seach_term(i_companyNameSearchTerm);
    this.a_set_divisions_find_my_uei_sam_uei_companies_arrayOfObjs([]);

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_divisions_find_my_uei_search_sam_companies", ["i_companyNameSearchTerm"], [i_companyNameSearchTerm]);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadCeCompressedSamUeiCompaniesMatchingCompanyNameSearchTerm", jsDescription);
    C_CallPhpScript.add_post_var("i_companyNameSearchTerm", i_companyNameSearchTerm);
    C_CallPhpScript.add_return_vars("ceCompressedSamUeiCompaniesMatrix");

    const functionOnSuccess = (i_parseResponse) => {
      const samUeiCompaniesArrayOfObjs = DatabaseMobx.uncompress_ce_compressed_data_table_arrayOfObjs(i_parseResponse.ceCompressedSamUeiCompaniesMatrix);
      this.a_set_divisions_find_my_uei_loading_flag("results");
      this.a_set_divisions_find_my_uei_sam_uei_companies_arrayOfObjs(samUeiCompaniesArrayOfObjs);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      this.a_set_divisions_find_my_uei_loading_flag("error");
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();
  }



  //users
  a_set_users_view_flag(i_newValue) {
    this.o_usersViewFlag = i_newValue;
  }




  //capture type
  a_set_selected_capture_type_id(i_newCaptureTypeID) {
    this.o_selectedCaptureTypeID = i_newCaptureTypeID;

    //set any parameters based on changing the selected capture type in a given admin subtab
    this.a_initialize_questions_pool_selected_receiving_stage();
  }

  a_ensure_selected_capture_type_exists_or_select_first_capture_type() {
    //check if the selected capture type exists, otherwise select the first capture type in sort order
    var selectedCaptureTypeID = this.o_selectedCaptureTypeID;
    if(!DatabaseMobx.o_tbl_a_capture_types.has(selectedCaptureTypeID)) {
      if(this.c_adminCaptureTypesWithMatchingCapturesArrayOfObjs.length === 0) {
        selectedCaptureTypeID = -1; //flag that there are 0 capture types available to choose from
      }
      else {
        selectedCaptureTypeID = this.c_adminCaptureTypesWithMatchingCapturesArrayOfObjs[0].id; //use the first capture type in sort order
      }
      this.a_set_selected_capture_type_id(selectedCaptureTypeID);
    }
  }

  a_capture_type_create_new_from_copy(i_captureTypeIDToCopyFrom) {
    const captureTypeMapToCopyFrom = DatabaseMobx.o_tbl_a_capture_types.get(i_captureTypeIDToCopyFrom);
    if(captureTypeMapToCopyFrom !== undefined) {
      const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_capture_type_create_new_from_copy", ["i_captureTypeIDToCopyFrom"], [i_captureTypeIDToCopyFrom]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //tbl_a_capture_types
      const captureTypeFieldNamesArray = ["name", "sort", "prime1_sub2", "card_ids_comma", "stage_ids_comma", "details_group_ids_comma", "details_snapshot_field_ids_comma", "budget_category_ids_comma"];
      const captureTypeIdsbArray = ["s", "i", "i", "s", "s", "s", "s", "s"];
      var captureTypeValuesArray = [];
      for(let f = 0; f < captureTypeFieldNamesArray.length; f++) {
        var captureTypeValue = captureTypeMapToCopyFrom.get(captureTypeFieldNamesArray[f]);
        if(captureTypeFieldNamesArray[f] === "name") { //modify the name from "Old Name" to "Copy of Old Name"
          captureTypeValue = "Copy of " + captureTypeValue;
        }
        else if(captureTypeFieldNamesArray[f] === "sort") { //modify the sort to be at the bottom of the list
          var maxCaptureTypeSort = JSFUNC.max_value_from_mapOfMaps_column(DatabaseMobx.o_tbl_a_capture_types, "sort");
          captureTypeValue = (maxCaptureTypeSort + 1); //add 1 to the max existing sort value to put this new entry at the bottom
        }
        captureTypeValuesArray.push(captureTypeValue);
      }
      C_CallPhpTblUID.add_insert("tbl_a_capture_types", captureTypeFieldNamesArray, captureTypeValuesArray, captureTypeIdsbArray);

      //create a function to be called after the new copied capture_type record is inserted so that the newly inserted id number is known
      const functionOnSuccess = (i_parseResponse) => {
        const captureTypeIDToDeleteAndCopyTo = i_parseResponse.outputObj.i0;

        const functionOnSuccessCopyQuestions = () => { //copy the question records 3rd in a separate call after the details fields
          this.a_capture_type_copy_capture_type_shaping_and_debrief_question_records(i_captureTypeIDToCopyFrom, captureTypeIDToDeleteAndCopyTo);
        }
        this.a_capture_type_copy_capture_type_details_dates_fields_records(i_captureTypeIDToCopyFrom, captureTypeIDToDeleteAndCopyTo, functionOnSuccessCopyQuestions); //copy the detais fields 2nd
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      C_CallPhpTblUID.execute();
    }
  }

  a_capture_type_copy_capture_type_details_dates_fields_records(i_captureTypeIDToCopyFrom, i_captureTypeIDToDeleteAndCopyTo, i_functionOnSuccess=undefined) {
    const captureTypeMapToCopyFrom = DatabaseMobx.o_tbl_a_capture_types.get(i_captureTypeIDToCopyFrom);
    const captureTypeMapToDeleteAndCopyTo = DatabaseMobx.o_tbl_a_capture_types.get(i_captureTypeIDToDeleteAndCopyTo);
    if(captureTypeMapToCopyFrom !== undefined && captureTypeMapToDeleteAndCopyTo !== undefined) { //make sure the copy from and copy to capture type records exist before proceeding
      const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_capture_type_copy_capture_type_details_dates_fields_records", ["i_captureTypeIDToCopyFrom", "i_captureTypeIDToDeleteAndCopyTo"], [i_captureTypeIDToCopyFrom, i_captureTypeIDToDeleteAndCopyTo]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //tbl_a_capture_types_details_fields
      var copyToDetailsFieldRecordIDsToDeleteArray = []; //record each existing record id to be deleted at the end
      const captureTypeDetailsFieldsFieldNamesArray = ["capture_type_id", "group_id", "field_id", "sort", "stage_id_relevant", "rec1_reqsig2_req3", "error_if_not_filled_out_01", "error_if_multiple_selected_01", "error_if_selected_not_allowed_ids_comma", "use_default_01", "default_value", "only_capture_managers_can_view_field_01"];
      const captureTypeDetailsFieldsIdsbArray = ["i", "i", "i", "i", "i", "i", "i", "i", "s", "i", "s", "i"];
      for(let captureTypeDetailsFieldMap of DatabaseMobx.o_tbl_a_capture_types_details_fields.values()) { //loop through all capture type shaping question records
        var captureTypeDetailsFieldRecordCaptureTypeID = captureTypeDetailsFieldMap.get("capture_type_id");
        if(captureTypeDetailsFieldRecordCaptureTypeID === i_captureTypeIDToDeleteAndCopyTo) { //if there are existing records for the capture type id to copy to, delete them all
          copyToDetailsFieldRecordIDsToDeleteArray.push(captureTypeDetailsFieldMap.get("id"));
        }
        else if(captureTypeDetailsFieldRecordCaptureTypeID === i_captureTypeIDToCopyFrom) { //for each copy from capture type id record, create a new inserted copy record with the copy to capture type id
          var captureTypeDetailsFieldsValuesArray = [i_captureTypeIDToDeleteAndCopyTo]; //start with the capture_type_id value already filled in
          for(let f = 1; f < captureTypeDetailsFieldsFieldNamesArray.length; f++) { //start at 1, skipping the capture_type_id column which has already been initialized in the array
            var captureTypeDetailsFieldsValue = captureTypeDetailsFieldMap.get(captureTypeDetailsFieldsFieldNamesArray[f]);
            captureTypeDetailsFieldsValuesArray.push(captureTypeDetailsFieldsValue);
          }
          C_CallPhpTblUID.add_insert("tbl_a_capture_types_details_fields", captureTypeDetailsFieldsFieldNamesArray, captureTypeDetailsFieldsValuesArray, captureTypeDetailsFieldsIdsbArray);
        }
      }

      //if there are existing records for the captureTypeID to copy to, delete them all
      if(copyToDetailsFieldRecordIDsToDeleteArray.length > 0) {
        C_CallPhpTblUID.add_delete("tbl_a_capture_types_details_fields", copyToDetailsFieldRecordIDsToDeleteArray); //deletes every record in this captureTypeID, no need to resort
      }

      //if an onSuccess function was provided, call it when this call has finished
      if(JSFUNC.is_function(i_functionOnSuccess)) {
        C_CallPhpTblUID.add_function("onSuccess", i_functionOnSuccess);
      }

      C_CallPhpTblUID.execute();
    }
  }

  a_capture_type_copy_capture_type_shaping_and_debrief_question_records(i_captureTypeIDToCopyFrom, i_captureTypeIDToDeleteAndCopyTo) {
    const captureTypeMapToCopyFrom = DatabaseMobx.o_tbl_a_capture_types.get(i_captureTypeIDToCopyFrom);
    const captureTypeMapToDeleteAndCopyTo = DatabaseMobx.o_tbl_a_capture_types.get(i_captureTypeIDToDeleteAndCopyTo);
    if(captureTypeMapToCopyFrom !== undefined && captureTypeMapToDeleteAndCopyTo !== undefined) { //make sure the copy from and copy to capture type records exist before proceeding
      const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_capture_type_copy_capture_type_shaping_and_debrief_question_records", ["i_captureTypeIDToCopyFrom", "i_captureTypeIDToDeleteAndCopyTo"], [i_captureTypeIDToCopyFrom, i_captureTypeIDToDeleteAndCopyTo]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //tbl_a_capture_types_shaping_questions
      var copyToShapingRecordIDsToDeleteArray = []; //record each existing record id to be deleted at the end
      const captureTypeShapingQuestionsFieldNamesArray = ["capture_type_id", "question_id", "sort", "progress_weight", "pwin_weight", "stage_id_relevant", "rec1_reqsig2_req3"];
      const captureTypeShapingQuestionsIdsbArray = ["i", "i", "i", "i", "i", "i", "i"];
      for(let captureTypeShapingQuestionMap of DatabaseMobx.o_tbl_a_capture_types_shaping_questions.values()) { //loop through all capture type shaping question records
        var captureTypeShapingQuestionRecordCaptureTypeID = captureTypeShapingQuestionMap.get("capture_type_id");
        if(captureTypeShapingQuestionRecordCaptureTypeID === i_captureTypeIDToDeleteAndCopyTo) { //if there are existing records for the capture type id to copy to, delete them all
          copyToShapingRecordIDsToDeleteArray.push(captureTypeShapingQuestionMap.get("id"));
        }
        else if(captureTypeShapingQuestionRecordCaptureTypeID === i_captureTypeIDToCopyFrom) { //for each copy from capture type id record, create a new inserted copy record with the copy to capture type id
          var captureTypeShapingQuestionsValuesArray = [i_captureTypeIDToDeleteAndCopyTo]; //start with the capture_type_id value already filled in
          for(let f = 1; f < captureTypeShapingQuestionsFieldNamesArray.length; f++) { //start at 1, skipping the capture_type_id column which has already been initialized in the array
            var captureTypeShapingQuestionsValue = captureTypeShapingQuestionMap.get(captureTypeShapingQuestionsFieldNamesArray[f]);
            captureTypeShapingQuestionsValuesArray.push(captureTypeShapingQuestionsValue);
          }
          C_CallPhpTblUID.add_insert("tbl_a_capture_types_shaping_questions", captureTypeShapingQuestionsFieldNamesArray, captureTypeShapingQuestionsValuesArray, captureTypeShapingQuestionsIdsbArray);
        }
      }

      //if there are existing records for the capture type id to copy to, delete them all
      if(copyToShapingRecordIDsToDeleteArray.length > 0) {
        C_CallPhpTblUID.add_delete("tbl_a_capture_types_shaping_questions", copyToShapingRecordIDsToDeleteArray); //deletes every record in this captureTypeID, no need to resort
      }

      //tbl_a_capture_types_debrief_questions
      var copyToDebriefRecordIDsToDeleteArray = []; //record each existing record id to be deleted at the end
      const captureTypeDebriefQuestionsFieldNamesArray = ["capture_type_id", "question_id", "sort", "closed_stage_id"];
      const captureTypeDebriefQuestionsIdsbArray = ["i", "i", "i", "i"];
      for(let captureTypeDebriefQuestionMap of DatabaseMobx.o_tbl_a_capture_types_debrief_questions.values()) { //loop through all capture type shaping question records
        var captureTypeDebriefQuestionRecordCaptureTypeID = captureTypeDebriefQuestionMap.get("capture_type_id");
        if(captureTypeDebriefQuestionRecordCaptureTypeID === i_captureTypeIDToDeleteAndCopyTo) { //if there are existing records for the capture type id to copy to, delete them all
          copyToDebriefRecordIDsToDeleteArray.push(captureTypeDebriefQuestionMap.get("id"));
        }
        else if(captureTypeDebriefQuestionRecordCaptureTypeID === i_captureTypeIDToCopyFrom) { //for each copy from capture type id record, create a new inserted copy record with the copy to capture type id
          var captureTypeDebriefQuestionsValuesArray = [i_captureTypeIDToDeleteAndCopyTo]; //start with the capture_type_id value already filled in
          for(let f = 1; f < captureTypeDebriefQuestionsFieldNamesArray.length; f++) { //start at 1, skipping the capture_type_id column which has already been initialized in the array
            var captureTypeDebriefQuestionsValue = captureTypeDebriefQuestionMap.get(captureTypeDebriefQuestionsFieldNamesArray[f]);
            captureTypeDebriefQuestionsValuesArray.push(captureTypeDebriefQuestionsValue);
          }
          C_CallPhpTblUID.add_insert("tbl_a_capture_types_debrief_questions", captureTypeDebriefQuestionsFieldNamesArray, captureTypeDebriefQuestionsValuesArray, captureTypeDebriefQuestionsIdsbArray);
        }
      }

      //if there are existing records for the capture type id to copy to, delete them all
      if(copyToDebriefRecordIDsToDeleteArray.length > 0) {
        C_CallPhpTblUID.add_delete("tbl_a_capture_types_debrief_questions", copyToDebriefRecordIDsToDeleteArray); //deletes every record in this captureTypeID, no need to resort
      }

      C_CallPhpTblUID.execute();
    }
  }




  //details/dates fields
  a_fields_set_selected_tab_snapshot_groups_dates(i_newValue) {
    this.o_fieldsSelectedTabSnapshotGroupsDates = i_newValue;
  }

  a_fields_set_search_filter_text(i_newValue) {
    this.o_fieldsSearchFilterText = i_newValue;
  }

  a_fields_set_receiving_details_card_group_id(i_fieldsSelectedReceivingDetailsGroupID) {
    this.o_fieldsSelectedReceivingDetailsCardGroupID = i_fieldsSelectedReceivingDetailsGroupID;
  }

  a_fields_initialize_selected_receiving_details_card_group() { //intialize the selected receiving details card group to be the first group
    this.a_fields_set_receiving_details_card_group_id((this.c_selectedCaptureTypeDetailsCardGroupIDsArray.length > 0) ? (this.c_selectedCaptureTypeDetailsCardGroupIDsArray[0]) : (-1));
  }

  a_fields_add_field_to_receiving_details_snapshot(i_appendFieldID) {
    const selectedCaptureTypeObj = this.c_selectedCaptureTypeObj;
    const newSnapshotFieldIDsComma = JSFUNC.add_value_to_comma_list(i_appendFieldID, selectedCaptureTypeObj.details_snapshot_field_ids_comma);

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_fields_add_field_to_receiving_details_snapshot", ["i_appendFieldID"], [i_appendFieldID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types", selectedCaptureTypeObj.id, "details_snapshot_field_ids_comma", newSnapshotFieldIDsComma, "s");
    C_CallPhpTblUID.execute();
  }

  a_fields_remove_field_from_details_snapshot(i_removeFieldID) {
    const selectedCaptureTypeObj = this.c_selectedCaptureTypeObj;
    const newSnapshotFieldIDsComma = JSFUNC.remove_all_values_from_comma_list(i_removeFieldID, selectedCaptureTypeObj.details_snapshot_field_ids_comma);

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_fields_remove_field_from_details_snapshot", ["i_removeFieldID"], [i_removeFieldID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types", selectedCaptureTypeObj.id, "details_snapshot_field_ids_comma", newSnapshotFieldIDsComma, "s");
    C_CallPhpTblUID.execute();
  }

  a_fields_add_field_to_receiving_details_card_group(i_groupID, i_fieldID) {
    const selectedCaptureTypeObj = this.c_selectedCaptureTypeObj;
    const allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs = this.c_allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs;

    //sort new field at the end to be resorted by the insert operation
    const newSort = JSFUNC.sort_max_mysqli_int();

    //default values for all fields (not captureTypeID, groupID, fieldID, or sort as they are filtering fields for this tbl)
    var newStageIDRelevant = -1;
    var newRec1Req3 = 1;
    var newErrorIfNotFilledOut01 = 0;
    var newErrorIfMultipleSelected01 = 0;
    var newErrorIfSelectedNotAllowedIDsComma = "";
    var newUseDefault01 = 0;
    var newDefaultValue = "";
    var newOnlyCaptureManagersCanViewField01 = 0;

    //if the field has already been added to the details/dates/revenue card, copy those required/error/default/privacy settings for this new insert
    for(let allFieldsCaptureTypeDetailsFieldObj of allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs) {
      if(allFieldsCaptureTypeDetailsFieldObj.field_id === i_fieldID) {
        newStageIDRelevant = allFieldsCaptureTypeDetailsFieldObj.stage_id_relevant;
        newRec1Req3 = allFieldsCaptureTypeDetailsFieldObj.rec1_reqsig2_req3;
        newErrorIfNotFilledOut01 = allFieldsCaptureTypeDetailsFieldObj.error_if_not_filled_out_01;
        newErrorIfMultipleSelected01 = allFieldsCaptureTypeDetailsFieldObj.error_if_multiple_selected_01;
        newErrorIfSelectedNotAllowedIDsComma = allFieldsCaptureTypeDetailsFieldObj.error_if_selected_not_allowed_ids_comma;
        newUseDefault01 = allFieldsCaptureTypeDetailsFieldObj.use_default_01;
        newDefaultValue = allFieldsCaptureTypeDetailsFieldObj.default_value;
        newOnlyCaptureManagersCanViewField01 = allFieldsCaptureTypeDetailsFieldObj.only_capture_managers_can_view_field_01;
        break;
      }
    }

    //perform insert of new capture type question record
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_fields_add_field_to_receiving_details_card_group", ["i_groupID", "i_fieldID"], [i_groupID, i_fieldID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["capture_type_id", "group_id", "field_id", "sort", "stage_id_relevant", "rec1_reqsig2_req3", "error_if_not_filled_out_01", "error_if_multiple_selected_01", "error_if_selected_not_allowed_ids_comma", "use_default_01", "default_value", "only_capture_managers_can_view_field_01"];
    const valuesArray = [selectedCaptureTypeObj.id, i_groupID, i_fieldID, newSort, newStageIDRelevant, newRec1Req3, newErrorIfNotFilledOut01, newErrorIfMultipleSelected01, newErrorIfSelectedNotAllowedIDsComma, newUseDefault01, newDefaultValue, newOnlyCaptureManagersCanViewField01];
    const idsbArray = ["i", "i", "i", "i", "i", "i", "i", "i", "s", "i", "s", "i"];
    const resortSortColumnName = "sort";
    const resortFilterFieldNameOrFieldNamesArray = ["capture_type_id", "group_id"];
    const resortFilterValueOrValuesArray = [selectedCaptureTypeObj.id, i_groupID];
    C_CallPhpTblUID.add_insert("tbl_a_capture_types_details_fields", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);

    C_CallPhpTblUID.execute();
  }

  a_fields_remove_field_from_details_card_group(i_captureTypeDetailsFieldRowID, i_captureTypeDetailsFieldCaptureTypeID, i_captureTypeDetailsFieldGroupID) {
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_fields_remove_field_from_details_card_group", ["i_captureTypeDetailsFieldRowID", "i_captureTypeDetailsFieldCaptureTypeID", "i_captureTypeDetailsFieldGroupID"], [i_captureTypeDetailsFieldRowID, i_captureTypeDetailsFieldCaptureTypeID, i_captureTypeDetailsFieldGroupID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_capture_types_details_fields", i_captureTypeDetailsFieldRowID, "sort", ["capture_type_id", "group_id"], [i_captureTypeDetailsFieldCaptureTypeID, i_captureTypeDetailsFieldGroupID]);
    C_CallPhpTblUID.execute();
  }



  //details fields options
  a_set_details_fields_options_selected_subtab(i_newValueString) {
    this.o_detailsFieldsOptionsSelectedSubtab = i_newValueString;
  }



  //deal shaping questions
  a_set_receiving_item_collection_id(i_questionsSelectedReceivingStageID) {
    this.o_questionsSelectedReceivingStageID = i_questionsSelectedReceivingStageID;
  }

  a_initialize_questions_pool_selected_receiving_stage() {
    var initialReceivingItemCollectionID = -1; //intialize the selected receiving stage to be the first active stage
    if(this.c_selectedCaptureTypeQuestionsPerStageObj.activeStagesArrayOfObjs.length > 0) {
      initialReceivingItemCollectionID = this.c_selectedCaptureTypeQuestionsPerStageObj.activeStagesArrayOfObjs[0].stageObj.id;
    }
    this.a_set_receiving_item_collection_id(initialReceivingItemCollectionID);
  }

  a_add_pool_question_to_receiving_stage(i_questionID, i_receivingStageID) {
    //verify that this stageID is one of the stages in this capture type timeline, if not, do not execute the insert of the new capture type question record
    const selectedCaptureTypeID = this.c_selectedCaptureTypeObj.id;
    if(DatabaseMobx.o_tbl_a_capture_types.has(selectedCaptureTypeID) && (i_receivingStageID === -1 || JSFUNC.in_array(i_receivingStageID, this.c_selectedCaptureTypeStageIDsArray))) {
      //get active/closed info about stage this is being added to
      const receivingStageMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_stages_pool", i_receivingStageID);
      const receivingStageIsActiveTF = (!receivingStageMap.get("isClosedStageTF"));

      //perform insert of new capture type question record
      const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_add_pool_question_to_receiving_stage", ["i_questionID", "i_receivingStageID"], [i_questionID, i_receivingStageID]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      var newSort = JSFUNC.sort_max_mysqli_int();
      if(receivingStageIsActiveTF) {
        const fieldNamesArray = ["capture_type_id", "question_id", "sort", "progress_weight", "pwin_weight", "stage_id_relevant", "rec1_reqsig2_req3"];
        const valuesArray = [selectedCaptureTypeID, i_questionID, newSort, 5, 0, i_receivingStageID, 1];
        const idsbArray = ["i", "i", "i", "i", "i", "i", "i"];
        const resortSortColumnName = "sort";
        const resortFilterFieldNameOrFieldNamesArray = ["capture_type_id", "stage_id_relevant"];
        const resortFilterValueOrValuesArray = [selectedCaptureTypeID, i_receivingStageID];
        C_CallPhpTblUID.add_insert("tbl_a_capture_types_shaping_questions", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
      }
      else { //debrief question added to closed stage
        const fieldNamesArray = ["capture_type_id", "question_id", "sort", "closed_stage_id"];
        const valuesArray = [selectedCaptureTypeID, i_questionID, newSort, i_receivingStageID];
        const idsbArray = ["i", "i", "i", "i"];
        const resortSortColumnName = "sort";
        const resortFilterFieldNameOrFieldNamesArray = ["capture_type_id", "closed_stage_id"];
        const resortFilterValueOrValuesArray = [selectedCaptureTypeID, i_receivingStageID];
        C_CallPhpTblUID.add_insert("tbl_a_capture_types_debrief_questions", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
      }

      C_CallPhpTblUID.execute();
    }
  }

  a_remove_question_from_capture_type_stage(i_activeTrueClosedDebriefFalse, i_captureTypeQuestionRecordIDToRemove, i_captureTypeQuestionCaptureTypeID, i_captureTypeQuestionStageID) {
    var tblName = "";
    var resortSortColumnName = "sort";
    var resortFilterFieldNameOrFieldNamesArray = [];
    var resortFilterValueOrValuesArray = [];
    if(i_activeTrueClosedDebriefFalse) { //active stage question
      tblName = "tbl_a_capture_types_shaping_questions";
      resortFilterFieldNameOrFieldNamesArray = ["capture_type_id", "stage_id_relevant"];
      resortFilterValueOrValuesArray = [i_captureTypeQuestionCaptureTypeID, i_captureTypeQuestionStageID];
    }
    else { //debrief question added to closed stage
      tblName = "tbl_a_capture_types_debrief_questions";
      resortFilterFieldNameOrFieldNamesArray = ["capture_type_id", "closed_stage_id"];
      resortFilterValueOrValuesArray = [i_captureTypeQuestionCaptureTypeID, i_captureTypeQuestionStageID];
    }

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_remove_question_from_capture_type_stage", ["i_activeTrueClosedDebriefFalse", "i_captureTypeQuestionRecordIDToRemove", "i_captureTypeQuestionCaptureTypeID", "i_captureTypeQuestionStageID"], [i_activeTrueClosedDebriefFalse, i_captureTypeQuestionRecordIDToRemove, i_captureTypeQuestionCaptureTypeID, i_captureTypeQuestionStageID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete(tblName, i_captureTypeQuestionRecordIDToRemove, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
    C_CallPhpTblUID.execute();
  }


  //teammates
  a_teammates_ratings_recompute_all_contact_teammate_ratings(i_functionOnSuccess, i_functionOnError) {
    const teammatesRatingsQuestionsArrayOfObjs = DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_teammates_ratings_recompute_all_contact_teammate_ratings", [], []);

    var columnDbNamesToLoadArray = ["id", "contact_company_id"]; //also include contact_company_id in the returned columns so the data can be split per contact company for individual calculation for every contact
    for(let teammatesRatingsQuestionObj of teammatesRatingsQuestionsArrayOfObjs) {
      columnDbNamesToLoadArray.push("q" + teammatesRatingsQuestionObj.id + "a");
    }
    const columnDbNamesToLoadComma = JSFUNC.convert_array_to_comma_list(columnDbNamesToLoadArray);

    const functionOnSuccess = (i_parseResponse) => {
      const allTrqsArrayOfObjs = i_parseResponse.teammatesRatingsQuestionnaireSubmissionsMatrix;

      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //loop over every contact company in the system and recompute each one
      for(let contactCompanyMap of DatabaseMobx.o_tbl_g_contacts_companies.values()) {
        var contactCompanyID = contactCompanyMap.get("id");

        var contactCompanyTrqsArrayOfObjs = JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(allTrqsArrayOfObjs, "contact_company_id", contactCompanyID);
        var cctrObj = OpenCaptureMobx.a_teammates_ratings_compute_contact_company_teammate_ratings_from_filtered_questionnaire_submissions_arrayOfObjs(contactCompanyTrqsArrayOfObjs);
        var numContactCompanyRatingFields = cctrObj.contactCompanyFieldNamesArray.length;

        //check if any of the recomputed values for the contact company have changed from their original values currently in the database
        var allContactCompanyRatingValuesMatchTF = true;
        for(let f = 0; f < numContactCompanyRatingFields; f++) {
          var fieldName = cctrObj.contactCompanyFieldNamesArray[f];
          var value = cctrObj.contactCompanyValuesArray[f];
          if(contactCompanyMap.get(fieldName) !== value) {
            allContactCompanyRatingValuesMatchTF = false;
            break; //can break loop early if any of the field values do not match
          }
        }

        if(!allContactCompanyRatingValuesMatchTF) {
          C_CallPhpTblUID.add_update("tbl_g_contacts_companies", contactCompanyID, cctrObj.contactCompanyFieldNamesArray, cctrObj.contactCompanyValuesArray, cctrObj.contactCompanyIdsbArray);
        }
      }

      if(JSFUNC.is_function(i_functionOnSuccess)) {
        C_CallPhpTblUID.add_function("onSuccess", i_functionOnSuccess);
      }

      if(JSFUNC.is_function(i_functionOnError)) {
        C_CallPhpTblUID.add_function("onError", i_functionOnError);
      }

      C_CallPhpTblUID.execute();
    }

    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadTeammateRatingsFromContactCompanyID", jsDescription);
    C_CallPhpScript.add_post_var("i_contactCompanyID", -1); //-1 flag to load all rows from tbl_c_teammates_ratings_questionnaire_submissions
    C_CallPhpScript.add_post_var("i_columnDbNamesToLoadComma", columnDbNamesToLoadComma);
    C_CallPhpScript.add_return_vars("teammatesRatingsQuestionnaireSubmissionsMatrix");
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
    if(JSFUNC.is_function(i_functionOnError)) {
      C_CallPhpScript.add_function("onError", i_functionOnError);
    }
    C_CallPhpScript.execute();
  }


  //teammate contracts
  a_teammate_contracts_load_all_contracts_from_database(i_functionOnSuccess=undefined, i_functionOnError=undefined) {
    const functionOnSuccess = (i_parseResponse) => {
      this.a_teammate_contracts_set_all_teammate_contracts_arrayOfObjs(i_parseResponse.teammateContractsDataMatrix);
      if(i_functionOnSuccess !== undefined) {
        i_functionOnSuccess(i_parseResponse);
      }
    }

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_teammate_contracts_load_all_contracts_from_database", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadAllTeammateContracts", jsDescription);
    C_CallPhpScript.add_return_vars("teammateContractsDataMatrix");
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
    if(i_functionOnError !== undefined) {
      C_CallPhpScript.add_function("onError", i_functionOnError);
    }
    C_CallPhpScript.execute();
  }

  a_teammate_contracts_set_all_teammate_contracts_arrayOfObjs(i_allTeammateContractsArrayOfObjs) {
    this.o_allTeammateContractsArrayOfObjs = i_allTeammateContractsArrayOfObjs;
  }



  //automated exports
  a_automated_exports_insert_new_job(i_jobName, i_recurrenceType123, i_recurrenceCount, i_runTimeHourInt0to23, i_captureTableViewButtonID, i_functionOnFinish=undefined) {
    const o_userID = UserMobx.o_userID;

    const viewButtonCapturesObj = this.compute_capture_ids_and_field_db_names_from_view_button_id(i_captureTableViewButtonID);

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_automated_exports_insert_new_job", ["i_jobName", "i_recurrenceType123", "i_recurrenceCount", "i_runTimeHourInt0to23", "i_captureTableViewButtonID", "i_functionOnFinish"], [i_jobName, i_recurrenceType123, i_recurrenceCount, i_runTimeHourInt0to23, i_captureTableViewButtonID, i_functionOnFinish]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["off0_on1", "job_name", "recurrence_type_123", "recurrence_count", "run_time_hour_0to23", "capture_table_view_button_preset_id", "last_run_datetime_est", "created_by_user_id", "date_created", "view_button_sorted_filtered_capture_ids_comma", "view_button_sorted_capture_field_db_names_comma"];
    const valuesArray = [1, i_jobName, i_recurrenceType123, i_recurrenceCount, i_runTimeHourInt0to23, i_captureTableViewButtonID, JSFUNC.blank_datetime(), o_userID, JSFUNC.now_date(), viewButtonCapturesObj.sortedFilteredCaptureIDsComma, viewButtonCapturesObj.sortedCaptureFieldDbNamesComma];
    const idsbArray = ["i", "s", "i", "i", "i", "i", "s", "i", "s", "s", "s"];
    C_CallPhpTblUID.add_insert("tbl_a_automated_exports", fieldNamesArray, valuesArray, idsbArray);

    if(JSFUNC.is_function(i_functionOnFinish)) {
      C_CallPhpTblUID.add_function("onSuccess", i_functionOnFinish);
    }

    C_CallPhpTblUID.execute();
  }

  a_automated_exports_update_single_field(i_tblRowID, i_fieldDbName, i_newValue, i_idsb) {
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_automated_exports_update_single_field", ["i_tblRowID", "i_fieldDbName", "i_newValue", "i_idsb"], [i_tblRowID, i_fieldDbName, i_newValue, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_automated_exports", i_tblRowID, i_fieldDbName, i_newValue, i_idsb);
    C_CallPhpTblUID.execute();
  }

  a_automated_exports_delete_job_and_server_files(i_automatedExportObj) {
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_automated_exports_delete_job_and_server_files", ["i_automatedExportObj"], [i_automatedExportObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_automated_exports", i_automatedExportObj.id);
    C_CallPhpTblUID.execute();
  }

  compute_capture_ids_and_field_db_names_from_view_button_id(i_captureTableViewButtonID) {
    var sortedFilteredCaptureIDsComma = "";
    var sortedCaptureFieldDbNamesComma = "";
    
    return({
      sortedFilteredCaptureIDsComma: sortedFilteredCaptureIDsComma,
      sortedCaptureFieldDbNamesComma: sortedCaptureFieldDbNamesComma
    })
  }




  //contacts fields
  a_admin_contacts_reassign(i_isPersonTF, i_contact1ID, i_contact2ID, i_functionOnSuccess, i_functionOnError) {
    const isPerson01 = ((i_isPersonTF) ? (1) : (0));
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_admin_contacts_reassign", ["i_isPersonTF", "i_contact1ID", "i_contact2ID"], [i_isPersonTF, i_contact1ID, i_contact2ID]);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("reassignContactAssignments", jsDescription);
    C_CallPhpScript.add_post_var("i_isPerson01", isPerson01);
    C_CallPhpScript.add_post_var("i_oldContactID", i_contact1ID);
    C_CallPhpScript.add_post_var("i_newContactID", i_contact2ID);
    C_CallPhpScript.add_return_vars("success01String");
    if(i_functionOnSuccess !== undefined) {
      C_CallPhpScript.add_function("onSuccess", i_functionOnSuccess);
    }
    if(i_functionOnError !== undefined) {
      C_CallPhpScript.add_function("onError", i_functionOnError);
    }
    C_CallPhpScript.execute();
  }



  
  //company communcations banners/broadcast
  a_set_company_communications_selected_tab_db_name(i_selectedTabDbName) {
    this.o_companyCommunicationsSelectedTabDbName = i_selectedTabDbName;
  }




  //shortcut presets
  a_shortcut_presets_set_selected_tab_db_name(i_selectedTabDbName) {
    this.o_shortcutPresetsSelectedTabDbName = i_selectedTabDbName;
  }

  a_shortcut_presets_documents_create_new_preset(i_newPresetName) {
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_shortcut_presets_documents_create_new_preset", ["i_newPresetName"], [i_newPresetName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["sort", "preset_name"];
    const valuesArray = [JSFUNC.sort_max_mysqli_int(), i_newPresetName];
    const idsbArray = ["i", "s"];
    const resortSortColumnName = "sort";
    const resortFilterFieldNameOrFieldNamesArray = [];
    const resortFilterValueOrValuesArray = [];
    C_CallPhpTblUID.add_insert("tbl_a_shortcut_presets_documents_card_folders", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedPresetID = JSFUNC.str2int(i_parseResponse.outputObj.i0);
      if(newlyInsertedPresetID > 0) {
        this.a_shortcut_presets_set_documents_card_folders_editing_preset_id_or_undefined(newlyInsertedPresetID);
      }
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  a_shortcut_presets_set_documents_card_folders_editing_preset_id_or_undefined(i_selectedDocumentsCardFoldersPresetIDOrUndefined) {
    this.o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined = i_selectedDocumentsCardFoldersPresetIDOrUndefined;
  }

  a_shortcut_presets_rename_documents_card_folders_preset(i_documentsCardFoldersPresetID, i_newPresetNameString) {
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_shortcut_presets_rename_documents_card_folders_preset", ["i_documentsCardFoldersPresetID", "i_newPresetNameString"], [i_documentsCardFoldersPresetID, i_newPresetNameString]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_shortcut_presets_documents_card_folders", i_documentsCardFoldersPresetID, "preset_name", i_newPresetNameString, "s");
    C_CallPhpTblUID.execute();
  }

  a_shortcut_presets_delete_documents_card_folders_preset(i_documentsCardFoldersPresetObj) {
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_shortcut_presets_delete_documents_card_folders_preset", ["i_documentsCardFoldersPresetObj"], [i_documentsCardFoldersPresetObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    if(JSFUNC.is_array(i_documentsCardFoldersPresetObj.presetFolderIDsArray)) {
      C_CallPhpTblUID.add_delete("tbl_a_shortcut_presets_documents_card_folders_filefoldersystem", i_documentsCardFoldersPresetObj.presetFolderIDsArray);
    }
    
    const resortSortColumnName = "sort";
    const resortFilterFieldNameOrFieldNamesArray = [];
    const resortFilterValueOrValuesArray = [];
    C_CallPhpTblUID.add_delete("tbl_a_shortcut_presets_documents_card_folders", i_documentsCardFoldersPresetObj.id, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
    
    C_CallPhpTblUID.execute();
  }




  //capture archiving
  a_set_capture_archiving_search_text(i_newValueString) {
    this.o_captureArchivingSearchText = i_newValueString;
  }

  a_capture_archving_set_loaded_slim_captures_arrayOfObjs(i_arrayOfObjs) {
    this.o_captureArchivingLoadedSlimCapturesArrayOfObjs = i_arrayOfObjs;
  }



  //admin changelog
  a_admin_changelog_set_loading_data_or_error_undefined_tfu(i_newValueTFU) {
    this.o_adminChangelogLoadingDataOrErrorUndefinedTFU = i_newValueTFU;
  }

  a_load_admin_changelog_data() {
    const c_adminChangelogTblNamesToLoadArray = DatabaseMobx.c_adminChangelogTblNamesToLoadArray;

    this.a_admin_changelog_set_loading_data_or_error_undefined_tfu(true);

    const functionOnFinish = (i_successTrueErrorFalse) => {
      const updatedLoadingDataOrErrorUndefinedTFU = ((i_successTrueErrorFalse) ? (false) : (undefined));
      this.a_admin_changelog_set_loading_data_or_error_undefined_tfu(updatedLoadingDataOrErrorUndefinedTFU);
    }
    JSPHP.php_db_api_load_multiple_full_database_tbls_from_tbl_names_array_and_uncompress_and_update_local_memory_maps(c_adminChangelogTblNamesToLoadArray, functionOnFinish);
  }

  a_set_admin_changelog_filter_field(i_adminChangelogFilterField, i_newValue) {
    //numItemsPerPage, currentPageNumber, adminActionCodeCategoryCodesComma, userID, dateMin, dateMax, sortColumnDbName, sortIsAscTF
    this.o_adminChangelogFilterObj[i_adminChangelogFilterField] = i_newValue;
  }





  //my account
  a_my_account_load_stripe_data() {
    const c_bitSGTStripeCustomerID = DatabaseMobx.c_bitSGTStripeCustomerID;

    this.o_myAccountStripeDataLoadingTF = true;

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx", "a_my_account_load_stripe_data", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("sgt_stripe_api", jsDescription);
    C_CallPhpScript.add_post_var("i_stripeApi", "fetchCustomerSubscriptionInvoiceDataFromStripeCustomerID");
    C_CallPhpScript.add_post_var("i_stripeCustomerID", c_bitSGTStripeCustomerID);
    C_CallPhpScript.add_return_vars("stripeCustomerDataObj");

    const functionOnSuccess = (i_parseResponse) => {
      DatabaseMobx.a_set_stripe_account_obj_or_undefined(i_parseResponse.stripeCustomerDataObj);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnFinish = () => {
      this.o_myAccountStripeDataLoadingTF = false;
    }
    C_CallPhpScript.add_function("onFinish", functionOnFinish);

    C_CallPhpScript.execute();
  }

}
export default new AdminMobx();
