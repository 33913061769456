import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import * as JSPHP from '../../CaptureExecLocalDatabaseMobx/JSPHP.js';

import AdminIntegrationsMobx from '../AdminIntegrations/AdminIntegrationsMobx.js';
import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import ContactsMobx from '../Contacts/ContactsMobx.js';

class AdminImportMobx {
  //capture import
  o_importFlag = "importCapturesUploadCsv"; //"importCapturesUploadCsv", "updateCapturesUploadCsv", "importContactCompaniesUploadCsv", "importContactPersonsUploadCsv", "capturesAnalysisTranslationAndImportOrUpdate", "contactsAnalysisAndImport"
  o_importCapturesImportTrueUpdateFalse = true;
  o_importContactsIsPersonTF = false;
  o_importUploadedCsvFileName = undefined;
  o_importUploadedCsvFileDataString = undefined;
  o_importUploadedCsvFileHasFirstHeaderRowTF = true;
  o_importLoadSavePresetFlag = "init"; //"init", "loadingDbTbls", "load", "save", "saving", "savingComplete", "savingError"
  o_importSelectedImportPresetID = -1;
  o_importSavingInsertedNewPresetOrDeletedOldPresetSettingsTF = false;
  o_importSavingNumTotalAutoAssigns = 0;
  o_importSavingNumCompletedAutoAssigns = 0;
  o_importSavingNumTotalColumnAssigns = 0;
  o_importSavingNumCompletedColumnAssigns = 0;
  o_importSavingNumTotalCustomAssigns = 0;
  o_importSavingNumCompletedCustomAssigns = 0;
  o_importLastChangesSavedTF = true;
  o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = -1; //for Capture update mode, selected column from .csv that represents the CaptureExec unique key/ID column to map against, -1 is a flag that no column is selected yet
  o_importVisibleCsvCapturesStartingIndex = 0; //capture index of first 'column' in import table showing capture raw data preview (this can be changed to view all of the data in increments of 5, initially seeing 0-4, then 5-9, 10-14, etc)
  o_importColumnIndicesViewingOtherMappingTypesArray = []; //array of column index numbers that pushed the 'view other mapping types' button to view the Teammates/Competitors/DSQ etc buttons below the main two ignore/details field buttons
  o_importCurrentAutoAssignsMapOfMaps = new Map();
  o_importCurrentColumnAssignsMapOfMaps = new Map();
  o_importCurrentCustomAssignsMapOfMaps = new Map();
  o_importDatabaseProgressFlag = undefined; //undefined, "importing", "finished"
  o_importDatabaseProgressCurrentCaptureIndexBeingImported = 0;
  o_importDatabaseManualCancelRecursionFlagTF = false;
  o_importCapturesSuccessSkippedFailuresObj = {successCaptureIDsArray:[], skippedCaptureIDsArray:[], failuresCaptureIDsArray:[]};

  constructor() {
    makeObservable(this, {
      o_importFlag: observable,
      o_importCapturesImportTrueUpdateFalse: observable,
      o_importContactsIsPersonTF: observable,
      o_importUploadedCsvFileName: observable,
      o_importUploadedCsvFileDataString: observable,
      o_importUploadedCsvFileHasFirstHeaderRowTF: observable,
      o_importLoadSavePresetFlag: observable,
      o_importSelectedImportPresetID: observable,
      o_importSavingNumTotalAutoAssigns: observable,
      o_importSavingNumCompletedAutoAssigns: observable,
      o_importSavingNumTotalColumnAssigns: observable,
      o_importSavingNumCompletedColumnAssigns: observable,
      o_importSavingNumTotalCustomAssigns: observable,
      o_importSavingNumCompletedCustomAssigns: observable,
      o_importLastChangesSavedTF: observable,
      o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1: observable,
      o_importVisibleCsvCapturesStartingIndex: observable,
      o_importColumnIndicesViewingOtherMappingTypesArray: observable,
      o_importCurrentAutoAssignsMapOfMaps: observable,
      o_importCurrentColumnAssignsMapOfMaps: observable,
      o_importCurrentCustomAssignsMapOfMaps: observable,
      o_importDatabaseProgressFlag: observable,
      o_importDatabaseProgressCurrentCaptureIndexBeingImported: observable,
      o_importDatabaseManualCancelRecursionFlagTF: observable,
      o_importCapturesSuccessSkippedFailuresObj: observable,

      c_importUploadedCsvFileDataArrayOfArrays: computed,
      c_importCsvFileIsUploadedTF: computed,
      c_importCsvFileDataHeadersArrayOfObjs: computed,
      c_importCsvFileDataCapturesArrayOfArrays: computed,
      c_importNumCapturesToImport: computed,
      c_importNumCsvColumns: computed,
      c_importAnalyzeCapturesReturnToTabDbNameWhenFinished: computed,
      c_importSelectCaptureImportOrUpdatePresetsFieldTypeObj: computed,
      c_importSelectedImportPresetNamePlainTextOrUndefined: computed,
      c_importCsvUniqueValuesPerColumnArrayOfArrayOfObjs: computed,
      c_importCurrentAutoAssignsArrayOfObjs: computed,
      c_importCurrentColumnAssignsArrayOfObjs: computed,
      c_importCurrentCustomAssignsArrayOfObjs: computed,
      c_importUnassignedCriticalCaptureFieldDisplayNamesArray: computed,
      c_importUpdateUniqueKeyFieldSelectCsvColumnFieldTypeObj: computed,
      c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined: computed,
      c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined: computed,
      c_importAllCaptureExecFieldIDsNotAvailableForImportArray: computed,
      c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined: computed,
      c_importConstantValuesCaptureFieldIDsAlreadyAssignedArray: computed,
      c_importExpandedCurrentAutoAssignsArrayOfObjs: computed,
      c_importVisibleCsvCaptureIndicesArray: computed,
      c_importColumnsArrayOfObjs: computed,
      c_importUnfinishedColumnIndicesPreventingImportArray: computed,
      c_contactsAllUniqueContactNamesLowercaseArray: computed,
      c_importContactsConvertedDataArrayOfObjs: computed,
      c_importContactsConvertedDataNoDuplicatesArrayOfObjs: computed,
      c_importNumContactsNoDuplicatesToImport: computed,
      c_importContactsAtLeast1NonDuplicateRowHasInvalidDataCellTF: computed,
      c_importProcessCurrentlyInProgressTF: computed,
      c_importProcessIsFinishedTF: computed,

      a_import_set_import_flag: action,
      a_import_set_uploaded_csv_file_name: action,
      a_import_set_uploaded_csv_file_data_string: action,
      a_import_toggle_csv_file_has_first_header_row_tf: action,
      a_import_set_load_save_preset_flag: action,
      a_import_set_selected_import_preset_id: action,
      a_import_set_saving_inserted_new_preset_or_deleted_old_preset_settings_tf: action,
      a_import_set_saving_num_total_auto_assigns: action,
      a_import_set_saving_num_completed_auto_assigns: action,
      a_import_set_saving_num_total_column_assigns: action,
      a_import_set_saving_num_completed_column_assigns: action,
      a_import_set_saving_num_total_custom_assigns: action,
      a_import_set_saving_num_completed_custom_assigns: action,
      a_import_set_last_changes_saved_tf: action,
      a_import_load_selected_import_preset: action,
      a_import_delete_import_preset_from_id: action,
      a_import_save_current_settings_as_new_or_overwrite_existing_import_preset: action,
      a_import_save_level1: action,
      a_import_save_level2_recursive: action,
      a_import_save_level3_recursive: action,
      a_import_set_update_unique_key_field_selected_csv_column_index_or_m1: action,
      a_import_add_skip_value_to_visible_csv_captures_starting_index: action,
      a_import_add_column_index_to_column_indices_viewing_other_mapping_types_array: action,
      a_import_remove_column_index_from_column_indices_viewing_other_mapping_types_array: action,
      a_import_set_current_auto_assign_captureexec_raw_value_string: action,
      a_import_delete_current_auto_assign_record_from_id: action,
      a_import_set_current_column_assign_field_from_column_index: action,
      a_import_delete_current_column_assign_record_and_all_mapped_custom_assign_records: action,
      a_import_set_current_custom_assign_captureexec_raw_value_string: action,
      a_import_delete_all_current_custom_assign_records_for_matching_column_index: action,
      a_import_delete_current_custom_assign_record_from_current_custom_assign_id: action,
      a_import_attempt_predictive_fill_in_of_mapping_custom_assigns: action,
      a_import_update_single_csv_cell_in_csv_file_data_string: action,
      a_import_set_import_database_progress_flag: action,
      a_import_set_database_progress_current_capture_index_being_imported: action,
      a_import_set_database_progress_manual_cancel_recursion_flag_tf: action,
      a_import_update_captures_success_skipped_failures_obj: action,
      a_import_recursive_import_captures_using_current_settings: action,
      a_import_recursive_update_captures_using_current_settings: action,
      a_import_recursive_import_contacts: action,
      a_import_recursive_import_contacts_recursive_internal: action
    });
  }



  get c_importUploadedCsvFileDataArrayOfArrays() {
    if(!JSFUNC.is_string(this.o_importUploadedCsvFileDataString)) {
      return(undefined);
    }
    return(JSFUNC.read_csv_file_data_string_into_arrayOfArrays(this.o_importUploadedCsvFileDataString));
  }

  get c_importCsvFileIsUploadedTF() {
    return(JSFUNC.is_array(this.c_importUploadedCsvFileDataArrayOfArrays));
  }

  get c_importCsvFileDataHeadersArrayOfObjs() {
    var headersArrayOfObjs = [];
    if(this.c_importCsvFileIsUploadedTF && this.o_importUploadedCsvFileHasFirstHeaderRowTF) {
      for(let c = 0; c < this.c_importNumCsvColumns; c++) {
        headersArrayOfObjs.push({
          column_index: c,
          header: this.c_importUploadedCsvFileDataArrayOfArrays[0][c]
        });
      }
    }
    return(headersArrayOfObjs);
  }

  get c_importCsvFileDataCapturesArrayOfArrays() {
    var capturesArrayOfArrays = [];
    if(this.c_importCsvFileIsUploadedTF) {
      const startRowIndex = ((this.o_importUploadedCsvFileHasFirstHeaderRowTF) ? (1) : (0));
      for(let r = startRowIndex; r < this.c_importUploadedCsvFileDataArrayOfArrays.length; r++) {
        capturesArrayOfArrays.push(this.c_importUploadedCsvFileDataArrayOfArrays[r]);
      }
    }
    return(capturesArrayOfArrays);
  }

  get c_importNumCapturesToImport() {
    if(this.c_importCsvFileIsUploadedTF) {
      return(this.c_importCsvFileDataCapturesArrayOfArrays.length);
    }
    return(0);
  }

  get c_importNumCsvColumns() {
    if(this.c_importNumCapturesToImport > 0) {
      if(JSFUNC.is_array(this.c_importCsvFileDataCapturesArrayOfArrays[0])) {
        return(this.c_importCsvFileDataCapturesArrayOfArrays[0].length);
      }
    }
    return(0);
  }

  get c_importAnalyzeCapturesReturnToTabDbNameWhenFinished() {
    const o_importCapturesImportTrueUpdateFalse = this.o_importCapturesImportTrueUpdateFalse;
    if(o_importCapturesImportTrueUpdateFalse) {
      return("importCapturesUploadCsv");
    }
    return("updateCapturesUploadCsv");
  }

  get c_importSelectCaptureImportOrUpdatePresetsFieldTypeObj() {
    const o_importCapturesImportTrueUpdateFalse = this.o_importCapturesImportTrueUpdateFalse;
    const c_selectAdminImportCaptureImportPresetsFieldTypeObj = DatabaseMobx.c_selectAdminImportCaptureImportPresetsFieldTypeObj;
    const c_selectAdminImportCaptureUpdatePresetsFieldTypeObj = DatabaseMobx.c_selectAdminImportCaptureUpdatePresetsFieldTypeObj;

    if(o_importCapturesImportTrueUpdateFalse) {
      return(c_selectAdminImportCaptureImportPresetsFieldTypeObj);
    }
    return(c_selectAdminImportCaptureUpdatePresetsFieldTypeObj);
  }

  get c_importSelectedImportPresetNamePlainTextOrUndefined() {
    const o_importSelectedImportPresetID = this.o_importSelectedImportPresetID;
    const c_importSelectCaptureImportOrUpdatePresetsFieldTypeObj = this.c_importSelectCaptureImportOrUpdatePresetsFieldTypeObj;

    if(DatabaseMobx.o_tbl_a_import_presets.has(o_importSelectedImportPresetID)) {
      return(DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(o_importSelectedImportPresetID, c_importSelectCaptureImportOrUpdatePresetsFieldTypeObj));
    }
    return(undefined);
  }

  get c_importCsvUniqueValuesPerColumnArrayOfArrayOfObjs() {
    var csvUniqueValuesPerColumnArrayOfArrayOfObjs = [];
    for(let c = 0; c < this.c_importNumCsvColumns; c++) {
      var columnValuesArrayOfObjs = [];
      for(let r = 0; r < this.c_importNumCapturesToImport; r++) {
        var columnValue = this.c_importCsvFileDataCapturesArrayOfArrays[r][c];
        var foundValueMatchTF = false;
        for(let columnValueObj of columnValuesArrayOfObjs) {
          if(columnValue === columnValueObj.value) {
            columnValueObj.count++;
            foundValueMatchTF = true;
            break;
          }
        }

        if(!foundValueMatchTF) {
          columnValuesArrayOfObjs.push({
            value: columnValue,
            count: 1
          });
        }
      }
      csvUniqueValuesPerColumnArrayOfArrayOfObjs.push(columnValuesArrayOfObjs);
    }
    return(csvUniqueValuesPerColumnArrayOfArrayOfObjs);
  }

  get c_importCurrentAutoAssignsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_importCurrentAutoAssignsMapOfMaps));
  }

  get c_importCurrentColumnAssignsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_importCurrentColumnAssignsMapOfMaps));
  }

  get c_importCurrentCustomAssignsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_importCurrentCustomAssignsMapOfMaps));
  }

  get c_importUnassignedCriticalCaptureFieldDisplayNamesArray() {
    //fields not yet assigned as constant value or to a csv column that are critical (on the create new capture form), displayed as a list next to the constant value table
    var unassignedCriticalCaptureFieldDisplayNamesArray = [];

    var allAssignedFieldIDsArray = [];
    for(let expandedColumnObj of this.c_importColumnsArrayOfObjs) { //assigned csv column fields
      allAssignedFieldIDsArray.push(expandedColumnObj.captureFieldID);
    }
    for(let expandedCurrentAutoAssignObj of this.c_importExpandedCurrentAutoAssignsArrayOfObjs) { //assigned constant value fields
      allAssignedFieldIDsArray.push(expandedCurrentAutoAssignObj.field_id);
    }

    var criticalFieldMapsArray = [
      DatabaseMobx.c_fieldMapOfOpportunityName,
      DatabaseMobx.c_fieldMapOfCaptureManagers,
      DatabaseMobx.c_fieldMapOfDivisionOwners,
      DatabaseMobx.c_fieldMapOfCaptureType,
      DatabaseMobx.c_fieldMapOfStage,
      DatabaseMobx.c_fieldMapOfContractType,
      DatabaseMobx.c_fieldMapOfContractOverallValue,
      DatabaseMobx.c_fieldMapOfPeriodOfPerformance,
      DatabaseMobx.c_fieldMapOfAddedDate
    ];

    for(let criticalFieldMap of criticalFieldMapsArray) {
      if(!JSFUNC.in_array(criticalFieldMap.get("id"), allAssignedFieldIDsArray)) {
        unassignedCriticalCaptureFieldDisplayNamesArray.push(criticalFieldMap.get("display_name"));
      }
    }

    return(unassignedCriticalCaptureFieldDisplayNamesArray);
  }

  get c_importUpdateUniqueKeyFieldSelectCsvColumnFieldTypeObj() {
    const c_importColumnsArrayOfObjs = this.c_importColumnsArrayOfObjs;

    var valueArray = [];
    var displayArray = [];
    var bgColorArray = [];
    for(let importColumnObj of c_importColumnsArrayOfObjs) {
      valueArray.push(importColumnObj.index);

      var displayText = "Csv Column " + importColumnObj.columnExcelLetters + " - '" + importColumnObj.header + "'";
      var bgColor = "ffffff";
      if(!importColumnObj.isIgnoredOrMappedTF) {
        displayText = "[not yet mapped] " + displayText;
        bgColor = "dddddd"; //not yet handled
      }
      else if(importColumnObj.isIgnoredTF) {
        displayText = "[ignored] " + displayText;
        bgColor = "bbbbbb"; //ignored field
      }
      else if(importColumnObj.isMappedDetailsFieldTF) {
        bgColor = "ffffff"; //mapped field
      }

      displayArray.push(displayText);
      bgColorArray.push(bgColor);
    }
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true, bgColorArray:bgColorArray};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Csv Column", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));    
  }

  get c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined() {
    const o_importCapturesImportTrueUpdateFalse = this.o_importCapturesImportTrueUpdateFalse;
    const o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = this.o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1;
    const c_importColumnsArrayOfObjs = this.c_importColumnsArrayOfObjs;

    var selectedCsvColumnIsFilledOutAndMappedTF = false;
    var selectedExpandedColumnObjOrUndefined = undefined;
    var selectedCsvColumnErrorMessage = "";
    if(!o_importCapturesImportTrueUpdateFalse) { //only compute these for Capture Update (not Import)
      if(o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 < 0) {
        selectedCsvColumnErrorMessage = "You must identify this column to map this data to existing CaptureExec opportunities";
      }
      else {
        selectedExpandedColumnObjOrUndefined = c_importColumnsArrayOfObjs[o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1];
        if(selectedExpandedColumnObjOrUndefined === undefined) {
          selectedCsvColumnErrorMessage = "Invalid .csv column selected";
        }
        else {
          if(selectedExpandedColumnObjOrUndefined.isIgnoredTF) {
            selectedCsvColumnErrorMessage = "Cannot select a column marked as 'Ignored'";
          }
          else if(!selectedExpandedColumnObjOrUndefined.isIgnoredOrMappedTF) {
            selectedCsvColumnErrorMessage = "Selected column needs to be mapped to its corresponding CaptureExec Details Field";
          }
          else if(!selectedExpandedColumnObjOrUndefined.isMappedDetailsFieldTF) {
            selectedCsvColumnErrorMessage = "Cannot select a column with a mapping other than 'CaptureExec Details Field'";
          }
          else {
            selectedCsvColumnIsFilledOutAndMappedTF = true;
          }
        }
      }
    }

    return({
      selectedCsvColumnIsFilledOutAndMappedTF: selectedCsvColumnIsFilledOutAndMappedTF,
      selectedExpandedColumnObjOrUndefined: selectedExpandedColumnObjOrUndefined,
      selectedCsvColumnErrorMessage: selectedCsvColumnErrorMessage
    });
  }

  get c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined() {
    const o_importCapturesImportTrueUpdateFalse = this.o_importCapturesImportTrueUpdateFalse;
    const c_importNumCapturesToImport = this.c_importNumCapturesToImport;
    const c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined = this.c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined;

    //capture import does not calculate this mapping and does not show any boxes in the headers of the import table
    if(o_importCapturesImportTrueUpdateFalse) {
      return(undefined);
    }

    const selectedCsvColumnIsFilledOutAndMappedTF = c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedCsvColumnIsFilledOutAndMappedTF;
    const selectedExpandedColumnObjOrUndefined = c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedExpandedColumnObjOrUndefined;

    //capture update looks at the selected unique key mapped column and maps it against Captures in the system
    var importUpdateUniqueKeyFieldVisibleCsvCaptureMappingsArrayOfObjs = [];
    if(!selectedCsvColumnIsFilledOutAndMappedTF) { //selected column obj not filled out or valid
      for(let c = 0; c < c_importNumCapturesToImport; c++) {
        importUpdateUniqueKeyFieldVisibleCsvCaptureMappingsArrayOfObjs.push({
          csvCaptureIndex: c,
          isMappedToCECaptureTF: false,
          captureID: -1,
          bgColor: "e4e4e4",
          fontClass: "fontItalic fontDarkRed",
          message: "Unique Key/ID not identified"
        });
      }
    }
    else {
      for(let c = 0; c < c_importNumCapturesToImport; c++) {
        var isMappedToCECaptureTF = false;
        var captureID = -1;
        var bgColor = "922";
        var fontClass = "fontWhite";
        var message = "No CE Capture match";
        var captureExecValueRawOrUndefined = this.import_convert_csv_capture_index_and_expanded_column_obj_to_captureexec_value_raw_or_undefined(c, selectedExpandedColumnObjOrUndefined);
        if(captureExecValueRawOrUndefined !== undefined) {
          var matchingCaptureMapOrUndefined = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_captures, selectedExpandedColumnObjOrUndefined.selectedCaptureFieldDbName, captureExecValueRawOrUndefined);
          if(matchingCaptureMapOrUndefined !== undefined) {
            isMappedToCECaptureTF = true;
            captureID = matchingCaptureMapOrUndefined.get("id");
            bgColor = "cfc";
            fontClass = "fontDarkGreen";
            message = DatabaseMobx.capture_name_plaintext_from_capture_map(matchingCaptureMapOrUndefined, true);
          }
        }

        importUpdateUniqueKeyFieldVisibleCsvCaptureMappingsArrayOfObjs.push({
          csvCaptureIndex: c,
          isMappedToCECaptureTF: isMappedToCECaptureTF,
          captureID: captureID,
          bgColor: bgColor,
          fontClass: fontClass,
          message: message
        });
      }
    }

    return(importUpdateUniqueKeyFieldVisibleCsvCaptureMappingsArrayOfObjs);
  }


  get c_importUpdateUniqueKeyFieldUnmappedCsvCaptureIncidesArrayOrUndefined() {
    const c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined = this.c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined;
    const c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined = this.c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined;
    
    //make sure unique key/ID capture mappings are filled out as an array
    if(!c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedCsvColumnIsFilledOutAndMappedTF || !JSFUNC.is_array(c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined)) {
      return(undefined);
    }

    //loop through all capture mappings for update from unique key/ID column, gather any csv capture indices that were unsuccessful matching to any capture in CE so that a box can be displayed altering that there are unmapped captures (which may be hidden because only 5 captures are displayed as columns in import per page)
    var unmappedCsvCaptureIndicesArray = [];
    for(let csvCaptureMappingObj of c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined) {
      if(!csvCaptureMappingObj.isMappedToCECaptureTF) {
        unmappedCsvCaptureIndicesArray.push(csvCaptureMappingObj.csvCaptureIndex);
      }
    }
    return(unmappedCsvCaptureIndicesArray);
  }


  get c_importAllCaptureExecFieldIDsNotAvailableForImportArray() {
    const o_importCapturesImportTrueUpdateFalse = this.o_importCapturesImportTrueUpdateFalse;

    var notAvailableFieldIDsArray = [];
    for(let expandedCaptureFieldMap of DatabaseMobx.c_tbl_captures_fields.values()) {
      var notAvailableTF = false;
      if(o_importCapturesImportTrueUpdateFalse) {
        notAvailableTF = expandedCaptureFieldMap.get("notAvailableForImportTF");
      }
      else {
        notAvailableTF = expandedCaptureFieldMap.get("notAvailableForUpdateTF");
      }

      if(notAvailableTF) {
        notAvailableFieldIDsArray.push(expandedCaptureFieldMap.get("id"));
      }
    }
    return(notAvailableFieldIDsArray);
  }

  get c_importConstantValuesCaptureFieldIDsAlreadyAssignedArray() {
    var constantValuesCaptureFieldIDsAlreadyAssignedArray = [];

    //start with all values not available for import
    for(let fieldIDNotAvailableForImport of this.c_importAllCaptureExecFieldIDsNotAvailableForImportArray) {
      constantValuesCaptureFieldIDsAlreadyAssignedArray.push(fieldIDNotAvailableForImport);
    }

    //get all values already assigned in the Constant Values section
    for(let currentAutoAssignObj of this.c_importCurrentAutoAssignsArrayOfObjs) {
      constantValuesCaptureFieldIDsAlreadyAssignedArray.push(currentAutoAssignObj.field_id);
    }

    //get all values already assigned to each .csv data column
    for(let currentColumnAssignObj of this.c_importCurrentColumnAssignsArrayOfObjs) {
      if(currentColumnAssignObj.mapping_type_id === 1) { //only matters if this mapping type is a details field for field_id (otherwise field_id may be referring to the id of deal shaping question rows)
        constantValuesCaptureFieldIDsAlreadyAssignedArray.push(currentColumnAssignObj.field_id);
      }
    }

    return(constantValuesCaptureFieldIDsAlreadyAssignedArray);
  }

  get c_importExpandedCurrentAutoAssignsArrayOfObjs() {
    var expandedCurrentAutoAssignsArrayOfObjs = [];
    for(let currentAutoAssignObj of this.c_importCurrentAutoAssignsArrayOfObjs) {
      //the capture field must exist to have the auto assign show up
      var expandedCaptureFieldMap = DatabaseMobx.c_tbl_captures_fields.get(currentAutoAssignObj.field_id);
      if(expandedCaptureFieldMap !== undefined) {
        var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");
        if(fieldTypeObj !== undefined) {
          expandedCurrentAutoAssignsArrayOfObjs.push({
            id: currentAutoAssignObj.id,
            import_preset_id: currentAutoAssignObj.import_preset_id,
            field_id: currentAutoAssignObj.field_id,
            captureexec_raw_value_string: currentAutoAssignObj.captureexec_raw_value_string,
            fieldDbName: expandedCaptureFieldMap.get("db_name"),
            fieldDisplayName: expandedCaptureFieldMap.get("display_name"),
            fieldTypeObj: fieldTypeObj,
            valueRaw: DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(currentAutoAssignObj.captureexec_raw_value_string, fieldTypeObj)
          });
        }
      }
    }
    return(expandedCurrentAutoAssignsArrayOfObjs);
  }

  get c_importVisibleCsvCaptureIndicesArray() {
    const o_importVisibleCsvCapturesStartingIndex = this.o_importVisibleCsvCapturesStartingIndex;
    const c_importNumCapturesToImport = this.c_importNumCapturesToImport;

    var importVisibleCsvCaptureIndicesArray = [];
    for(let i = o_importVisibleCsvCapturesStartingIndex; i < (o_importVisibleCsvCapturesStartingIndex + 5); i++) {
      //if(i < c_importNumCapturesToImport){
        importVisibleCsvCaptureIndicesArray.push(i);
      //}
    }
    return(importVisibleCsvCaptureIndicesArray);
  }

  get c_importColumnsArrayOfObjs() {
    const c_importNumCapturesToImport = this.c_importNumCapturesToImport;

    var columnsArrayOfObjs = [];
    for(let c = 0; c < this.c_importNumCsvColumns; c++) {
      var index = c;
      var columnNumber = (c + 1);
      var columnExcelLetters = JSFUNC.excel_AZAAZZ_from_int(columnNumber);
      var header = "";
      var mappingTypeID = -1;
      var captureFieldID = -1;
      var isIgnoredTF = false;
      var isMappedDetailsFieldTF = false;
      var isMappedTeammatesTF = false;
      var isMappedCompetitorsTF = false;
      var isMappedConversationsTF = false;
      var isMappedDealShapingSelectTF = false;
      var isMappedDealShapingTextareaTF = false;
      var isIgnoredOrMappedTF = false;
      var canExpandOrCollapseTF = false;
      var isCollapsedTF = false;
      var percentFieldMultiplyBy100TF = false;
      var selectedCaptureFieldExistsTF = false;
      var selectedCaptureFieldDbName = "";
      var selectedCaptureFieldDisplayName = "";
      var selectedCaptureFieldSelectTblName = "";
      var selectedCaptureFieldFieldTypeObj = undefined;
      var selectedCaptureFieldRequiresCustomAssignmentTF = false;
      var selectedCaptureFieldIsPercentTF = false;
      var selectedCaptureFieldIsMoneyTF = false;
      var csvUniqueValuesAndCustomAssignsArrayOfObjs = [];
      var captureFieldIDsAlreadyAssignedToOtherColumnsArray = [];
      var dealShapingQuestionIDsAlreadyAssignedToOtherColumnsArray = [];
      var visibleCsvCaptureRawValuesArray = [];
      var visibleCsvCaptureIndicesArray = [];

      //get header from csv data (if provided)
      var headerObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_importCsvFileDataHeadersArrayOfObjs, "column_index", index);
      if(headerObj !== undefined) {
        header = headerObj.header;
      }

      //begin the fieldIDs already assigned by including all non import fields and all fields already used above in the Constant Values section
      for(let fieldIDNotAvailableForImport of this.c_importAllCaptureExecFieldIDsNotAvailableForImportArray) {
        captureFieldIDsAlreadyAssignedToOtherColumnsArray.push(fieldIDNotAvailableForImport);
      }
      for(let currentAutoAssignObj of this.c_importCurrentAutoAssignsArrayOfObjs) {
        captureFieldIDsAlreadyAssignedToOtherColumnsArray.push(currentAutoAssignObj.field_id);
      }

      //get capture field assignment information
      var columnAssignObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_importCurrentColumnAssignsArrayOfObjs, "column_index", index);
      if(columnAssignObj !== undefined) {
        //if the column index was found as a valid row, then this row is ignored or has one of the mappings selected
        isIgnoredOrMappedTF = true;

        //mapping_type_id determines if this column is ignored, a capture field, the teammates/competitor card
        mappingTypeID = columnAssignObj.mapping_type_id;
        isMappedDetailsFieldTF = (mappingTypeID === 1); //1 is the flag that this csv data column is currently being ignored for this import
        isMappedTeammatesTF = (mappingTypeID === 2); //2 for teammates mapping
        isMappedCompetitorsTF = (mappingTypeID === 3); //3 for competitors mapping
        isMappedConversationsTF = (mappingTypeID === 4); //4 for conversations mapping
        isMappedDealShapingSelectTF = (mappingTypeID === 5); //6 for deal shaping select mapping
        isMappedDealShapingTextareaTF = (mappingTypeID === 6); //6 for deal shaping textarea mapping
        isIgnoredTF = (!isMappedDetailsFieldTF && !isMappedTeammatesTF && !isMappedCompetitorsTF && !isMappedConversationsTF && !isMappedDealShapingSelectTF && !isMappedDealShapingTextareaTF);

        //capture field_id is used to determine which capture details field or which deal shaping question was selected based on the mapping type
        captureFieldID = columnAssignObj.field_id;

        //switch only used when a percent type field is selected for this csv column
        percentFieldMultiplyBy100TF = (columnAssignObj.percent_field_multiply_100_01 === 1);

        //mark all already assigned fieldIDs to other columns as not available for this column
        if(isMappedDetailsFieldTF) {
          for(let currentColumnAssignObj of this.c_importCurrentColumnAssignsArrayOfObjs) {
            if(currentColumnAssignObj.column_index !== index) { //don't include the field_id that this column is currently assigned to (want it to still be in the list as the one currently selected for this column)
              captureFieldIDsAlreadyAssignedToOtherColumnsArray.push(currentColumnAssignObj.field_id);
            }
          }
        }
        else if(isMappedDealShapingSelectTF || isMappedDealShapingTextareaTF) { //mark all already selected deal shaping questions (select an textarea types together)
          for(let currentColumnAssignObj of this.c_importCurrentColumnAssignsArrayOfObjs) {
            if(currentColumnAssignObj.column_index !== index) { //don't include the deal shaping question (using field_id column) that this column is currently assigned to
              dealShapingQuestionIDsAlreadyAssignedToOtherColumnsArray.push(currentColumnAssignObj.field_id);
            }
          }
        }

        //get the capture field map from the fieldID, if it is a select field that requires mapping, find all the unique strings for this .csv column
        var columnObjDetailsFieldParametersObj = this.import_compute_column_obj_select_mapping_parameters_from_column_index_and_field_id(index, mappingTypeID, captureFieldID);
        selectedCaptureFieldExistsTF = columnObjDetailsFieldParametersObj.selectedCaptureFieldExistsTF;
        selectedCaptureFieldDbName = columnObjDetailsFieldParametersObj.selectedCaptureFieldDbName;
        selectedCaptureFieldDisplayName = columnObjDetailsFieldParametersObj.selectedCaptureFieldDisplayName;
        selectedCaptureFieldSelectTblName = columnObjDetailsFieldParametersObj.selectedCaptureFieldSelectTblName;
        selectedCaptureFieldFieldTypeObj = columnObjDetailsFieldParametersObj.selectedCaptureFieldFieldTypeObj;
        selectedCaptureFieldRequiresCustomAssignmentTF = columnObjDetailsFieldParametersObj.selectedCaptureFieldRequiresCustomAssignmentTF;
        selectedCaptureFieldIsPercentTF = columnObjDetailsFieldParametersObj.selectedCaptureFieldIsPercentTF;
        selectedCaptureFieldIsMoneyTF = columnObjDetailsFieldParametersObj.selectedCaptureFieldIsMoneyTF;
        csvUniqueValuesAndCustomAssignsArrayOfObjs = columnObjDetailsFieldParametersObj.csvUniqueValuesAndCustomAssignsArrayOfObjs;

        //if the preset has this column collapsed
        canExpandOrCollapseTF = ((isMappedDetailsFieldTF && selectedCaptureFieldRequiresCustomAssignmentTF) || isMappedTeammatesTF || isMappedCompetitorsTF); //ability to collapse a row is based on the type of row selected from field_id
        isCollapsedTF = (canExpandOrCollapseTF && (columnAssignObj.collapsed_01 === 1));
      }

      //get csv capture raw data values for the 5 visible columns being displayed
      for(let visibleCsvCaptureIndex of this.c_importVisibleCsvCaptureIndicesArray) {
        var visibleCsvCaptureRawValue = undefined;
        if(visibleCsvCaptureIndex < c_importNumCapturesToImport) {
          visibleCsvCaptureRawValue = this.c_importCsvFileDataCapturesArrayOfArrays[visibleCsvCaptureIndex][index];
        }
        visibleCsvCaptureRawValuesArray.push(visibleCsvCaptureRawValue); //get the csv raw capture value at the capture index and column index of this column
        visibleCsvCaptureIndicesArray.push(visibleCsvCaptureIndex);
      }

      columnsArrayOfObjs.push({
        index: index,
        columnNumber: columnNumber,
        columnExcelLetters: columnExcelLetters,
        header: header,
        mappingTypeID: mappingTypeID,
        captureFieldID: captureFieldID,
        isIgnoredTF: isIgnoredTF,
        isMappedDetailsFieldTF: isMappedDetailsFieldTF,
        isMappedTeammatesTF: isMappedTeammatesTF,
        isMappedCompetitorsTF: isMappedCompetitorsTF,
        isMappedConversationsTF: isMappedConversationsTF,
        isMappedDealShapingSelectTF: isMappedDealShapingSelectTF,
        isMappedDealShapingTextareaTF: isMappedDealShapingTextareaTF,
        isIgnoredOrMappedTF: isIgnoredOrMappedTF,
        canExpandOrCollapseTF: canExpandOrCollapseTF,
        isCollapsedTF: isCollapsedTF,
        percentFieldMultiplyBy100TF: percentFieldMultiplyBy100TF,
        selectedCaptureFieldExistsTF: selectedCaptureFieldExistsTF,
        selectedCaptureFieldDbName: selectedCaptureFieldDbName,
        selectedCaptureFieldDisplayName: selectedCaptureFieldDisplayName,
        selectedCaptureFieldSelectTblName: selectedCaptureFieldSelectTblName,
        selectedCaptureFieldFieldTypeObj: selectedCaptureFieldFieldTypeObj,
        selectedCaptureFieldRequiresCustomAssignmentTF: selectedCaptureFieldRequiresCustomAssignmentTF,
        selectedCaptureFieldIsPercentTF: selectedCaptureFieldIsPercentTF,
        selectedCaptureFieldIsMoneyTF: selectedCaptureFieldIsMoneyTF,
        csvUniqueValuesAndCustomAssignsArrayOfObjs: csvUniqueValuesAndCustomAssignsArrayOfObjs,
        captureFieldIDsAlreadyAssignedToOtherColumnsArray: captureFieldIDsAlreadyAssignedToOtherColumnsArray,
        dealShapingQuestionIDsAlreadyAssignedToOtherColumnsArray: dealShapingQuestionIDsAlreadyAssignedToOtherColumnsArray,
        visibleCsvCaptureRawValuesArray: visibleCsvCaptureRawValuesArray,
        visibleCsvCaptureIndicesArray: visibleCsvCaptureIndicesArray
      });
    }
    return(columnsArrayOfObjs);
  }

  import_compute_column_obj_select_mapping_parameters_from_column_index_and_field_id(i_columnIndex, i_mappingTypeID, i_captureFieldID) {
    var selectedCaptureFieldExistsTF = false;
    var selectedCaptureFieldDbName = "";
    var selectedCaptureFieldDisplayName = "";
    var selectedCaptureFieldSelectTblName = "";
    var selectedCaptureFieldFieldTypeObj = undefined;
    var selectedCaptureFieldRequiresCustomAssignmentTF = false;
    var selectedCaptureFieldIsPercentTF = false;
    var selectedCaptureFieldIsMoneyTF = false;
    var csvUniqueValuesAndCustomAssignsArrayOfObjs = [];

    if(i_mappingTypeID === 1) { //details field
      const expandedCaptureFieldMap = DatabaseMobx.c_tbl_captures_fields.get(i_captureFieldID);
      if(expandedCaptureFieldMap !== undefined) {
        selectedCaptureFieldExistsTF = true;
        selectedCaptureFieldDbName = expandedCaptureFieldMap.get("db_name");
        selectedCaptureFieldDisplayName = expandedCaptureFieldMap.get("display_name");
        selectedCaptureFieldSelectTblName = expandedCaptureFieldMap.get("select_tbl_name");
        selectedCaptureFieldFieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");

        if(selectedCaptureFieldDbName === "prime_contact_company_id") {
          selectedCaptureFieldFieldTypeObj = DatabaseMobx.c_selectContactCompanyWithUsMinus2AtTopFieldTypeObj;
        }
        else if(selectedCaptureFieldDbName === "incumbent_contact_company_ids_comma") {
          selectedCaptureFieldFieldTypeObj = DatabaseMobx.c_selectMultiContactCompaniesWithUsMinus2AtTopFieldTypeObj;
        }

        if(selectedCaptureFieldFieldTypeObj !== undefined) { //if the fieldTypeObj is valid
          selectedCaptureFieldRequiresCustomAssignmentTF = selectedCaptureFieldFieldTypeObj.requiresSelectWithSearchDataObjTF;
          selectedCaptureFieldIsPercentTF = selectedCaptureFieldFieldTypeObj.percentTF;
          selectedCaptureFieldIsMoneyTF = selectedCaptureFieldFieldTypeObj.moneyTF;

          //get all of the custom assigns for this assigned column capture field that requires a selectWithSearch
          if(selectedCaptureFieldRequiresCustomAssignmentTF) {
            csvUniqueValuesAndCustomAssignsArrayOfObjs = this.import_compute_column_obj_csv_unique_values_and_custom_assigns_arrayOfObjs_from_column_index_and_field_type_obj(i_columnIndex, selectedCaptureFieldFieldTypeObj);
          }
        }
      }
    }
    else if(i_mappingTypeID === 2) { //teammates card import mapping type
      selectedCaptureFieldExistsTF = true;
      selectedCaptureFieldDbName = "--tbl_c_teammates--";
      selectedCaptureFieldDisplayName = "--tbl_c_teammates--";
      selectedCaptureFieldSelectTblName = "--tbl_c_teammates--";
      selectedCaptureFieldFieldTypeObj = DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj;
      selectedCaptureFieldRequiresCustomAssignmentTF = true;
      csvUniqueValuesAndCustomAssignsArrayOfObjs = this.import_compute_column_obj_csv_unique_values_and_custom_assigns_arrayOfObjs_from_column_index_and_field_type_obj(i_columnIndex, selectedCaptureFieldFieldTypeObj);
    }
    else if(i_mappingTypeID === 3) { //competitors card import mapping type
      selectedCaptureFieldExistsTF = true;
      selectedCaptureFieldDbName = "--tbl_c_competitors--";
      selectedCaptureFieldDisplayName = "--tbl_c_competitors--";
      selectedCaptureFieldSelectTblName = "--tbl_c_competitors--";
      selectedCaptureFieldFieldTypeObj = DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj;
      selectedCaptureFieldRequiresCustomAssignmentTF = true;
      csvUniqueValuesAndCustomAssignsArrayOfObjs = this.import_compute_column_obj_csv_unique_values_and_custom_assigns_arrayOfObjs_from_column_index_and_field_type_obj(i_columnIndex, selectedCaptureFieldFieldTypeObj);
    }
    else if(i_mappingTypeID === 5) { //deal shaping questions select mapping type
      selectedCaptureFieldExistsTF = JSFUNC.select_int_is_filled_out_tf(i_captureFieldID); //check that a valid deal shaping select question was picked
      selectedCaptureFieldDbName = "--tbl_c_shaping_answers_select--";
      selectedCaptureFieldDisplayName = "--tbl_c_shaping_answers_select--";
      selectedCaptureFieldSelectTblName = "--tbl_c_shaping_answers_select--";
      selectedCaptureFieldFieldTypeObj = DatabaseMobx.get_question_field_type_obj_from_question_id(i_captureFieldID);
      selectedCaptureFieldRequiresCustomAssignmentTF = true;
      csvUniqueValuesAndCustomAssignsArrayOfObjs = this.import_compute_column_obj_csv_unique_values_and_custom_assigns_arrayOfObjs_from_column_index_and_field_type_obj(i_columnIndex, selectedCaptureFieldFieldTypeObj);
    }
    else if(i_mappingTypeID === 6) { //deal shaping questions textarea mapping type
      selectedCaptureFieldExistsTF = JSFUNC.select_int_is_filled_out_tf(i_captureFieldID); //check that a valid deal shaping textarea question was picked
      selectedCaptureFieldDbName = "--tbl_c_shaping_answers_textarea--";
      selectedCaptureFieldDisplayName = "--tbl_c_shaping_answers_textarea--";
      selectedCaptureFieldSelectTblName = "--tbl_c_shaping_answers_textarea--";
      selectedCaptureFieldFieldTypeObj = undefined;
      selectedCaptureFieldRequiresCustomAssignmentTF = false;
      csvUniqueValuesAndCustomAssignsArrayOfObjs = [];
    }

    return({
      selectedCaptureFieldExistsTF: selectedCaptureFieldExistsTF,
      selectedCaptureFieldDbName: selectedCaptureFieldDbName,
      selectedCaptureFieldDisplayName: selectedCaptureFieldDisplayName,
      selectedCaptureFieldSelectTblName: selectedCaptureFieldSelectTblName,
      selectedCaptureFieldFieldTypeObj: selectedCaptureFieldFieldTypeObj,
      selectedCaptureFieldRequiresCustomAssignmentTF: selectedCaptureFieldRequiresCustomAssignmentTF,
      selectedCaptureFieldIsPercentTF: selectedCaptureFieldIsPercentTF,
      selectedCaptureFieldIsMoneyTF: selectedCaptureFieldIsMoneyTF,
      csvUniqueValuesAndCustomAssignsArrayOfObjs: csvUniqueValuesAndCustomAssignsArrayOfObjs
    });
  }

  import_compute_column_obj_csv_unique_values_and_custom_assigns_arrayOfObjs_from_column_index_and_field_type_obj(i_columnIndex, i_fieldTypeObj) {
    var csvUniqueValuesAndCustomAssignsArrayOfObjs = [];

    const selectWithSearchDataObj = i_fieldTypeObj.selectWithSearchDataObj
    if(selectWithSearchDataObj !== undefined) {
      var uniqueValuesInColumnArrayOfObjs = this.c_importCsvUniqueValuesPerColumnArrayOfArrayOfObjs[i_columnIndex];
      if(JSFUNC.is_array(uniqueValuesInColumnArrayOfObjs)) { //verify that this is an array of objs (not undefined)
        //loop through all unique values found in this csv column
        for(let uniqueValueObj of uniqueValuesInColumnArrayOfObjs) {
          //match the column index and unique value to a custom assign entry, if not found, then this value has not been mapped to a captureexec value yet
          var mappedCaptureExecValueRaw = -1; //default unassigned value for custom assigns not yet made
          if(selectWithSearchDataObj.isMultiSelectTF) {
            mappedCaptureExecValueRaw = ""; //default unassigned value for custom assigns (for multiselects and sharedpercents) not yet made
          }

          var currentCustomAssignRecordID = -1;
          var intentionallyUnassigned01 = 0;
          var customAssignObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_importCurrentCustomAssignsArrayOfObjs, ["column_index", "csv_value_string"], [i_columnIndex, uniqueValueObj.value]);
          if(customAssignObj !== undefined) {
            currentCustomAssignRecordID = customAssignObj.id;
            mappedCaptureExecValueRaw = DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(customAssignObj.captureexec_raw_value_string, i_fieldTypeObj);
            intentionallyUnassigned01 = customAssignObj.intentionally_unassigned_01;
          }

          var mappedCaptureExecValueIsFilledOutTF = DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(mappedCaptureExecValueRaw, i_fieldTypeObj);

          csvUniqueValuesAndCustomAssignsArrayOfObjs.push({
            currentCustomAssignRecordID: currentCustomAssignRecordID,
            value: uniqueValueObj.value,
            count: uniqueValueObj.count,
            mappedCaptureExecValueRaw: mappedCaptureExecValueRaw,
            mappedCaptureExecValueIsFilledOutTF: mappedCaptureExecValueIsFilledOutTF,
            intentionallyUnassignedTF: (intentionallyUnassigned01 === 1)
          });
        }
      }
    }

    return(csvUniqueValuesAndCustomAssignsArrayOfObjs);
  }

  get c_importUnfinishedColumnIndicesPreventingImportArray() {
    var importNumColumnsNeitherMappedNorIgnored = [];
    for(let columnObj of this.c_importColumnsArrayOfObjs) {
      var isUnfinishedTF = false;
      if(!columnObj.isIgnoredOrMappedTF) { //check if this column is still in the initial state where neither option (ignore or map) is selected yet
        isUnfinishedTF = true;
      }
      else if(columnObj.isMappedDetailsFieldTF && !columnObj.selectedCaptureFieldExistsTF) {
        isUnfinishedTF = true;
      }
      else if((columnObj.isMappedDealShapingSelectTF || columnObj.isMappedDealShapingTextareaTF) && !columnObj.selectedCaptureFieldExistsTF) {
        isUnfinishedTF = true;
      }
      else if(columnObj.selectedCaptureFieldRequiresCustomAssignmentTF) { //check all custom assigns in this column to see if any are not filled out (and not marked as intentionally unassigned)
        for(let customAssignObj of columnObj.csvUniqueValuesAndCustomAssignsArrayOfObjs) {
          if(!customAssignObj.mappedCaptureExecValueIsFilledOutTF && !customAssignObj.intentionallyUnassignedTF) {
            isUnfinishedTF = true;
            break;
          }
        }
      }

      if(isUnfinishedTF) {
        importNumColumnsNeitherMappedNorIgnored.push(columnObj.index);
      }
    }
    return(importNumColumnsNeitherMappedNorIgnored);
  }





  get c_contactsAllUniqueContactNamesLowercaseArray() {
    const o_importContactsIsPersonTF = this.o_importContactsIsPersonTF;

    //build an existing list of all contacts already in the system
    var allUniqueContactNamesArray = [];
    if(o_importContactsIsPersonTF) {
      for(let contactPersonMap of DatabaseMobx.o_tbl_g_contacts_persons.values()) {
        allUniqueContactNamesArray.push(contactPersonMap.get("first_name").toLowerCase() + contactPersonMap.get("last_name").toLowerCase() + contactPersonMap.get("contact_company_id"));
      }
    }
    else {
      for(let contactCompanyMap of DatabaseMobx.o_tbl_g_contacts_companies.values()) {
        allUniqueContactNamesArray.push(contactCompanyMap.get("legal_name").toLowerCase());
      }
    }
    return(allUniqueContactNamesArray);
  }


  get c_importContactsConvertedDataArrayOfObjs() {
    const o_importFlag = this.o_importFlag;
    const o_importContactsIsPersonTF = this.o_importContactsIsPersonTF;
    const c_importCsvFileDataCapturesArrayOfArrays = this.c_importCsvFileDataCapturesArrayOfArrays;
    const c_contactsAllUniqueContactNamesLowercaseArray = this.c_contactsAllUniqueContactNamesLowercaseArray;

    if(o_importFlag !== "contactsAnalysisAndImport") {
      return([]);
    }

    var allUniqueContactNamesArray = JSFUNC.copy_array(c_contactsAllUniqueContactNamesLowercaseArray);

    //loop through the csv data
    var convertedImportContactsArrayOfObjs = [];
    if(o_importContactsIsPersonTF) { //persons
      for(let csvRowArray of c_importCsvFileDataCapturesArrayOfArrays) {
        var contactPersonMap = new Map();
        for(let c = 0; c < csvRowArray.length; c++) {
          var csvCellString = csvRowArray[c];

          if(c === 0) { //Parent Company
            var contactCompanyID = ContactsMobx.find_first_lowecase_exact_match_contact_company_id_or_undefined_from_name_string(csvCellString);
            if(!JSFUNC.is_number_not_nan_gt_0(contactCompanyID)) {
              contactCompanyID = -1;
            }
            contactPersonMap.set("contact_company_id", contactCompanyID);
          }
          else if(c === 1) { //First Name
            contactPersonMap.set("first_name", csvCellString);
          }
          else if(c === 2) { //Last Name
            contactPersonMap.set("last_name", csvCellString);
          }
          else if(c === 3) { //Title
            contactPersonMap.set("title", csvCellString);
          }
          else if(c === 4) { //Email
            contactPersonMap.set("email", csvCellString);
          }
          else if(c === 5) { //Phone
            contactPersonMap.set("phone", csvCellString);
          }
          else { //extra person fields
            var extraFieldIndex = (c - 6);
            if(extraFieldIndex < DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs.length) {
              var contactsPersonsExtraFieldObj = DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs[extraFieldIndex];
              contactPersonMap.set(contactsPersonsExtraFieldObj.db_name, csvCellString);
            }
          }
        }

        var contactPersonObj = ContactsMobx.contact_person_obj_from_tbl_g_contacts_persons_row_map(contactPersonMap);

        //determine if contact person name is blank
        contactPersonObj.isBlankTF = ((contactPersonObj.first_name === "") && (contactPersonObj.last_name === ""));

        //determine if contact person row is a duplicate from this import
        var uniqueContactName = contactPersonObj.first_name.toLowerCase() + contactPersonObj.last_name.toLowerCase() + contactPersonObj.contact_company_id; //don't want same name from same company
        if(!JSFUNC.in_array(uniqueContactName, allUniqueContactNamesArray) || contactPersonObj.isBlankTF) {
          contactPersonObj.isDuplicateTF = false;
          allUniqueContactNamesArray.push(uniqueContactName);
        }
        else {
          contactPersonObj.isDuplicateTF = true;
        }

        //determine if any contact person cells have invalid data which prevents import
        contactPersonObj.personFirstNameInvalidTF = (!JSFUNC.text_or_number_is_filled_out_tf(contactPersonObj.first_name));
        contactPersonObj.personLastNameInvalidTF = (!JSFUNC.text_or_number_is_filled_out_tf(contactPersonObj.last_name));
        contactPersonObj.personEmailInvalidTF = ((contactPersonObj.email !== "") && (JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(contactPersonObj.email) !== undefined));

        contactPersonObj.rowInvalidPreventImportTF = (contactPersonObj.personFirstNameInvalidTF || contactPersonObj.personLastNameInvalidTF || contactPersonObj.personEmailInvalidTF);

        convertedImportContactsArrayOfObjs.push(contactPersonObj);
      }
    }
    else { //companies
      for(let csvRowArray of c_importCsvFileDataCapturesArrayOfArrays) {
        var importContactCompanyMap = new Map();
        for(let c = 0; c < csvRowArray.length; c++) {
          var csvCellString = csvRowArray[c];

          if(c === 0) { //Legal Name
            importContactCompanyMap.set("legal_name", csvCellString);
          }
          else if(c === 1) { //Abbr Name
            importContactCompanyMap.set("abbreviated_name", csvCellString);
          }
          else if(c === 2) { //Business Type (input is single id numbers)
            var businessTypeID = JSFUNC.str2int(csvCellString, -1);
            //var businessTypeID = JSFUNC.get_first_array2_value_or_undefined_where_array1_matches_input(csvCellIntOrMinus1, DatabaseMobx.c_valueDisplayArraysObjBusinessTypes.displayArray, DatabaseMobx.c_valueDisplayArraysObjBusinessTypes.valueArray, -1);
            importContactCompanyMap.set("business_type_id", businessTypeID);
          }
          else if(c === 3) { //SB Certs (input is comma list or comma list with spaces or semicolons)
            var sbCertificationsBmSetAsideIDsArray = JSFUNC.convert_comma_list_with_or_without_spaces_to_array_blank_entries_removed(csvCellString);
            var sbCertificationsBmSetAsideIDsComma = JSFUNC.convert_array_to_comma_list(sbCertificationsBmSetAsideIDsArray);
            //var sbCertificationsBmSetAsideIDsComma = JSFUNC.get_comma_list_of_array2_values_where_array1_matches_input_comma_string(csvCellString, DatabaseMobx.c_valueDisplayArraysObjBitMasterSetAsidesShortNames.displayArray, DatabaseMobx.c_valueDisplayArraysObjBitMasterSetAsidesShortNames.valueArray, "");
            importContactCompanyMap.set("sb_certifications_bm_set_aside_ids_comma", sbCertificationsBmSetAsideIDsComma);
          }
          else if(c === 4) { //Capabilities (input is comma list or comma list with spaces or semicolons)
            var capabilityIDsArray = JSFUNC.convert_comma_list_with_or_without_spaces_to_array_blank_entries_removed(csvCellString);
            var capabilityIDsComma = JSFUNC.convert_array_to_comma_list(capabilityIDsArray);
            //var capabilityIDsComma = JSFUNC.get_comma_list_of_array2_values_where_array1_matches_input_comma_string(csvCellString, DatabaseMobx.c_valueDisplayArraysObjCapabilities.displayArray, DatabaseMobx.c_valueDisplayArraysObjCapabilities.valueArray, "");
            importContactCompanyMap.set("capability_ids_comma", capabilityIDsComma);
          }
          else if(c === 5) { //NAICS (input is NAICS 6 digit code numbers in a comma list or comma list with spaces or semicolons)
            var naicsCodeCodesArray = JSFUNC.convert_comma_list_with_or_without_spaces_to_array_blank_entries_removed(csvCellString);
            var naicsCodeCodesComma = JSFUNC.convert_array_to_comma_list(naicsCodeCodesArray);
            var naicsCodeIDsComma = JSFUNC.get_comma_list_of_array2_values_where_array1_matches_input_comma_string(naicsCodeCodesComma, DatabaseMobx.c_valueDisplayArraysObjBitMasterNaicsCodesCodes.displayArray, DatabaseMobx.c_valueDisplayArraysObjBitMasterNaicsCodesCodes.valueArray, "");
            importContactCompanyMap.set("naics_code_ids_comma", naicsCodeIDsComma);
          }
          else { //extra company fields (assumed to all be text/textarea string types)
            var extraFieldIndex = (c - 6);
            if(extraFieldIndex < DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs.length) {
              var contactsCompaniesExtraFieldObj = DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs[extraFieldIndex];
              importContactCompanyMap.set(contactsCompaniesExtraFieldObj.db_name, csvCellString);
            }
          }
        }

        var contactCompanyObj = ContactsMobx.contact_company_obj_from_tbl_g_contacts_companies_row_map(importContactCompanyMap);

        var businessTypeMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(contactCompanyObj.business_type_id, DatabaseMobx.c_selectBusinessTypeFieldTypeObj);
        var sbCertificationsMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(contactCompanyObj.sb_certifications_bm_set_aside_ids_comma, DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj);
        var capabilitiesMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(contactCompanyObj.capability_ids_comma, DatabaseMobx.c_selectMultiCapabilitiesFieldTypeObj);
        var naicsCodesMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(contactCompanyObj.naics_code_ids_comma, DatabaseMobx.c_selectMultiBitMasterNaicsCodesCombinedNamesFieldTypeObj);

        contactCompanyObj.businessTypeMask = businessTypeMaskSortIfoObj.valueMask;
        contactCompanyObj.businessTypeMaskPlainText = businessTypeMaskSortIfoObj.valueMaskPlainText;
        contactCompanyObj.sbCertificationsMask = sbCertificationsMaskSortIfoObj.valueMask;
        contactCompanyObj.sbCertificationsMaskPlainText = sbCertificationsMaskSortIfoObj.valueMaskPlainText;
        contactCompanyObj.capabilitiesMask = capabilitiesMaskSortIfoObj.valueMask;
        contactCompanyObj.capabilitiesMaskPlainText = capabilitiesMaskSortIfoObj.valueMaskPlainText;
        contactCompanyObj.naicsCodesMask = naicsCodesMaskSortIfoObj.valueMask;
        contactCompanyObj.naicsCodesMaskPlainText = naicsCodesMaskSortIfoObj.valueMaskPlainText;

        //determine if contact company name is blank
        contactCompanyObj.isBlankTF = (contactCompanyObj.legal_name === "");

        //determine if contact company row is a duplicate from this import
        var uniqueContactName = contactCompanyObj.legal_name.toLowerCase();
        if(!JSFUNC.in_array(uniqueContactName, allUniqueContactNamesArray) || contactCompanyObj.isBlankTF) { //don't mark blanks as duplicate
          contactCompanyObj.isDuplicateTF = false;
          allUniqueContactNamesArray.push(uniqueContactName);
        }
        else {
          contactCompanyObj.isDuplicateTF = true;
        }

        //determine if any contact company cells have invalid data which prevents import
        contactCompanyObj.companyLegalNameInvalidTF = (!JSFUNC.text_or_number_is_filled_out_tf(contactCompanyObj.legal_name));
        contactCompanyObj.businessTypeInvalidTF = (!businessTypeMaskSortIfoObj.isFilledOutTF);

        contactCompanyObj.rowInvalidPreventImportTF = (contactCompanyObj.personFirstNameInvalidTF || contactCompanyObj.businessTypeInvalidTF);

        convertedImportContactsArrayOfObjs.push(contactCompanyObj);
      }
    }

    //add a unique key field for each contact
    var contactCount = 1;
    for(let convertedImportContactObj of convertedImportContactsArrayOfObjs) {
      convertedImportContactObj.uniqueKey = contactCount;
      convertedImportContactObj.csvRowIndexAfterHeader = (contactCount - 1);
      contactCount++;
    }

    return(convertedImportContactsArrayOfObjs);
  }

  get c_importContactsConvertedDataNoDuplicatesArrayOfObjs() {
    var importContactsConvertedDataNoDuplicatesArrayOfObjs = [];
    for(let contactConvertedDataObj of this.c_importContactsConvertedDataArrayOfObjs) {
      if(!contactConvertedDataObj.isDuplicateTF && !contactConvertedDataObj.isBlankTF) {
        importContactsConvertedDataNoDuplicatesArrayOfObjs.push(contactConvertedDataObj);
      }
    }
    return(importContactsConvertedDataNoDuplicatesArrayOfObjs);
  }

  get c_importNumContactsNoDuplicatesToImport() {
    return(this.c_importContactsConvertedDataNoDuplicatesArrayOfObjs.length);
  }

  get c_importContactsAtLeast1NonDuplicateRowHasInvalidDataCellTF() {
    for(let contactConvertedDataObj of this.c_importContactsConvertedDataArrayOfObjs) {
      if(!contactConvertedDataObj.isDuplicateTF && contactConvertedDataObj.rowInvalidPreventImportTF) {
        return(true);
      }
    }
    return(false);
  }



  get c_importProcessCurrentlyInProgressTF() {
    return(this.o_importDatabaseProgressFlag === "importing");
  }

  get c_importProcessIsFinishedTF() {
    return(this.o_importDatabaseProgressFlag === "finished");
  }











  a_import_set_import_flag(i_importFlag) {
    const o_importFlag = this.o_importFlag; //previous tab before switching to the new one provided in the input

    var updatedCapturesImportTrueUpdateFalse = true;
    var updatedContactsIsPersonTF = false;
    if(JSFUNC.in_array(i_importFlag, ["importCapturesUploadCsv", "updateCapturesUploadCsv", "importContactCompaniesUploadCsv", "importContactPersonsUploadCsv"])) { //cancelling import and returning back to upload csv screen
      //set tf flags for captures/contacts based on initial .csv upload tab currently switching to
      updatedCapturesImportTrueUpdateFalse = (i_importFlag === "updateCapturesUploadCsv");
      updatedContactsIsPersonTF = (i_importFlag === "importContactPersonsUploadCsv");

      //reset all uploaded .csv parameters
      this.o_importUploadedCsvFileName = undefined;
      this.o_importUploadedCsvFileDataString = undefined;
      this.o_importUploadedCsvFileHasFirstHeaderRowTF = true;
      this.o_importDatabaseProgressFlag = undefined;
      this.o_importDatabaseProgressCurrentCaptureIndexBeingImported = 0;
      this.o_importDatabaseManualCancelRecursionFlagTF = false;
      this.a_import_update_captures_success_skipped_failures_obj(undefined, undefined, undefined);
    }
    else if(i_importFlag === "capturesAnalysisTranslationAndImportOrUpdate") { //having csv ready and proceeding to translation
      //set tf flags for captures/contacts based on initial .csv upload tab switching away from
      updatedCapturesImportTrueUpdateFalse = (o_importFlag !== "updateCapturesUploadCsv");

      //give every new import an auto assign for added date being today to start
      var initialAutoAssignDateCaptureFieldID = -1;
      if(updatedCapturesImportTrueUpdateFalse) { //import captures, inject 'Added Date' as first Auto Assign field with today's date
        initialAutoAssignDateCaptureFieldID = DatabaseMobx.c_fieldMapOfAddedDate.get("id");
      }
      else { //update captures, inject 'Last Changed Date' as first Auto Assign field with today's date
        initialAutoAssignDateCaptureFieldID = DatabaseMobx.c_fieldMapOfLastChangedDate.get("id");
      }

      var initialCurrentAutoAssignsMapOfMaps = new Map();
      if(JSFUNC.select_int_is_filled_out_tf(initialAutoAssignDateCaptureFieldID)) { //if a valid date field was found, inject it
        const todayDate = JSFUNC.now_date();
        const initialAutoAssignMap = JSFUNC.map_from_obj({id:1, import_preset_id:-1, field_id:initialAutoAssignDateCaptureFieldID, captureexec_raw_value_string:todayDate});
        initialCurrentAutoAssignsMapOfMaps = new Map([[1, initialAutoAssignMap]]);
      }
      
      //initialize preset vars for new import
      this.o_importLoadSavePresetFlag = "init";
      this.o_importSelectedImportPresetID = -1;
      this.o_importLastChangesSavedTF = true;
      this.o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = -1;
      this.o_importVisibleCsvCapturesStartingIndex = 0;
      this.o_importCurrentAutoAssignsMapOfMaps = initialCurrentAutoAssignsMapOfMaps;
      this.o_importCurrentColumnAssignsMapOfMaps = new Map();
      this.o_importCurrentCustomAssignsMapOfMaps = new Map();
    }
    else if(i_importFlag === "contactsAnalysisAndImport") {
      //set tf flags for captures/contacts based on initial .csv upload tab switching away from
      updatedContactsIsPersonTF = (o_importFlag === "importContactPersonsUploadCsv");

      //for contact persons, run a conversion on column A (parent contact company) and convert the text .csv values to contact company IDs
      /*if(o_importContactsIsPersonTF) {

      }*/
    }

    //set as new input importFlag string
    this.o_importFlag = i_importFlag;
    this.o_importCapturesImportTrueUpdateFalse = updatedCapturesImportTrueUpdateFalse;
    this.o_importContactsIsPersonTF = updatedContactsIsPersonTF;
  }

  a_import_set_uploaded_csv_file_name(i_uploadedCsvFileName) {
    this.o_importUploadedCsvFileName = i_uploadedCsvFileName;
  }

  a_import_set_uploaded_csv_file_data_string(i_uploadedCsvFileDataString) {
    this.o_importUploadedCsvFileDataString = i_uploadedCsvFileDataString;
  }

  a_import_toggle_csv_file_has_first_header_row_tf() {
    this.o_importUploadedCsvFileHasFirstHeaderRowTF = (!this.o_importUploadedCsvFileHasFirstHeaderRowTF);
  }

  a_import_set_load_save_preset_flag(i_loadSavePresetFlag) {
    this.o_importLoadSavePresetFlag = i_loadSavePresetFlag;
  }

  a_import_set_selected_import_preset_id(i_selectedImportPresetID) {
    this.o_importSelectedImportPresetID = i_selectedImportPresetID;
  }

  a_import_set_saving_inserted_new_preset_or_deleted_old_preset_settings_tf(i_newValueTF) { this.o_importSavingInsertedNewPresetOrDeletedOldPresetSettingsTF = i_newValueTF; }
  a_import_set_saving_num_total_auto_assigns(i_newValue) { this.o_importSavingNumTotalAutoAssigns = i_newValue; }
  a_import_set_saving_num_completed_auto_assigns(i_newValue) { this.o_importSavingNumCompletedAutoAssigns = i_newValue; }
  a_import_set_saving_num_total_column_assigns(i_newValue) { this.o_importSavingNumTotalColumnAssigns = i_newValue; }
  a_import_set_saving_num_completed_column_assigns(i_newValue) { this.o_importSavingNumCompletedColumnAssigns = i_newValue; }
  a_import_set_saving_num_total_custom_assigns(i_newValue) { this.o_importSavingNumTotalCustomAssigns = i_newValue; }
  a_import_set_saving_num_completed_custom_assigns(i_newValue) { this.o_importSavingNumCompletedCustomAssigns = i_newValue; }

  a_import_set_last_changes_saved_tf(i_lastChangedSavedTF) {
    this.o_importLastChangesSavedTF = i_lastChangedSavedTF;
  }

  a_import_load_selected_import_preset() {
    const o_importSelectedImportPresetID = this.o_importSelectedImportPresetID;

    //empty the existing translation settings
    this.o_importCurrentAutoAssignsMapOfMaps = new Map();
    this.o_importCurrentColumnAssignsMapOfMaps = new Map();
    this.o_importCurrentCustomAssignsMapOfMaps = new Map();

    //preset record
    const importPresetMapOrUndefined = DatabaseMobx.o_tbl_a_import_presets.get(o_importSelectedImportPresetID);
    if(importPresetMapOrUndefined !== undefined) {
      this.a_import_set_update_unique_key_field_selected_csv_column_index_or_m1(importPresetMapOrUndefined.get("update_unique_key_field_column_index_or_m1"));
    }

    //auto assigns
    for(let presetAutoAssignMap of DatabaseMobx.o_tbl_a_import_auto_assigns.values()) {
      if(presetAutoAssignMap.get("import_preset_id") === o_importSelectedImportPresetID) {
        this.o_importCurrentAutoAssignsMapOfMaps.set(presetAutoAssignMap.get("id"), presetAutoAssignMap);
      }
    }

    //column assigns
    for(let presetColumnAssignMap of DatabaseMobx.o_tbl_a_import_column_assigns.values()) {
      if(presetColumnAssignMap.get("import_preset_id") === o_importSelectedImportPresetID) {
        this.o_importCurrentColumnAssignsMapOfMaps.set(presetColumnAssignMap.get("id"), presetColumnAssignMap);
      }
    }

    //custom assigns
    for(let presetCustomAssignMap of DatabaseMobx.o_tbl_a_import_custom_assigns.values()) {
      if(presetCustomAssignMap.get("import_preset_id") === o_importSelectedImportPresetID) {
        this.o_importCurrentCustomAssignsMapOfMaps.set(presetCustomAssignMap.get("id"), presetCustomAssignMap);
      }
    }

    //since this saved preset was just loaded, the last changes are saved
    this.a_import_set_last_changes_saved_tf(true);
  }

  a_import_delete_import_preset_from_id(i_importPresetIDToDelete, i_deleteImportPresetRecordTF=true, i_functionOnSuccess=undefined, i_functionOnError=undefined) {
    //delete the existing records for this overwritten preset
    const jsDescription = JSFUNC.js_description_from_action("AdminImportMobx", "a_import_delete_import_preset_from_id", ["i_importPresetIDToDelete", "i_deleteImportPresetRecordTF"], [i_importPresetIDToDelete, i_deleteImportPresetRecordTF]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const autoAssignRecordIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_a_import_auto_assigns, "import_preset_id", i_importPresetIDToDelete, "id");
    C_CallPhpTblUID.add_delete("tbl_a_import_auto_assigns", autoAssignRecordIDsToDeleteArray); //no tbl sort column for delete resort

    const columnAssignRecordIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_a_import_column_assigns, "import_preset_id", i_importPresetIDToDelete, "id");
    C_CallPhpTblUID.add_delete("tbl_a_import_column_assigns", columnAssignRecordIDsToDeleteArray); //no tbl sort column for delete resort

    const customAssignRecordIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_a_import_custom_assigns, "import_preset_id", i_importPresetIDToDelete, "id");
    C_CallPhpTblUID.add_delete("tbl_a_import_custom_assigns", customAssignRecordIDsToDeleteArray); //no tbl sort column for delete resort

    if(i_deleteImportPresetRecordTF) {
      C_CallPhpTblUID.add_delete("tbl_a_import_presets", i_importPresetIDToDelete); //no tbl sort column for delete resort
    }

    if(JSFUNC.is_function(i_functionOnSuccess) && JSFUNC.is_function(i_functionOnError)) {
      const functionOnSuccess = (i_parseResponse) => {
        var allDeletesSuccessfulTF = true;
        for(var propertyName in i_parseResponse.outputObj) {
          if(i_parseResponse.outputObj.hasOwnProperty(propertyName)) {
            if(i_parseResponse.outputObj[propertyName] === "0") {
              allDeletesSuccessfulTF = false;
            }
          }
        }

        if(allDeletesSuccessfulTF) { //successful deletes in auto/column/custom assign tbls
          i_functionOnSuccess(i_parseResponse);
        }
        else {
          i_functionOnError();
        }
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    if(JSFUNC.is_function(i_functionOnError)) {
      C_CallPhpTblUID.add_function("onError", i_functionOnError);
    }

    C_CallPhpTblUID.execute();
  }


  a_import_save_current_settings_as_new_or_overwrite_existing_import_preset(i_newImportPresetSaveName) {
    const o_importCapturesImportTrueUpdateFalse = this.o_importCapturesImportTrueUpdateFalse;
    const o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = this.o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1;
    
    //set the flag to 'saving' to open the save progress floating box
    this.a_import_set_load_save_preset_flag("saving");

    //set the saving progress count variables
    const numAutoAssigns = this.o_importCurrentAutoAssignsMapOfMaps.size;
    const numColumnAssigns = this.o_importCurrentColumnAssignsMapOfMaps.size;
    const numCustomAssigns = this.o_importCurrentCustomAssignsMapOfMaps.size;

    this.a_import_set_saving_inserted_new_preset_or_deleted_old_preset_settings_tf(false);
    this.a_import_set_saving_num_completed_auto_assigns(0);
    this.a_import_set_saving_num_total_auto_assigns(numAutoAssigns);
    this.a_import_set_saving_num_completed_column_assigns(0);
    this.a_import_set_saving_num_total_column_assigns(numColumnAssigns);
    this.a_import_set_saving_num_completed_custom_assigns(0);
    this.a_import_set_saving_num_total_custom_assigns(numCustomAssigns);

    const jsDescription = JSFUNC.js_description_from_action("AdminImportMobx", "a_import_save_level1", ["i_newImportPresetSaveName"], [i_newImportPresetSaveName]);

    const functionOnSuccessFullSave = () => {
      //reset the save/load preset back to its initial state (and closes the saving progress floating box)
      this.a_import_set_load_save_preset_flag("savingComplete");

      //mark that the latest changes to the current import settings have been saved as a preset
      this.a_import_set_last_changes_saved_tf(true);
    }

    const functionOnErrorFullSave = () => {
      this.a_import_set_load_save_preset_flag("savingError");
    }

    //try to find an existing import preset with the exact same name as the one provided
    const existingImportPresetMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_a_import_presets, "name", i_newImportPresetSaveName);
    if(existingImportPresetMap !== undefined) { //an existing preset name was provided, delete all entries for the old preset and write in the new ones using the same id number
      const existingImportPresetID = existingImportPresetMap.get("id");

      //if saving an update preset, overwrite the database unique key/ID selected .csv column index in the preset record (which is not deleted in the delete command below)
      if(!o_importCapturesImportTrueUpdateFalse) {
        const C_CallPhpTblUIDUpdateExistingPresetRecord = new JSPHP.ClassCallPhpTblUID(jsDescription);
        C_CallPhpTblUIDUpdateExistingPresetRecord.add_update("tbl_a_import_presets", existingImportPresetID, "update_unique_key_field_column_index_or_m1", o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1, "i");
        C_CallPhpTblUIDUpdateExistingPresetRecord.execute();
      }

      const deleteImportPresetRecordTF = false;

      const functionOnSuccess = (i_parseResponse) => {
        this.a_import_save_level1(existingImportPresetID, functionOnSuccessFullSave, functionOnErrorFullSave);
      }

      this.a_import_delete_import_preset_from_id(existingImportPresetID, deleteImportPresetRecordTF, functionOnSuccess, functionOnErrorFullSave);
    }
    else { //a unique preset name was provided, create a new preset record and get its new id number
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const newImportPresetImport0Update1 = ((o_importCapturesImportTrueUpdateFalse) ? (0) : (1));

      const importPresetFieldNamesArray = ["name", "import0_update1", "update_unique_key_field_column_index_or_m1"];
      const importPresetValuesArray = [i_newImportPresetSaveName, newImportPresetImport0Update1, o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1];
      const importPresetIdsbArray = ["s", "i", "i"];
      C_CallPhpTblUID.add_insert("tbl_a_import_presets", importPresetFieldNamesArray, importPresetValuesArray, importPresetIdsbArray);

      const functionOnSuccess = (i_parseResponse) => {
        const newlyInsertedEntryID = i_parseResponse.outputObj.i0;
        if(newlyInsertedEntryID !== "0") { //successful tblUID insert
          this.a_import_save_level1(newlyInsertedEntryID, functionOnSuccessFullSave, functionOnErrorFullSave);
        }
        else {
          functionOnErrorFullSave();
        }
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      C_CallPhpTblUID.add_function("onError", functionOnErrorFullSave);

      C_CallPhpTblUID.execute();
    }
  }

  a_import_save_level1(i_importPresetID, i_functionOnSuccessFullSave, i_functionOnErrorFullSave) {
    //set the saving progress that the previous step of inserting a new preset or deleting the data from the existing one is complete
    this.a_import_set_saving_inserted_new_preset_or_deleted_old_preset_settings_tf(true);

    //set the selected import preset as this presetID
    this.a_import_set_selected_import_preset_id(i_importPresetID);

    const numCompletedAutoAssigns = this.o_importSavingNumCompletedAutoAssigns;
    const numTotalAutoAssigns = this.o_importSavingNumTotalAutoAssigns;
    const currentAutoAssignsMapOfMaps = this.o_importCurrentAutoAssignsMapOfMaps;

    //insert all auto assign records
    const jsDescription = JSFUNC.js_description_from_action("AdminImportMobx", "a_import_save_level1", ["i_importPresetID"], [i_importPresetID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const autoAssignFieldNamesArray = ["import_preset_id", "field_id", "captureexec_raw_value_string"];
    const autoAssignIdsbArray = ["i", "i", "s"];
    for(let currentAutoAssignMap of this.o_importCurrentAutoAssignsMapOfMaps.values()) {
      var autoAssignValuesArray = [i_importPresetID, currentAutoAssignMap.get("field_id"), currentAutoAssignMap.get("captureexec_raw_value_string")];
      C_CallPhpTblUID.add_insert("tbl_a_import_auto_assigns", autoAssignFieldNamesArray, autoAssignValuesArray, autoAssignIdsbArray);
    }

    const functionOnSuccess = (i_parseResponse) => {
      var allInsertsSuccessfulTF = true;
      for(var propertyName in i_parseResponse.outputObj) {
        if(i_parseResponse.outputObj.hasOwnProperty(propertyName)) {
          if(i_parseResponse.outputObj[propertyName] === "0") {
            allInsertsSuccessfulTF = false;
          }
        }
      }

      if(allInsertsSuccessfulTF) { //successful tblUID inserts
        this.a_import_set_saving_num_completed_auto_assigns(numTotalAutoAssigns);

        this.a_import_save_level2_recursive(i_importPresetID, i_functionOnSuccessFullSave, i_functionOnErrorFullSave);
      }
      else {
        i_functionOnErrorFullSave();
      }
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.add_function("onError", i_functionOnErrorFullSave);

    C_CallPhpTblUID.execute();
  }

  a_import_save_level2_recursive(i_importPresetID, i_functionOnSuccessFullSave, i_functionOnErrorFullSave) {
    const numCompletedColumnAssigns = this.o_importSavingNumCompletedColumnAssigns;
    const numTotalColumnAssigns = this.o_importSavingNumTotalColumnAssigns;
    const currentColumnAssignsMapOfMaps = this.o_importCurrentColumnAssignsMapOfMaps;

    const maxInserts = 100;

    var finalBatchTF = false;
    const startCount = (numCompletedColumnAssigns + 1);
    var endCount = (numCompletedColumnAssigns + maxInserts);
    if(endCount >= numTotalColumnAssigns) {
      endCount = numTotalColumnAssigns;
      finalBatchTF = true;
    }

    //insert all column assign records
    const jsDescription = JSFUNC.js_description_from_action("AdminImportMobx", "a_import_save_level2_recursive", ["i_importPresetID"], [i_importPresetID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const columnAssignFieldNamesArray = ["import_preset_id", "column_index", "mapping_type_id", "field_id", "collapsed_01", "percent_field_multiply_100_01"];
    const columnAssignIdsbArray = ["i", "i", "i", "i", "i", "i"];
    var count = 1;
    for(let currentColumnAssignMap of currentColumnAssignsMapOfMaps.values()) {
      if((count >= startCount) && (count <= endCount)) {
        var columnAssignValuesArray = [i_importPresetID, currentColumnAssignMap.get("column_index"), currentColumnAssignMap.get("mapping_type_id"), currentColumnAssignMap.get("field_id"), currentColumnAssignMap.get("collapsed_01"), currentColumnAssignMap.get("percent_field_multiply_100_01")];
        C_CallPhpTblUID.add_insert("tbl_a_import_column_assigns", columnAssignFieldNamesArray, columnAssignValuesArray, columnAssignIdsbArray);
      }
      count++;

      if(count > endCount) {
        break;
      }
    }

    const functionOnSuccess = (i_parseResponse) => {
      var allInsertsSuccessfulTF = true;
      for(var propertyName in i_parseResponse.outputObj) {
        if(i_parseResponse.outputObj.hasOwnProperty(propertyName)) {
          if(i_parseResponse.outputObj[propertyName] === "0") {
            allInsertsSuccessfulTF = false;
          }
        }
      }

      if(allInsertsSuccessfulTF) { //successful tblUID inserts
        this.a_import_set_saving_num_completed_column_assigns(endCount);

        if(finalBatchTF) { //all column assigns are finished
          this.a_import_save_level3_recursive(i_importPresetID, i_functionOnSuccessFullSave, i_functionOnErrorFullSave);
        }
        else { //work on the next batch of column assign inserts
          this.a_import_save_level2_recursive(i_importPresetID, i_functionOnSuccessFullSave, i_functionOnErrorFullSave);
        }
      }
      else {
        i_functionOnErrorFullSave();
      }
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.add_function("onError", i_functionOnErrorFullSave);

    C_CallPhpTblUID.execute();
  }

  a_import_save_level3_recursive(i_importPresetID, i_functionOnSuccessFullSave, i_functionOnErrorFullSave) {
    const numCompletedCustomAssigns = this.o_importSavingNumCompletedCustomAssigns;
    const numTotalCustomAssigns = this.o_importSavingNumTotalCustomAssigns;
    const currentCustomAssignsMapOfMaps = this.o_importCurrentCustomAssignsMapOfMaps;

    const maxInserts = 100;

    var finalBatchTF = false;
    const startCount = (numCompletedCustomAssigns + 1);
    var endCount = (numCompletedCustomAssigns + maxInserts);
    if(endCount >= numTotalCustomAssigns) {
      endCount = numTotalCustomAssigns;
      finalBatchTF = true;
    }

    //insert all custom assign records
    const jsDescription = JSFUNC.js_description_from_action("AdminImportMobx", "a_import_save_level3_recursive", ["i_importPresetID"], [i_importPresetID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const customAssignFieldNamesArray = ["import_preset_id", "column_index", "csv_value_string", "captureexec_raw_value_string", "intentionally_unassigned_01"];
    const customAssignIdsbArray = ["i", "i", "s", "s", "i"];
    var count = 1;
    for(let currentCustomAssignMap of currentCustomAssignsMapOfMaps.values()) {
      if((count >= startCount) && (count <= endCount)) {
        var customAssignValuesArray = [i_importPresetID, currentCustomAssignMap.get("column_index"), currentCustomAssignMap.get("csv_value_string"), currentCustomAssignMap.get("captureexec_raw_value_string"), currentCustomAssignMap.get("intentionally_unassigned_01")];
        C_CallPhpTblUID.add_insert("tbl_a_import_custom_assigns", customAssignFieldNamesArray, customAssignValuesArray, customAssignIdsbArray);
      }
      count++;

      if(count > endCount) {
        break;
      }
    }

    const functionOnSuccess = (i_parseResponse) => {
      var allInsertsSuccessfulTF = true;
      for(var propertyName in i_parseResponse.outputObj) {
        if(i_parseResponse.outputObj.hasOwnProperty(propertyName)) {
          if(i_parseResponse.outputObj[propertyName] === "0") {
            allInsertsSuccessfulTF = false;
          }
        }
      }

      if(allInsertsSuccessfulTF) { //successful tblUID inserts
        this.a_import_set_saving_num_completed_custom_assigns(endCount);

        if(finalBatchTF) { //all custom assigns are finished
          i_functionOnSuccessFullSave();
        }
        else { //work on the next batch of custom assign inserts
          this.a_import_save_level3_recursive(i_importPresetID, i_functionOnSuccessFullSave, i_functionOnErrorFullSave);
        }
      }
      else {
        i_functionOnErrorFullSave();
      }
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.add_function("onError", i_functionOnErrorFullSave);

    C_CallPhpTblUID.execute();
  }


  a_import_set_update_unique_key_field_selected_csv_column_index_or_m1(i_newValueInt) {
    this.o_importUpdateUniqueKeyFieldSelectedCsvColumnIndexOrM1 = i_newValueInt;

    //updating the unique key/ID field mapping resets the preset save flag
    this.a_import_set_last_changes_saved_tf(false);
  }


  a_import_add_skip_value_to_visible_csv_captures_starting_index(i_indexSkipValue) {
    this.o_importVisibleCsvCapturesStartingIndex += i_indexSkipValue;
  }

  a_import_add_column_index_to_column_indices_viewing_other_mapping_types_array(i_columnIndex) {
    if(!JSFUNC.in_array(i_columnIndex, this.o_importColumnIndicesViewingOtherMappingTypesArray)) {
      this.o_importColumnIndicesViewingOtherMappingTypesArray.push(i_columnIndex);
    }
  }

  a_import_remove_column_index_from_column_indices_viewing_other_mapping_types_array(i_columnIndex) {
    if(JSFUNC.in_array(i_columnIndex, this.o_importColumnIndicesViewingOtherMappingTypesArray)) {
      this.o_importColumnIndicesViewingOtherMappingTypesArray = JSFUNC.remove_all_values_from_array(i_columnIndex, this.o_importColumnIndicesViewingOtherMappingTypesArray);
    }
  }

  a_import_set_current_auto_assign_captureexec_raw_value_string(i_fieldID, i_newCaptureExecRawValue) {
    var foundExistingCurrentAutoAssignEntryTF = false;
    var largestID = 1;
    for(let currentAutoAssignMap of this.o_importCurrentAutoAssignsMapOfMaps.values()) {
      if(currentAutoAssignMap.get("field_id") === i_fieldID) {
        currentAutoAssignMap.set("captureexec_raw_value_string", i_newCaptureExecRawValue);
        foundExistingCurrentAutoAssignEntryTF = true;
        break;
      }

      var columnID = currentAutoAssignMap.get("id");
      if(columnID > largestID) {
        largestID = columnID;
      }
    }

    //if this column_index does not exist in the list, add it with a unique id of the largest found id + 1
    if(!foundExistingCurrentAutoAssignEntryTF) {
      const newAutoAssignRecordID = (largestID + 1);
      const newAutoAssignRecordMap = JSFUNC.map_from_obj({id:newAutoAssignRecordID, import_preset_id:-1, field_id:i_fieldID, captureexec_raw_value_string:i_newCaptureExecRawValue});

      this.o_importCurrentAutoAssignsMapOfMaps.set(newAutoAssignRecordID, newAutoAssignRecordMap);

      //when a new auto assign field is added, open the pencil for editing right then
      const ceEditItemString = "importAutoAssignID" + newAutoAssignRecordID;
      CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id(ceEditItemString, false);
    }

    //all updates to the translation resets the preset save flag
    this.a_import_set_last_changes_saved_tf(false);
  }

  a_import_delete_current_auto_assign_record_from_id(i_currentAutoAssignRecordIDToDelete) {
    this.o_importCurrentAutoAssignsMapOfMaps.delete(i_currentAutoAssignRecordIDToDelete);

    //all updates to the translation resets the preset save flag
    this.a_import_set_last_changes_saved_tf(false);
  }

  a_import_set_current_column_assign_field_from_column_index(i_columnIndex, i_fieldNameOrArrayOfFieldNamesToUpdate, i_newValueOrArrayOfValues) {
    //convert the input to arrays
    const fieldNamesToUpdateArray = JSFUNC.convert_single_or_array_to_array(i_fieldNameOrArrayOfFieldNamesToUpdate); //can update "mapping_type_id", "field_id", "collapsed_01", or "percent_field_multiply_100_01"
    const newValuesArray = JSFUNC.convert_single_or_array_to_array(i_newValueOrArrayOfValues);

    //search for existing column_index to update the field with the new value
    var foundExistingCurrentColumnAssignEntryTF = false;
    var largestID = 0;
    for(let currentColumnAssignMap of this.o_importCurrentColumnAssignsMapOfMaps.values()) {
      if(currentColumnAssignMap.get("column_index") === i_columnIndex) {
        foundExistingCurrentColumnAssignEntryTF = true;
        for(let f = 0; f < fieldNamesToUpdateArray.length; f++) {
          currentColumnAssignMap.set(fieldNamesToUpdateArray[f], newValuesArray[f]);
        }
        break;
      }

      var columnID = currentColumnAssignMap.get("id");
      if(columnID > largestID) {
        largestID = columnID;
      }
    }

    //if this column_index does not exist in the list, add it with a unique id of the largest found id + 1
    if(!foundExistingCurrentColumnAssignEntryTF) {
      const newUniqueColumnRecordID = (largestID + 1);

      const newIgnoreColumnRecordMap = JSFUNC.map_from_obj({
        id: newUniqueColumnRecordID,
        import_preset_id: -1,
        column_index: i_columnIndex,
        mapping_type_id: -1,
        field_id: -1,
        collapsed_01: 0,
        percent_field_multiply_100_01: 0
      });

      //overwrite the initialized value for the new column assign entry with the new value for the specified field
      for(let f = 0; f < fieldNamesToUpdateArray.length; f++) {
        newIgnoreColumnRecordMap.set(fieldNamesToUpdateArray[f], newValuesArray[f]);
      }
      this.o_importCurrentColumnAssignsMapOfMaps.set(newUniqueColumnRecordID, newIgnoreColumnRecordMap);
    }

    //if the mapping_type_id/field_id was updated, delete any custom assign records for this column index
    if(JSFUNC.in_array("mapping_type_id", fieldNamesToUpdateArray) || JSFUNC.in_array("field_id", fieldNamesToUpdateArray)) {
      this.a_import_delete_all_current_custom_assign_records_for_matching_column_index(i_columnIndex);
    }

    //all updates to the translation resets the preset save flag
    this.a_import_set_last_changes_saved_tf(false);
  }

  a_import_delete_current_column_assign_record_and_all_mapped_custom_assign_records(i_columnIndex) {
    //erase any custom assigns that match this column
    const currentCustomAssignsToDeleteArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(this.o_importCurrentCustomAssignsMapOfMaps, "column_index", i_columnIndex);
    for(let currentCustomAssignToDeleteObj of currentCustomAssignsToDeleteArrayOfObjs) {
      this.o_importCurrentCustomAssignsMapOfMaps.delete(currentCustomAssignToDeleteObj.id);
    }

    //delete the column assign record that matches the input column index
    const currentColumnAssignsToDeleteArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(this.o_importCurrentColumnAssignsMapOfMaps, "column_index", i_columnIndex);
    for(let currentColumnAssignsToDeleteObj of currentColumnAssignsToDeleteArrayOfObjs) {
      this.o_importCurrentColumnAssignsMapOfMaps.delete(currentColumnAssignsToDeleteObj.id);
    }

    //all updates to the translation resets the preset save flag
    this.a_import_set_last_changes_saved_tf(false);

    //remove this column index from the list of columns viewing the other mapping types
    this.a_import_remove_column_index_from_column_indices_viewing_other_mapping_types_array(i_columnIndex);
  }

  a_import_set_current_custom_assign_captureexec_raw_value_string(i_columnIndex, i_csvValue, i_newCaptureExecRawValue, i_newIntentionallyUnassigned01) {
    var foundExistingCurrentCustomAssignEntryTF = false;
    var largestID = 1;
    for(let currentCustomAssignMap of this.o_importCurrentCustomAssignsMapOfMaps.values()) {
      if((currentCustomAssignMap.get("column_index") === i_columnIndex) && (currentCustomAssignMap.get("csv_value_string") === i_csvValue)) {
        currentCustomAssignMap.set("captureexec_raw_value_string", i_newCaptureExecRawValue);
        currentCustomAssignMap.set("intentionally_unassigned_01", i_newIntentionallyUnassigned01);
        foundExistingCurrentCustomAssignEntryTF = true;
        break;
      }

      var columnID = currentCustomAssignMap.get("id");
      if(columnID > largestID) {
        largestID = columnID;
      }
    }

    //if this column_index does not exist in the list, add it with a unique id of the largest found id + 1
    if(!foundExistingCurrentCustomAssignEntryTF) {
      const newCustomAssignRecordID = (largestID + 1);
      const newCustomAssignRecordMap = JSFUNC.map_from_obj({
        id: newCustomAssignRecordID,
        import_preset_id: -1,
        column_index: i_columnIndex,
        csv_value_string: i_csvValue,
        captureexec_raw_value_string: i_newCaptureExecRawValue,
        intentionally_unassigned_01: i_newIntentionallyUnassigned01
      });

      this.o_importCurrentCustomAssignsMapOfMaps.set(newCustomAssignRecordID, newCustomAssignRecordMap);
    }

    //all updates to the translation resets the preset save flag
    this.a_import_set_last_changes_saved_tf(false);
  }

  a_import_delete_all_current_custom_assign_records_for_matching_column_index(i_columnIndex) {
    //find all of the matching custom assign records for this column index
    var currentCustomAssignRecordIDsToDeleteArray = [];
    for(let [currentCustomAssignRecordID, currentCustomAssignMap] of this.o_importCurrentCustomAssignsMapOfMaps) {
      if(currentCustomAssignMap.get("column_index") === i_columnIndex) {
        currentCustomAssignRecordIDsToDeleteArray.push(currentCustomAssignRecordID);
      }
    }

    //delete the custom assign records
    for(let currentCustomAssignRecordID of currentCustomAssignRecordIDsToDeleteArray) {
      this.a_import_delete_current_custom_assign_record_from_current_custom_assign_id(currentCustomAssignRecordID);
    }
  }

  a_import_delete_current_custom_assign_record_from_current_custom_assign_id(i_currentCustomAssignRecordID) {
    if(i_currentCustomAssignRecordID > 0) { //set to -1 when the custom assign record does not exist
      this.o_importCurrentCustomAssignsMapOfMaps.delete(i_currentCustomAssignRecordID);

      //all updates to the translation resets the preset save flag
      this.a_import_set_last_changes_saved_tf(false);
    }
  }

  a_import_attempt_predictive_fill_in_of_mapping_custom_assigns(i_columnIndex, i_mappingTypeID, i_captureFieldID) {
    const columnObjDetailsFieldParametersObj = this.import_compute_column_obj_select_mapping_parameters_from_column_index_and_field_id(i_columnIndex, i_mappingTypeID, i_captureFieldID);
    var fieldTypeObj = columnObjDetailsFieldParametersObj.selectedCaptureFieldFieldTypeObj;
    if(fieldTypeObj !== undefined) {
      var selectWithSearchDataObj = fieldTypeObj.selectWithSearchDataObj;
      if(selectWithSearchDataObj !== undefined) { //if the selectWithSearchDataObj is valid within the fieldTypeObj
        if(fieldTypeObj.selectContactCompanyTF) { //contact company fill in
          for(let csvUniqueValueAndCustomAssignObj of columnObjDetailsFieldParametersObj.csvUniqueValuesAndCustomAssignsArrayOfObjs) { //loop through all unique csv column strings
            var matchingValue = undefined;
            if(selectWithSearchDataObj.isMultiSelectTF) { //contact company multiselect
              matchingValue = ContactsMobx.find_multiple_lowercase_exact_match_contact_company_ids_comma_or_undefined_from_comma_list_of_names_string(csvUniqueValueAndCustomAssignObj.value);
            }
            else { //contact company select
              matchingValue = ContactsMobx.find_first_lowecase_exact_match_contact_company_id_or_undefined_from_name_string(csvUniqueValueAndCustomAssignObj.value);
            }

            if(matchingValue !== undefined) {
              this.a_import_set_current_custom_assign_captureexec_raw_value_string(i_columnIndex, csvUniqueValueAndCustomAssignObj.value, matchingValue, 0);
            }
          }
        }
        else if(columnObjDetailsFieldParametersObj.selectedCaptureFieldRequiresCustomAssignmentTF) { //if this newly selected capture details field is a select/multiselect and requires mapping
          var valueArray = selectWithSearchDataObj.valueArray;
          var displayArray = selectWithSearchDataObj.displayArray;

          //naics code mappings to use 'code' column instead of 'name' combined column that's in the displayArray
          if(columnObjDetailsFieldParametersObj.selectedCaptureFieldSelectTblName === "tbl_bit_master_naics_codes") {
            valueArray = [];
            displayArray = [];
            for(let [naicsCodeID, naicsCodeMap] of DatabaseMobx.c_tbl_bit_master_naics_codes) {
              valueArray.push(naicsCodeID);
              displayArray.push(JSFUNC.num2str(naicsCodeMap.get("code")));
            }
          }

          if(JSFUNC.is_array(valueArray) && JSFUNC.is_array(displayArray)) { //ensure the selectWithSearchDataObj contains both the value and display arrays
            var numValues = valueArray.length;

            //convert all the displayArray values to lowercase
            var displayArrayLowercase = [];
            for(let v = 0; v < numValues; v++) {
              var display = displayArray[v];

              var displayLowercase = "";
              if(JSFUNC.is_string(display)) {
                displayLowercase = display.toLowerCase();
              }

              displayArrayLowercase.push(displayLowercase);
            }

            for(let csvUniqueValueAndCustomAssignObj of columnObjDetailsFieldParametersObj.csvUniqueValuesAndCustomAssignsArrayOfObjs) { //loop through all unique csv column strings
              var csvDisplayLowercase = "";
              if(JSFUNC.is_string(csvUniqueValueAndCustomAssignObj.value)) {
                csvDisplayLowercase = csvUniqueValueAndCustomAssignObj.value.toLowerCase();
              }

              var matchingValue = undefined;
              if(fieldTypeObj.sharedPercentTF) { //sharedpercent (special multiselect) field type predictive handling with and without colon percents in the input string
                var multiCsvDisplaysColonPercentsLowerArray = JSFUNC.convert_comma_list_with_or_without_spaces_to_array_blank_entries_removed(csvDisplayLowercase);
                var numCsvDisplayColonPercents = multiCsvDisplaysColonPercentsLowerArray.length;
                var equalPercentsAddTo100Array = JSFUNC.array_of_equal_ints_add_up_to_100(numCsvDisplayColonPercents);
                var matchingValuesAndPercentsArrayOfObjs = [];
                for(let d = 0; d < numCsvDisplayColonPercents; d++) {
                  var singleCsvDisplayColonPercentLower = multiCsvDisplaysColonPercentsLowerArray[d];
                  var equalPercent = equalPercentsAddTo100Array[d];
                  var valuePercentObj = JSFUNC.convert_value_colon_percent_string_to_value_percent_obj(singleCsvDisplayColonPercentLower, false, equalPercent); //split the display string from the percent by finding a colon, if a valid percent is not found, use the equal percent
                  var singleCsvDisplayLower = valuePercentObj.value;
                  var singleCsvPercentOrEqualPercent = valuePercentObj.percent;
                  var singleMatchingValue = JSFUNC.get_first_array2_value_or_undefined_where_array1_matches_input(singleCsvDisplayLower, displayArrayLowercase, valueArray);
                  if(singleMatchingValue !== undefined) {
                    matchingValuesAndPercentsArrayOfObjs.push({
                      value: singleMatchingValue,
                      percent: singleCsvPercentOrEqualPercent
                    });
                  }
                }

                if(matchingValuesAndPercentsArrayOfObjs.length > 0) {
                  matchingValue = JSFUNC.convert_arrayOfObjs_to_colon_comma_list(matchingValuesAndPercentsArrayOfObjs, "value", "percent"); //colon comma list string of matching values and percents (like "3:40,15:35,6:20,12:5")
                }
              }
              else if(selectWithSearchDataObj.isMultiSelectTF) { //multiselect
                matchingValue = JSFUNC.get_comma_list_of_array2_values_where_array1_matches_input_comma_string(csvDisplayLowercase, displayArrayLowercase, valueArray, undefined); //comma list string of matching values (usually id numbers like "3,15,6,12")
              }
              else { //select
                matchingValue = JSFUNC.get_first_array2_value_or_undefined_where_array1_matches_input(csvDisplayLowercase, displayArrayLowercase, valueArray, undefined);
              }

              //if a matching display within the select options was found, create a new custom assign record for this column index
              if(matchingValue !== undefined) {
                this.a_import_set_current_custom_assign_captureexec_raw_value_string(i_columnIndex, csvUniqueValueAndCustomAssignObj.value, matchingValue, 0);
              }
            }
          }
        }
      }
    }
  }

  a_import_update_single_csv_cell_in_csv_file_data_string(i_csvRowIndexAfterHeader, i_csvColumnIndex, i_newCsvValueString, i_oldCsvValueString) {
    const o_importUploadedCsvFileDataString = this.o_importUploadedCsvFileDataString;
    const o_importUploadedCsvFileHasFirstHeaderRowTF = this.o_importUploadedCsvFileHasFirstHeaderRowTF;

    if(i_newCsvValueString !== i_oldCsvValueString) {
      const csvFileDataRowIndexToUpdate = ((o_importUploadedCsvFileHasFirstHeaderRowTF) ? (i_csvRowIndexAfterHeader + 1) : (i_csvRowIndexAfterHeader));
      var copyCsvFileDataArrayOfArrays = JSFUNC.read_csv_file_data_string_into_arrayOfArrays(o_importUploadedCsvFileDataString);
      copyCsvFileDataArrayOfArrays[csvFileDataRowIndexToUpdate][i_csvColumnIndex] = i_newCsvValueString;
      const updatedCsvFileDataString = JSFUNC.convert_data_table_to_csv_string(copyCsvFileDataArrayOfArrays);
      this.a_import_set_uploaded_csv_file_data_string(updatedCsvFileDataString);
    }
  }

  a_import_set_import_database_progress_flag(i_databaseProgressFlag) {
    this.o_importDatabaseProgressFlag = i_databaseProgressFlag;
  }

  a_import_set_database_progress_current_capture_index_being_imported(i_databaseProgressCurrentCaptureIndexBeingImported) {
    this.o_importDatabaseProgressCurrentCaptureIndexBeingImported = i_databaseProgressCurrentCaptureIndexBeingImported;
  }

  a_import_set_database_progress_manual_cancel_recursion_flag_tf(i_newValueTF) {
    this.o_importDatabaseManualCancelRecursionFlagTF = i_newValueTF;
  }

  a_import_update_captures_success_skipped_failures_obj(i_successCaptureIDOrArrayOfIDsOrUndefined, i_skippedCaptureIDOrArrayOfIDsOrUndefined, i_failuresCaptureIDOrArrayOfIDsOrUndefined) {
    const o_importCapturesSuccessSkippedFailuresObj = this.o_importCapturesSuccessSkippedFailuresObj;

    const inputSuccessTF = (i_successCaptureIDOrArrayOfIDsOrUndefined !== undefined);
    const inputSkippedTF = (i_skippedCaptureIDOrArrayOfIDsOrUndefined !== undefined);
    const inputFailuresTF = (i_failuresCaptureIDOrArrayOfIDsOrUndefined !== undefined);

    var updatedSuccessCaptureIDsArray = [];
    var updatedSkippedCaptureIDsArray = [];
    var updatedFailuresCaptureIDsArray = [];
    if(inputSuccessTF || inputSkippedTF || inputFailuresTF) { //if all 3 inputs are undefined, reset obj completely with empty arrays, otherwise append the input to the appropriate array in the obj
      updatedSuccessCaptureIDsArray = o_importCapturesSuccessSkippedFailuresObj.successCaptureIDsArray;
      updatedSkippedCaptureIDsArray = o_importCapturesSuccessSkippedFailuresObj.skippedCaptureIDsArray;
      updatedFailuresCaptureIDsArray = o_importCapturesSuccessSkippedFailuresObj.failuresCaptureIDsArray;

      if(inputSuccessTF) {
        updatedSuccessCaptureIDsArray = JSFUNC.concat_arrays_or_values_into_new_array(o_importCapturesSuccessSkippedFailuresObj.successCaptureIDsArray, i_successCaptureIDOrArrayOfIDsOrUndefined);
      }
      else if(inputSkippedTF) {
        updatedSkippedCaptureIDsArray = JSFUNC.concat_arrays_or_values_into_new_array(o_importCapturesSuccessSkippedFailuresObj.skippedCaptureIDsArray, i_skippedCaptureIDOrArrayOfIDsOrUndefined);
      }
      else if(inputFailuresTF) {
        updatedFailuresCaptureIDsArray = JSFUNC.concat_arrays_or_values_into_new_array(o_importCapturesSuccessSkippedFailuresObj.failuresCaptureIDsArray, i_failuresCaptureIDOrArrayOfIDsOrUndefined);
      }
    }

    this.o_importCapturesSuccessSkippedFailuresObj = {
      successCaptureIDsArray: updatedSuccessCaptureIDsArray,
      skippedCaptureIDsArray: updatedSkippedCaptureIDsArray,
      failuresCaptureIDsArray: updatedFailuresCaptureIDsArray
    };
  }

  generate_success_skipped_failures_text_lines(i_separatorComma0LineDashes1) {
    const o_importCapturesImportTrueUpdateFalse = this.o_importCapturesImportTrueUpdateFalse;
    const o_importCapturesSuccessSkippedFailuresObj = this.o_importCapturesSuccessSkippedFailuresObj;

    var separator = ", ";
    if(i_separatorComma0LineDashes1 === 1) {
      separator = "\n\n-----------------------------\n";
    }

    const importedUpdatedText = ((o_importCapturesImportTrueUpdateFalse) ? ("Imported") : ("Updated"));
    const importingUpdatingText = ((o_importCapturesImportTrueUpdateFalse) ? ("Importing") : ("Updating"));
    const importUpdateText = ((o_importCapturesImportTrueUpdateFalse) ? ("Import") : ("Update"));

    var textLines = "";

    if(JSFUNC.is_array(o_importCapturesSuccessSkippedFailuresObj.successCaptureIDsArray)) {
      const numSuccess = o_importCapturesSuccessSkippedFailuresObj.successCaptureIDsArray.length;
      if(numSuccess > 0) {
        textLines += "Successfully " + importedUpdatedText + " " + numSuccess + " " + JSFUNC.plural(numSuccess, "Capture", "Captures") + " (Capture IDs: " + o_importCapturesSuccessSkippedFailuresObj.successCaptureIDsArray.join(", ") + ")";
      }
    }
    
    if(JSFUNC.is_array(o_importCapturesSuccessSkippedFailuresObj.skippedCaptureIDsArray)) {
      const numSkipped = o_importCapturesSuccessSkippedFailuresObj.skippedCaptureIDsArray.length;
      if(numSkipped > 0) {
        if(textLines !== "") { textLines += separator; }
        textLines += "Skipped " + importingUpdatingText + " " + numSkipped + " " + JSFUNC.plural(numSkipped, "Capture", "Captures") + " (.csv Row #'s: " + o_importCapturesSuccessSkippedFailuresObj.skippedCaptureIDsArray.join(", ") + ")";
      }
    }
    
    if(JSFUNC.is_array(o_importCapturesSuccessSkippedFailuresObj.failuresCaptureIDsArray)) {
      const numFailures = o_importCapturesSuccessSkippedFailuresObj.failuresCaptureIDsArray.length;
      if(numFailures > 0) {
        if(textLines !== "") { textLines += separator; }
        textLines += "Failed to " + importUpdateText + " " + numFailures + " " + JSFUNC.plural(numFailures, "Capture", "Captures") + " (Capture IDs: " + o_importCapturesSuccessSkippedFailuresObj.failuresCaptureIDsArray.join(", ") + ")";
      }
    }

    return(textLines);
  }

  a_import_recursive_import_captures_using_current_settings() {
    const currentCaptureIndex = this.o_importDatabaseProgressCurrentCaptureIndexBeingImported;
    const o_importDatabaseManualCancelRecursionFlagTF = this.o_importDatabaseManualCancelRecursionFlagTF;
    const c_importAnalyzeCapturesReturnToTabDbNameWhenFinished = this.c_importAnalyzeCapturesReturnToTabDbNameWhenFinished;
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;

    //set the import state flag as currently importing
    this.a_import_set_import_database_progress_flag("importing");

    const jsDescription = JSFUNC.js_description_from_action("AdminImportMobx", "a_import_recursive_import_captures_using_current_settings", [], []);

    if(o_importDatabaseManualCancelRecursionFlagTF || (currentCaptureIndex >= this.c_importNumCapturesToImport)) { //the last capture has already been inserted (or cancel was requested), write an admin changelog entry
      //admin changelog entry
      const C_CallPhpTblUIDAdminChangelog = new JSPHP.ClassCallPhpTblUID(jsDescription);
      const adminChangeLogString = ".csv file: '" + this.o_importUploadedCsvFileName + "', Import Preset: '" + this.c_importSelectedImportPresetNamePlainTextOrUndefined + "', " + this.generate_success_skipped_failures_text_lines(0);
      C_CallPhpTblUIDAdminChangelog.add_changelog_admin(300, adminChangeLogString);
      C_CallPhpTblUIDAdminChangelog.execute();

      //set import process as finished
      if(o_importDatabaseManualCancelRecursionFlagTF) { //don't show finished floating box when cancel was pushed
        this.a_import_set_import_flag(c_importAnalyzeCapturesReturnToTabDbNameWhenFinished);
      }
      else {
        this.a_import_set_import_database_progress_flag("finished");
      }
    }
    else { //translate this capture (for the current progress capture index) and insert it into the database, then increment the capture index for the next capture imported and call the recursive self when completed
      //initialize the newCaptureObj for this Admin Import setup of field/value assignments
      var importNewCaptureObj = {}; //fill with each capture field that has a set custom or auto assign value
      var importUtilizedCustomFieldIDsArray = []; //keep track of all custom capture field mappings used in the import setup (so that any duplicated use in auto assigns is not overwritten with the auto assign values)

      //add all columns for this capture index, convert the column string values to correctly formatted (int/string/etc) capture raw values
      for(let expandedColumnObj of this.c_importColumnsArrayOfObjs) {
        if(expandedColumnObj.isMappedDetailsFieldTF && expandedColumnObj.selectedCaptureFieldExistsTF) {
          var captureExecValueRawOrUndefined = this.import_convert_csv_capture_index_and_expanded_column_obj_to_captureexec_value_raw_or_undefined(currentCaptureIndex, expandedColumnObj);
          if(captureExecValueRawOrUndefined !== undefined) {
            importNewCaptureObj[expandedColumnObj.selectedCaptureFieldDbName] = captureExecValueRawOrUndefined; //add this field/value pair to this new capture record to be inserted
            importUtilizedCustomFieldIDsArray.push(expandedColumnObj.captureFieldID);
          }
        }
      }

      //add all auto assign constant value fields (all are already in the correct captureexec raw value format ready for the database)
      for(let expandedCurrentAutoAssignObj of this.c_importExpandedCurrentAutoAssignsArrayOfObjs) {
        if(!JSFUNC.in_array(expandedCurrentAutoAssignObj.field_id, importUtilizedCustomFieldIDsArray)) { //make sure not to add any constant values that have already been used as a column assignment (should never happen, prevented by user interface)
          importNewCaptureObj[expandedCurrentAutoAssignObj.fieldDbName] = expandedCurrentAutoAssignObj.valueRaw;
        }
      }

      const adminImportNewCaptureFieldAndLogArraysObj = DatabaseMobx.create_new_capture_field_names_values_idsb_and_log_arrays_obj_from_new_capture_obj(importNewCaptureObj);
      const adminImportCaptureFieldIDsArray = adminImportNewCaptureFieldAndLogArraysObj.fieldIDsArray;
      const adminImportCaptureFieldDbNamesArray = adminImportNewCaptureFieldAndLogArraysObj.fieldDbNamesArray;
      const adminImportCaptureRawValuesArray = adminImportNewCaptureFieldAndLogArraysObj.rawValuesArray;
      const adminImportCaptureIdsbArray = adminImportNewCaptureFieldAndLogArraysObj.idsbArray;
      const newCaptureSelectedPrimeContactCompanyID = adminImportNewCaptureFieldAndLogArraysObj.newCaptureSelectedPrimeContactCompanyID;
      const newCaptureOurPrimeSubTeammateAllocationPercent0to100 = adminImportNewCaptureFieldAndLogArraysObj.newCaptureOurPrimeSubTeammateAllocationPercent0to100;

      //open the tblUID database php call to insert the single new capture into tbl_captures
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //insert the single new capture record with all column assign, constant value assign, and default values
      C_CallPhpTblUID.add_insert("tbl_captures", adminImportCaptureFieldDbNamesArray, adminImportCaptureRawValuesArray, adminImportCaptureIdsbArray);

      //add a success function to get the newly inserted captureID and add it to the list of new captureIDs that goes into the admin changelog entry
      const functionOnSuccess = (i_parseResponse) => {
        const newlyInsertedCaptureID = i_parseResponse.outputObj.i0;

        //record the new captureID successfully inserted
        this.a_import_update_captures_success_skipped_failures_obj(newlyInsertedCaptureID, undefined, undefined);

        //if user integration create/update captures option switch is on, and if integration is turned on, insert the new record into the linked integration system with a newly created integration uniqueID string which is updated into the CE record as well
        if(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF) {
          AdminIntegrationsMobx.integration_insert_integration_new_opp_with_newly_created_unique_id_string_from_new_ce_capture_id(newlyInsertedCaptureID, adminImportCaptureFieldIDsArray, adminImportCaptureRawValuesArray);
        }

        //create multi inserts for other capture cards (teammates, competitors, conversations) that were mapped from the csv in the import
        const C_CallPhpTblUIDOtherCaptureCards = new JSPHP.ClassCallPhpTblUID(jsDescription);

        //add all changelog entries for this new capture
        C_CallPhpTblUIDOtherCaptureCards.add_all_new_capture_changelogs_from_new_capture_field_and_log_arrays_obj(adminImportNewCaptureFieldAndLogArraysObj, newlyInsertedCaptureID, DatabaseMobx.k_cardIDAdminImportNewCapture, "Admin Import New Capture");

        //add inserts from details fields that require the captureID to be known
        if(JSFUNC.select_int_is_filled_out_tf(newCaptureSelectedPrimeContactCompanyID)) { //if a contact company was selected for prime company (valid with ID > 0), insert a teammate record for this prime company
          var primeTeammateAllocationPercent = (100 - newCaptureOurPrimeSubTeammateAllocationPercent0to100); //new prime teammate allocation is (100 - ourAllocation)
          var fieldNamesArray = ["capture_id", "contact_company_id", "prime_01", "selected_01", "allocation_percent"];
          var valuesArray = [newlyInsertedCaptureID, newCaptureSelectedPrimeContactCompanyID, 1, 1, primeTeammateAllocationPercent];
          var idsbArray = ["i", "i", "i", "i", "d"];
          C_CallPhpTblUIDOtherCaptureCards.add_insert("tbl_c_teammates", fieldNamesArray, valuesArray, idsbArray);
        }

        //add insert records into tbl_c_teammates for every teammates mapping column found
        for(let expandedColumnObj of this.c_importColumnsArrayOfObjs) {
          if(expandedColumnObj.isMappedTeammatesTF) {
            var csvCaptureColumnValueRaw = this.c_importCsvFileDataCapturesArrayOfArrays[currentCaptureIndex][expandedColumnObj.index];
            if(csvCaptureColumnValueRaw !== undefined) { //make sure both indices are within the limits of the csv data array (always should be, if not raw value is undefined)
              var csvUniqueValueAndCustomAssignObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(expandedColumnObj.csvUniqueValuesAndCustomAssignsArrayOfObjs, "value", csvCaptureColumnValueRaw);
              if(csvUniqueValueAndCustomAssignObj !== undefined) { //the matching unique csv data raw value was found
                var teammateContactCompanyIDsComma = csvUniqueValueAndCustomAssignObj.mappedCaptureExecValueRaw;
                var teammateContactCompanyIDsArray = JSFUNC.convert_comma_list_to_int_array(teammateContactCompanyIDsComma);
                if(teammateContactCompanyIDsArray.length > 0) { //if there is at least 1 temmate to insert (leaving the value intentionally blank results in a raw value of "" with 0 teammates to insert)
                  var fieldNamesArray = ["capture_id", "contact_company_id", "prime_01", "selected_01", "allocation_percent"];
                  var valuesArrayOfArrays = [newlyInsertedCaptureID, teammateContactCompanyIDsArray, 0, 1, 0];
                  var valueIdsbArray = ["i", "i", "i", "i", "d"];
                  C_CallPhpTblUIDOtherCaptureCards.add_multi_insert("tbl_c_teammates", fieldNamesArray, valuesArrayOfArrays, valueIdsbArray);
                }
              }
            }
          }
        }

        //add insert records into tbl_c_competitors for every competitors mapping column found
        for(let expandedColumnObj of this.c_importColumnsArrayOfObjs) {
          if(expandedColumnObj.isMappedCompetitorsTF) {
            var csvCaptureColumnValueRaw = this.c_importCsvFileDataCapturesArrayOfArrays[currentCaptureIndex][expandedColumnObj.index];
            if(csvCaptureColumnValueRaw !== undefined) { //make sure both indices are within the limits of the csv data array (always should be, if not raw value is undefined)
              var csvUniqueValueAndCustomAssignObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(expandedColumnObj.csvUniqueValuesAndCustomAssignsArrayOfObjs, "value", csvCaptureColumnValueRaw);
              if(csvUniqueValueAndCustomAssignObj !== undefined) { //the matching unique csv data raw value was found
                var competitorContactCompanyIDsComma = csvUniqueValueAndCustomAssignObj.mappedCaptureExecValueRaw;
                var competitorContactCompanyIDsArray = JSFUNC.convert_comma_list_to_int_array(competitorContactCompanyIDsComma);
                var numCompetitorsToInsert = competitorContactCompanyIDsArray.length;
                if(numCompetitorsToInsert > 0) {
                  for(let competitorContactCompanyID of competitorContactCompanyIDsArray) {
                    if(competitorContactCompanyID === 987987987987) {
                      competitorContactCompanyID = -2;
                    }
                  }

                  var competitorSortNumbersArray = JSFUNC.array_fill_incrementing_x_to_y(1, numCompetitorsToInsert);
                  var fieldNamesArray = ["capture_id", "sort", "contact_company_id"];
                  var valuesArrayOfArrays = [newlyInsertedCaptureID, competitorSortNumbersArray, competitorContactCompanyIDsArray];
                  var valueIdsbArray = ["i", "i", "i"];
                  C_CallPhpTblUIDOtherCaptureCards.add_multi_insert("tbl_c_competitors", fieldNamesArray, valuesArrayOfArrays, valueIdsbArray);
                }
              }
            }
          }
        }

        //add insert records into tbl_c_shaping_answers_select for imported answers to deal shaping select questions
        for(let expandedColumnObj of this.c_importColumnsArrayOfObjs) {
          if(expandedColumnObj.isMappedDealShapingSelectTF) {
            var csvCaptureColumnValueRaw = this.c_importCsvFileDataCapturesArrayOfArrays[currentCaptureIndex][expandedColumnObj.index];
            if(csvCaptureColumnValueRaw !== undefined) { //make sure both indices are within the limits of the csv data array (always should be, if not raw value is undefined)
              var csvUniqueValueAndCustomAssignObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(expandedColumnObj.csvUniqueValuesAndCustomAssignsArrayOfObjs, "value", csvCaptureColumnValueRaw);
              if(csvUniqueValueAndCustomAssignObj !== undefined) { //the matching unique csv data raw value was found
                var mappedDealShapingSelectAnswerID = csvUniqueValueAndCustomAssignObj.mappedCaptureExecValueRaw;
                if(JSFUNC.select_int_is_filled_out_tf(mappedDealShapingSelectAnswerID)) {
                  var dealShapingQuestionPoolID = expandedColumnObj.captureFieldID; //using this import column field 'field_id' as the deal shaping question id when the mapping type is deal shaping questions
                  var fieldNamesArray = ["capture_id", "question_id", "answer_id"];
                  var valuesArray = [newlyInsertedCaptureID, dealShapingQuestionPoolID, mappedDealShapingSelectAnswerID];
                  var idsbArray = ["i", "i", "i"];
                  C_CallPhpTblUIDOtherCaptureCards.add_insert("tbl_c_shaping_answers_select", fieldNamesArray, valuesArray, idsbArray);
                }
              }
            }
          }
        }

        //add insert records into tbl_c_shaping_answers_textarea for imported answers to deal shaping textarea questions
        for(let expandedColumnObj of this.c_importColumnsArrayOfObjs) {
          if(expandedColumnObj.isMappedDealShapingTextareaTF) {
            var csvCaptureColumnValueRaw = this.c_importCsvFileDataCapturesArrayOfArrays[currentCaptureIndex][expandedColumnObj.index];
            if(JSFUNC.text_or_number_is_filled_out_tf(csvCaptureColumnValueRaw)) {
              var dealShapingQuestionPoolID = expandedColumnObj.captureFieldID; //using this import column field 'field_id' as the deal shaping question id when the mapping type is deal shaping questions
              var fieldNamesArray = ["capture_id", "question_id", "answer_text", "score0to100"];
              var valuesArray = [newlyInsertedCaptureID, dealShapingQuestionPoolID, csvCaptureColumnValueRaw, 100];
              var idsbArray = ["i", "i", "s", "i"];
              C_CallPhpTblUIDOtherCaptureCards.add_insert("tbl_c_shaping_answers_textarea", fieldNamesArray, valuesArray, idsbArray);
            }
          }
        }

        C_CallPhpTblUIDOtherCaptureCards.execute();
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      const functionOnError = () => {
        //record failure of inserting this capture (using .csv Capture index instead of any CaptureID)
        this.a_import_update_captures_success_skipped_failures_obj(undefined, undefined, currentCaptureIndex);
      }
      C_CallPhpTblUID.add_function("onError", functionOnError);

      //when finished (successful or not) go to the next capture to insert calling this function recursively
      const functionOnFinish = () => {
        //increment the capture index being worked on by 1
        const newProgressCaptureIndex = (currentCaptureIndex + 1);
        this.a_import_set_database_progress_current_capture_index_being_imported(newProgressCaptureIndex);

        //recursive call to this function with the incremented capture index
        this.a_import_recursive_import_captures_using_current_settings();
      }
      C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

      C_CallPhpTblUID.execute();
    }
  }

  a_import_recursive_update_captures_using_current_settings() {
    const o_importUploadedCsvFileName = this.o_importUploadedCsvFileName;
    const o_importUploadedCsvFileHasFirstHeaderRowTF = this.o_importUploadedCsvFileHasFirstHeaderRowTF;
    const o_importDatabaseProgressCurrentCaptureIndexBeingImported = this.o_importDatabaseProgressCurrentCaptureIndexBeingImported;
    const o_importDatabaseManualCancelRecursionFlagTF = this.o_importDatabaseManualCancelRecursionFlagTF;
    const c_importNumCapturesToImport = this.c_importNumCapturesToImport;
    const c_importAnalyzeCapturesReturnToTabDbNameWhenFinished = this.c_importAnalyzeCapturesReturnToTabDbNameWhenFinished;
    const c_importSelectedImportPresetNamePlainTextOrUndefined = this.c_importSelectedImportPresetNamePlainTextOrUndefined;
    const c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined = this.c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined;
    const c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined = this.c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined;
    const c_importExpandedCurrentAutoAssignsArrayOfObjs = this.c_importExpandedCurrentAutoAssignsArrayOfObjs;
    const c_importColumnsArrayOfObjs = this.c_importColumnsArrayOfObjs;
    const k_cardIDAdminUpdateExistingCapture = DatabaseMobx.k_cardIDAdminUpdateExistingCapture;
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;
    const c_fieldMapOfCaptureID = DatabaseMobx.c_fieldMapOfCaptureID;
    const c_fieldMapOfContractStartDate = DatabaseMobx.c_fieldMapOfContractStartDate;
    const c_fieldMapOfPeriodOfPerformance = DatabaseMobx.c_fieldMapOfPeriodOfPerformance;
    const c_fieldMapOfContractEndDate = DatabaseMobx.c_fieldMapOfContractEndDate;

    const selectedCsvColumnIsFilledOutAndMappedTF = c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedCsvColumnIsFilledOutAndMappedTF;
    const selectedExpandedColumnObjOrUndefined = c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedExpandedColumnObjOrUndefined;
    const selectedCsvColumnErrorMessage = c_importUpdateUniqueKeyFieldSelectedCsvColumnObjObjOrUndefined.selectedCsvColumnErrorMessage;

    const allCsvCapturesMappingsIsArrayTF = JSFUNC.is_array(c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined);
    const captureIDFieldID = c_fieldMapOfCaptureID.get("id");
    const contractStartDateFieldID = c_fieldMapOfContractStartDate.get("id");
    const periodOfPerformanceFieldID = c_fieldMapOfPeriodOfPerformance.get("id");
    
    //set the import state flag as currently importing
    this.a_import_set_import_database_progress_flag("importing");

    const jsDescription = JSFUNC.js_description_from_action("AdminImportMobx", "a_import_recursive_update_captures_using_current_settings", [], []);

    if(!allCsvCapturesMappingsIsArrayTF || !selectedCsvColumnIsFilledOutAndMappedTF || o_importDatabaseManualCancelRecursionFlagTF || (o_importDatabaseProgressCurrentCaptureIndexBeingImported >= c_importNumCapturesToImport)) { //update mapping not filled out (should never happen from UI), the last capture has already been updated, or cancel was requested, write an admin changelog entry
      //admin changelog entry
      const C_CallPhpTblUIDAdminChangelog = new JSPHP.ClassCallPhpTblUID(jsDescription);
      const adminChangeLogString = ".csv file: '" + o_importUploadedCsvFileName + "', Update Preset: '" + c_importSelectedImportPresetNamePlainTextOrUndefined + "', " + this.generate_success_skipped_failures_text_lines(0);
      C_CallPhpTblUIDAdminChangelog.add_changelog_admin(301, adminChangeLogString);
      C_CallPhpTblUIDAdminChangelog.execute();

      //set import process as finished
      if(o_importDatabaseManualCancelRecursionFlagTF) { //don't show finished floating box when cancel was pushed
        this.a_import_set_import_flag(c_importAnalyzeCapturesReturnToTabDbNameWhenFinished);
      }
      else {
        this.a_import_set_import_database_progress_flag("finished");
      }
    }
    else { //translate this capture (for the current progress capture index) and update it in the database, then increment the capture index for the next capture imported and call the recursive self when completed
      //initialize vars required to perform an update
      var existingCaptureID = -1;
      var updateFieldIDsArray = [];
      var updateFieldNamesArray = [];
      var updateValuesArray = [];
      var updateIdsbArray = [];
      var logDetailsFieldIDsArray = [];
      var logDetailsFieldDisplayNamesArray = [];
      var logDetailsValueMaskPlainTextChangelogsArray = [];

      //initialize vars for computed fields
      var updatedContractStartDateRawOrUndefined = undefined;
      var updatedPopMonthsRawOrUndefined = undefined;

      //find capture mapping for this capture index currently being updated in this recursive loop iteration
      const csvCaptureMappingObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_importUpdateUniqueKeyFieldAllCsvCapturesMappingsArrayOfObjsOrUndefined, "csvCaptureIndex", o_importDatabaseProgressCurrentCaptureIndexBeingImported);
      if(csvCaptureMappingObjOrUndefined !== undefined) {
        existingCaptureID = csvCaptureMappingObjOrUndefined.captureID;
        if(JSFUNC.is_number_not_nan_gt_0(existingCaptureID)) {
          //add all columns (except the mapped unique key/ID column) for this capture index, convert the column string values to correctly formatted (int/string/etc) capture raw values
          for(let expandedColumnObj of c_importColumnsArrayOfObjs) {
            if(expandedColumnObj.isMappedDetailsFieldTF && expandedColumnObj.selectedCaptureFieldExistsTF) {
              if(expandedColumnObj.captureFieldID !== selectedExpandedColumnObjOrUndefined.captureFieldID) { //do not add the mapped unique key/ID column for the update
                if(expandedColumnObj.captureFieldID !== captureIDFieldID) { //do not add CaptureID as a field that can be modified by this update call, even if it was mapped to do so
                  if(expandedColumnObj.selectedCaptureFieldFieldTypeObj !== undefined) {
                    if(expandedColumnObj.selectedCaptureFieldFieldTypeObj.idsbIsValidTF) {
                      var captureExecValueRawOrUndefined = this.import_convert_csv_capture_index_and_expanded_column_obj_to_captureexec_value_raw_or_undefined(o_importDatabaseProgressCurrentCaptureIndexBeingImported, expandedColumnObj);
                      if(captureExecValueRawOrUndefined !== undefined) {
                        var valueMaskPlainTextChangelog = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(captureExecValueRawOrUndefined, expandedColumnObj.selectedCaptureFieldFieldTypeObj);
                        
                        updateFieldIDsArray.push(expandedColumnObj.captureFieldID);
                        updateFieldNamesArray.push(expandedColumnObj.selectedCaptureFieldDbName);
                        updateValuesArray.push(captureExecValueRawOrUndefined);
                        updateIdsbArray.push(expandedColumnObj.selectedCaptureFieldFieldTypeObj.idsb);
                        logDetailsFieldIDsArray.push(expandedColumnObj.captureFieldID);
                        logDetailsFieldDisplayNamesArray.push(expandedColumnObj.selectedCaptureFieldDisplayName);
                        logDetailsValueMaskPlainTextChangelogsArray.push(valueMaskPlainTextChangelog);

                        if(expandedColumnObj.captureFieldID === contractStartDateFieldID) {
                          updatedContractStartDateRawOrUndefined = captureExecValueRawOrUndefined;
                        }
                        else if(expandedColumnObj.captureFieldID === periodOfPerformanceFieldID) {
                          updatedPopMonthsRawOrUndefined = captureExecValueRawOrUndefined;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          //add all auto assign constant value fields (all are already in the correct captureexec raw value format ready for the database)
          for(let expandedCurrentAutoAssignObj of c_importExpandedCurrentAutoAssignsArrayOfObjs) {
            if(!JSFUNC.in_array(expandedCurrentAutoAssignObj.field_id, logDetailsFieldIDsArray)) { //make sure not to add any constant values that have already been used as a column assignment (should never happen, prevented by user interface)
              if(expandedCurrentAutoAssignObj.field_id !== selectedExpandedColumnObjOrUndefined.captureFieldID) { //also make sure not to add the unique key/ID field as a constant value (UI should prevent this)
                if(expandedCurrentAutoAssignObj.field_id !== captureIDFieldID) { //do not add CaptureID as a field that can be modified by this update call, even if it was mapped to do so
                  if(expandedCurrentAutoAssignObj.fieldTypeObj !== undefined) {
                    if(expandedCurrentAutoAssignObj.fieldTypeObj.idsbIsValidTF) {
                      var valueMaskPlainTextChangelog = DatabaseMobx.value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(expandedCurrentAutoAssignObj.valueRaw, expandedCurrentAutoAssignObj.fieldTypeObj);
                      
                      updateFieldIDsArray.push(expandedCurrentAutoAssignObj.field_id);
                      updateFieldNamesArray.push(expandedCurrentAutoAssignObj.fieldDbName);
                      updateValuesArray.push(expandedCurrentAutoAssignObj.valueRaw);
                      updateIdsbArray.push(expandedCurrentAutoAssignObj.fieldTypeObj.idsb);
                      logDetailsFieldIDsArray.push(expandedCurrentAutoAssignObj.field_id);
                      logDetailsFieldDisplayNamesArray.push(expandedCurrentAutoAssignObj.fieldDisplayName);
                      logDetailsValueMaskPlainTextChangelogsArray.push(valueMaskPlainTextChangelog);

                      if(expandedCurrentAutoAssignObj.field_id === contractStartDateFieldID) {
                        updatedContractStartDateRawOrUndefined = expandedCurrentAutoAssignObj.valueRaw;
                      }
                      else if(expandedCurrentAutoAssignObj.field_id === periodOfPerformanceFieldID) {
                        updatedPopMonthsRawOrUndefined = expandedCurrentAutoAssignObj.valueRaw;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if(JSFUNC.is_number_not_nan_gt_0(existingCaptureID) && JSFUNC.is_number_not_nan_gt_0(updateFieldNamesArray.length)) { //if found matching existing captureID and updating at least 1 field in the capture
        //get old capture map for old value comparisons
        const oldCaptureMapOrUndefined = DatabaseMobx.o_tbl_captures.get(existingCaptureID);
        const oldCapturemapExistsTF = (oldCaptureMapOrUndefined !== undefined);

        //++++++++++++++++++++++++++++++++++++
        //determine if any of the fields to be updated are contract start date or PoP, if so, force an update of the computed field contract end date
        if((updatedContractStartDateRawOrUndefined !== undefined) || (updatedPopMonthsRawOrUndefined !== undefined)) {
          if(updatedContractStartDateRawOrUndefined === undefined) {
            if(oldCapturemapExistsTF) {
              updatedContractStartDateRawOrUndefined = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(oldCaptureMapOrUndefined, c_fieldMapOfContractStartDate);
            }
          }

          if(updatedPopMonthsRawOrUndefined === undefined) {
            if(oldCapturemapExistsTF) {
              updatedPopMonthsRawOrUndefined = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(oldCaptureMapOrUndefined, c_fieldMapOfPeriodOfPerformance);
            }
          }

          if((updatedContractStartDateRawOrUndefined !== undefined) && (updatedPopMonthsRawOrUndefined !== undefined)) {
            var contractEndDateValueRaw = JSFUNC.compute_end_date_from_start_date_and_num_months(updatedContractStartDateRawOrUndefined, updatedPopMonthsRawOrUndefined);
            updateFieldIDsArray.push(c_fieldMapOfContractEndDate.get("id"));
            updateFieldNamesArray.push(c_fieldMapOfContractEndDate.get("db_name"));
            updateValuesArray.push(contractEndDateValueRaw);
            updateIdsbArray.push(c_fieldMapOfContractEndDate.get("fieldTypeObj").idsb);
          }
        }
        //++++++++++++++++++++++++++++++++++++

        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
        //only keep update values that have changed 
        if(oldCapturemapExistsTF) {
          var unchangedFieldIDsArray = [];
          for(let f = 0; f < updateFieldIDsArray.length; f++) {
            var fieldID = updateFieldIDsArray[f];
            var newValueRaw = updateValuesArray[f];
            var expandedCaptureFieldMapOrUndefined = DatabaseMobx.c_tbl_captures_fields.get(fieldID);
            if(expandedCaptureFieldMapOrUndefined !== undefined) {
              var oldCaptureValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(oldCaptureMapOrUndefined, expandedCaptureFieldMapOrUndefined);
              if(newValueRaw === oldCaptureValueRaw) {
                unchangedFieldIDsArray.push(fieldID);
              }
            }
          }

          //if at least 1 update field was unchanged by the .csv new value (compared to the field value in this capture)
          if(unchangedFieldIDsArray.length > 0) {
            //build the update arrays again, not including unchanged fieldIDs
            var tempUpdateFieldIDsArray = JSFUNC.copy_array(updateFieldIDsArray);
            var tempUpdateFieldNamesArray = JSFUNC.copy_array(updateFieldNamesArray);
            var tempUpdateValuesArray = JSFUNC.copy_array(updateValuesArray);
            var tempUpdateIdsbArray = JSFUNC.copy_array(updateIdsbArray);
            var updateFieldIDsArray = [];
            var updateFieldNamesArray = [];
            var updateValuesArray = [];
            var updateIdsbArray = [];
            for(let f = 0; f < tempUpdateFieldIDsArray.length; f++) {
              if(!JSFUNC.in_array(tempUpdateFieldIDsArray[f], unchangedFieldIDsArray)) {
                updateFieldIDsArray.push(tempUpdateFieldIDsArray[f]);
                updateFieldNamesArray.push(tempUpdateFieldNamesArray[f]);
                updateValuesArray.push(tempUpdateValuesArray[f]);
                updateIdsbArray.push(tempUpdateIdsbArray[f]);
              }
            }

            //build the log arrays again, not including unchanged fieldIDs
            var tempLogDetailsFieldIDsArray = JSFUNC.copy_array(logDetailsFieldIDsArray);
            var tempLogDetailsFieldDisplayNamesArray = JSFUNC.copy_array(logDetailsFieldDisplayNamesArray);
            var tempLogDetailsValueMaskPlainTextChangelogsArray = JSFUNC.copy_array(logDetailsValueMaskPlainTextChangelogsArray);
            var logDetailsFieldIDsArray = [];
            var logDetailsFieldDisplayNamesArray = [];
            var logDetailsValueMaskPlainTextChangelogsArray = [];
            for(let f = 0; f < tempLogDetailsFieldIDsArray.length; f++) {
              if(!JSFUNC.in_array(tempLogDetailsFieldIDsArray[f], unchangedFieldIDsArray)) {
                logDetailsFieldIDsArray.push(tempLogDetailsFieldIDsArray[f]);
                logDetailsFieldDisplayNamesArray.push(tempLogDetailsFieldDisplayNamesArray[f]);
                logDetailsValueMaskPlainTextChangelogsArray.push(tempLogDetailsValueMaskPlainTextChangelogsArray[f]);
              }
            }
          }
        }
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
        
        //open the tblUID database php call to update tbl_captures
        const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

        //update the existing capture record with all column assign, constant value assign, and default values
        C_CallPhpTblUID.add_update("tbl_captures", existingCaptureID, updateFieldNamesArray, updateValuesArray, updateIdsbArray);

        //add all changelog entries for this new capture
        for(let f = 0; f < logDetailsFieldIDsArray.length; f++) {
          C_CallPhpTblUID.add_changelog_details(existingCaptureID, k_cardIDAdminUpdateExistingCapture, logDetailsFieldIDsArray[f], logDetailsFieldDisplayNamesArray[f], logDetailsValueMaskPlainTextChangelogsArray[f]);
        }

        const functionOnSuccess = () => {
          //record this captureID successfully updated
          this.a_import_update_captures_success_skipped_failures_obj(existingCaptureID, undefined, undefined);

          //if integration is turned on, update the integrated opp with each updated CE field
          if(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF) {
            AdminIntegrationsMobx.integration_update_multiple_integration_opp_fields_if_ce_fields_are_paired(existingCaptureID, updateFieldIDsArray, updateValuesArray);
          }
        }
        C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

        const functionOnError = () => {
          //record failure of updating this capture
          this.a_import_update_captures_success_skipped_failures_obj(undefined, undefined, existingCaptureID);
        }
        C_CallPhpTblUID.add_function("onError", functionOnError);
        
        //when finished (successful or not) go to the next capture to insert calling this function recursively
        const functionOnFinish = () => {
          //increment the capture index being worked on by 1
          const newProgressCaptureIndex = (o_importDatabaseProgressCurrentCaptureIndexBeingImported + 1);
          this.a_import_set_database_progress_current_capture_index_being_imported(newProgressCaptureIndex);

          //recursive call to this function with the incremented capture index
          this.a_import_recursive_update_captures_using_current_settings();
        }
        C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

        C_CallPhpTblUID.execute();
      }
      else { //skip php call and go straight to next recursive capture index
        //record this .csv Row # skipped
        var csvRowNumber = (o_importDatabaseProgressCurrentCaptureIndexBeingImported + 1);
        if(o_importUploadedCsvFileHasFirstHeaderRowTF) {
          csvRowNumber += 1;
        }
        this.a_import_update_captures_success_skipped_failures_obj(undefined, csvRowNumber, undefined);

        const newProgressCaptureIndex = (o_importDatabaseProgressCurrentCaptureIndexBeingImported + 1);
        this.a_import_set_database_progress_current_capture_index_being_imported(newProgressCaptureIndex);

        this.a_import_recursive_update_captures_using_current_settings();
      }
    }
  }

  import_convert_csv_capture_index_and_expanded_column_obj_to_captureexec_value_raw_or_undefined(i_csvCaptureIndex, i_expandedColumnObj) {
    const c_importCsvFileDataCapturesArrayOfArrays = this.c_importCsvFileDataCapturesArrayOfArrays;

    var captureExecValueRaw = undefined;
    var fieldTypeObj = i_expandedColumnObj.selectedCaptureFieldFieldTypeObj;
    if(fieldTypeObj !== undefined) { //make sure the fieldTypeObj exists
      //get the raw capture value for this particular capture index for this import column index
      var csvCaptureColumnValueRaw = c_importCsvFileDataCapturesArrayOfArrays[i_csvCaptureIndex][i_expandedColumnObj.index];
      if(csvCaptureColumnValueRaw !== undefined) { //make sure both indices are within the limits of the csv data array (always should be, if not raw value is undefined)
        //convert the csv raw value to a captureexec raw value (through custom assigns mapping or a csv string data translation)
        if(i_expandedColumnObj.selectedCaptureFieldRequiresCustomAssignmentTF) { //find the matching csv data raw value in the unique value list and get its assigned captureexec raw value
          var csvUniqueValueAndCustomAssignObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(i_expandedColumnObj.csvUniqueValuesAndCustomAssignsArrayOfObjs, "value", csvCaptureColumnValueRaw);
          if(csvUniqueValueAndCustomAssignObj !== undefined) { //the matching unique csv data raw value was found
            captureExecValueRaw = csvUniqueValueAndCustomAssignObj.mappedCaptureExecValueRaw;
          }
        }
        else { //convert dates, numbers, text
          captureExecValueRaw = this.import_convert_date_number_money_percent_type_csv_value_raw_string_into_captureexec_value_raw(csvCaptureColumnValueRaw, fieldTypeObj, i_expandedColumnObj.percentFieldMultiplyBy100TF);
        }

        //if the raw value was filled out, check specific fieldnames for further special conversion handling
        if(captureExecValueRaw !== undefined) {
          //special details fields to alter the raw value of or record selected values
          if(i_expandedColumnObj.selectedCaptureFieldDbName === "incumbent_contact_company_ids_comma") {
            captureExecValueRaw = JSFUNC.convert_all_occurances_of_string_to_different_string_in_comma_list(captureExecValueRaw, "987987987987", "-2"); //replace all occurances of 987987987987 as a selected value in the comma list with -2
          }
          else if(i_expandedColumnObj.selectedCaptureFieldDbName === "prime_contact_company_id") {
            if(captureExecValueRaw === 987987987987) { //selected "++ Our Company ++" (987987987987) special option in the contact company select
              captureExecValueRaw = -1; //set value to --No Contact Company Selected-- if 'Us' is selected as the prime contact company
            }
          }
        }
      }
    }

    return(captureExecValueRaw);
  }

  import_convert_date_number_money_percent_type_csv_value_raw_string_into_captureexec_value_raw(i_csvValueRawString, i_fieldTypeObj, i_percentFieldMultiplyBy100TF=false) {
    var convertedValueRaw = undefined;
    if(i_fieldTypeObj.valueDisplayIsDateOrDateTimeTF) { //date (date, reldate, durdate, datefromtime) or datetime
      if(i_fieldTypeObj.dateTimeTF || i_fieldTypeObj.dateLocalFromRawDateTimeUtcTF) { //datetime, dateFromRawDateTime
        convertedValueRaw = JSFUNC.convert_any_style_string_to_ce_datetime_YmdHis(i_csvValueRawString);
      }
      else { //date (dateTF, dateWithRelativeDateTF, dateWithDurationTF)
        convertedValueRaw = JSFUNC.convert_any_style_string_to_ymd_date(i_csvValueRawString);
      }
    }
    else if(i_fieldTypeObj.valueDisplayIsNumericTF) { //numeric display (intTF, decimalTF, percentTF, moneyTF)
      if(i_fieldTypeObj.valueRawIsNumericDecimalTF) { //decimal
        convertedValueRaw = JSFUNC.convert_any_style_string_to_decimal(i_csvValueRawString);
      }
      else { //int
        convertedValueRaw = JSFUNC.convert_any_style_string_to_int(i_csvValueRawString);
      }

      //if the column field is a percent (int or decimal) apply the multiply by 100 if specified by the switch
      if(i_fieldTypeObj.percentTF && i_percentFieldMultiplyBy100TF) {
        convertedValueRaw *= 100;
      }
      else if(i_fieldTypeObj.moneyTF && i_percentFieldMultiplyBy100TF) {
        convertedValueRaw *= 1000;
      }
    }
    else { //all other string types (text, textarea, website, email, phone, etc)
      convertedValueRaw = i_csvValueRawString;
    }
    return(convertedValueRaw);
  }



  a_import_recursive_import_contacts() {
    const o_importContactsIsPersonTF = this.o_importContactsIsPersonTF;
    const c_importContactsConvertedDataNoDuplicatesArrayOfObjs = this.c_importContactsConvertedDataNoDuplicatesArrayOfObjs;
    const c_contactsPersonsExtraFieldsArrayOfObjs = DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs;
    const c_contactsCompaniesExtraFieldsArrayOfObjs = DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs;

    //set the import state flag as currently importing
    this.a_import_set_import_database_progress_flag("importing");

    //set the starting import contact index to 0
    this.a_import_set_database_progress_current_capture_index_being_imported(0);

    //get the contacts tblName (companies or persons) and build the fields/idsb arrays from the fixed fields plus the extra contacts fields
    var companiesOrPersonsString = "";
    var tblName = "";
    var fieldNamesArray = [];
    var idsbArray = [];
    if(o_importContactsIsPersonTF) {
      companiesOrPersonsString = "Persons";
      tblName = "tbl_g_contacts_persons";
      fieldNamesArray = ["contact_company_id", "first_name", "last_name", "title", "email", "phone"];
      idsbArray = ["i", "s", "s", "s", "s", "s"];
      for(let contactsPersonsExtraFieldObj of c_contactsPersonsExtraFieldsArrayOfObjs) {
        fieldNamesArray.push(contactsPersonsExtraFieldObj.db_name);
        idsbArray.push("s");
      }
    }
    else {
      companiesOrPersonsString = "Companies";
      tblName = "tbl_g_contacts_companies";
      fieldNamesArray = ["org_topdiv_id", "tree_id", "legal_name", "abbreviated_name", "business_type_id", "sb_certifications_bm_set_aside_ids_comma", "capability_ids_comma", "naics_code_ids_comma"];
      idsbArray = ["i", "s", "s", "s", "i", "s", "s", "s"];
      for(let contactsCompaniesExtraFieldObj of c_contactsCompaniesExtraFieldsArrayOfObjs) {
        fieldNamesArray.push(contactsCompaniesExtraFieldObj.db_name);
        idsbArray.push("s");
      }
    }

    const copiedImportContactsConvertedDataNoDuplicatesArrayOfObjs = JSFUNC.copy_array(c_importContactsConvertedDataNoDuplicatesArrayOfObjs);
    const copiedImportNumContactsNoDuplicatesToImport = copiedImportContactsConvertedDataNoDuplicatesArrayOfObjs.length;
    const jsDescription = JSFUNC.js_description_from_action("AdminImportMobx", "a_import_recursive_import_contacts", [], []);

    this.a_import_recursive_import_contacts_recursive_internal(companiesOrPersonsString, tblName, fieldNamesArray, idsbArray, copiedImportContactsConvertedDataNoDuplicatesArrayOfObjs, copiedImportNumContactsNoDuplicatesToImport, jsDescription);
  }

  a_import_recursive_import_contacts_recursive_internal(i_companiesOrPersonsString, i_tblName, i_fieldNamesArray, i_idsbArray, i_importedContactsConvertedDataNoDuplicatesArrayOfObjs, i_importNumContactsNoDuplicatesToImport, i_jsDescription) {
    const o_importContactsIsPersonTF = this.o_importContactsIsPersonTF;
    const currentContactIndex = this.o_importDatabaseProgressCurrentCaptureIndexBeingImported;
    const o_importDatabaseManualCancelRecursionFlagTF = this.o_importDatabaseManualCancelRecursionFlagTF;

    if(o_importDatabaseManualCancelRecursionFlagTF || (currentContactIndex >= i_importNumContactsNoDuplicatesToImport)) { //the last contact has already been inserted (or cancel was requested), write an admin changelog entry
      //admin changelog entry
      const C_CallPhpTblUIDAdminChangelog = new JSPHP.ClassCallPhpTblUID(i_jsDescription);
      const adminChangelogImportContactsActionCode = ((o_importContactsIsPersonTF) ? (401) : (400));
      const importedNewContactIDsComma = JSFUNC.convert_array_to_comma_list(this.o_importCapturesSuccessSkippedFailuresObj.successCaptureIDsArray);
      const adminChangeLogString = ".csv file: '" + this.o_importUploadedCsvFileName + "', Contact " + i_companiesOrPersonsString + " Imported: " + i_importNumContactsNoDuplicatesToImport + ", New Contact IDs: [" + importedNewContactIDsComma + "]";
      C_CallPhpTblUIDAdminChangelog.add_changelog_admin(adminChangelogImportContactsActionCode, adminChangeLogString);
      C_CallPhpTblUIDAdminChangelog.execute();

      //set import process as finished
      if(o_importDatabaseManualCancelRecursionFlagTF) { //don't show finished floating box when cancel was pushed
        var importFlagTabDbNameAfterFinish = "importContactCompaniesUploadCsv";
        if(o_importContactsIsPersonTF) {
          importFlagTabDbNameAfterFinish = "importContactPersonsUploadCsv";
        }
        this.a_import_set_import_flag(importFlagTabDbNameAfterFinish);
      }
      else {
        this.a_import_set_import_database_progress_flag("finished");
      }
    }
    else { //translate this contact (for the current progress contact index) and insert it into the database, then increment the contact index for the next contact imported and call the recursive self when completed
      //48: [0-24 25-47]          - 0+25 >= 48 No  -> insert 25        - 25+25 >= 48 Yes -> insert 23 = (48%25) = 23
      //49: [0-24 25-48]          - 0+25 >= 49 No  -> insert 25        - 25+25 >= 49 Yes -> insert 24 = (49%25) = 24
      //50: [0-24 25-49]          - 0+25 >= 50 No  -> insert 25        - 25+25 >= 50 Yes -> insert 25 = (50%25) = 0 (+25)
      //51: [0-24 25-49 50-50]    - 0+25 >= 51 No  -> insert 25        - 25+25 >= 51 No  -> insert 25                        - 50+25 >= 51 Yes -> insert 1 = (51%25) = 1
      //52: [0-24 25-49 50-51]    - 0+25 >= 52 No  -> insert 25        - 25+25 >= 52 No  -> insert 25                        - 50+25 >= 52 Yes -> insert 2 = (51%25) = 2
      const maxNumSimultaneousContactInserts = 25;
      var numContactsToInsert = maxNumSimultaneousContactInserts;
      if((currentContactIndex + maxNumSimultaneousContactInserts) >= i_importNumContactsNoDuplicatesToImport) {
        numContactsToInsert = (i_importNumContactsNoDuplicatesToImport % maxNumSimultaneousContactInserts);
        if(numContactsToInsert === 0) {
          numContactsToInsert = maxNumSimultaneousContactInserts;
        }
      }

      //open the tblUID database php call to insert the single new contact into tbl_captures
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(i_jsDescription);

      for(let i = 0; i < numContactsToInsert; i++) {
        var tempSimultaneousContactIndex = (currentContactIndex + i);

        var valuesArray = [];
        for(let f = 0; f < i_fieldNamesArray.length; f++) {
          var fieldName = i_fieldNamesArray[f];
          var idsb = i_idsbArray[f];

          var newValue = i_importedContactsConvertedDataNoDuplicatesArrayOfObjs[tempSimultaneousContactIndex][fieldName];
          if(newValue === undefined) {
            if(idsb === "i") {
              newValue = -1;
            }
            else if(idsb === "s") {
              newValue = "";
            }
          }

          valuesArray.push(newValue);
        }

        //insert the single new contact record with all column assign, constant value assign, and default values
        C_CallPhpTblUID.add_insert(i_tblName, i_fieldNamesArray, valuesArray, i_idsbArray);
      }

      //add a success function to get all newly inserted contactIDs and add them to the list of new contactIDs that goes into the admin changelog entry
      const functionOnSuccess = (i_parseResponse) => {
        var allNewlyInsertedContactIDsArray = [];
        for(let i = 0; i < numContactsToInsert; i++) {
          var iPrefix = "i" + i;
          var newlyInsertedContactID = i_parseResponse.outputObj[iPrefix];
          allNewlyInsertedContactIDsArray.push(newlyInsertedContactID);
        }
        this.a_import_update_captures_success_skipped_failures_obj(allNewlyInsertedContactIDsArray, undefined, undefined);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      //when finished (successful or not) go to the next contact to insert calling this function recursively
      const functionOnFinish = () => {
        //increment the contact index being worked on by the number of simultaneous contacts inserted
        const newProgressContactIndex = (currentContactIndex + numContactsToInsert);
        this.a_import_set_database_progress_current_capture_index_being_imported(newProgressContactIndex);

        //recursive call to this function with the incremented contact index
        this.a_import_recursive_import_contacts_recursive_internal(i_companiesOrPersonsString, i_tblName, i_fieldNamesArray, i_idsbArray, i_importedContactsConvertedDataNoDuplicatesArrayOfObjs, i_importNumContactsNoDuplicatesToImport, i_jsDescription);
      }
      C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

      C_CallPhpTblUID.execute();
    }
  }


}
export default new AdminImportMobx();
